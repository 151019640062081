
 /* Copyright 2019 Square Inc.
  Licensed under the Apache License, Version 2.0 (the "License");
  you may not use this file except in compliance with the License.
  You may obtain a copy of the License at
      http://www.apache.org/licenses/LICENSE-2.0
  Unless required by applicable law or agreed to in writing, software
  distributed under the License is distributed on an "AS IS" BASIS,
  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  See the License for the specific language governing permissions and
  limitations under the License.*/


* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body, html {
    background-color: #F7F8F9;
    color: #373F4A;
    font-family: "Urbanist", sans-serif !important;
    font-weight: normal;
    height: 100%;
}

button {
    border: 0;
    font-weight: 500;
}

fieldset {
    margin: 0;
    padding: 0;
    border: 0;
}

#form-container {
    margin-top: 5px;
    position: relative;
    width: 100%;
}

.third {
    float: left;
    width: calc((100% - 32px) / 3);
    padding: 0;
    margin: 0 16px 16px 0;
}

    .third:last-of-type {
        margin-right: 0;
    }

 /*Define how SqPaymentForm iframes should look*/ 
.sq-input, #name {
    height: 48px !important;
    box-sizing: border-box !important;
    border: 1px solid #E0E2E3;
    background-color: white !important;
    border-radius: 6px !important;
    display: inline-block !important;
    -webkit-transition: border-color .2s ease-in-out !important;
    -moz-transition: border-color .2s ease-in-out !important;
    transition: border-color .2s ease-in-out !important;
    font-size: 16px !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    color: #373F4A !important;
    margin-bottom: 15px !important;
    font-weight: 400;
}

 /*Define how SqPaymentForm iframes should look when they have focus*/
.sq-input--focus {
    border: 1px solid #4A90E2;
    box-shadow: 0 0 2px 0 rgba(0,0,0,0.10), 0 2px 2px 0 rgba(0,0,0,0.10);
}

 /*Define how SqPaymentForm iframes should look when they contain invalid values*/ 
.sq-input--error {
    border: 1px solid red;
}

#sq-card-number {
    margin-bottom: 16px;
    font-family: "Urbanist", sans-serif !important;
}

 /*Customize the "Pay with Credit Card" button*/ 
.button-credit-card {
    width: 100%;
    height: 56px;
    margin-bottom: -100px;
    background: #4A90E2;
    border-radius: 6px;
    cursor: pointer;
    display: block;
    color: #FFFFFF;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    letter-spacing: 0;
    text-align: center;
}

    .button-credit-card.Edit {
        margin-bottom: 20px !important;
    }

    .button-credit-card:hover {
        background-color: #4281CB;
    }

.errormsg {
    padding-top: 5px !important;
    text-align: center;
    color: #000000;
    display: block !important;
    font-size: 12px;
    text-align: left;
}

.sqbox {
    margin-top: 0px !important;
    position: relative;
    font-family: "Urbanist", sans-serif !important;
}

#sq-expiration-date, #sq-cvv, #sq-postal-code {
    width: 30%;
    float: left;
    font-family: "Urbanist", sans-serif !important;
}

#sq-cvv {
    margin: 0 5% 0 5% !important;
}

.right-view #form-container .footer .yes_btn {
    float: left;
    margin-right: 5%;
}

.order-section .right-view input[type=text]:focus + span, .order-section .right-view input[type=text]:not(:placeholder-shown) + span {
    top: 0;
    z-index: 1;
    background: #fff;
}
#sq-ccbox span {
    display: none !important;
}

 
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@100;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700;800;900&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.12.0-2/css/all.min.css");
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,300..900;1,300..900&display=swap');

* {
    padding: 0;
    margin: 0;
    text-decoration: none;
    outline: none;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
}

body {
    background: #f4f7fc;
    font-family: "Urbanist", sans-serif;
}

// Variables
$red: #EE934A;
$black: #1d252d;
$white: #ffffff;
$dark-gray: #506d85;

@mixin nunitosans() {
    font-family: "Nunito Sans", sans-serif !important;
}

@mixin Urbanist() {
    font-family: "Urbanist", sans-serif !important;
}

@mixin firasans() {
    font-family: "Fira Sans", sans-serif !important;
}

@mixin nunito() {
    font-family: "Nunito", sans-serif !important;
}

@mixin ibmplex() {
    font-family: 'IBM Plex Sans', sans-serif;
}

@font-face {
    font-family: 'Gilroy_bold';
    src: url('./fonts/Gilroy-Bold.eot');
    src: url('./fonts/Gilroy-Bold.eot?#iefix') format('embedded-opentype'), url('./fonts/Gilroy-Bold.woff2') format('woff2'), url('./fonts/Gilroy-Bold.woff') format('woff'), url('./fonts/Gilroy-Bold.ttf') format('truetype'), url('./fonts/Gilroy-Bold.svg#Gilroy-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@mixin Gilroy_bold {
    font-family: "Gilroy_bold" !important;
}

.container_signup {
    width: 100%;
    max-width: 1260px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    flex-flow: column;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 30px;
        padding-bottom: 30px;

        a.logosec {
            display: flex;
            width: 233px;
            //height: 131px;
            align-items: center;
            justify-content: flex-start;

            img {
                width: 200px;
            }
        }

        a.btn {
            display: flex;
            align-items: center;
            margin-left: auto;
            width: 127px;
            height: 33px;
            border-radius: 8px;
            border: solid 1px #dde5ed;
            background-color: #ffffff;
            font-size: 14px;
            font-weight: 700;
            font-family: "Urbanist", sans-serif;
            color: #1d252d;
            justify-content: center;
            //margin-top: 32px;
            padding: 0;
        }
    }

    .signupbox_area {
        display: flex;
        margin-top: 30px;

        .image_area {
            width: 100%;
            max-width: 689px;
            align-items: center;

            img {
                width: 100%;
            }
        }

        .signuparea {
            margin-left: 57px;
            display: flex;
            flex-flow: column;
            width: 100%;
            max-width: 394px;

            > div.fields {
                //margin-top: -10px;

                p {
                    font-size: 24px;
                    font-weight: 800;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 24px;
                    max-width: 322px;
                    letter-spacing: normal;
                    color: #1d252d;
                    @include Urbanist;
                    margin-bottom: 30px;

                    &.paaword_area {
                        position: relative;
                        max-width: 100%;

                        i {
                            width: 22px;
                            height: 12px;
                            background: url(./images/eye_icon.png) no-repeat;
                            position: absolute;
                            right: 10px;
                            top: 50%;
                            transform: translate(0, -50%);
                            cursor: pointer;
                            background-size: 100%;

                            &.active {
                                background: url(./images/eye_selected.png) no-repeat;
                                background-size: 100%;
                            }
                        }

                        > span {
                            color: #000000 !important;
                            @include Urbanist;
                            font-size: 14px !important;
                            line-height: normal;
                            font-weight: 400 !important;
                            top: 50%;
                            transform: translate(0, -50%);
                            position: absolute;
                            left: 17px;
                            display: flex;
                            width: auto;
                            padding-left: 3.5px;
                            padding-right: 3.5px;
                            background: #f4f7fc;
                            transition: all 0.16s linear;
                            //z-index: -1;
                        }
                    }

                    &.errors {
                        position: relative;
                        /* input[type="text"], input[type="email"] {
                            border-color: #EE934A !important;
                        }*/
                        span {
                            top: 0;
                            z-index: 1;

                            & + span {
                                top: 50%;
                                color: #000000 !important;
                                z-index: 1;
                                padding-left: 25px;
                            }
                        }

                        &:before {
                            content: "!";
                            width: 16px;
                            height: 16px;
                            border-radius: 50%;
                            background: #000000;
                            display: flex;
                            color: #fff;
                            align-items: center;
                            justify-content: center;
                            font-size: 13px;
                            position: absolute;
                            top: 50%;
                            transform: translate(0, -50%);
                            left: 20px;
                            z-index: 9;
                        }
                    }
                }

                > div {
                    position: relative;

                    > span {
                        color: #000000 !important;
                        @include Urbanist;
                        font-size: 14px !important;
                        line-height: normal;
                        font-weight: 400 !important;
                        top: 50%;
                        transform: translate(0, -50%);
                        position: absolute;
                        left: 17px;
                        display: flex;
                        width: auto;
                        padding-left: 3.5px;
                        padding-right: 3.5px;
                        background: #f4f7fc;
                        transition: all 0.16s linear;
                        //z-index: -1;
                    }

                    &.errors {
                        position: relative;
                        /* input[type="text"], input[type="email"] {
                            border-color: #EE934A !important;
                        }*/
                        span {
                            top: 0;
                            z-index: 1;

                            & + span {
                                top: 50%;
                                color: #000000 !important;
                                z-index: 1;
                                padding-left: 25px;
                            }
                        }

                        &:before {
                            content: "!";
                            width: 16px;
                            height: 16px;
                            border-radius: 50%;
                            background: #000000;
                            display: flex;
                            color: #fff;
                            align-items: center;
                            justify-content: center;
                            font-size: 13px;
                            position: absolute;
                            top: 50%;
                            transform: translate(0, -50%);
                            left: 20px;
                            z-index: 9;
                        }
                    }
                }

                input {
                    &[type="text"],
                    &[type="password"],
                    &[type="email"] {
                        width: 100%;
                        height: 52px;
                        display: flex;
                        align-items: center;
                        border-radius: 8px;
                        border: solid 1.1px #dde5ed;
                        background-color: transparent;
                        margin-bottom: 30px;
                        padding-left: 21px;

                        &:focus,
                        &:not(:placeholder-shown) {
                            & + span {
                                top: 0;
                                z-index: 1;
                            }
                        }
                    }
                }

                .save_continue {
                    width: 186px;
                    height: 58px;
                    border-radius: 8px;
                    background-color: #EE934A;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    @include Urbanist;
                    font-size: 16px;
                    font-weight: 700;
                    cursor: pointer;
                    margin-bottom: 63px;
                    cursor: pointer;
                    position: relative;
                    transition: all .2s ease-in-out;
                    background-image: radial-gradient(circle at bottom right, #F1857F 1%, #EE934A 100%);

                    &.loader {
                        pointer-events: none;
                        position: relative;
                        display: flex;
                        align-items: center;
                        transition: all .2s ease-in-out;
                        width: auto;
                        max-width: 200px;
                        justify-content: space-around;
                        max-width: 190px;

                        &:after {
                            content: "";
                            border: 4px solid #EE934A;
                            border-radius: 50%;
                            border-top: 4px solid #fff;
                            width: 25px;
                            height: 25px;
                            -webkit-animation: .5s linear infinite spin;
                            animation: .5s linear infinite spin;
                            position: relative;
                            right: 10px;
                            order: 2;
                        }
                    }

                    &.widthloader {
                        transition: all .2s ease-in-out;
                        width: 230px;
                    }
                }

                .forgotpass_link {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 24px;
                    margin-bottom: 56px;

                    > .checkbox_sec {
                        display: flex;
                        align-items: center;

                        input {
                            display: none;

                            & + label {
                                @include Urbanist;
                                font-size: 14px;
                                font-weight: 700;
                                color: #444444;
                                display: flex;
                                align-items: center;
                                cursor: pointer;

                                span {
                                    width: 20px;
                                    height: 20px;
                                    border-radius: 4px;
                                    border: solid 1px #8ba6c1;
                                    background-color: #ffffff;
                                    margin-right: 11px;
                                    position: relative;
                                }
                            }

                            &:checked {
                                & + label {
                                    span {
                                        background: #8ba6c1;

                                        &:after {
                                            content: "";
                                            transform: rotate(45deg);
                                            height: 12px;
                                            width: 5px;
                                            border-bottom: 3px solid #fff;
                                            border-right: 3px solid #fff;
                                            position: absolute;
                                            left: 0;
                                            right: 0;
                                            margin: 0 auto;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    > .forgot_pass_btn {
                        @include Urbanist;
                        font-size: 14px;
                        font-weight: 700;
                        color: #222222;
                        cursor: pointer;
                    }
                }

                .already_link {
                    @include Urbanist;
                    font-size: 14px;
                    font-weight: 700;
                    color: #000000;
                    cursor: pointer;
                    display: flex;

                    > div {
                        color: #000000;
                        text-decoration: none;
                        margin-left: 10px;
                    }
                }
            }

            > .welcome_signup {
                display: flex;
                flex-flow: column;

                img {
                    width: 82px;
                    height: 77px;
                    margin-top: 28px;
                    margin-bottom: 40px;
                }

                p {
                    @include Urbanist;
                    font-size: 24px;
                    font-weight: 800;
                    color: #1d252d;
                    max-width: 372px;
                }

                .btn {
                    width: 186px;
                    height: 58px;
                    border-radius: 8px;
                    background-color: #EE934A;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 34px;
                    color: #fff;
                    font-size: 16px;
                    font-weight: 700;
                    cursor: pointer;
                    @include Urbanist;
                    background-image: radial-gradient(circle at bottom right, #F1857F 1%, #EE934A 100%);
                }
            }
        }
    }
}

.Dashboard_Area {
    display: flex;
    background: #f4f7fc;
}

.left_sidebar {
    display: flex;
    height: 100vh;
    min-width: 272px;
    max-width: 272px;
    box-shadow: 0 2px 14px 0 rgba(139, 166, 193, 0.2);
    background-color: #ffffff;
    z-index: 10;
    padding: 20px 15px 0 37px;
    flex-flow: column;
    position: fixed;

    .Logo_img {
        align-items: center;

        &.consumer {
            img {
                width: 100%;
            }
        }

        img {
            width: 100%;
            max-width: 200px;
        }
    }

    .navbar {
        margin-top: 25px;

        @media screen and (min-width:1100px) and (max-width:1440px) {
            margin-top: 22px;
        }

        ul {
            list-style: none;

            li {
                list-style: none;
                display: flex;
                align-items: center;
                min-height: 51px;
                max-height: 51px;
                @include Urbanist;
                font-size: 16px;
                font-weight: 700;
                color: #000000;
                cursor: pointer;

                @media screen and (min-width:1100px) and (max-width:1440px) {
                    min-height: 48px;
                    max-height: 48px;
                }

                a {
                    display: flex;
                    width: 100%;
                    align-items: center;
                    @include Urbanist;
                    font-size: 16px;
                    font-weight: 700;
                    color: #000000;
                    cursor: pointer;

                    > div {
                        max-width: 30px;
                        min-width: 30px;
                        margin-right: 20px;


                        img {
                            width: auto;
                            max-width: 100%;
                        }
                    }
                }

                > div {
                    max-width: 30px;
                    min-width: 30px;
                    margin-right: 20px;


                    img {
                        width: auto;
                        max-width: 100%;
                    }
                }

                &.active {
                    color: #EE934A;

                    a {
                        color: #EE934A;
                    }
                }

                &.summary-link {
                    div {
                        background: url(./images/summary_inactive.svg) no-repeat;
                        background-size: 28px 29px;
                        height: 29px;
                    }

                    &.active {
                        a {
                            div {
                                background: url(./images/summary_active.svg) no-repeat;
                                background-size: 28px 29px;
                                height: 29px;
                            }
                        }
                    }
                }

                &.training-link {
                    div {
                        background: url(./images/training_icon.svg) no-repeat;
                        background-size: 26px 26px;
                        height: 25px;
                    }

                    &.active {
                        a {
                            div {
                                background: url(./images/training_icon_active.svg) no-repeat;
                                background-size: 26px 26px;
                                height: 25px;
                            }
                        }
                    }
                }

                &.sharecard-link {
                    div {
                        background: url(./images/share-card.svg) no-repeat;
                        background-size: 26px 26px;
                        height: 26px;
                    }

                    &.active {
                        a {
                            div {
                                background: url(./images/share-card-active.svg) no-repeat;
                                background-size: 26px 26px;
                                height: 26px;
                            }
                        }
                    }
                }

                &.affiliate-link {
                    div {
                        background: url(./images/affiliate_info_inactive.png) no-repeat;
                        background-size: 26px 26px;
                        height: 25px;
                    }

                    &.active {
                        a {
                            div {
                                background: url(./images/affiliate_url_active.svg) no-repeat;
                                background-size: 26px 26px;
                                height: 25px;
                            }
                        }
                    }
                }

                &.transactions-link {
                    div {
                        background: url(./images/dashboard.svg) no-repeat;
                        background-size: 26px 26px;
                        height: 25px;
                    }

                    &.active {
                        a {
                            div {
                                background: url(./images/dashboard_active.svg) no-repeat;
                                background-size: 26px 26px;
                                height: 25px;
                            }
                        }
                    }
                }

                &.dashboard-link {
                    div {
                        background: url(./images/claims_dashboard.svg) no-repeat;
                        background-size: 27px 30px;
                        height: 30px;
                    }

                    &.active {
                        a {
                            div {
                                background: url(./images/claims_dashboard_active.svg) no-repeat;
                                background-size: 27px 30px;
                                hheight: 30px;
                            }
                        }
                    }
                }

                &.cards-link {
                    div {
                        background: url(./images/order_cards.svg) no-repeat;
                        background-size: 30px 25px;
                        height: 25px;
                    }

                    &.active {
                        a {
                            div {
                                background: url(./images/order_cards_active.svg) no-repeat;
                                background-size: 30px 25px;
                                height: 25px;
                            }
                        }
                    }
                }

                &.account-link {
                    div {
                        background: url(./images/account_info.svg) no-repeat;
                        background-size: 24px 24px;
                        height: 24px;
                    }

                    &.active {
                        a {
                            div {
                                background: url(./images/account_info_active.svg) no-repeat;
                                background-size: 24px 24px;
                                height: 24px;
                            }
                        }
                    }
                }

                &.faq-link {
                    div {
                        background: url(./images/faq.svg) no-repeat;
                        background-size: 30px 30px;
                        height: 30px;
                    }

                    &.active {
                        a {
                            div {
                                background: url(./images/faq_active.svg) no-repeat;
                                background-size: 30px 30px;
                                height: 30px;
                            }
                        }
                    }
                }
                /*&:nth-child(7) {
                    div {
                        background: url(./images/compliance.svg) no-repeat;
                        background-size: 30px 30px;
                        height: 30px;
                    }

                    &.active {
                        a {
                            div {
                                background: url(./images/compliance_active.svg) no-repeat;
                                background-size: 30px 30px;
                                height: 30px;
                            }
                        }
                    }
                }*/

                &.timi-link {
                    div {
                        background: url(./images/timi-grey-icon.png) no-repeat;
                        background-size: 24px 24px;
                        height: 24px;
                    }

                    &.active {
                        a {
                            div {
                                background: url(./images/timi-icon-active.png) no-repeat;
                                background-size: 24px 24px;
                                height: 24px;
                            }
                        }
                    }
                }

                &.logout-link {
                    div {
                        background: url(./images/logout.svg) no-repeat;
                        background-size: auto 30px;
                        height: 30px;
                    }
                }
            }
        }

        &.admin_navbar {
            ul {
                li {
                    &:first-child {
                        div {
                            background: url(./images/group_no_inactive.svg) no-repeat;
                            background-size: 24px 23px;
                            height: 23px;
                        }

                        &.active {
                            a {
                                div {
                                    background: url(./images/group_no_active.svg) no-repeat;
                                    background-size: 24px 23px;
                                    height: 23px;
                                }
                            }
                        }
                    }

                    &:nth-child(3) {
                        div {
                            background: url(./images/users_inactive.svg) no-repeat;
                            background-size: 20px 23px;
                            height: 23px;
                        }

                        &.active {
                            a {
                                div {
                                    background: url(./images/users_active.svg) no-repeat;
                                    background-size: 20px 23px;
                                    height: 23px;
                                }
                            }
                        }
                    }

                    &:nth-child(4) {
                        div {
                            background: url(./images/report_inactive.svg) no-repeat;
                            background-size: 24px 23px;
                            height: 23px;
                        }

                        &.active {
                            a {
                                div {
                                    background: url(./images/report_active.svg) no-repeat;
                                    background-size: 24px 23px;
                                    height: 23px;
                                }
                            }
                        }
                    }

                    &:nth-child(5) {
                        div {
                            background: url(./images/dashboard.svg) no-repeat;
                            background-size: 24px 23px;
                            height: 23px;
                        }

                        &.active {
                            a {
                                div {
                                    background: url(./images/dashboard_active.svg) no-repeat;
                                    background-size: 24px 23px;
                                    height: 23px;
                                }
                            }
                        }
                    }

                    &:nth-child(7) {
                        div {
                            background: url(./images/pricing_inactive.svg) no-repeat;
                            background-size: 24px 23px;
                            height: 23px;
                        }

                        &.active {
                            a {
                                div {
                                    background: url(./images/pricing_active.svg) no-repeat;
                                    background-size: 24px 23px;
                                    height: 23px;
                                }
                            }
                        }
                    }

                    &:nth-child(8) {
                        div {
                            background: url(./images/order_cards.svg) no-repeat;
                            background-size: 24px 23px;
                            height: 23px;
                        }

                        &.active {
                            a {
                                div {
                                    background: url(./images/order_cards_active.svg) no-repeat;
                                    background-size: 24px 23px;
                                    height: 23px;
                                }
                            }
                        }
                    }

                    &:nth-child(9) {
                        div {
                            background: url(./images/sec-link-gray.png) no-repeat;
                            background-size: 24px 23px;
                            height: 23px;
                        }

                        &.active {
                            a {
                                div {
                                    background: url(./images/sec-link-red.png) no-repeat;
                                    background-size: 24px 23px;
                                    height: 23px;
                                }
                            }
                        }
                    }

                    &.logout {
                        div {
                            background: url(./images/logout.svg) no-repeat;
                            background-size: auto 30px;
                            height: 30px;
                        }
                    }
                }
            }
        }
    }
}

.contentarea {
    width: calc(100% - 272px);
    margin-left: 272px;

    &.none_event {
        pointer-events: none;
    }



    .Header_Area {
        position: relative;
        width: 100%;
        height: 80px;
        padding: 16px 50px;
        box-shadow: 0 1px 7px 0 rgba(139, 166, 193, 0.07);
        background-color: #ffffff;
        margin-left: auto;
        display: flex;
        align-items: center;

        .searchbar {
            width: 100%;
            max-width: 563px;
            height: 48px;
            border-radius: 4px;
            border: solid 1px #dde5ed;
            background-color: #f5f8fa;
            display: flex;
            align-items: center;
            padding: 0 14px;

            img {
                max-width: 26px;
                margin-right: 10px;
            }

            input {
                width: calc(100% - 65px);
                background: transparent;
                border: none;
                min-height: 26px;
                max-height: 26px;
                outline: none;
                @include Urbanist;
                font-weight: 500;
                color: #8ba6c1;
                font-size: 14px;

                &::placeholder {
                    @include Urbanist;
                    font-weight: 500 !important;
                    color: #8ba6c1 !important;
                    font-size: 14px !important;
                }
            }
        }

        .profile_top_section {
            margin-left: auto;
            display: flex;
            align-items: center;
            cursor: pointer;

            .profile_image_name {
                width: 40px;
                height: 40px;
                background-color: #EE934A;
                display: flex;
                align-items: center;
                justify-content: center;
                @include Urbanist;
                font-size: 16px;
                font-weight: 800;
                border-radius: 50%;
                margin-right: 15px;
                color: #ffffff;
            }

            .profile_name_area {
                display: flex;
                flex-flow: column;
                align-items: flex-start;
                justify-content: space-between;
                margin-right: 15px;

                p {
                    @include Urbanist;
                    font-size: 16px;
                    font-weight: 700;
                    color: #1d252d;
                }

                span {
                    @include Urbanist;
                    font-size: 12px;
                    font-weight: 400;
                    color: #1d252d;
                }
            }

            &.admin {
                position: relative;
                padding-right: 10px;

                .profile_name_area {
                    &::after {
                        content: "";
                        width: 0px;
                        height: 0px;
                        border-left: 5px solid transparent;
                        border-right: 5px solid transparent;
                        border-top: 5px solid #1d252d;
                        position: absolute;
                        top: 18px;
                        right: 0px;
                    }
                }

                .menu {
                    top: 55px;
                    background: #fff;
                    padding: 15px 10px;
                    min-height: 50px;
                    font-size: 16px;
                    font-weight: 400;
                    width: 100%;
                    height: auto;
                    max-width: 310px;
                    max-height: 295px;
                    box-shadow: 0 2px 24px 0 rgba(0,0,0,.05), 0 9px 24px 0 rgba(0,0,0,.2);
                    border: 1px solid rgba(80,109,133,.42);
                    background-color: #fff;
                    display: none;
                    flex-flow: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    position: absolute;
                    right: 0;
                    bottom: auto;
                    left: auto;
                    border-radius: 6px;
                    padding-left: 15px;
                    padding-bottom: 15px;
                    z-index: 99999;

                    &.open {
                        display: flex;
                    }

                    &:before {
                        content: "";
                        width: 14px;
                        height: 14px;
                        max-width: 14px;
                        max-height: 14px;
                        background: #fff;
                        border: 1px solid rgba(80,109,133,.42);
                        position: absolute;
                        top: -8px;
                        right: auto;
                        bottom: auto;
                        left: 14px;
                        -webkit-transform: rotate(45deg);
                        transform: rotate(45deg);
                        border-bottom: none;
                        border-right: none;
                    }

                    p {
                        justify-content: center;
                        @include Urbanist;
                        font-size: 12px;
                        font-weight: 400;
                    }

                    ul {
                        list-style: none;

                        li {
                            justify-content: center;
                            @include Urbanist;
                            font-size: 16px;
                            font-weight: 400;
                            cursor: pointer;
                            background: url(https://www.nulifespanrx.com/assets/images/change_pass.svg) no-repeat;
                            padding-left: 30px;
                            line-height: 26px;
                            margin-top: 10px;
                        }
                    }
                }
            }
        }
    }

    .content_sec {
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 28px;
        padding-bottom: 66px;
        position: relative;

        .manage-content {
            padding-right: 35px;
        }

        .back-button {
            cursor: pointer;
        }

        h2 {
            @include Urbanist;
            font-size: 28px;
            font-weight: 800;
            color: #1d252d;
            line-height: normal;
            margin-bottom: 14px;
        }

        .info_paragraph {
            padding: 0;
            margin: 0;
            @include Urbanist;
            font-weight: 400;
            color: #000000;
            line-height: 19px;
            max-width: 1092px;
            margin-bottom: 16px;
            font-size: 14px;
        }

        .affiliate_url_sec {
            display: flex;
            flex-flow: row wrap;

            .add_sec {
                width: 304px;
                margin-right: 16px;
                position: relative;
                @include Urbanist;
                font-size: 28px;
                font-weight: 800;
                color: #fff;
                background: #EE934A;
                border-radius: 8px;

                img {
                    position: absolute;
                    max-width: 100%;
                    bottom: 0;
                }

                p {
                    position: absolute;
                    top: 44px;
                    left: 40px;
                    z-index: 9;
                    right: 31px;
                }
            }

            .refferals_list {
                width: 100%;
                margin-top: 16px;
            }
        }

        .url_card_sec {
            width: calc(100% - 320px);

            .card {
                border-radius: 8px;
                box-shadow: 0 1px 7px 0 rgba(139, 166, 193, 0.07);
                background-color: #ffffff;
                padding: 50px 40px;
                display: flex;
                margin-bottom: 16px;



                .id_box {
                    display: flex;
                    flex-flow: column;
                    margin-right: 10%;
                }

                p {
                    @include Urbanist;
                    font-size: 20px;
                    color: #000000;
                    font-weight: 400;
                    margin-bottom: 10px;
                }

                span {
                    @include Urbanist;
                    font-size: 22px;
                    font-weight: 700;
                    color: #1d252d;
                }

                .btn {
                    margin-left: auto;
                    width: 140px;
                    height: 58px;
                    border-radius: 8px;
                    background-color: #2babe2;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @include Urbanist;
                    font-size: 16px;
                    font-weight: 700;
                    color: #fff;
                    cursor: pointer;
                }

                &.pricing_tool_box {
                    padding: 0;

                    .requesting_box {
                        display: flex;
                        align-items: center;
                        min-height: 160px;
                        max-height: 160px;
                        width: 100%;

                        p {
                            font-weight: 400;
                            font-size: 20px;
                            @include Urbanist;
                            color: #000000;
                            padding-left: 41px;
                        }

                        .btn_box {
                            margin-left: auto;
                            display: flex;
                            align-items: center;

                            .text_request {
                                font-size: 16px;
                                font-weight: 700;
                                @include Urbanist;
                                color: #fff;
                                height: 56px;
                                line-height: 56px;
                                background: #000000;
                                margin-right: 32px;
                                cursor: pointer;
                                padding-left: 20px;
                                padding-right: 20px;
                                border-radius: 8px;

                                a {
                                    font-size: 16px;
                                    font-weight: 700;
                                    @include Urbanist;
                                    color: #fff;
                                    cursor: pointer;
                                }
                            }

                            &.rejected {
                                .text_request {
                                    cursor: default;
                                    color: #000000;
                                }
                            }

                            .add_url_btn {
                                margin-left: auto;
                                width: 135px;
                                height: 40px;
                                border-radius: 4px;
                                box-shadow: 0 1px 7px 0 rgba(139, 166, 193, 0.07);
                                border: solid 1px #000000;
                                background-color: transparent;
                                @include Urbanist;
                                font-weight: 700;
                                font-size: 14px;
                                color: #000000;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                cursor: pointer;
                                margin-right: 40px;
                                pointer-events: none;
                                opacity: .5;
                            }

                            .btn_setup {
                                width: 160px;
                                height: 56px;
                                border-radius: 8px;
                                background-color: #EE934A;
                                cursor: pointer;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                margin-right: 40px;
                                color: #fff;
                                @include Urbanist;
                                font-size: 16px;
                                font-weight: 700;
                                pointer-events: fill;

                                &.disabled {
                                    background-color: rgba(249, 67, 48, .5);
                                    pointer-events: none;
                                }
                            }
                        }
                    }

                    .requestbox_popup {
                        position: fixed;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        background-color: rgba(29, 37, 45, .5);
                        z-index: 99;

                        > div {
                            width: 390px;
                            border-radius: 4px;
                            box-shadow: 0 0 47px 0 rgba(0, 0, 0, 0.23);
                            border: solid 1px #dde5ed;
                            background-color: #ffffff;
                            position: absolute;
                            top: 50%;
                            left: 0;
                            right: 0;
                            margin: 0 auto;
                            transform: translate(0, -50%);
                            padding: 14px 24px 24px 30px;

                            .header {
                                display: flex;
                                align-items: center;
                                @include Urbanist;
                                font-weight: 700;
                                color: #1d252d;
                                font-size: 20px;
                                margin-bottom: 30px;

                                .close {
                                    cursor: pointer;
                                    margin-left: auto;

                                    img {
                                        width: 16px;
                                        height: 16px;
                                    }
                                }
                            }

                            .body {
                                p {
                                    padding: 0;
                                    margin: 0;
                                    @include Urbanist;
                                    font-weight: 400;
                                    font-size: 20px;
                                    color: #1d252d;
                                    line-height: 32px;
                                }
                            }

                            .footer {
                                margin-top: 30px;
                                display: flex;

                                > div {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    @include Urbanist;
                                    font-size: 16px;
                                    width: 88px;
                                    height: 48px;
                                    border-radius: 6px;
                                    cursor: pointer;

                                    &.yes_btn {
                                        background-color: #EE934A;
                                        color: #ffffff;
                                        background-image: radial-gradient(circle at bottom right, #F1857F 1%, #EE934A 100%);

                                        &.loader {
                                            pointer-events: none;
                                            position: relative;
                                            transition: all .2s ease-in-out;

                                            &:before {
                                                content: "";
                                                border: 4px solid #EE934A;
                                                border-radius: 50%;
                                                border-top: 4px solid #fff;
                                                width: 25px;
                                                height: 25px;
                                                -webkit-animation: .5s linear infinite spin;
                                                animation: .5s linear infinite spin;
                                                position: absolute;
                                                right: 80px;
                                            }
                                        }
                                    }

                                    &.no_btn {
                                        border: solid 1px #dde5ed;
                                        background-color: #f5f8fa;
                                        margin-left: 16px;
                                        color: #000000;
                                    }
                                }
                            }
                        }
                    }

                    .pricing_setup_pop {
                        position: fixed;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        background-color: rgba(29, 37, 45, .5);
                        z-index: 99;

                        > div {
                            width: 406px;
                            border-radius: 4px;
                            box-shadow: 0 0 47px 0 rgba(0, 0, 0, 0.23);
                            border: solid 1px #dde5ed;
                            background-color: #ffffff;
                            position: absolute;
                            top: 50%;
                            left: 0;
                            right: 0;
                            margin: 0 auto;
                            transform: translate(0, -50%);

                            .header {
                                display: flex;
                                align-items: center;
                                @include Urbanist;
                                font-weight: 700;
                                color: #1d252d;
                                font-size: 20px;
                                padding: 13px 30px;
                                border-bottom: 1px solid #dde5ed;

                                .close {
                                    cursor: pointer;
                                    margin-left: auto;

                                    img {
                                        width: 16px;
                                        height: 16px;
                                    }
                                }
                            }

                            .body {
                                padding: 20px 30px 0 30px;

                                p {
                                    padding: 0;
                                    margin: 0;
                                    @include Urbanist;
                                    font-weight: 400;
                                    font-size: 16px;
                                    color: #000000;
                                    line-height: 24px;
                                    padding-bottom: 35px;
                                }

                                .textarea_box {
                                    position: relative;
                                    margin-bottom: 27px;

                                    span {
                                        color: #000000 !important;
                                        @include Urbanist;
                                        font-size: 14px !important;
                                        line-height: normal;
                                        font-weight: 400 !important;
                                        top: 50%;
                                        transform: translate(0, -50%);
                                        position: absolute;
                                        left: 17px;
                                        display: flex;
                                        width: auto;
                                        padding-left: 3.5px;
                                        padding-right: 3.5px;
                                        background: #fff;
                                        transition: all 0.16s linear;
                                        z-index: -1;
                                    }

                                    input {
                                        &[type="text"] {
                                            width: 100%;
                                            height: 52px;
                                            display: flex;
                                            align-items: center;
                                            border-radius: 8px;
                                            border: solid 1.1px #dde5ed;
                                            background-color: transparent;
                                            padding-left: 21px;
                                            @include Urbanist;
                                            font-size: 14px;
                                            color: #1b252e;
                                            font-weight: 700;

                                            &:focus,
                                            &:not(:placeholder-shown) {
                                                & + span {
                                                    top: 0;
                                                    z-index: 1;
                                                }
                                            }
                                        }
                                    }

                                    select {
                                        width: 100%;
                                        height: 52px;
                                        display: flex;
                                        align-items: center;
                                        border-radius: 8px;
                                        border: solid 1.1px #dde5ed;
                                        background-color: transparent;
                                        padding-left: 21px;
                                        @include Urbanist;
                                        font-size: 14px;
                                        color: #1b252e;
                                        font-weight: 700;
                                        -webkit-appearance: none;

                                        & + span {
                                            top: 0;
                                            z-index: 1;
                                        }
                                    }

                                    &.select {
                                        &:after {
                                            content: "";
                                            width: 0px;
                                            height: 0px;
                                            border-left: 5px solid transparent;
                                            border-right: 5px solid transparent;
                                            border-top: 5px solid #1d252d;
                                            display: flex;
                                            position: absolute;
                                            right: 10px;
                                            top: 50%;
                                            transform: translate(0, -50%);
                                            z-index: 9;
                                        }

                                        &.errors {
                                            position: relative;

                                            span {
                                                top: 0;
                                                z-index: 1;

                                                & + span {
                                                    top: 50%;
                                                    color: #000000 !important;
                                                    z-index: 1;
                                                    padding-left: 25px;
                                                    background: #fff;
                                                }
                                            }

                                            &:before {
                                                content: "!";
                                                width: 16px;
                                                height: 16px;
                                                border-radius: 50%;
                                                background: #000000;
                                                display: flex;
                                                color: #fff;
                                                align-items: center;
                                                justify-content: center;
                                                font-size: 13px;
                                                position: absolute;
                                                top: 50%;
                                                transform: translate(0, -50%);
                                                left: 20px;
                                                z-index: 9;
                                            }
                                        }
                                    }

                                    &.errors {
                                        position: relative;

                                        span {
                                            top: 0;
                                            z-index: 1;

                                            & + span {
                                                top: 50%;
                                                color: #000000 !important;
                                                z-index: 1;
                                                padding-left: 25px;
                                                background: #fff;
                                            }
                                        }

                                        &:before {
                                            content: "!";
                                            width: 16px;
                                            height: 16px;
                                            border-radius: 50%;
                                            background: #000000;
                                            display: flex;
                                            color: #fff;
                                            align-items: center;
                                            justify-content: center;
                                            font-size: 13px;
                                            position: absolute;
                                            top: 50%;
                                            transform: translate(0, -50%);
                                            left: 20px;
                                            z-index: 9;
                                        }
                                    }
                                }
                            }

                            .footer {
                                margin: 6px 0 30px 0;
                                display: flex;
                                padding: 0 30px;

                                > div {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    @include Urbanist;
                                    font-size: 16px;
                                    width: 96px;
                                    height: 48px;
                                    border-radius: 6px;
                                    cursor: pointer;

                                    &.yes_btn {
                                        background-color: #EE934A;
                                        color: #ffffff;
                                        transition: all .5s ease-in-out;
                                        background-image: radial-gradient(circle at bottom right, #F1857F 1%, #EE934A 100%);

                                        &.loader {
                                            pointer-events: none;
                                            position: relative;
                                            transition: all .2s ease-in-out;
                                            justify-content: space-evenly;
                                            transition: all .5s ease-in-out;
                                            width: 120px;

                                            &:after {
                                                content: "";
                                                border: 4px solid #EE934A;
                                                border-radius: 50%;
                                                border-top: 4px solid #fff;
                                                width: 25px;
                                                height: 25px;
                                                -webkit-animation: .5s linear infinite spin;
                                                animation: .5s linear infinite spin;
                                                position: relative;
                                                right: 0;
                                            }
                                        }
                                    }

                                    &.no_btn {
                                        border: solid 1px #dde5ed;
                                        background-color: #f5f8fa;
                                        margin-left: 16px;
                                        color: #000000;
                                    }
                                }
                            }
                        }
                    }

                    .setup_done_box {
                        padding: 24px 32px 40px 32px;
                        width: 100%;

                        .header {
                            @include Urbanist;
                            font-size: 20px;
                            color: #000000;
                            font-weight: 400;
                            display: flex;

                            span {
                                @include Urbanist;
                                font-size: 16px;
                                font-weight: 700;
                                color: #000000;
                                margin-left: auto;
                                cursor: pointer;
                            }
                        }

                        .body {
                            display: flex;
                            flex-flow: column;
                            width: 100%;
                            margin-top: 18px;

                            .head {
                                width: 100%;
                                display: flex;

                                > div {
                                    @include Urbanist;
                                    font-size: 14px;
                                    color: #000000;
                                    font-weight: 400;
                                }

                                .groupno_title {
                                    min-width: 120px;
                                    margin-right: 160px;
                                }
                            }

                            .content {
                                margin-top: 6px;
                                display: flex;

                                > div {
                                    color: #1d252d;
                                    font-weight: 700;
                                    @include Urbanist;
                                    font-size: 20px;

                                    &.group_no_box {
                                        min-width: 120px;
                                        margin-right: 160px;
                                        font-size: 18px;
                                    }

                                    &.url_box {
                                        display: flex;
                                        align-items: center;
                                        word-break: break-all;
                                        position: relative;
                                        font-size: 18px;

                                        img {
                                            margin-left: 11px;
                                            cursor: pointer;
                                            display: flex;
                                            width: 16px;
                                            height: 16px;
                                        }

                                        > div {
                                            position: relative;

                                            &:before {
                                                content: '';
                                                width: 0px;
                                                height: 0px;
                                                border-left: 6px solid transparent;
                                                border-right: 6px solid transparent;
                                                border-bottom: 6px solid #DDE5ED;
                                                position: absolute;
                                                bottom: -12px;
                                                right: 4px;
                                                display: none;
                                            }

                                            &:after {
                                                content: '';
                                                width: 0px;
                                                height: 0px;
                                                border-left: 5px solid transparent;
                                                border-right: 5px solid transparent;
                                                border-bottom: 5px solid #fff;
                                                position: absolute;
                                                bottom: -13px;
                                                z-index: 999;
                                                display: none;
                                                right: 5px;
                                            }

                                            span {
                                                position: absolute;
                                                bottom: 0;
                                                padding-left: 10px;
                                                padding-right: 10px;
                                                background: #FFFFFF;
                                                border: 1px solid #DDE5ED;
                                                box-shadow: 0 2px 14px #8ba6c126;
                                                min-width: max-content;
                                                font-style: normal;
                                                padding-top: 5px;
                                                padding-bottom: 5px;
                                                border-radius: 4px;
                                                color: #000000;
                                                font-family: "Urbanist", sans-serif !important;
                                                font-size: 12px;
                                                font-weight: 400;
                                                bottom: -40px;
                                                right: -10px;
                                                display: none !important;
                                                z-index: 9;
                                            }

                                            &:hover {
                                                &:before, &:after, span {
                                                    display: flex !important;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                &.affiliate_refferal {
                    padding: 17px 26px 40px 32px;
                    display: flex;
                    flex-flow: column;
                    width: 100%;

                    .card_header {
                        display: flex;
                        width: 100%;
                        min-height: 40px;
                        align-items: center;

                        p {
                            @include Urbanist;
                            font-size: 20px;
                            font-weight: 400;
                            color: #000000;
                            margin: 0;
                        }

                        .add_url_btn {
                            margin-left: auto;
                            width: 135px;
                            height: 40px;
                            border-radius: 4px;
                            box-shadow: 0 1px 7px 0 rgba(139, 166, 193, 0.07);
                            border: solid 1px #000000;
                            background-color: transparent;
                            @include Urbanist;
                            font-weight: 700;
                            font-size: 14px;
                            color: #000000;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                        }
                    }

                    .table_area {
                        margin-top: 20px;
                        display: flex;
                        flex-flow: column;
                        width: 100%;

                        .table_head {
                            width: 100%;
                            display: flex;
                            height: 40px;
                            border-radius: 8px;
                            background-color: #f5f8fa;
                            align-items: center;
                            justify-content: space-between;
                            padding-left: 16px;

                            > div {
                                @include Urbanist;
                                font-size: 14px;
                                color: #000000;
                                font-weight: 400;
                                width: 100%;

                                &:first-child {
                                    max-width: 300px;
                                }

                                &:nth-child(2) {
                                    max-width: 130px;
                                    text-align: center;
                                }

                                &:nth-child(3) {
                                    max-width: 150px;
                                }
                            }
                        }

                        .table_body {
                            display: flex;
                            flex-flow: column;
                            padding-left: 16px;

                            .table_row {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                padding: 13px 0;
                                align-items: center;
                                height: auto;

                                > div:not(.mobile_menu_icon) {
                                    width: 100%;
                                    @include Urbanist;
                                    font-size: 16px;
                                    font-weight: 600;
                                    color: #1d252d;
                                    line-height: normal;
                                    align-items: center;
                                    height: auto;

                                    &:first-child {
                                        max-width: 300px;
                                        word-break: break-all;
                                    }

                                    &:nth-child(2) {
                                        max-width: 130px;
                                        text-align: center;
                                    }

                                    &:nth-child(3) {
                                        max-width: 150px;
                                        display: flex;
                                        align-items: center;


                                        > div {
                                            display: flex;
                                            align-items: center;
                                            margin-right: 20px;
                                            cursor: pointer;
                                            position: relative;

                                            &:last-child {
                                                margin-right: 0;
                                            }

                                            span {
                                                position: absolute;
                                                bottom: 0;
                                                padding-left: 10px;
                                                padding-right: 10px;
                                                background: #FFFFFF;
                                                border: 1px solid #DDE5ED;
                                                box-shadow: 0 2px 14px #8ba6c126;
                                                min-width: max-content;
                                                font-style: normal;
                                                padding-top: 5px;
                                                padding-bottom: 5px;
                                                border-radius: 4px;
                                                color: #000000;
                                                @include Urbanist;
                                                font-size: 12px;
                                                font-weight: 400;
                                                bottom: -40px;
                                                right: -10px;
                                                display: none !important;
                                                z-index: 9;
                                            }

                                            &:after {
                                                content: '';
                                                width: 0px;
                                                height: 0px;
                                                border-left: 5px solid transparent;
                                                border-right: 5px solid transparent;
                                                border-bottom: 5px solid #fff;
                                                position: absolute;
                                                bottom: -13px;
                                                z-index: 999;
                                                display: none;
                                            }

                                            &:before {
                                                content: '';
                                                width: 0px;
                                                height: 0px;
                                                border-left: 6px solid transparent;
                                                border-right: 6px solid transparent;
                                                border-bottom: 6px solid #DDE5ED;
                                                position: absolute;
                                                bottom: -12px;
                                                left: -1px;
                                                display: none;
                                            }

                                            &:hover {
                                                span, &:before, &:after {
                                                    display: flex !important;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .add_url_pop {
                        position: fixed;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        background-color: rgba(29, 37, 45, .5);
                        z-index: 99;

                        > div {
                            width: 406px;
                            border-radius: 4px;
                            box-shadow: 0 0 47px 0 rgba(0, 0, 0, 0.23);
                            border: solid 1px #dde5ed;
                            background-color: #ffffff;
                            position: absolute;
                            top: 50%;
                            left: 0;
                            right: 0;
                            margin: 0 auto;
                            transform: translate(0, -50%);

                            .header {
                                display: flex;
                                align-items: center;
                                @include Urbanist;
                                font-weight: 700;
                                color: #1d252d;
                                font-size: 20px;
                                padding: 13px 30px;
                                border-bottom: 1px solid #dde5ed;

                                .close {
                                    cursor: pointer;
                                    margin-left: auto;

                                    img {
                                        width: 16px;
                                        height: 16px;
                                    }
                                }
                            }

                            .body {
                                padding: 20px 30px 0 30px;

                                p {
                                    padding: 0;
                                    margin: 0;
                                    @include Urbanist;
                                    font-weight: 400;
                                    font-size: 16px;
                                    color: #000000;
                                    line-height: 24px;
                                    padding-bottom: 35px;
                                }

                                .textarea_box {
                                    position: relative;

                                    span {
                                        color: #000000 !important;
                                        @include Urbanist;
                                        font-size: 14px !important;
                                        line-height: normal;
                                        font-weight: 400 !important;
                                        top: 50%;
                                        transform: translate(0, -50%);
                                        position: absolute;
                                        left: 17px;
                                        display: flex;
                                        width: auto;
                                        padding-left: 3.5px;
                                        padding-right: 3.5px;
                                        background: #fff;
                                        transition: all 0.16s linear;
                                        z-index: -1;
                                    }

                                    input {
                                        &[type="text"] {
                                            width: 100%;
                                            height: 52px;
                                            display: flex;
                                            align-items: center;
                                            border-radius: 8px;
                                            border: solid 1.1px #dde5ed;
                                            background-color: transparent;
                                            margin-bottom: 27px;
                                            padding-left: 21px;
                                            @include Urbanist;
                                            font-size: 14px;
                                            color: #1b252e;
                                            font-weight: 700;

                                            &:focus,
                                            &:not(:placeholder-shown) {
                                                & + span {
                                                    top: 0;
                                                    z-index: 1;
                                                }
                                            }
                                        }
                                    }

                                    select {
                                        width: 100%;
                                        height: 52px;
                                        display: flex;
                                        align-items: center;
                                        border-radius: 8px;
                                        border: solid 1.1px #dde5ed;
                                        background-color: transparent;
                                        margin-bottom: 27px;
                                        padding-left: 21px;
                                        @include Urbanist;
                                        font-size: 14px;
                                        color: #1b252e;
                                        font-weight: 700;
                                        -webkit-appearance: none;

                                        & + span {
                                            top: 0;
                                            z-index: 1;
                                        }
                                    }

                                    &.select {
                                        &:after {
                                            content: "";
                                            width: 0px;
                                            height: 0px;
                                            border-left: 5px solid transparent;
                                            border-right: 5px solid transparent;
                                            border-top: 5px solid #1d252d;
                                            display: flex;
                                            position: absolute;
                                            right: 10px;
                                            top: 50%;
                                            transform: translate(0, -50%);
                                            z-index: 9;
                                        }
                                    }

                                    &.errors {
                                        position: relative;

                                        span {
                                            top: 0;
                                            z-index: 1;

                                            & + span {
                                                top: 50%;
                                                color: #000000 !important;
                                                padding-left: 25px;
                                                background: #fff;
                                            }
                                        }

                                        &:before {
                                            content: "!";
                                            width: 16px;
                                            height: 16px;
                                            border-radius: 50%;
                                            background: #000000;
                                            display: flex;
                                            color: #fff;
                                            align-items: center;
                                            justify-content: center;
                                            font-size: 13px;
                                            position: absolute;
                                            top: 50%;
                                            transform: translate(0, -50%);
                                            left: 20px;
                                            z-index: 9;
                                        }
                                    }
                                }
                            }

                            .footer {
                                margin: 6px 0 30px 0;
                                display: flex;
                                padding: 0 30px;

                                > div {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    @include Urbanist;
                                    font-size: 16px;
                                    width: 96px;
                                    height: 48px;
                                    border-radius: 6px;
                                    cursor: pointer;

                                    &.yes_btn {
                                        background-color: #EE934A;
                                        color: #ffffff;
                                        background-image: radial-gradient(circle at bottom right, #F1857F 1%, #EE934A 100%);

                                        &.loader {
                                            pointer-events: none;
                                            position: relative;
                                            transition: all .2s ease-in-out;
                                            justify-content: space-evenly;
                                            transition: all .5s ease-in-out;
                                            width: 120px;

                                            &:after {
                                                content: "";
                                                border: 4px solid #EE934A;
                                                border-radius: 50%;
                                                border-top: 4px solid #fff;
                                                width: 25px;
                                                height: 25px;
                                                animation: .5s linear infinite spin;
                                                position: relative;
                                                right: 0;
                                            }
                                        }
                                    }

                                    &.no_btn {
                                        border: solid 1px #dde5ed;
                                        background-color: #f5f8fa;
                                        margin-left: 16px;
                                        color: #000000;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            > div {
                &:last-child {
                    > div {
                        margin-bottom: 0
                    }
                }
            }
        }

        .refferals_list {
            width: 100%;
            margin-top: 16px;

            .refferal_list_box {
                border-radius: 8px;
                box-shadow: 0 1px 7px 0 rgba(139, 166, 193, 0.07);
                background-color: #ffffff;
                padding: 32px;

                .heading {
                    @include Urbanist;
                    font-size: 24px;
                    font-weight: 700;
                    color: #1d252d;
                }

                .subheading {
                    @include Urbanist;
                    font-size: 16px;
                    font-weight: 600;
                    color: #000000;
                    margin-top: 8px;

                    span {
                        font-weight: 800;
                        color: #1d252d;
                    }
                }

                .refferal_table {
                    display: flex;
                    flex-flow: column;
                    width: 100%;
                    margin-top: 15px;

                    .header {
                        height: 32px;
                        border-radius: 4px;
                        background-color: #f5f8fa;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 6px;

                        > div {
                            @include Urbanist;
                            font-size: 14px;
                            font-weight: 400;
                            color: #000000;
                            padding: 0 24px;
                            width: calc(100% / 4);
                        }
                    }

                    .content {
                        display: flex;
                        flex-flow: column;

                        .trow {
                            display: flex;
                            justify-content: space-between;

                            > div {
                                padding: 12px 24px;
                                width: calc(100% / 4);
                                color: #1d252d;
                                @include Urbanist;
                                font-weight: 400;
                                font-size: 16px;
                                word-break: break-all;
                            }
                        }
                    }
                }

                .paination_box {
                    height: 56px;
                    box-shadow: 0 -6px 7px 0 rgba(139, 166, 193, 0.07);
                    background-color: #ffffff;
                    display: flex;
                }
            }
        }

        .summary_left_part {
            display: flex;
            flex-flow: column;
            width: 100%;
            max-width: calc(70% - 8px);
            margin-right: auto;

            .Transactions_Summary {
                border-radius: 8px;
                box-shadow: 0 1px 7px 0 rgba(139, 166, 193, 0.07);
                background-color: #ffffff;
                padding: 24px 30px;
                display: flex;
                margin-bottom: 16px;
                flex-flow: column;

                .header {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 23px;
                    @include Urbanist;
                    font-weight: 700;
                    color: #1d252d;
                    width: 100%;
                    font-size: 24px;
                    align-items: flex-end;


                    > div, a {
                        font-size: 16px;
                        color: #000000;
                        cursor: pointer;

                        img {
                            margin-left: 12.5px;
                        }
                    }
                }

                .body {
                    display: flex;
                    justify-content: space-between;
                    max-width: 90%;

                    &.affiliate_body {
                        max-width: 53%;
                    }

                    > div {
                        display: flex;
                        min-width: 165px;
                        flex-flow: column;
                    }

                    .header_title {
                        @include Urbanist;
                        font-size: 20px;
                        font-weight: 400;
                        color: #000000;
                        line-height: 27px;
                    }

                    .content {
                        @include Urbanist;
                        font-size: 28px;
                        font-weight: 700;
                        color: #1d252d;
                        line-height: 38px;
                        margin-bottom: 4px;
                    }

                    .footer {
                        @include Urbanist;
                        font-size: 16px;
                        font-weight: 700;
                        color: #000000;

                        &.ddl {
                            display: flex;
                            align-items: center;
                            cursor: pointer;
                            position: relative;
                            min-height: 17px;

                            &:after {
                                content: '';
                                width: 0px;
                                height: 0px;
                                border-left: 5px solid transparent;
                                border-right: 5px solid transparent;
                                border-top: 5px solid #1d252d;
                                margin-left: 0;
                                position: absolute;
                                right: 0;
                            }

                            select {
                                border: none;
                                outline: none;
                                background: transparent;
                                appearance: none;
                                font-size: 16px;
                                font-weight: 700;
                                color: #000000;
                                width: 100%;
                                position: absolute;
                                left: 0;
                                right: 0;
                                top: 0;
                                z-index: 9;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }

            .payments_box {
                background: #EE934A url('./images/paymet_summary-bg.svg') no-repeat;
                min-height: 164px;
                background-position: center top;
                display: flex;
                background-size: auto 153%;
                flex-flow: row;

                .contentbox {
                    display: flex;
                    flex-flow: column;
                    max-width: 300px;

                    h3 {
                        padding: 0;
                        margin: 0;
                        font-weight: 900;
                        font-size: 24px;
                        color: #ffffff;
                        line-height: 32px;
                        margin-bottom: 10px;
                        @include Urbanist;
                    }

                    p {
                        padding: 0;
                        margin: 0;
                        @include Urbanist;
                        font-weight: 400;
                        font-size: 24px;
                        color: #ffffff;
                    }
                }

                a.btns_payment_summary {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #ffffff;
                    cursor: pointer;
                    border-radius: 8px;
                    background: #FFFFFF;
                    @include Urbanist;
                    font-weight: 700;
                    color: #000000;
                    font-size: 16px;
                    width: 161px;
                    height: 48px;
                    margin-left: auto;
                    margin-top: auto;
                    margin-bottom: auto;

                    img {
                        margin-left: 8px;
                    }
                }
            }

            .payments_box_green {
                background: #59b97d;
                min-height: 164px;
                background-position: center top;
                display: flex;
                background-size: auto 153%;
                flex-flow: row;

                .contentbox {
                    display: flex;
                    flex-flow: column;
                    max-width: 300px;

                    h3 {
                        padding: 0;
                        margin: 0;
                        font-weight: 900;
                        font-size: 24px;
                        color: #ffffff;
                        line-height: 32px;
                        margin-bottom: 10px;
                        @include Urbanist;
                    }

                    p {
                        padding: 0;
                        margin: 0;
                        @include Urbanist;
                        font-weight: 400;
                        font-size: 24px;
                        color: #ffffff;
                    }
                }

                .btns_payment_summary {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #ffffff;
                    cursor: pointer;
                    border-radius: 8px;
                    background: #FFFFFF;
                    @include Urbanist;
                    font-weight: 700;
                    color: #000000;
                    font-size: 16px;
                    width: 111px;
                    height: 48px;
                    margin-left: auto;
                    margin-top: auto;
                    margin-bottom: auto;

                    a {
                        @include Urbanist;
                        font-weight: 700;
                        color: #000000;
                        font-size: 16px;
                    }
                }
            }
        }

        .summary_right_part {
            display: flex;
            flex-flow: column;
            width: 100%;
            max-width: calc(30% - 8px);
            margin-left: auto;

            .Transactions_Summary {
                border-radius: 8px;
                box-shadow: 0 1px 7px 0 rgba(139, 166, 193, 0.07);
                background-color: #ffffff;
                padding: 24px 30px;
                display: flex;
                margin-bottom: 16px;
                flex-flow: column;

                &.group_no_box {
                    min-height: 532px;
                    max-height: 532px;
                }

                .header {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 15px;
                    @include Urbanist;
                    font-weight: 700;
                    color: #1d252d;
                    width: 100%;
                    font-size: 24px;
                    align-items: flex-end;


                    > div, a {
                        font-size: 16px;
                        color: #000000;
                        cursor: pointer;

                        img {
                            margin-left: 12.5px;
                        }
                    }
                }

                .content {
                    margin-bottom: 15px;

                    p {
                        @include Urbanist;
                        font-weight: 600;
                        color: #000000;
                        font-size: 16px;
                        padding: 0;
                        margin: 0;


                        span {
                            font-weight: 700;
                            color: #1D252D;
                        }
                    }
                }

                .searchbox {
                    input[type="text"] {
                        width: 100%;
                        border-radius: 4px;
                        background: #f5f8fa;
                        outline: none;
                        border: none;
                        height: 32px;
                        padding-left: 23px;
                        @include Urbanist;
                        font-size: 14px;
                        color: #000000;
                        display: flex;
                        align-items: center;
                        font-weight: 400;

                        &::placeholder {
                            font-weight: 400 !important;
                            font-size: 14px !important;
                            color: #000000 !important;
                        }
                    }
                }

                .groupnumbers_summary {
                    padding-left: 24px;
                    width: 100%;
                    flex-flow: column;
                    overflow-y: auto;
                    margin-top: 5px;
                    /*max-height: 85px;

                    &::-webkit-scrollbar {
                        width: 5px;
                    }

                    &::-webkit-scrollbar-track {
                        background-color: #f4f7fc;
                        border-radius: 3px;
                    }
                    &::-webkit-scrollbar {
                        width: 5px;
                    }

                    &::-webkit-scrollbar-track {
                        background-color: #f4f7fc;
                        border-radius: 3px;
                    }
                    &::-webkit-scrollbar-thumb {
                        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                        border-radius: 3px;
                    }
                    */
                    > div {
                        min-height: 40px;
                        display: flex;
                        align-items: center;
                        color: #1D252D;
                        @include Urbanist;
                        font-weight: 400;
                        font-size: 16px;
                    }
                }
            }

            .payments_box {
                background: #EE934A;
                min-height: 164px;
                background-position: center top;
                display: flex;
                background-size: auto 153%;
                flex-flow: row;

                .contentbox {
                    display: flex;
                    flex-flow: column;
                    max-width: 300px;

                    h3 {
                        padding: 0;
                        margin: 0;
                        font-weight: 900;
                        font-size: 24px;
                        color: #ffffff;
                        line-height: 32px;
                        margin-bottom: 10px;
                        @include Urbanist;
                    }

                    p {
                        padding: 0;
                        margin: 0;
                        @include Urbanist;
                        font-weight: 400;
                        font-size: 24px;
                        color: #ffffff;
                    }
                }

                .btns_payment_summary {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #ffffff;
                    cursor: pointer;
                    border-radius: 8px;
                    background: #FFFFFF;
                    @include Urbanist;
                    font-weight: 700;
                    color: #000000;
                    font-size: 16px;
                    width: 111px;
                    height: 48px;
                    margin-left: auto;
                    margin-top: auto;
                    margin-bottom: auto;

                    a {
                        @include Urbanist;
                        font-weight: 700;
                        color: #000000;
                        font-size: 16px;
                    }
                }
            }
        }

        .Account_info_form_parent {
            padding: 24px 66px 35px 33px;
            box-shadow: 0 1px 7px 0 rgba(139, 166, 193, 0.07);
            background-color: #ffffff;
            border-radius: 8px;
            position: relative;

            &.loader {
                pointer-events: none;
                position: relative;
                transition: all .2s ease-in-out;

                &:before {
                    content: "";
                    border: 4px solid #EE934A;
                    border-radius: 50%;
                    border-top: 4px solid #fff;
                    width: 25px;
                    height: 25px;
                    -webkit-animation: .5s linear infinite spin;
                    animation: .5s linear infinite spin;
                    position: absolute;
                    right: 80px;
                }
            }

            > div {
                display: flex;
                flex-flow: row wrap;
                margin-bottom: 10px;

                h4 {
                    @include Urbanist;
                    font-weight: 700;
                    padding: 0;
                    margin: 0;
                    color: #1D252D;
                    margin-bottom: 23px;
                    width: 100%;
                    font-size: 24px;
                }

                > div {
                    position: relative;
                    width: calc(33% - 8px);
                    margin-right: 12px;
                    margin-bottom: 20px;
                    z-index: 1;

                    &.width100 {
                        width: calc(100% - 12px);
                    }

                    .drpdown {
                        &:after {
                            content: "";
                            width: 0px;
                            height: 0px;
                            border-left: 5px solid transparent;
                            border-right: 5px solid transparent;
                            border-top: 5px solid #1d252d;
                            display: flex;
                        }
                    }

                    > span {
                        color: #000000 !important;
                        @include Urbanist;
                        font-size: 14px !important;
                        line-height: normal;
                        font-weight: 400 !important;
                        top: 50%;
                        transform: translate(0, -50%);
                        position: absolute;
                        left: 17px;
                        display: flex;
                        width: auto;
                        padding-left: 3.5px;
                        padding-right: 3.5px;
                        background: #fff;
                        transition: all 0.16s linear;
                        z-index: -1;
                    }

                    &.errors {
                        position: relative;
                        /* input[type="text"], input[type="email"] {
                            border-color: #EE934A !important;
                        }*/
                        span {
                            top: 0;
                            z-index: 1;

                            & + span {
                                top: 50%;
                                color: #000000 !important;
                                z-index: 1;
                                padding-left: 25px;
                                background: #fff;
                            }
                        }

                        &:before {
                            content: "!";
                            width: 16px;
                            height: 16px;
                            border-radius: 50%;
                            background: #000000;
                            display: flex;
                            color: #fff;
                            align-items: center;
                            justify-content: center;
                            font-size: 13px;
                            position: absolute;
                            top: 50%;
                            transform: translate(0, -50%);
                            left: 20px;
                            z-index: 9;
                        }
                    }
                }

                input {
                    &[type="text"],
                    &[type="password"],
                    &[type="email"] {
                        width: 100%;
                        height: 52px;
                        display: flex;
                        align-items: center;
                        border-radius: 8px;
                        border: solid 1.1px #dde5ed;
                        background-color: transparent;
                        margin-bottom: 0;
                        padding-left: 21px;
                        @include Urbanist;
                        color: #1B252E;
                        font-weight: 700;
                        font-size: 14px;

                        &:focus,
                        &:not(:placeholder-shown) {
                            & + span {
                                top: 0;
                                z-index: 1;
                            }
                        }
                    }
                }

                select {
                    width: 100%;
                    height: 52px;
                    display: flex;
                    align-items: center;
                    border-radius: 8px;
                    border: solid 1.1px #dde5ed;
                    background-color: transparent;
                    margin-bottom: 0;
                    padding-left: 21px;
                    font-family: "Urbanist", sans-serif !important;
                    color: #1B252E;
                    font-weight: 700;
                    font-size: 14px;
                    padding-right: 15px;
                    appearance: none;
                    -webkit-apperance: none;

                    & + span {
                        top: 0;
                        z-index: 1;
                    }
                }

                .changepassword_btn {
                    color: #fff;
                    @include Urbanist;
                    font-size: 16px;
                    font-weight: 700;
                    cursor: pointer;
                    width: auto;
                    background: #EE934A;
                    width: 180px;
                    height: 58px;
                    cursor: pointer;
                    border-radius: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-image: radial-gradient(circle at bottom right, #F1857F 1%, #EE934A 100%);
                }

                .saveprofile_setting_btn {
                    width: 229px;
                    height: 58px;
                    margin-top: 0px;
                    cursor: pointer;
                    border-radius: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #EE934A;
                    @include Urbanist;
                    font-weight: 700;
                    font-size: 16px;
                    color: #FFFFFF;
                    background-image: radial-gradient(circle at bottom right, #F1857F 1%, #EE934A 100%);

                    &.loader {
                        pointer-events: none;
                        position: relative;
                        transition: all .2s ease-in-out;
                        justify-content: space-evenly;

                        &:after {
                            content: "";
                            border: 4px solid #EE934A;
                            border-radius: 50%;
                            border-top: 4px solid #fff;
                            width: 25px;
                            height: 25px;
                            -webkit-animation: .5s linear infinite spin;
                            animation: .5s linear infinite spin;
                            position: relative;
                            right: 0;
                        }
                    }
                }
            }

            .changepassword_box {
                position: fixed;
                width: 100%;
                height: 100%;
                left: 0;
                right: 0;
                top: 0;
                z-index: 9999;

                .pricing_setup_pop {
                    position: fixed;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background-color: rgba(29, 37, 45, .5);
                    z-index: 99;
                    width: 100%;
                    margin: 0;

                    > div {
                        width: 406px;
                        border-radius: 4px;
                        box-shadow: 0 0 47px 0 rgba(0, 0, 0, 0.23);
                        border: solid 1px #dde5ed;
                        background-color: #ffffff;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        right: 0;
                        margin: 0 auto;
                        transform: translate(0, -50%);

                        .header {
                            display: flex;
                            align-items: center;
                            @include Urbanist;
                            font-weight: 700;
                            color: #1d252d;
                            font-size: 20px;
                            padding: 13px 30px;
                            border-bottom: 1px solid #dde5ed;

                            .close {
                                cursor: pointer;
                                margin-left: auto;

                                img {
                                    width: 16px;
                                    height: 16px;
                                }
                            }
                        }

                        .body {
                            padding: 20px 30px 0 30px;

                            p {
                                padding: 0;
                                margin: 0;
                                @include Urbanist;
                                font-weight: 400;
                                font-size: 14px;
                                color: #000000;
                                line-height: 24px;
                                padding-bottom: 0;

                                &:nth-child(4) {
                                    margin-bottom: 24px;
                                }

                                &:first-child {
                                    font-size: 16px;
                                    color: #1D252D;
                                }
                            }

                            .textarea_box {
                                position: relative;

                                i {
                                    width: 22px;
                                    height: 12px;
                                    background: url(./images/eye_icon.png) no-repeat;
                                    position: absolute;
                                    right: 10px;
                                    top: 50%;
                                    transform: translate(0, -50%);
                                    cursor: pointer;
                                    background-size: 100%;

                                    &.active {
                                        background: url(./images/eye_selected.png) no-repeat;
                                        background-size: 100%;
                                    }
                                }

                                span {
                                    color: #000000 !important;
                                    @include Urbanist;
                                    font-size: 14px !important;
                                    line-height: normal;
                                    font-weight: 400 !important;
                                    top: 50%;
                                    transform: translate(0, -50%);
                                    position: absolute;
                                    left: 17px;
                                    display: flex;
                                    width: auto;
                                    padding-left: 3.5px;
                                    padding-right: 3.5px;
                                    background: #fff;
                                    transition: all 0.16s linear;
                                    z-index: -1;
                                }

                                input {
                                    &[type="text"], &[type="password"] {
                                        width: 100%;
                                        height: 52px;
                                        display: flex;
                                        align-items: center;
                                        border-radius: 8px;
                                        border: solid 1.1px #dde5ed;
                                        background-color: transparent;
                                        margin-bottom: 27px;
                                        padding-left: 21px;
                                        @include Urbanist;
                                        font-size: 14px;
                                        color: #1b252e;
                                        font-weight: 700;

                                        &:focus,
                                        &:not(:placeholder-shown) {
                                            & + span {
                                                top: 0;
                                                z-index: 1;
                                            }
                                        }
                                    }
                                }

                                select {
                                    width: 100%;
                                    height: 52px;
                                    display: flex;
                                    align-items: center;
                                    border-radius: 8px;
                                    border: solid 1.1px #dde5ed;
                                    background-color: transparent;
                                    margin-bottom: 27px;
                                    padding-left: 21px;
                                    @include Urbanist;
                                    font-size: 14px;
                                    color: #1b252e;
                                    font-weight: 700;
                                    -webkit-appearance: none;

                                    & + span {
                                        top: 0;
                                        z-index: 1;
                                    }
                                }

                                &.select {
                                    &:after {
                                        content: "";
                                        width: 0px;
                                        height: 0px;
                                        border-left: 5px solid transparent;
                                        border-right: 5px solid transparent;
                                        border-top: 5px solid #1d252d;
                                        display: flex;
                                        position: absolute;
                                        right: 10px;
                                        top: 50%;
                                        transform: translate(0, -50%);
                                        z-index: 9;
                                    }
                                }

                                &.errors {
                                    position: relative;

                                    span {
                                        top: 0;
                                        z-index: 1;

                                        & + span {
                                            top: 50%;
                                            color: #000000 !important;
                                            z-index: 1;
                                            padding-left: 25px;
                                            background: #fff;
                                        }
                                    }

                                    &:before {
                                        content: "!";
                                        width: 16px;
                                        height: 16px;
                                        border-radius: 50%;
                                        background: #000000;
                                        display: flex;
                                        color: #fff;
                                        align-items: center;
                                        justify-content: center;
                                        font-size: 13px;
                                        position: absolute;
                                        top: 50%;
                                        transform: translate(0, -50%);
                                        left: 20px;
                                        z-index: 9;
                                    }
                                }
                            }
                        }

                        .footer {
                            margin: 6px 0 30px 0;
                            display: flex;
                            padding: 0 30px;

                            > div {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                @include Urbanist;
                                font-size: 16px;
                                width: 96px;
                                height: 48px;
                                border-radius: 6px;
                                cursor: pointer;

                                &.yes_btn {
                                    background-color: #EE934A;
                                    color: #ffffff;
                                    width: 140px;
                                    background-image: radial-gradient(circle at bottom right, #F1857F 1%, #EE934A 100%);

                                    &.loader {
                                        pointer-events: none;
                                        position: relative;
                                        transition: all .2s ease-in-out;
                                        justify-content: space-evenly;
                                        width: 180px;

                                        &:after {
                                            content: "";
                                            border: 4px solid #EE934A;
                                            border-radius: 50%;
                                            border-top: 4px solid #fff;
                                            width: 25px;
                                            height: 25px;
                                            -webkit-animation: .5s linear infinite spin;
                                            animation: .5s linear infinite spin;
                                            position: relative;
                                            right: 0;
                                        }
                                    }
                                }

                                &.no_btn {
                                    border: solid 1px #dde5ed;
                                    background-color: #f5f8fa;
                                    margin-left: 16px;
                                    color: #000000;
                                }
                            }
                        }
                    }
                }
            }
        }

        .Card_Page_header {
            display: flex;
            justify-content: space-between;
            padding-bottom: 12px;
            border-bottom: 1px solid #DDE5ED;
            align-items: flex-start;
            margin-bottom: 15px;



            .h2_title {
                margin-right: auto;
                display: flex;
                flex-flow: column;

                h2 {
                    margin-bottom: 0;
                }

                p {
                    color: #000000;
                    font-weight: 400;
                    @include Urbanist;
                    font-size: 14px;
                }
            }

            .buttons_area {
                margin-left: auto;
                min-width: 350px;
                max-width: 450px;
                display: flex;
                justify-content: space-between;

                .button {
                    padding: 13px 18px;
                    border: 1px solid #DDE5ED;
                    border-radius: 8px;
                    @include Urbanist;
                    font-weight: 700;
                    color: #000000;
                    font-size: 16px;
                    cursor: pointer;
                    background: #fff;

                    &.red {
                        background: #EE934A;
                        color: #fff;
                        background-image: radial-gradient(circle at bottom right, #F1857F 1%, #EE934A 100%);

                        a {
                            color: #fff;
                            text-decoration: none;
                        }
                    }
                }
            }
        }

        .card_page_content {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;

            .card {
                box-shadow: 0 1px 7px 0 rgba(139, 166, 193, 0.07);
                background-color: #ffffff;
                padding: 25px 32px 40px 32px;
                width: calc(50% - 16px);
                border-radius: 8px;
                margin-bottom: 24px;

                .header {
                    display: flex;

                    h3 {
                        margin-right: auto;
                        @include Urbanist;
                        font-weight: 700;
                        color: #1D252D;
                        font-size: 20px;
                    }

                    .buttons {
                        width: 77px;
                        margin-left: auto;
                        display: flex;
                        justify-content: space-between;

                        .download {
                            width: 20px;
                            height: 22px;
                            cursor: pointer;
                            background: url(./images/download_card.png) no-repeat;
                            background-size: 100%;
                        }

                        .print {
                            width: 24px;
                            height: 22px;
                            cursor: pointer;
                            background: url(./images/card_print.png) no-repeat;
                            background-size: 100%;
                        }
                    }
                }

                .content {
                    padding: 18px 50px 0 50px;

                    img {
                        width: 100%;
                    }

                    .image_container {
                        box-shadow: 0 1px 7px 0 rgba(139, 166, 193, 0.07);
                        background-color: #ffffff;
                        border-radius: 8px;

                        .header {
                            min-height: 74px;
                            width: 100%;
                            background: #20C1BC url(./images/card_header_bg.png) no-repeat;
                            border-radius: 8px 8px 0 0;
                            background-position: -74px -74px;
                            display: flex;
                            align-items: center;
                            padding-right: 18px;

                            &.red {
                                background: #E53030 url(./images/red_header.png) no-repeat;
                                background-position: -74px -74px;
                            }

                            &.orange {
                                background: #FF8531 url(./images/orange_header.png) no-repeat;
                                background-position: -74px -74px;
                            }

                            &.blue {
                                background: #204ACB url(./images/blue_header.png) no-repeat;
                                background-position: -74px -74px;
                            }

                            .title {
                                display: flex;
                                flex-flow: column;
                                padding-left: 18px;


                                span {
                                    @include Gilroy_bold;
                                    font-size: 15px;
                                    color: #fff;
                                }

                                p {
                                    font-size: 21px;
                                    @include Gilroy_bold;
                                    color: #fff;
                                }
                            }

                            img {
                                margin-left: auto;
                                max-width: 125px;
                            }
                        }

                        .card_content {
                            display: flex;
                            border-bottom: 1px solid #DDE5ED;
                            margin-bottom: 15px;

                            .left_side {
                                width: 100%;
                                max-width: 280px;
                                padding-right: 35px;
                                border-right: 1px solid #DDE5ED;
                                padding-top: 20px;
                                padding-left: 18px;

                                h3 {
                                    color: #11BF80;
                                    font-size: 14.75px;
                                    line-height: 14.75px;
                                    margin-bottom: 8px;
                                    @include Gilroy_bold;

                                    &.red {
                                        color: #EE934A;
                                    }

                                    &.orange {
                                        color: #FF8531;
                                    }

                                    &.blue {
                                        color: #204ACB
                                    }
                                }

                                .groupno_box {
                                    display: flex;
                                    margin-top: 10px;
                                    margin-bottom: 8px;

                                    > div {
                                        &:first-child {
                                            width: 91px;
                                            font-weight: normal;
                                            color: #1D252D;
                                            font-size: 13.22px;
                                            @include Urbanist;
                                            font-weight: 400;
                                        }

                                        &:last-child {
                                            width: 91px;
                                            font-weight: normal;
                                            color: #1D252D;
                                            font-size: 16px;
                                            @include Gilroy_bold;
                                        }
                                    }
                                }
                            }

                            .right_side {
                                padding-top: 20px;
                                width: 100%;
                                max-width: calc(100% - 270px);
                                padding-left: 27px;
                                padding-right: 27px;

                                p {
                                    @include Urbanist;
                                    color: #1D252D;
                                    font-size: 11px;
                                }

                                img {
                                    width: 100%;
                                    margin-top: 10px;
                                }
                            }
                        }

                        .disclaimer {
                            @include Urbanist;
                            font-size: 9px;
                            color: #1D252D;
                            font-weight: 400;
                            padding-left: 18px;
                            padding-bottom: 50px;
                        }

                        .instruction {
                            display: flex;
                            padding-left: 18px;
                            padding-right: 18px;

                            img {
                                margin-left: auto;
                                width: auto;
                            }

                            h4 {
                                @include Gilroy_bold;
                                font-size: 9px;
                                color: #1D252D;
                            }

                            ul {
                                list-style: none;

                                li {
                                    @include Urbanist;
                                    color: #000000;
                                    font-size: 11px;
                                    font-weight: 500;
                                }
                            }

                            &.noqr {
                                > div {
                                    width: 100%;
                                }
                            }
                        }

                        .footer {
                            padding: 18px;
                            margin-top: 10px;
                            background: #EAF0F6;
                            border-radius: 0 0 8px 8px;

                            p {
                                color: #1D252D;
                                font-size: 9px;
                                line-height: 14px;
                                margin-bottom: 8px;
                                @include Urbanist;
                                font-weight: 500;
                            }

                            > div {
                                display: flex;

                                a {
                                    color: #1D47C7;
                                    font-size: 9px;
                                    letter-spacing: 0.222063px;
                                    @include Gilroy_bold;
                                    margin-right: 10px;
                                    text-decoration: none;
                                }

                                p {
                                    color: #000000;
                                    font-size: 9px;
                                    @include Urbanist;
                                    margin-left: auto;
                                }
                            }
                        }
                    }
                }
            }
        }

        .claim_dashboard {
            h2 {
                text-align: center;
            }

            .dashboard_box {
                box-shadow: 0 1px 7px 0 rgba(139, 166, 193, 0.07);
                background-color: #ffffff;
                padding: 37px 0;
                display: flex;
                flex-flow: column;
                align-items: center;
                width: 100%;
                max-width: 750px;
                border-radius: 8px;
                margin-left: auto;
                margin-right: auto;

                img {
                    max-width: 370px;
                }

                .view_btn {
                    margin-top: 17px;
                    cursor: pointer;
                    width: 229px;
                    height: 58px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #EE934A;
                    border-radius: 8px;
                    @include Urbanist;
                    font-weight: 700;
                    font-size: 16px;
                    color: #FFFFFF;

                    a {
                        @include Urbanist;
                        font-weight: 700;
                        font-size: 16px;
                        color: #FFFFFF;
                        text-decoration: none;
                    }
                }

                p {
                    margin-top: 18px;
                    @include Urbanist;
                    font-weight: 400;
                    color: #000000;
                    font-size: 14px;
                }
            }

            .bottom_text {
                width: 100%;
                max-width: 717px;
                margin-left: auto;
                margin-right: auto;
                @include Urbanist;
                font-weight: 400;
                color: #000000;
                font-size: 14px;
                text-align: center;
                margin-top: 17px;
            }
        }

        .no_border {
            border: none;
        }

        .accordion_content {
            .accordion {
                width: 100%;
                max-width: 100%;
            }

            .panel {
                background-color: #f0ebe1;
                box-shadow: 0 1px 7px 0 rgba(139, 166, 193, 0.07);
                background-color: #ffffff;
                border-radius: 8px;
            }

            .panel__label {
                position: relative;
                display: block;
                width: 100%;
                background: none;
                border: none;
                text-align: left;
                padding: 30px 75px 25px 25px;
                font-weight: 500;
                font-size: 17px;
                font-family: inherit;
                -webkit-transition: color 0.2s linear;
                transition: color 0.2s linear;
                cursor: pointer;
                @include Urbanist;
                font-weight: 700;
                color: #222222;
                font-size: 20px;
            }

            .panel__label:focus {
                outline: none;
            }

            .panel__label span {
                position: absolute;
                right: 23px;
                width: 44px;
                height: 44px;
                top: 50%;
                transform: translate(0, -50%);
                border-radius: 8px;
            }

            .panel__label:after,
            .panel__label:before {
                content: '';
                position: absolute;
                right: 35px;
                top: 50%;
                width: 22px;
                height: 2px;
                background-color: #1d252d;
            }

            .panel__label:before {
                -webkit-transform: rotate(-90deg);
                transform: rotate(-90deg);
                -webkit-transition: -webkit-transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
                transition: -webkit-transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
                transition: transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
                transition: transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1), -webkit-transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
            }

            .panel[aria-expanded='true'] .panel__content {
                opacity: 1;
            }

            .panel[aria-expanded='true'] .panel__label {
                color: #1D252D;
                padding-bottom: 13px;
            }

            .panel[aria-expanded='true'] .panel__label:before {
                -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
            }

            .panel__inner {
                overflow: hidden;
                will-change: height;
                -webkit-transition: height 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
                transition: height 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
            }

            .panel__content {
                margin: 5px 25px 25px;
                font-size: 14px;
                color: #000000;
                opacity: 0;
                -webkit-transition: opacity 0.3s linear 0.18s;
                transition: opacity 0.3s linear 0.18s;
                @include Urbanist;
                line-height: 25px;
                font-size: 16px;

                ul {
                    margin-top: 10px;
                    padding-left: 15px;

                    li {
                        margin-bottom: 8px;

                        a {
                            color: #000000;
                        }
                    }
                }
            }

            .panel:not(:last-child) {
                margin-bottom: 16px;
            }
        }

        .header_bard_button {
            display: flex;



            .searchbar {
                width: 100%;
                max-width: 480px;
                height: 48px;
                border-radius: 4px;
                border: solid 1px #dde5ed;
                background-color: #fff;
                display: flex;
                align-items: center;
                padding: 0 14px;

                img {
                    max-width: 18px;
                    margin-right: 10px;
                }

                input {
                    width: calc(100% - 65px);
                    background: transparent;
                    border: none;
                    min-height: 26px;
                    max-height: 26px;
                    outline: none;
                    @include Urbanist;
                    font-weight: 500;
                    color: #8ba6c1;
                    font-size: 14px;

                    &::placeholder {
                        @include Urbanist;
                        font-weight: 500 !important;
                        color: #8ba6c1 !important;
                        font-size: 14px !important;
                    }
                }
            }

            .tabbed_button {
                display: flex;
                width: 100%;
                max-width: 378px;
                margin-left: auto;
                margin-right: 24px;

                &.loader {
                    pointer-events: none;
                    position: relative;
                    transition: all .2s ease-in-out;
                    align-items: center;

                    &:before {
                        content: "";
                        border: 4px solid #EE934A;
                        border-radius: 50%;
                        border-top: 4px solid #f4f7fc;
                        width: 25px;
                        height: 25px;
                        -webkit-animation: .5s linear infinite spin;
                        animation: .5s linear infinite spin;
                        position: absolute;
                        left: -50px;
                    }
                }

                > div {
                    width: calc(100% / 3);
                    color: #000000;
                    font-weight: 700;
                    @include Urbanist;
                    font-size: 16px;
                    height: 48px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &:first-child {
                        border: 1px solid #DDE5ED;
                        border-radius: 8px 0 0 8px;
                        background: #FFFFFF;
                    }

                    &:nth-child(2) {
                        border: 1px solid #DDE5ED;
                        border-left: none;
                        background: #FFFFFF;
                    }

                    &:nth-child(3) {
                        border: 1px solid #DDE5ED;
                        border-left: none;
                        border-radius: 0 8px 8px 0;
                        background: #FFFFFF;
                    }

                    &.active {
                        border-color: #000000 !important;
                        background: #000000;
                        color: #FFFFFF;
                    }

                    &.pointer {
                        cursor: pointer;
                    }
                }
            }

            .add_group_no_btn {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #000000;
                border-radius: 8px;
                width: 201px;
                height: 48px;
                color: #000000;
                font-weight: 700;
                @include Urbanist;
                font-size: 16px;
                background: #FFFFFF;
                cursor: pointer;

                &.usersarea {
                    margin-left: auto;

                    &.noborderr {
                        width: 0;
                        border: none;
                    }

                    &.loader {
                        pointer-events: none;
                        position: relative;
                        transition: all .2s ease-in-out;

                        &:before {
                            content: "";
                            border: 4px solid #EE934A;
                            border-radius: 50%;
                            border-top: 4px solid #fff;
                            width: 25px;
                            height: 25px;
                            -webkit-animation: .5s linear infinite spin;
                            animation: .5s linear infinite spin;
                            position: absolute;
                            right: 220px;
                        }

                        &.noborderr {
                            width: 0;
                            border: none;

                            &:before {
                                right: 0;
                            }
                        }
                    }
                }
            }
        }

        .tabular_box {
            border-radius: 8px;
            box-shadow: 0 1px 7px 0 rgba(139, 166, 193, 0.07);
            background-color: #ffffff;
            display: flex;
            margin-bottom: 16px;
            flex-flow: column;
            width: 100%;
            margin-top: 20px;

            .table_header {
                background: #F5F8FA;
                width: 100%;
                height: 32px;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-left: 10px;
                padding-right: 10px;
                margin-top: 25px;
                margin-left: 32px;
                margin-right: 32px;
                max-width: calc(100% - 64px);

                > div {
                    font-size: 14px;
                    color: #000000;
                    @include Urbanist;
                    font-weight: 400;

                    &:first-child {
                        width: 100%;
                        max-width: 95px;
                    }

                    &:nth-child(2) {
                        width: 100%;
                        max-width: 110px;
                    }

                    &:nth-child(3) {
                        width: 100%;
                        max-width: 365px;
                    }

                    &:nth-child(4) {
                        width: 100%;
                        max-width: 94px;
                    }

                    &:nth-child(5) {
                        width: 100%;
                        max-width: 85px;
                    }

                    &:nth-child(6) {
                        width: 100%;
                        max-width: 150px;
                    }
                }

                &.user_page {
                    > div {
                        &:first-child {
                            width: 100%;
                            max-width: 109px;
                        }

                        &:nth-child(2) {
                            width: 100%;
                            max-width: 180px;
                        }

                        &:nth-child(3) {
                            width: 100%;
                            max-width: 250px;
                        }

                        &:nth-child(4) {
                            width: 100%;
                            max-width: 146px;
                        }

                        &:nth-child(5) {
                            width: 100%;
                            max-width: 120px;
                        }

                        &:nth-child(6) {
                            width: 100%;
                            max-width: 150px;
                        }
                    }
                }
            }

            .table_body {
                min-height: 491px;
                //max-height: 491px;

                .table_row {
                    background: #fff;
                    width: 100%;
                    height: 48px;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: auto;

                    &:nth-child(even) {
                        > div {
                            background-color: #F5F8FA;
                        }
                    }

                    > div {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding-left: 10px;
                        padding-right: 10px;
                        max-width: calc(100% - 64px);
                        margin-left: 32px;
                        margin-right: 32px;
                        height: auto;
                    }

                    > div > div {
                        font-size: 16px;
                        color: #1D252D;
                        @include Urbanist;
                        font-weight: 400;
                        word-break: break-all;

                        &:first-child {
                            width: 100%;
                            max-width: 95px;
                        }

                        &:nth-child(2) {
                            width: 100%;
                            max-width: 110px;
                        }

                        &:nth-child(3) {
                            width: 100%;
                            max-width: 365px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        &:nth-child(4) {
                            width: 100%;
                            max-width: 94px;
                        }

                        &:nth-child(5) {
                            width: 100%;
                            max-width: 85px;
                        }

                        &:nth-child(6) {
                            width: 100%;
                            max-width: 150px;
                            display: flex;

                            span {
                                display: flex;
                            }
                        }

                        i {
                            cursor: pointer;
                            margin-right: 25px;
                            display: flex;
                            position: relative;

                            &:last-child {
                                margin: 0;
                            }

                            &.edit {
                                min-width: 16px;
                                height: 16px;
                                background-size: 16px !important;
                                background: url(./images/group_edit.svg)
                            }

                            &.reset {
                                min-width: 20px;
                                height: 17px;
                                background-size: 20px 17px !important;
                                background: url(./images/group_reset.svg)
                            }

                            &.assign {
                                min-width: 16px;
                                height: 16px;
                                background-size: 16px !important;
                                background: url(./images/group_assign.svg)
                            }

                            &.restrict {
                                min-width: 16px;
                                height: 16px;
                                background-size: 16px !important;
                                background: url(./images/group_restrict.svg)
                            }

                            &.release {
                                min-width: 16px;
                                height: 16px;
                                background-size: 16px !important;
                                background: url(./images/group_release.svg)
                            }

                            &:nth-child(2) {
                                span {
                                    right: auto !important;
                                    left: -10px;
                                }
                            }

                            &:nth-child(3) {
                                span {
                                    right: auto !important;
                                    left: -60px;
                                }
                            }

                            span {
                                position: absolute;
                                bottom: 0;
                                padding-left: 10px;
                                padding-right: 10px;
                                background: #FFFFFF;
                                border: 1px solid #DDE5ED;
                                box-shadow: 0 2px 14px #8ba6c126;
                                min-width: max-content;
                                font-style: normal;
                                padding-top: 5px;
                                padding-bottom: 5px;
                                border-radius: 4px;
                                color: #000000;
                                @include Urbanist;
                                font-size: 12px;
                                font-weight: 400;
                                bottom: -40px;
                                right: -10px;
                                display: none !important;
                                z-index: 9;
                            }

                            &:after {
                                content: '';
                                width: 0px;
                                height: 0px;
                                border-left: 5px solid transparent;
                                border-right: 5px solid transparent;
                                border-bottom: 5px solid #fff;
                                position: absolute;
                                bottom: -13px;
                                z-index: 999;
                                display: none;
                            }

                            &:before {
                                content: '';
                                width: 0px;
                                height: 0px;
                                border-left: 6px solid transparent;
                                border-right: 6px solid transparent;
                                border-bottom: 6px solid #DDE5ED;
                                position: absolute;
                                bottom: -12px;
                                left: -1px;
                                display: none;
                            }

                            &:hover {
                                span, &:before, &:after {
                                    display: flex !important;
                                }
                            }
                        }
                    }

                    &.user_page {

                        > div > div {
                            &:first-child {
                                width: 100%;
                                max-width: 109px;
                            }

                            &:nth-child(2) {
                                width: 100%;
                                max-width: 180px;
                            }

                            &:nth-child(3) {
                                width: 100%;
                                max-width: 250px;
                            }

                            &:nth-child(4) {
                                width: 100%;
                                max-width: 146px;
                            }

                            &:nth-child(5) {
                                width: 100%;
                                max-width: 120px;
                            }

                            &:nth-child(6) {
                                width: 100%;
                                max-width: 150px;
                                display: flex;

                                span {
                                    display: flex;
                                }
                            }

                            i {
                                cursor: pointer;
                                margin-right: 25px;
                                display: flex;
                                position: relative;


                                span {
                                    position: absolute;
                                    bottom: 0;
                                    padding-left: 10px;
                                    padding-right: 10px;
                                    background: #FFFFFF;
                                    border: 1px solid #DDE5ED;
                                    box-shadow: 0 2px 14px #8ba6c126;
                                    min-width: max-content;
                                    font-style: normal;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    border-radius: 4px;
                                    color: #000000;
                                    @include Urbanist;
                                    font-size: 12px;
                                    font-weight: 400;
                                    bottom: -40px;
                                    right: -10px;
                                    display: none !important;
                                    z-index: 9;
                                }

                                &:after {
                                    content: '';
                                    width: 0px;
                                    height: 0px;
                                    border-left: 5px solid transparent;
                                    border-right: 5px solid transparent;
                                    border-bottom: 5px solid #fff;
                                    position: absolute;
                                    bottom: -13px;
                                    z-index: 999;
                                    display: none;
                                }

                                &:before {
                                    content: '';
                                    width: 0px;
                                    height: 0px;
                                    border-left: 6px solid transparent;
                                    border-right: 6px solid transparent;
                                    border-bottom: 6px solid #DDE5ED;
                                    position: absolute;
                                    bottom: -12px;
                                    left: -1px;
                                    display: none;
                                }

                                &:hover {
                                    span, &:before, &:after {
                                        display: flex !important;
                                    }
                                }

                                &:last-child {
                                    margin: 0;
                                }

                                &.edit {
                                    min-width: 16px;
                                    height: 16px;
                                    background-size: 16px !important;
                                    background: url(./images/group_edit.svg)
                                }

                                &.deactivate {
                                    min-width: 19px;
                                    height: 17px;
                                    background-size: 19px 17px !important;
                                    background: url(./images/deactivate_user.svg)
                                }

                                &.deactive {
                                    min-width: 16px;
                                    height: 16px;
                                    width: 16px;
                                    background-size: 16px !important;
                                    background: url(./images/group_edit.svg) !important;
                                    background-size: 100% !important;
                                }

                                &.activate {
                                    min-width: 18px;
                                    height: 16px;
                                    background-size: 18px 16px !important;
                                    background: url(./images/activate.svg)
                                }

                                &.resend_email {
                                    min-width: 22px;
                                    height: 20px;
                                    background-size: 22px 20px !important;
                                    background: url(./images/resend_email.svg)
                                }

                                &.change_credentials {
                                    min-width: 18px;
                                    height: 17px;
                                    background-size: 18px 17px !important;
                                    background: url(./images/change_credentials.svg)
                                }

                                &.referrals {
                                    min-width: 16px;
                                    height: 16px;
                                    background-size: 16px !important;
                                    background: url(./images/referrals.svg)
                                }

                                &.copytext {
                                    min-width: 16px;
                                    height: 16px;
                                    background-size: 16px !important;
                                    background: url(./images/copy.svg)
                                }
                            }
                        }
                    }
                }
            }

            .table_footer {
                height: 56px;
                width: 100%;
                background-color: #ffffff;
                box-shadow: 0 -6px 7px #8ba6c112;
                border-radius: 0 0 8px 8px;
                align-items: center;
                justify-content: flex-end;
                padding-right: 35px;
                display: flex;

                .pagination {
                    display: flex;
                    list-style: none;

                    li {
                        cursor: pointer;
                        min-height: 24px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        a {
                            color: #000000;
                            font-weight: 400;
                            font-size: 14px;
                            @include Urbanist;
                        }

                        &.disabled {
                            cursor: default;
                        }

                        &.numbers {
                            min-width: 24px;
                            height: 24px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-left: 5px;
                            margin-right: 5px;

                            &.active {
                                border-radius: 50%;
                                background: #000000;

                                a {
                                    color: #fff;
                                }
                            }
                        }

                        &.first {
                            margin-right: 12px;
                        }

                        &.last {
                            margin-left: 12px;
                        }

                        &.prev {
                            width: 9px;
                            height: 24px;
                            background-size: 9px 16px !important;
                            background: url(./images/prev_arrow.svg) no-repeat;
                            margin-right: 10px;
                            background-position: center;

                            a {
                                display: flex;
                                width: 100%;
                                height: 100%;
                            }
                        }

                        &.next {
                            width: 9px;
                            height: 24px;
                            background-size: 9px 16px !important;
                            background: url(./images/prev_arrow.svg) no-repeat;
                            margin-left: 6px;
                            background-position: center;
                            transform: rotate(180deg);

                            a {
                                display: flex;
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }
            }
        }




        .resetgroup_box {
            .add_url_pop {
                position: fixed;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-color: rgba(29, 37, 45, .5);
                z-index: 99;

                > div {
                    width: 470px;
                    border-radius: 4px;
                    box-shadow: 0 0 47px 0 rgba(0, 0, 0, 0.23);
                    border: solid 1px #dde5ed;
                    background-color: #ffffff;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    transform: translate(0, -50%);

                    .header {
                        display: flex;
                        align-items: center;
                        @include Urbanist;
                        font-weight: 700;
                        color: #1d252d;
                        font-size: 20px;
                        padding: 13px 30px;
                        border-bottom: 1px solid #dde5ed;

                        .close {
                            cursor: pointer;
                            margin-left: auto;

                            img {
                                width: 16px;
                                height: 16px;
                            }
                        }
                    }

                    .body {
                        padding: 20px 30px 0 30px;

                        p {
                            padding: 0;
                            margin: 0;
                            @include Urbanist;
                            font-weight: 400;
                            font-size: 16px;
                            color: #1D252D;
                            line-height: 24px;
                        }

                        .textarea_box {
                            position: relative;

                            span {
                                color: #000000 !important;
                                @include Urbanist;
                                font-size: 14px !important;
                                line-height: normal;
                                font-weight: 400 !important;
                                top: 50%;
                                transform: translate(0, -50%);
                                position: absolute;
                                left: 17px;
                                display: flex;
                                width: auto;
                                padding-left: 3.5px;
                                padding-right: 3.5px;
                                background: #fff;
                                transition: all 0.16s linear;
                                z-index: -1;
                            }

                            input {
                                &[type="text"] {
                                    width: 100%;
                                    height: 52px;
                                    display: flex;
                                    align-items: center;
                                    border-radius: 8px;
                                    border: solid 1.1px #dde5ed;
                                    background-color: transparent;
                                    margin-bottom: 27px;
                                    padding-left: 21px;
                                    @include Urbanist;
                                    font-size: 14px;
                                    color: #1b252e;
                                    font-weight: 700;

                                    &:focus,
                                    &:not(:placeholder-shown) {
                                        & + span {
                                            top: 0;
                                            z-index: 1;
                                        }
                                    }
                                }
                            }

                            select {
                                width: 100%;
                                height: 52px;
                                display: flex;
                                align-items: center;
                                border-radius: 8px;
                                border: solid 1.1px #dde5ed;
                                background-color: transparent;
                                margin-bottom: 27px;
                                padding-left: 21px;
                                @include Urbanist;
                                font-size: 14px;
                                color: #1b252e;
                                font-weight: 700;
                                -webkit-appearance: none;

                                & + span {
                                    top: 0;
                                    z-index: 1;
                                }
                            }

                            &.select {
                                &:after {
                                    content: "";
                                    width: 0px;
                                    height: 0px;
                                    border-left: 5px solid transparent;
                                    border-right: 5px solid transparent;
                                    border-top: 5px solid #1d252d;
                                    display: flex;
                                    position: absolute;
                                    right: 10px;
                                    top: 50%;
                                    transform: translate(0, -50%);
                                    z-index: 9;
                                }
                            }

                            &.errors {
                                position: relative;

                                span {
                                    top: 0;
                                    z-index: 1;

                                    & + span {
                                        top: 50%;
                                        color: #000000 !important;
                                        z-index: -1;
                                        padding-left: 25px;
                                    }
                                }

                                &:before {
                                    content: "!";
                                    width: 16px;
                                    height: 16px;
                                    border-radius: 50%;
                                    background: #000000;
                                    display: flex;
                                    color: #fff;
                                    align-items: center;
                                    justify-content: center;
                                    font-size: 13px;
                                    position: absolute;
                                    top: 50%;
                                    transform: translate(0, -50%);
                                    left: 20px;
                                }
                            }
                        }
                    }

                    .footer {
                        margin: 32px 0 30px 0;
                        display: flex;
                        padding: 0 30px;

                        > div {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            @include Urbanist;
                            font-size: 16px;
                            width: 96px;
                            height: 48px;
                            border-radius: 6px;
                            cursor: pointer;

                            &.yes_btn {
                                background-color: #EE934A;
                                color: #ffffff;
                                background-image: radial-gradient(circle at bottom right, #F1857F 1%, #EE934A 100%);

                                &.primary {
                                    width: 206px;
                                }
                            }

                            &.no_btn {
                                border: solid 1px #dde5ed;
                                background-color: #f5f8fa;
                                margin-left: 16px;
                                color: #000000;
                            }
                        }
                    }
                }
            }
        }

        .asign_edit_pop {
            .add_url_pop {
                position: fixed;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-color: rgba(29, 37, 45, .5);
                z-index: 99;

                > div {
                    width: 812px;
                    border-radius: 4px;
                    box-shadow: 0 0 47px 0 rgba(0, 0, 0, 0.23);
                    border: solid 1px #dde5ed;
                    background-color: #ffffff;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    transform: translate(0, -50%);

                    .header {
                        display: flex;
                        align-items: center;
                        @include Urbanist;
                        font-weight: 700;
                        color: #1d252d;
                        font-size: 20px;
                        padding: 13px 30px;
                        border-bottom: 1px solid #dde5ed;

                        &.loader {
                            position: relative;
                            transition: all .2s ease-in-out;

                            &:before {
                                content: "";
                                border: 4px solid #EE934A;
                                border-radius: 50%;
                                border-top: 4px solid #fff;
                                width: 25px;
                                height: 25px;
                                -webkit-animation: .5s linear infinite spin;
                                animation: .5s linear infinite spin;
                                position: absolute;
                                right: 80px;
                            }
                        }

                        .close {
                            cursor: pointer;
                            margin-left: auto;

                            img {
                                width: 16px;
                                height: 16px;
                            }
                        }
                    }

                    .body {
                        padding: 0 30px 0 30px;

                        .user_list_commision {
                            width: calc(100% - 4px);
                            margin-left: 2px;
                            padding: 24px 0;
                            border-bottom: 1px solid #DDE5ED;
                            display: flex;
                            justify-content: space-between;

                            > div {
                                width: 50%;
                                max-width: calc(50% - 16px);
                                position: relative;

                                &.disable_select {
                                    pointer-events: none;

                                    > div {
                                        background: #F4F7FC;
                                        color: #000000;
                                    }
                                }

                                > ul {
                                    max-height: 250px;
                                    overflow: hidden;
                                    overflow-y: auto;
                                    position: absolute;
                                    background: #fff;
                                    padding: 0;
                                    top: 52px;
                                    box-shadow: 0 2px 6px #ccc;
                                    border-radius: 0 0 5px 5px;
                                    width: 100%;
                                    display: none;
                                    z-index: 9;

                                    li {
                                        padding: 8px 10px;
                                        cursor: pointer;
                                        font-size: 16px;
                                        @include Urbanist;
                                        font-weight: 400;
                                        color: #1b252e;

                                        &:hover {
                                            background: #F5F8FA;
                                        }
                                    }

                                    &.open {
                                        display: block;
                                    }
                                }

                                > span {
                                    color: #000000 !important;
                                    @include Urbanist;
                                    font-size: 14px !important;
                                    line-height: normal;
                                    font-weight: 400 !important;
                                    top: 0;
                                    transform: translate(0, -50%);
                                    position: absolute;
                                    left: 17px;
                                    display: flex;
                                    width: auto;
                                    padding-left: 3.5px;
                                    padding-right: 3.5px;
                                    background: #fff;
                                    transition: all 0.16s linear;
                                    z-index: 1;

                                    & + span {
                                        display: none;
                                    }
                                }

                                input {
                                    &[type="text"],
                                    &[type="password"],
                                    &[type="email"] {
                                        width: 100%;
                                        height: 52px;
                                        display: flex;
                                        align-items: center;
                                        border-radius: 8px;
                                        border: solid 1.1px #dde5ed;
                                        background-color: transparent;
                                        padding-left: 30px;
                                        font-size: 14px;
                                        color: #1b252e;
                                        @include Urbanist;
                                        font-weight: 700;

                                        &:focus,
                                        &:not(:placeholder-shown) {
                                            & + span {
                                                top: 0;
                                                z-index: 1;
                                            }
                                        }
                                    }
                                }

                                select {
                                    width: 100%;
                                    height: 52px;
                                    display: flex;
                                    align-items: center;
                                    border-radius: 8px;
                                    border: solid 1.1px #dde5ed;
                                    background-color: transparent;
                                    padding-left: 21px;
                                    @include Urbanist;
                                    font-size: 14px;
                                    color: #1b252e;
                                    font-weight: 700;
                                    -webkit-appearance: none;

                                    & + span {
                                        top: 0;
                                        z-index: 1;
                                    }

                                    &.disable_select {
                                        pointer-events: none;
                                        background: #F4F7FC;
                                        color: #000000;
                                    }
                                }

                                &:last-child {
                                    &:before {
                                        content: '$';
                                        position: absolute;
                                        top: 50%;
                                        transform: translate(0, -50%);
                                        font-size: 14px;
                                        color: #1b252e;
                                        @include Urbanist;
                                        font-weight: 700;
                                        left: 21px;
                                    }
                                }

                                &.errors {
                                    span + span {
                                        color: #000000 !important;
                                        display: flex;
                                        position: absolute;
                                        top: 40%;
                                        transform: translate(0, -50%);
                                        left: 10px;
                                        background: #fff;
                                    }
                                }
                            }
                        }

                        .overidebox {
                            min-height: 349px;
                            padding-top: 24px;

                            .override_header {
                                height: 24px;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                height: 40px;

                                h3 {
                                    @include Urbanist;
                                    font-weight: 700;
                                    color: #1D252D;
                                    font-size: 20px;
                                }

                                .add_btn {
                                    width: 167px;
                                    height: 40px;
                                    border: 1px solid #000000;
                                    box-shadow: 0 1px 7px #8ba6c112;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    @include Urbanist;
                                    font-weight: 600;
                                    color: #000000;
                                    font-size: 14px;
                                    border-radius: 4px;
                                    cursor: pointer;
                                }
                            }

                            .override_body {
                                height: 285px;
                                padding-top: 16px;
                                position: relative;

                                .no_override {
                                    position: absolute;
                                    width: 224px;
                                    left: 0;
                                    right: 0;
                                    top: 50%;
                                    transform: translate(0, -50%);
                                    text-align: center;
                                    margin: 0 auto;

                                    p {
                                        @include Urbanist;
                                        font-weight: 600;
                                        color: #8BA6C1;
                                        font-size: 14px;
                                        margin-bottom: 12px;
                                    }

                                    .btn {
                                        width: 100%;
                                        height: 40px;
                                        border: 1px solid #000000;
                                        box-shadow: 0 1px 7px #8ba6c112;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        @include Urbanist;
                                        font-weight: 600;
                                        color: #000000;
                                        font-size: 14px;
                                        border-radius: 4px;
                                        cursor: pointer;
                                    }
                                }

                                .over_ride_table {
                                    .theader {
                                        display: flex;
                                        width: 100%;
                                        background: #F5F8FA;
                                        height: 32px;
                                        align-items: center;
                                        justify-content: space-between;
                                        padding: 0 16px 0 24px;

                                        > div {
                                            @include Urbanist;
                                            font-size: 14px;
                                            font-weight: 400;
                                            color: #000000;

                                            &:first-child {
                                                width: 219px;
                                            }

                                            &:nth-child(2) {
                                                width: 238px;
                                            }

                                            &:nth-child(3) {
                                                width: 150px;
                                            }
                                        }
                                    }

                                    .tbody_parent {
                                        max-height: 229px;
                                        overflow-y: auto;
                                        min-height: 229px;
                                    }

                                    .table_body {
                                        display: flex;
                                        width: 100%;
                                        background: transparent;
                                        height: 48px;
                                        align-items: center;
                                        justify-content: space-between;
                                        padding: 0 16px 0 24px;

                                        > div {
                                            @include Urbanist;
                                            font-size: 14px;
                                            font-weight: 400;
                                            color: #000000;

                                            > ul {
                                                max-height: 250px;
                                                overflow: hidden;
                                                overflow-y: auto;
                                                position: absolute;
                                                background: #fff;
                                                padding: 0;
                                                top: 33px;
                                                box-shadow: 0 2px 6px #ccc;
                                                border-radius: 0 0 5px 5px;
                                                width: 100%;
                                                display: none;
                                                z-index: 9;

                                                li {
                                                    padding: 8px 10px;
                                                    cursor: pointer;
                                                    font-size: 16px;
                                                    @include Urbanist;
                                                    font-weight: 400;
                                                    color: #1b252e;

                                                    &:hover {
                                                        background: #F5F8FA;
                                                    }
                                                }

                                                &.open {
                                                    display: block;
                                                }
                                            }

                                            &:first-child {
                                                width: 219px;
                                            }

                                            &:nth-child(2) {
                                                width: 238px;
                                            }

                                            &:nth-child(3) {
                                                width: 150px;
                                            }

                                            select, input {
                                                display: none;
                                            }

                                            &.action_btns {
                                                .new_group {
                                                    display: none;
                                                }

                                                .view_group {
                                                    display: flex;

                                                    .edit {
                                                        min-width: 16px;
                                                        height: 16px;
                                                        background-size: 16px !important;
                                                        background: url(./images/group_edit.svg) no-repeat;
                                                        margin-right: 20px;
                                                        cursor: pointer;
                                                    }

                                                    .delete {
                                                        min-width: 16px;
                                                        height: 17px;
                                                        background-size: 16px 17px !important;
                                                        background: url(./images/delete_icon.svg) no-repeat;
                                                        margin-right: 20px;
                                                        cursor: pointer;
                                                    }
                                                }
                                            }
                                        }

                                        .errors_span {
                                            display: none;
                                        }

                                        .selectize-control.single {
                                            display: none;
                                        }

                                        &.add_new, &.edit_row {
                                            border: 1px solid #DDE5ED;
                                            border-top: none;
                                            box-shadow: 0 0 4px #DDE5ED;

                                            .selectize-control.single {
                                                display: flex;
                                            }

                                            > div {
                                                &:first-child {
                                                    position: relative;

                                                    input[type="text"] {
                                                        width: 100%;
                                                        height: 32px;
                                                        display: flex;
                                                        align-items: center;
                                                        border-radius: 8px;
                                                        border: solid 1.1px #dde5ed;
                                                        background-color: transparent;
                                                        margin-bottom: 0;
                                                        padding-left: 21px;
                                                        @include Urbanist;
                                                        font-size: 14px;
                                                        color: #1b252e;
                                                        font-weight: 700;
                                                        -webkit-appearance: none;
                                                        box-shadow: 0 0 3px #dde5ed5e;
                                                    }
                                                }

                                                &:nth-child(2) {
                                                    position: relative;

                                                    &:before {
                                                        content: '$';
                                                        position: absolute;
                                                        top: 50%;
                                                        transform: translate(0, -50%);
                                                        font-size: 14px;
                                                        color: #1b252e;
                                                        font-family: "Urbanist", sans-serif !important;
                                                        font-weight: 700;
                                                        left: 21px;
                                                    }

                                                    input {
                                                        &[type="text"],
                                                        &[type="password"],
                                                        &[type="email"] {
                                                            width: 100%;
                                                            height: 32px;
                                                            display: flex;
                                                            align-items: center;
                                                            border-radius: 8px;
                                                            border: solid 1.1px #dde5ed;
                                                            background-color: transparent;
                                                            padding-left: 30px;
                                                            font-size: 14px;
                                                            color: #1b252e;
                                                            @include Urbanist;
                                                            font-weight: 700;
                                                        }
                                                    }
                                                }

                                                span {
                                                    display: none;
                                                }

                                                &.errors {
                                                    span.errors_span {
                                                        color: #000000;
                                                        display: flex;
                                                        position: absolute;
                                                        top: 50%;
                                                        transform: translate(0, -50%);
                                                        left: 10px;
                                                        background: #fff;
                                                    }
                                                }

                                                &.action_btns {
                                                    .view_group {
                                                        display: none;
                                                    }

                                                    .new_group {
                                                        width: 100%;
                                                        display: flex;

                                                        .add {
                                                            width: 64px;
                                                            height: 32px;
                                                            background: #EE934A;
                                                            border-radius: 8px;
                                                            display: flex;
                                                            align-items: center;
                                                            justify-content: center;
                                                            @include Urbanist;
                                                            font-weight: 600;
                                                            font-size: 14px;
                                                            color: #FFFFFF;
                                                            margin-right: 10px;
                                                            cursor: pointer;
                                                        }

                                                        .Cancel {
                                                            width: 72px;
                                                            height: 32px;
                                                            background: #F5F8FA;
                                                            border-radius: 8px;
                                                            display: flex;
                                                            align-items: center;
                                                            justify-content: center;
                                                            @include Urbanist;
                                                            font-weight: 600;
                                                            font-size: 14px;
                                                            color: #000000;
                                                            margin-right: 10px;
                                                            border: 1px solid #DDE5ED;
                                                            cursor: pointer;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .footer {
                        padding: 12px 32px 12px 32px;
                        display: flex;
                        box-shadow: 0 -6px 7px #8ba6c112;

                        > div {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            @include Urbanist;
                            font-size: 16px;
                            width: 96px;
                            height: 48px;
                            border-radius: 6px;
                            cursor: pointer;

                            &.yes_btn {
                                background-color: #EE934A;
                                color: #ffffff;
                                width: 143px;
                                transition: all .2s ease-in-out;
                                background-image: radial-gradient(circle at bottom right, #F1857F 1%, #EE934A 100%);

                                &.loader {
                                    pointer-events: none;
                                    position: relative;
                                    transition: all .2s ease-in-out;
                                    width: 180px;
                                    justify-content: space-evenly;

                                    &:after {
                                        content: "";
                                        border: 4px solid #EE934A;
                                        border-radius: 50%;
                                        border-top: 4px solid #fff;
                                        width: 25px;
                                        height: 25px;
                                        -webkit-animation: .5s linear infinite spin;
                                        animation: .5s linear infinite spin;
                                        position: relative;
                                        right: 0;
                                    }
                                }

                                &.primary {
                                    width: 206px;
                                }
                            }

                            &.no_btn {
                                border: solid 1px #dde5ed;
                                background-color: #f5f8fa;
                                margin-left: 16px;
                                color: #000000;
                            }

                            &.error_submit_msg {
                                width: auto;
                                padding-left: 10px;
                                @include Urbanist;
                                color: #000000;
                                font-size: 14px;
                                cursor: default;
                            }
                        }
                    }
                }
            }

            &.edit_user_popup {
                .add_url_pop {
                    > div {
                        .body {
                            padding: 19px 32px 24px 32px;

                            h3 {
                                @include Urbanist;
                                font-weight: 700;
                                color: #1D252D;
                                font-size: 20px;
                                padding-bottom: 13px;
                            }

                            .personal_box {
                                > div {
                                    display: flex;
                                    margin-bottom: 18px;
                                    justify-content: space-between;

                                    > div {
                                        position: relative;
                                        width: calc(50% - 16px);
                                        margin-right: 12px;
                                        margin-bottom: 0;
                                        z-index: 1;

                                        &.width100 {
                                            width: calc(100% - 12px);
                                        }

                                        .drpdown {
                                            &:after {
                                                content: "";
                                                width: 0px;
                                                height: 0px;
                                                border-left: 5px solid transparent;
                                                border-right: 5px solid transparent;
                                                border-top: 5px solid #1d252d;
                                                display: flex;
                                            }
                                        }

                                        > span {
                                            color: #000000 !important;
                                            @include Urbanist;
                                            font-size: 14px !important;
                                            line-height: normal;
                                            font-weight: 400 !important;
                                            top: 50%;
                                            transform: translate(0, -50%);
                                            position: absolute;
                                            left: 17px;
                                            display: flex;
                                            width: auto;
                                            padding-left: 3.5px;
                                            padding-right: 3.5px;
                                            background: #fff;
                                            transition: all 0.16s linear;
                                            z-index: -1;
                                        }

                                        &.errors {
                                            position: relative;

                                            span {
                                                top: 0;
                                                z-index: 1;
                                                background: #fff;

                                                & + span {
                                                    top: 50%;
                                                    color: #000000 !important;
                                                    z-index: 1;
                                                    padding-left: 25px;
                                                    background: #fff;
                                                }
                                            }

                                            &:before {
                                                content: "!";
                                                width: 16px;
                                                height: 16px;
                                                border-radius: 50%;
                                                background: #000000;
                                                display: flex;
                                                color: #fff;
                                                align-items: center;
                                                justify-content: center;
                                                font-size: 13px;
                                                position: absolute;
                                                top: 50%;
                                                transform: translate(0, -50%);
                                                left: 20px;
                                                z-index: 9;
                                            }
                                        }
                                    }

                                    input {
                                        &[type="text"],
                                        &[type="password"],
                                        &[type="email"] {
                                            width: 100%;
                                            height: 52px;
                                            display: flex;
                                            align-items: center;
                                            border-radius: 8px;
                                            border: solid 1.1px #dde5ed;
                                            background-color: transparent;
                                            margin-bottom: 0;
                                            padding-left: 21px;
                                            @include Urbanist;
                                            color: #1B252E;
                                            font-weight: 700;
                                            font-size: 14px;

                                            &:focus,
                                            &:not(:placeholder-shown) {
                                                & + span {
                                                    top: 0 !important;
                                                    z-index: 1;
                                                }
                                            }
                                        }

                                        &.disabled {
                                            pointer-events: none;
                                            background: #F4F7FC;
                                            color: #000000;
                                        }
                                    }

                                    select {
                                        width: 100%;
                                        height: 52px;
                                        display: flex;
                                        align-items: center;
                                        border-radius: 8px;
                                        border: solid 1.1px #dde5ed;
                                        background-color: transparent;
                                        margin-bottom: 0;
                                        padding-left: 21px;
                                        font-family: "Urbanist", sans-serif !important;
                                        color: #1B252E;
                                        font-weight: 700;
                                        font-size: 14px;
                                        padding-right: 15px;
                                        appearance: none;
                                        -webkit-apperance: none;

                                        & + span {
                                            top: 0;
                                            z-index: 1;
                                        }
                                    }

                                    &.user_table {
                                        display: flex;
                                        width: 100%;
                                        flex-flow: column;

                                        .header_row {
                                            width: 100%;
                                            background: #F5F8FA;
                                            height: 32px;
                                            display: flex;
                                            padding-left: 24px;
                                            padding-right: 16px;
                                            align-items: center;
                                            justify-content: space-between;

                                            > div {
                                                @include Urbanist;
                                                font-weight: 400;
                                                color: #000000;
                                                font-size: 14px;

                                                &:first-child {
                                                    width: 90px;
                                                }

                                                &:nth-child(2) {
                                                    width: 100px;
                                                }

                                                &:nth-child(3) {
                                                    width: 141px;
                                                }

                                                &:nth-child(4) {
                                                    width: 60px;
                                                }
                                            }
                                        }

                                        > .user_table_body {
                                            width: 100%;
                                            max-height: 200px;
                                            overflow: hidden;


                                            .body_row {
                                                width: 100%;
                                                background: #ffffff;
                                                height: auto;
                                                min-height: 48px;
                                                display: flex;
                                                padding-left: 24px;
                                                padding-right: 16px;
                                                align-items: center;
                                                justify-content: space-between;
                                                flex-flow: row wrap;

                                                &:nth-child(even) {
                                                    background: #DDE5ED;
                                                }

                                                &.opened_group {
                                                    box-shadow: 0 0 4px #DDE5ED;
                                                    border: 1px solid #DDE5ED;

                                                    .toggle_row {
                                                        transform: rotate(180deg);
                                                        transition: all .1s ease-in-out;
                                                    }
                                                }

                                                > div {
                                                    @include Urbanist;
                                                    font-weight: 400;
                                                    color: #000000;
                                                    font-size: 14px;
                                                    min-height: 48px;
                                                    display: flex;
                                                    align-items: center;

                                                    &:first-child {
                                                        width: 90px;
                                                    }

                                                    &:nth-child(2) {
                                                        width: 100px;
                                                    }

                                                    &:nth-child(3) {
                                                        width: 141px;
                                                    }

                                                    &:nth-child(4) {
                                                        width: 60px;

                                                        .delete {
                                                            min-width: 16px;
                                                            height: 17px;
                                                            background-size: 16px 17px !important;
                                                            background: url(./images/delete_icon.svg) no-repeat;
                                                            margin-right: 20px;
                                                            cursor: pointer;
                                                        }

                                                        .toggle_row {
                                                            width: 0px;
                                                            height: 0px;
                                                            border-left: 5px solid transparent;
                                                            border-right: 5px solid transparent;
                                                            border-top: 5px solid #1D252D;
                                                            cursor: pointer;
                                                            transition: all .1s ease-in-out;
                                                        }
                                                    }

                                                    &.inner_groups {
                                                        display: none;
                                                        width: 100%;
                                                        flex-flow: column;
                                                        margin-left: -24px;
                                                        min-width: calc(100% + 40px);
                                                        background: #fff;

                                                        .header_row {
                                                            > div {
                                                                width: 100px;

                                                                &:last-child {
                                                                    position: relative;
                                                                    left: -16px;
                                                                }
                                                            }
                                                        }

                                                        .user_table_body {
                                                            max-height: 112px;
                                                            overflow: auto;
                                                        }

                                                        .body_row {
                                                            background: #fff !important;
                                                            min-height: 36px;

                                                            > div {
                                                                min-height: 36px;

                                                                &:first-child {
                                                                    width: 100px;
                                                                }

                                                                &:last-child {
                                                                    .delete {
                                                                        min-width: 16px;
                                                                        height: 17px;
                                                                        background-size: 16px 17px !important;
                                                                        background: url(./images/delete_icon.svg) no-repeat;
                                                                        margin-right: 20px;
                                                                        cursor: pointer;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            &.fields {
                                margin-top: -10px;

                                p {
                                    font-size: 24px;
                                    font-weight: 800;
                                    font-stretch: normal;
                                    font-style: normal;
                                    line-height: 24px;
                                    max-width: 322px;
                                    letter-spacing: normal;
                                    color: #1d252d;
                                    @include Urbanist;
                                    margin-bottom: 30px;

                                    &.paaword_area {
                                        position: relative;
                                        max-width: 100%;

                                        i {
                                            width: 22px;
                                            height: 12px;
                                            background: url(./images/eye_icon.png) no-repeat;
                                            position: absolute;
                                            right: 10px;
                                            top: 50%;
                                            transform: translate(0, -50%);
                                            cursor: pointer;
                                            background-size: 100%;

                                            &.active {
                                                background: url(./images/eye_selected.png) no-repeat;
                                                background-size: 100%;
                                            }
                                        }

                                        > span {
                                            color: #000000 !important;
                                            @include Urbanist;
                                            font-size: 14px !important;
                                            line-height: normal;
                                            font-weight: 400 !important;
                                            top: 50%;
                                            transform: translate(0, -50%);
                                            position: absolute;
                                            left: 17px;
                                            display: flex;
                                            width: auto;
                                            padding-left: 3.5px;
                                            padding-right: 3.5px;
                                            background: transparent;
                                            transition: all 0.16s linear;
                                            z-index: -1;
                                        }
                                    }

                                    &.errors {
                                        position: relative;
                                        /* input[type="text"], input[type="email"] {
                            border-color: #EE934A !important;
                        }*/
                                        span {
                                            top: 0;
                                            z-index: 1;

                                            & + span {
                                                top: 50%;
                                                color: #000000 !important;
                                                z-index: -1;
                                                padding-left: 25px;
                                                background: #fff;
                                            }
                                        }

                                        &:before {
                                            content: "!";
                                            width: 16px;
                                            height: 16px;
                                            border-radius: 50%;
                                            background: #000000;
                                            display: flex;
                                            color: #fff;
                                            align-items: center;
                                            justify-content: center;
                                            font-size: 13px;
                                            position: absolute;
                                            top: 50%;
                                            transform: translate(0, -50%);
                                            left: 20px;
                                            z-index: 9;
                                        }
                                    }
                                }

                                > div {
                                    position: relative;

                                    &.userchk {
                                        margin-bottom: 30px;
                                        display: flex;
                                    }

                                    > span {
                                        color: #000000 !important;
                                        @include Urbanist;
                                        font-size: 14px !important;
                                        line-height: normal;
                                        font-weight: 400 !important;
                                        top: 50%;
                                        transform: translate(0, -50%);
                                        position: absolute;
                                        left: 17px;
                                        display: flex;
                                        width: auto;
                                        padding-left: 3.5px;
                                        padding-right: 3.5px;
                                        background: transparent;
                                        transition: all 0.16s linear;
                                        z-index: -1;
                                    }

                                    > label {
                                        color: #000000 !important;
                                        @include Urbanist;
                                        font-size: 14px !important;
                                        line-height: normal;
                                        font-weight: 400 !important;
                                        padding-left: 3.5px;
                                        padding-right: 3.5px;
                                    }

                                    &.errors {
                                        position: relative;

                                        span {
                                            top: 0;
                                            z-index: 1;
                                            background: #ffffff;

                                            & + span {
                                                top: 50%;
                                                color: #000000 !important;
                                                z-index: -1;
                                                padding-left: 25px;
                                                background: #fff;
                                            }
                                        }

                                        &:before {
                                            content: "!";
                                            width: 16px;
                                            height: 16px;
                                            border-radius: 50%;
                                            background: #000000;
                                            display: flex;
                                            color: #fff;
                                            align-items: center;
                                            justify-content: center;
                                            font-size: 13px;
                                            position: absolute;
                                            top: 50%;
                                            transform: translate(0, -50%);
                                            left: 20px;
                                            z-index: 9;
                                        }
                                    }
                                }

                                input {
                                    &[type="text"],
                                    &[type="password"],
                                    &[type="email"] {
                                        width: 100%;
                                        height: 52px;
                                        display: flex;
                                        align-items: center;
                                        border-radius: 8px;
                                        border: solid 1.1px #dde5ed;
                                        background-color: transparent;
                                        margin-bottom: 30px;
                                        padding-left: 20px;

                                        &:focus,
                                        &:not(:placeholder-shown) {
                                            & + span {
                                                top: 0;
                                                z-index: 1;
                                                background: #fff;
                                            }
                                        }
                                    }
                                }

                                select {
                                    width: 100%;
                                    height: 52px;
                                    display: flex;
                                    align-items: center;
                                    border-radius: 8px;
                                    border: solid 1.1px #dde5ed;
                                    background-color: transparent;
                                    margin-bottom: 30px;
                                    padding-left: 16px;
                                }


                                .save_continue {
                                    width: 186px;
                                    height: 58px;
                                    border-radius: 8px;
                                    background-color: #EE934A;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    color: #fff;
                                    @include Urbanist;
                                    font-size: 16px;
                                    font-weight: 700;
                                    cursor: pointer;
                                    margin-bottom: 63px;
                                    cursor: pointer;
                                    position: relative;
                                    transition: all .2s ease-in-out;
                                    background-image: radial-gradient(circle at bottom right, #F1857F 1%, #EE934A 100%);

                                    &.loader {
                                        pointer-events: none;
                                        position: relative;
                                        display: flex;
                                        align-items: center;
                                        transition: all .2s ease-in-out;
                                        width: auto;
                                        max-width: 200px;
                                        justify-content: space-around;
                                        max-width: 190px;

                                        &:after {
                                            content: "";
                                            border: 4px solid #EE934A;
                                            border-radius: 50%;
                                            border-top: 4px solid #fff;
                                            width: 25px;
                                            height: 25px;
                                            -webkit-animation: .5s linear infinite spin;
                                            animation: .5s linear infinite spin;
                                            position: relative;
                                            right: 10px;
                                            order: 2;
                                        }
                                    }

                                    &.widthloader {
                                        transition: all .2s ease-in-out;
                                        width: 230px;
                                    }
                                }

                                .forgotpass_link {
                                    display: flex;
                                    justify-content: space-between;
                                    margin-top: 24px;
                                    margin-bottom: 56px;

                                    > .checkbox_sec {
                                        display: flex;
                                        align-items: center;

                                        input {
                                            display: none;

                                            & + label {
                                                @include Urbanist;
                                                font-size: 14px;
                                                font-weight: 700;
                                                color: #444444;
                                                display: flex;
                                                align-items: center;
                                                cursor: pointer;

                                                span {
                                                    width: 20px;
                                                    height: 20px;
                                                    border-radius: 4px;
                                                    border: solid 1px #8ba6c1;
                                                    background-color: #ffffff;
                                                    margin-right: 11px;
                                                    position: relative;
                                                }
                                            }

                                            &:checked {
                                                & + label {
                                                    span {
                                                        background: #8ba6c1;

                                                        &:after {
                                                            content: "";
                                                            transform: rotate(45deg);
                                                            height: 12px;
                                                            width: 5px;
                                                            border-bottom: 3px solid #fff;
                                                            border-right: 3px solid #fff;
                                                            position: absolute;
                                                            left: 0;
                                                            right: 0;
                                                            margin: 0 auto;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    > .forgot_pass_btn {
                                        @include Urbanist;
                                        font-size: 14px;
                                        font-weight: 700;
                                        color: #222222;
                                        cursor: pointer;
                                    }
                                }

                                .already_link {
                                    @include Urbanist;
                                    font-size: 14px;
                                    font-weight: 700;
                                    color: #000000;
                                    cursor: pointer;
                                    display: flex;

                                    > div {
                                        color: #000000;
                                        text-decoration: none;
                                        margin-left: 10px;
                                    }
                                }
                            }
                        }
                    }

                    .add_url_pop {
                        position: fixed;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        background-color: transparent;
                        z-index: 99;
                        width: 100%;
                        height: auto;
                        transform: none;

                        > div {
                            width: 470px;
                            border-radius: 4px;
                            box-shadow: 0 0 47px 0 rgba(0, 0, 0, 0.23);
                            border: solid 1px #dde5ed;
                            background-color: #ffffff;
                            position: absolute;
                            top: 50%;
                            left: 0;
                            right: 0;
                            margin: 0 auto;
                            transform: translate(0, -50%);

                            .header {
                                display: flex;
                                align-items: center;
                                @include Urbanist;
                                font-weight: 700;
                                color: #1d252d;
                                font-size: 20px;
                                padding: 13px 30px;
                                border-bottom: 1px solid #dde5ed;

                                .close {
                                    cursor: pointer;
                                    margin-left: auto;

                                    img {
                                        width: 16px;
                                        height: 16px;
                                    }
                                }
                            }

                            .body {
                                padding: 20px 30px 0 30px;

                                p {
                                    padding: 0;
                                    margin: 0;
                                    @include Urbanist;
                                    font-weight: 400;
                                    font-size: 16px;
                                    color: #1D252D;
                                    line-height: 24px;
                                }

                                .textarea_box {
                                    position: relative;

                                    span {
                                        color: #000000 !important;
                                        @include Urbanist;
                                        font-size: 14px !important;
                                        line-height: normal;
                                        font-weight: 400 !important;
                                        top: 50%;
                                        transform: translate(0, -50%);
                                        position: absolute;
                                        left: 17px;
                                        display: flex;
                                        width: auto;
                                        padding-left: 3.5px;
                                        padding-right: 3.5px;
                                        background: #fff;
                                        transition: all 0.16s linear;
                                        z-index: -1;
                                    }

                                    input {
                                        &[type="text"] {
                                            width: 100%;
                                            height: 52px;
                                            display: flex;
                                            align-items: center;
                                            border-radius: 8px;
                                            border: solid 1.1px #dde5ed;
                                            background-color: transparent;
                                            margin-bottom: 27px;
                                            padding-left: 21px;
                                            @include Urbanist;
                                            font-size: 14px;
                                            color: #1b252e;
                                            font-weight: 700;

                                            &:focus,
                                            &:not(:placeholder-shown) {
                                                & + span {
                                                    top: 0;
                                                    z-index: 1;
                                                }
                                            }
                                        }
                                    }

                                    select {
                                        width: 100%;
                                        height: 52px;
                                        display: flex;
                                        align-items: center;
                                        border-radius: 8px;
                                        border: solid 1.1px #dde5ed;
                                        background-color: transparent;
                                        margin-bottom: 27px;
                                        padding-left: 21px;
                                        @include Urbanist;
                                        font-size: 14px;
                                        color: #1b252e;
                                        font-weight: 700;
                                        -webkit-appearance: none;

                                        & + span {
                                            top: 0;
                                            z-index: 1;
                                        }
                                    }

                                    &.select {
                                        &:after {
                                            content: "";
                                            width: 0px;
                                            height: 0px;
                                            border-left: 5px solid transparent;
                                            border-right: 5px solid transparent;
                                            border-top: 5px solid #1d252d;
                                            display: flex;
                                            position: absolute;
                                            right: 10px;
                                            top: 50%;
                                            transform: translate(0, -50%);
                                            z-index: 9;
                                        }
                                    }

                                    &.errors {
                                        position: relative;

                                        span {
                                            top: 0;
                                            z-index: 1;

                                            & + span {
                                                top: 50%;
                                                color: #000000 !important;
                                                z-index: -1;
                                                padding-left: 25px;
                                            }
                                        }

                                        &:before {
                                            content: "!";
                                            width: 16px;
                                            height: 16px;
                                            border-radius: 50%;
                                            background: #000000;
                                            display: flex;
                                            color: #fff;
                                            align-items: center;
                                            justify-content: center;
                                            font-size: 13px;
                                            position: absolute;
                                            top: 50%;
                                            transform: translate(0, -50%);
                                            left: 20px;
                                        }
                                    }
                                }
                            }

                            .footer {
                                margin: 32px 0 30px 0;
                                display: flex;
                                padding: 0 30px;

                                > div {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    @include Urbanist;
                                    font-size: 16px;
                                    width: 96px;
                                    height: 48px;
                                    border-radius: 6px;
                                    cursor: pointer;

                                    &.yes_btn {
                                        background-color: #EE934A;
                                        color: #ffffff;
                                        background-image: radial-gradient(circle at bottom right, #F1857F 1%, #EE934A 100%);

                                        &.primary {
                                            width: 206px;
                                        }
                                    }

                                    &.no_btn {
                                        border: solid 1px #dde5ed;
                                        background-color: #f5f8fa;
                                        margin-left: 16px;
                                        color: #000000;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .reset_pass_box {
            &.changepassword_box {
                position: fixed;
                width: 100%;
                height: 100%;
                left: 0;
                right: 0;
                top: 0;
                z-index: 9999;

                .pricing_setup_pop {
                    position: fixed;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background-color: rgba(29, 37, 45, .5);
                    z-index: 99;
                    width: 100%;
                    margin: 0;

                    > div {
                        width: 406px;
                        border-radius: 4px;
                        box-shadow: 0 0 47px 0 rgba(0, 0, 0, 0.23);
                        border: solid 1px #dde5ed;
                        background-color: #ffffff;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        right: 0;
                        margin: 0 auto;
                        transform: translate(0, -50%);

                        .header {
                            display: flex;
                            align-items: center;
                            @include Urbanist;
                            font-weight: 700;
                            color: #1d252d;
                            font-size: 20px;
                            padding: 13px 30px;
                            border-bottom: 1px solid #dde5ed;

                            .close {
                                cursor: pointer;
                                margin-left: auto;

                                img {
                                    width: 16px;
                                    height: 16px;
                                }
                            }
                        }

                        .body {
                            padding: 20px 30px 0 30px;

                            p {
                                padding: 0;
                                margin: 0;
                                @include Urbanist;
                                font-weight: 400;
                                font-size: 14px;
                                color: #000000;
                                line-height: 24px;
                                padding-bottom: 0;

                                &:nth-child(3) {
                                    margin-bottom: 24px;
                                }

                                &:first-child {
                                    font-size: 16px;
                                    color: #1D252D;
                                }
                            }

                            .textarea_box {
                                position: relative;

                                i {
                                    width: 22px;
                                    height: 12px;
                                    background: url(./images/eye_icon.png) no-repeat;
                                    position: absolute;
                                    right: 10px;
                                    top: 50%;
                                    transform: translate(0, -50%);
                                    cursor: pointer;
                                    background-size: 100%;

                                    &.active {
                                        background: url(./images/eye_selected.png) no-repeat;
                                        background-size: 100%;
                                    }
                                }

                                span {
                                    color: #000000 !important;
                                    @include Urbanist;
                                    font-size: 14px !important;
                                    line-height: normal;
                                    font-weight: 400 !important;
                                    top: 50%;
                                    transform: translate(0, -50%);
                                    position: absolute;
                                    left: 17px;
                                    display: flex;
                                    width: auto;
                                    padding-left: 3.5px;
                                    padding-right: 3.5px;
                                    background: #fff;
                                    transition: all 0.16s linear;
                                    z-index: -1;
                                }

                                input {
                                    &[type="text"], &[type="password"] {
                                        width: 100%;
                                        height: 52px;
                                        display: flex;
                                        align-items: center;
                                        border-radius: 8px;
                                        border: solid 1.1px #dde5ed;
                                        background-color: transparent;
                                        margin-bottom: 27px;
                                        padding-left: 21px;
                                        @include Urbanist;
                                        font-size: 14px;
                                        color: #1b252e;
                                        font-weight: 700;

                                        &:focus,
                                        &:not(:placeholder-shown) {
                                            & + span {
                                                top: 0;
                                                z-index: 1;
                                            }
                                        }
                                    }
                                }

                                select {
                                    width: 100%;
                                    height: 52px;
                                    display: flex;
                                    align-items: center;
                                    border-radius: 8px;
                                    border: solid 1.1px #dde5ed;
                                    background-color: transparent;
                                    margin-bottom: 27px;
                                    padding-left: 21px;
                                    @include Urbanist;
                                    font-size: 14px;
                                    color: #1b252e;
                                    font-weight: 700;
                                    -webkit-appearance: none;

                                    & + span {
                                        top: 0;
                                        z-index: 1;
                                    }
                                }

                                &.select {
                                    &:after {
                                        content: "";
                                        width: 0px;
                                        height: 0px;
                                        border-left: 5px solid transparent;
                                        border-right: 5px solid transparent;
                                        border-top: 5px solid #1d252d;
                                        display: flex;
                                        position: absolute;
                                        right: 10px;
                                        top: 50%;
                                        transform: translate(0, -50%);
                                        z-index: 9;
                                    }
                                }

                                &.errors {
                                    position: relative;

                                    span {
                                        top: 0;
                                        z-index: 1;

                                        & + span {
                                            top: 50%;
                                            color: #000000 !important;
                                            z-index: 1;
                                            padding-left: 25px;
                                            background: #fff;
                                        }
                                    }

                                    &:before {
                                        content: "!";
                                        width: 16px;
                                        height: 16px;
                                        border-radius: 50%;
                                        background: #000000;
                                        display: flex;
                                        color: #fff;
                                        align-items: center;
                                        justify-content: center;
                                        font-size: 13px;
                                        position: absolute;
                                        top: 50%;
                                        transform: translate(0, -50%);
                                        left: 20px;
                                        z-index: 9;
                                    }
                                }
                            }
                        }

                        .footer {
                            margin: 6px 0 30px 0;
                            display: flex;
                            padding: 0 30px;

                            > div {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                @include Urbanist;
                                font-size: 16px;
                                width: 96px;
                                height: 48px;
                                border-radius: 6px;
                                cursor: pointer;

                                &.yes_btn {
                                    background-color: #EE934A;
                                    color: #ffffff;
                                    width: 140px;
                                    background-image: radial-gradient(circle at bottom right, #F1857F 1%, #EE934A 100%);
                                }

                                &.no_btn {
                                    border: solid 1px #dde5ed;
                                    background-color: #f5f8fa;
                                    margin-left: 16px;
                                    color: #000000;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .content_sec .tabular_box .table_body .table_row > div {
        min-height: 48px;
    }
}

.error {
    color: #F1857F;
    font-size: 0.625em;
}

@media print {
    .contentarea .content_sec .card_page_content .card > .header, .left_sidebar, .Header_Area, .Card_Page_header {
        display: none !important;
    }

    .pagebreak.hide {
        display: none;
    }

    .pagebreak {
        clear: both;
        page-break-after: always;
    }

    .printarea {
        display: block !important;
    }

    .contentarea {
        margin-left: 0;
        max-width: 100%;
    }

    .header {
        min-height: 74px;
        width: 100%;
        background: #20C1BC url(./images/card_header_bg.png) no-repeat;
        border-radius: 8px 8px 0 0;
        background-position: -74px -74px;
        display: flex;
        align-items: center;
        padding-right: 18px;
        -webkit-print-color-adjust: exact !important;

        &.red {
            background: #E53030 url(./images/red_header.png) no-repeat;
            background-position: -74px -74px;
        }

        &.orange {
            background: #FF8531 url(./images/orange_header.png) no-repeat;
            background-position: -74px -74px;
        }

        &.blue {
            background: #204ACB url(./images/blue_header.png) no-repeat;
            background-position: -74px -74px;
        }

        .title {
            display: flex;
            flex-flow: column;
            padding-left: 18px;


            span {
                @include Gilroy_bold;
                font-size: 15px;
                color: #fff;
            }

            p {
                font-size: 21px;
                @include Gilroy_bold;
                color: #fff;
            }
        }

        img {
            margin-left: auto;
            max-width: 125px;
        }
    }

    .card_content {
        display: flex;
        border-bottom: 1px solid #DDE5ED;
        margin-bottom: 15px;

        .left_side {
            width: 100%;
            max-width: 280px;
            padding-right: 35px;
            border-right: 1px solid #DDE5ED;
            padding-top: 20px;
            padding-left: 18px;

            h3 {
                color: #11BF80;
                font-size: 14.75px;
                line-height: 14.75px;
                margin-bottom: 8px;
                @include Gilroy_bold;

                &.red {
                    color: #EE934A;
                }

                &.orange {
                    color: #FF8531;
                }

                &.blue {
                    color: #204ACB
                }
            }

            .groupno_box {
                display: flex;
                margin-top: 10px;
                margin-bottom: 8px;

                > div {
                    &:first-child {
                        width: 91px;
                        font-weight: normal;
                        color: #1D252D;
                        font-size: 13.22px;
                        @include Urbanist;
                        font-weight: 400;
                    }

                    &:last-child {
                        width: 91px;
                        font-weight: normal;
                        color: #1D252D;
                        font-size: 16px;
                        @include Gilroy_bold;
                    }
                }
            }
        }

        .right_side {
            padding-top: 20px;
            width: 100%;
            max-width: calc(100% - 270px);
            padding-left: 27px;
            padding-right: 27px;

            p {
                @include Urbanist;
                color: #1D252D;
                font-size: 11px;
            }

            img {
                width: 100%;
                margin-top: 10px;
            }
        }
    }

    .disclaimer {
        @include Urbanist;
        font-size: 9px;
        color: #1D252D;
        font-weight: 400;
        padding-left: 18px;
        padding-bottom: 50px;
    }

    .instruction {
        display: flex;
        padding-left: 18px;
        padding-right: 18px;

        img {
            margin-left: auto;
            width: auto;
        }

        h4 {
            @include Gilroy_bold;
            font-size: 9px;
            color: #1D252D;
        }

        ul {
            list-style: none;

            li {
                @include Urbanist;
                color: #000000;
                font-size: 11px;
                font-weight: 500;
            }
        }
    }

    .footer {
        padding: 18px;
        margin-top: 10px;
        background: #EAF0F6;
        border-radius: 0 0 8px 8px;

        p {
            color: #1D252D;
            font-size: 9px;
            line-height: 14px;
            margin-bottom: 8px;
            @include Urbanist;
            font-weight: 500;
        }

        > div {
            display: flex;

            a {
                color: #1D47C7;
                font-size: 9px;
                letter-spacing: 0.222063px;
                @include Gilroy_bold;
                margin-right: 10px;
                text-decoration: none;
            }

            p {
                color: #000000;
                font-size: 9px;
                @include Urbanist;
                margin-left: auto;
            }
        }
    }

    .contentarea {
        min-width: 100%
    }

    .card {
        box-shadow: none !important;
        background-color: transparent !important;
        padding: 0 !important;
        min-width: 100% !important;
        border-radius: 0 !important;
        margin-bottom: 0 !important;
        min-height: 100vh;

        .header {
            display: none;

            h3 {
                margin-right: auto;
                @include Urbanist;
                font-weight: 700;
                color: #1D252D;
                font-size: 20px;
            }

            .buttons {
                width: 77px;
                margin-left: auto;
                display: flex;
                justify-content: space-between;

                .download {
                    width: 20px;
                    height: 22px;
                    cursor: pointer;
                    background: url(./images/download_card.png) no-repeat;
                    background-size: 100%;
                }

                .print {
                    width: 24px;
                    height: 22px;
                    cursor: pointer;
                    background: url(./images/card_print.png) no-repeat;
                    background-size: 100%;
                }
            }
        }

        .content {
            padding: 18px 50px 0 50px;

            img {
                width: 100%;
            }

            .image_container {
                box-shadow: 0 1px 7px 0 rgba(139, 166, 193, 0.07);
                background-color: #ffffff;
                border-radius: 8px;

                .header {
                    min-height: 74px;
                    width: 100%;
                    background: #20C1BC url(./images/card_header_bg.png) no-repeat;
                    border-radius: 8px 8px 0 0;
                    background-position: -74px -74px;
                    display: flex;
                    align-items: center;
                    padding-right: 18px;

                    &.red {
                        background: #E53030 url(./images/red_header.png) no-repeat;
                        background-position: -74px -74px;
                    }

                    &.orange {
                        background: #FF8531 url(./images/orange_header.png) no-repeat;
                        background-position: -74px -74px;
                    }

                    &.blue {
                        background: #204ACB url(./images/blue_header.png) no-repeat;
                        background-position: -74px -74px;
                    }

                    .title {
                        display: flex;
                        flex-flow: column;
                        padding-left: 18px;


                        span {
                            @include Gilroy_bold;
                            font-size: 15px;
                            color: #fff;
                        }

                        p {
                            font-size: 21px;
                            @include Gilroy_bold;
                            color: #fff;
                        }
                    }

                    img {
                        margin-left: auto;
                        max-width: 125px;
                    }
                }

                .card_content {
                    display: flex;
                    border-bottom: 1px solid #DDE5ED;
                    margin-bottom: 15px;

                    .left_side {
                        width: 100%;
                        max-width: 280px;
                        padding-right: 35px;
                        border-right: 1px solid #DDE5ED;
                        padding-top: 20px;
                        padding-left: 18px;

                        h3 {
                            color: #11BF80;
                            font-size: 14.75px;
                            line-height: 14.75px;
                            margin-bottom: 8px;
                            @include Gilroy_bold;

                            &.red {
                                color: #EE934A;
                            }

                            &.orange {
                                color: #FF8531;
                            }

                            &.blue {
                                color: #204ACB
                            }
                        }

                        .groupno_box {
                            display: flex;
                            margin-top: 10px;
                            margin-bottom: 8px;

                            > div {
                                &:first-child {
                                    width: 91px;
                                    font-weight: normal;
                                    color: #1D252D;
                                    font-size: 13.22px;
                                    @include Urbanist;
                                    font-weight: 400;
                                }

                                &:last-child {
                                    width: 91px;
                                    font-weight: normal;
                                    color: #1D252D;
                                    font-size: 16px;
                                    @include Gilroy_bold;
                                }
                            }
                        }
                    }

                    .right_side {
                        padding-top: 20px;
                        width: 100%;
                        max-width: calc(100% - 270px);
                        padding-left: 27px;
                        padding-right: 27px;

                        p {
                            @include Urbanist;
                            color: #1D252D;
                            font-size: 11px;
                        }

                        img {
                            width: 100%;
                            margin-top: 10px;
                        }
                    }
                }

                .disclaimer {
                    @include Urbanist;
                    font-size: 9px;
                    color: #1D252D;
                    font-weight: 400;
                    padding-left: 18px;
                    padding-bottom: 50px;
                }

                .instruction {
                    display: flex;
                    padding-left: 18px;
                    padding-right: 18px;

                    img {
                        margin-left: auto;
                        width: auto;
                    }

                    h4 {
                        @include Gilroy_bold;
                        font-size: 9px;
                        color: #1D252D;
                    }

                    ul {
                        list-style: none;

                        li {
                            @include Urbanist;
                            color: #000000;
                            font-size: 11px;
                            font-weight: 500;
                        }
                    }
                }

                .footer {
                    padding: 18px;
                    margin-top: 10px;
                    background: #EAF0F6;
                    border-radius: 0 0 8px 8px;

                    p {
                        color: #1D252D;
                        font-size: 9px;
                        line-height: 14px;
                        margin-bottom: 8px;
                        @include Urbanist;
                        font-weight: 500;
                    }

                    > div {
                        display: flex;

                        a {
                            color: #1D47C7;
                            font-size: 9px;
                            letter-spacing: 0.222063px;
                            @include Gilroy_bold;
                            margin-right: 10px;
                            text-decoration: none;
                        }

                        p {
                            color: #000000;
                            font-size: 9px;
                            @include Urbanist;
                            margin-left: auto;
                        }
                    }
                }
            }
        }
    }
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.none_event {
    pointer-events: none !important;
}

.powerdby {
    width: 100%;
    max-width: 110px;
    margin: 0 auto;
    position: absolute;
    bottom: 50px;
    background-size: 100%;
    height: 105px;
    display: flex;
    flex-flow: column;
    background-size: 80px auto;
    background-position: bottom;
    display: none;

    &:after {
        position: absolute;
        bottom: 0;
        text-align: center;
        left: 0;
        right: 0;
        font-size: 12px;
        color: #1D252D;
        @include Urbanist;
        font-weight: 400;
    }
}

.signup_box_parent {
    position: relative;
    width: 100%;
    background: #F5F9FE;

    .container_signup {
        max-width: 100%;

        .signupbox_area {
            margin: 0;
            position: relative;

            > div {
                width: 50%;
                max-width: 50%;

                > div {
                    position: absolute;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    max-width: 1260px;
                    top: 0;
                    padding-top: 30px;
                    padding-left: 15px;

                    .signup_logo {
                        margin-bottom: 30px;

                        img {
                            max-width: 232px;
                            //margin-left: -15px;
                        }
                    }

                    .signuparea {
                        margin-left: 0;
                        max-width: 454px;

                        > div {
                            &.fields {
                                p {
                                    font-size: 36px;
                                    font-weight: 700;
                                    font-stretch: normal;
                                    font-style: normal;
                                    line-height: 54px;
                                    max-width: 100%;
                                    letter-spacing: normal;
                                    color: #1d252d;
                                    margin-bottom: 30px;
                                }

                                input {
                                    background: #FFFFFF !important;
                                    @include Urbanist;
                                    font-weight: 700;
                                    font-size: 16px;
                                    color: #1B252E;

                                    + span {
                                        background: transparent;
                                        z-index: 1;
                                        @include Urbanist;
                                        font-weight: 700 !important;
                                        font-size: 16px !important;
                                        color: #1B252E !important;
                                    }

                                    &:focus, &:not(:placeholder-shown) {
                                        + span {
                                            background: #FFFFFF !important;
                                            border-radius: 5px;
                                        }
                                    }
                                }

                                > div, p {
                                    &.errors {
                                        &:before {
                                            z-index: 9;
                                        }

                                        span {
                                            z-index: 1;
                                            background: #FFFFFF !important;
                                        }
                                    }
                                }

                                .save_continue {
                                    margin-bottom: 30px;
                                    background: #EE934A;
                                    background-image: radial-gradient(circle at bottom right, #F1857F 1%, #EE934A 100%);
                                }
                            }
                        }
                    }
                }
            }

            .image_area {
                img {
                    max-height: 100vh;
                    object-fit: cover;
                    object-position: top left;
                }
            }
        }
    }
}

.signup_video_parent {
    background: #FFFFFF !important;
    padding-top: 70px;
    padding-bottom: 70px;

    .container_sec {
        max-width: 1260px;
        margin: 0 auto;
        display: flex;
        padding-left: 15px;
        padding-right: 15px;

        .video_sec {
            position: relative;
            width: 608px;
            height: 442px;
            background: url(./images/signup_video_bg.png) no-repeat;

            video, iframe {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                max-width: 100%;
                height: 100%;
                z-index: 9;
                background: #000;
                border-radius: 10px;
                opacity: 0;
                transition: all .5s ease-in-out;
                width: 100%;
                border: none;
            }

            .video_play_btn {
                width: 112px;
                height: 112px;
                background: url(./images/video_play_btn.png);
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                margin: 0 auto;
                left: 0;
                right: 0;
                cursor: pointer;
                z-index: 10;
                opacity: 1;
                transition: all .5s ease-in-out;
            }

            &.videoplay {
                background: transparent;

                video, iframe {
                    opacity: 1;
                    transition: all .5s ease-in-out;
                }

                .video_play_btn {
                    opacity: 0;
                    transition: all .5s ease-in-out;
                }
            }
        }

        .video_content {
            margin-left: 100px;
            max-width: 450px;
            padding-top: 30px;

            p {
                margin-bottom: 10px;
                @include Urbanist;
                font-weight: 500;
                color: #000000;
                font-size: 14px;

                &.second {
                    color: #000000;
                    font-size: 20px;
                    @include Urbanist;
                    font-weight: 400;
                    line-height: 34px;
                    margin: 0;
                }
            }

            h2 {
                color: #1D252D;
                @include Urbanist;
                font-weight: 700;
                font-size: 36px;
                line-height: 50px;
                margin-bottom: 18px;
            }

            .signup_btn {
                width: 236px;
                height: 58px;
                border-radius: 8px;
                background-color: #EE934A;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                font-family: "Urbanist", sans-serif !important;
                font-size: 16px;
                font-weight: 600;
                cursor: pointer;
                margin-top: 40px;
                cursor: pointer;
                position: relative;
                transition: all .2s ease-in-out;
                //@include Urbanist;
                background-image: radial-gradient(circle at bottom right, #F1857F 1%, #EE934A 100%);
            }
        }
    }
}

.signup_faq {
    position: relative;
    background: #F5F9FE;
    padding-top: 50px;

    > h2 {
        @include Urbanist;
        font-weight: 700;
        color: #1D252D;
        font-size: 36px;
        margin-bottom: 10px;
        text-align: center;
    }

    > p {
        margin-bottom: 52px;
        color: #1D252D;
        font-weight: 500;
        font-size: 20px;
        text-align: center;
        @include Urbanist;
    }

    .faq_area {
        max-width: 1260px;
        margin: 0 auto;
        margin-top: 50px;
        padding-left: 15px;
        padding-right: 15px;

        .accordion_content {
            .accordion {
                width: 100%;
                max-width: 100%;
            }

            .panel {
                background-color: #f0ebe1;
                box-shadow: 0 1px 7px 0 rgba(139, 166, 193, 0.07);
                background-color: #ffffff;
                border-radius: 8px;
            }

            .panel__label {
                position: relative;
                display: block;
                width: 100%;
                background: none;
                border: none;
                text-align: left;
                padding: 30px 60px 25px 25px;
                font-weight: 500;
                font-size: 17px;
                font-family: inherit;
                -webkit-transition: color 0.2s linear;
                transition: color 0.2s linear;
                cursor: pointer;
                @include Urbanist;
                color: #1D252D;
                letter-spacing: -0.416667px;
                font-size: 20px;
            }

            .panel__label:focus {
                outline: none;
            }

            .panel__label span {
                position: absolute;
                right: 23px;
                width: 44px;
                height: 44px;
                top: 50%;
                transform: translate(0, -50%);
                border-radius: 8px;
            }

            .panel__label:after,
            .panel__label:before {
                content: '';
                position: absolute;
                right: 35px;
                top: 50%;
                width: 22px;
                height: 2px;
                background-color: #1D252D;
            }

            .panel__label:before {
                -webkit-transform: rotate(-90deg);
                transform: rotate(-90deg);
                -webkit-transition: -webkit-transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
                transition: -webkit-transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
                transition: transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
                transition: transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1), -webkit-transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
            }

            .panel[aria-expanded='true'] .panel__content {
                opacity: 1;
            }

            .panel[aria-expanded='true'] .panel__label {
                color: #1D252D;
                padding-bottom: 13px;
                font-weight: 700;
            }

            .panel[aria-expanded='true'] .panel__label:before {
                -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
            }

            .panel__inner {
                overflow: hidden;
                will-change: height;
                -webkit-transition: height 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
                transition: height 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
            }

            .panel__content {
                margin: 5px 25px 25px;
                font-size: 14px;
                color: #000000;
                opacity: 0;
                -webkit-transition: opacity 0.3s linear 0.18s;
                transition: opacity 0.3s linear 0.18s;
                @include Urbanist;
                line-height: 25px;
                font-size: 16px;
                font-weight: 500;

                ul {
                    margin-top: 10px;
                    padding-left: 15px;

                    li {
                        margin-bottom: 8px;

                        a {
                            color: #000000;
                        }
                    }
                }
            }

            .panel:not(:last-child) {
                margin-bottom: 16px;
            }
        }
    }

    .signup_btn {
        width: 236px;
        height: 58px;
        border-radius: 8px;
        background-color: #EE934A;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-family: "Urbanist", sans-serif !important;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
        margin-top: 40px;
        cursor: pointer;
        position: relative;
        transition: all .2s ease-in-out;
        @include Urbanist;
        margin: 48px auto;
        background-image: radial-gradient(circle at bottom right, #F1857F 1%, #EE934A 100%);
    }
}

.discount_card_sec_signup {
    background: #fff;
    position: relative;
    padding-top: 50px;
    padding-bottom: 50px;

    > h2 {
        @include Urbanist;
        font-weight: 700;
        color: #1D252D;
        font-size: 36px;
        margin-bottom: 10px;
        text-align: center;
        margin-bottom: 50px;
    }

    .cards_area {
        max-width: 1260px;
        margin: 0 auto;

        .card_page_content {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            padding-left: 15px;
            padding-right: 15px;

            .card {
                box-shadow: 0 1px 7px 0 rgba(139, 166, 193, 0.07);
                background-color: #ffffff;
                padding: 0;
                width: calc(50% - 16px);
                border-radius: 8px;
                margin-bottom: 24px;

                .header {
                    display: none;
                }

                .content {
                    padding: 0;

                    img {
                        width: 100%;
                    }

                    .image_container {
                        box-shadow: 0 1px 7px 0 rgba(139, 166, 193, 0.07);
                        background-color: #ffffff;
                        border-radius: 8px;

                        .header {
                            min-height: 74px;
                            width: 100%;
                            background: #20C1BC url(./images/card_header_bg.png) no-repeat;
                            border-radius: 8px 8px 0 0;
                            background-position: -74px -74px;
                            display: flex;
                            align-items: center;
                            padding-right: 18px;

                            &.red {
                                background: #E53030 url(./images/red_header.png) no-repeat;
                                background-position: -74px -74px;
                            }

                            &.orange {
                                background: #FF8531 url(./images/orange_header.png) no-repeat;
                                background-position: -74px -74px;
                            }

                            &.blue {
                                background: #204ACB url(./images/blue_header.png) no-repeat;
                                background-position: -74px -74px;
                            }

                            .title {
                                display: flex;
                                flex-flow: column;
                                padding-left: 18px;


                                span {
                                    @include Gilroy_bold;
                                    font-size: 15px;
                                    color: #fff;
                                }

                                p {
                                    font-size: 21px;
                                    @include Gilroy_bold;
                                    color: #fff;
                                }
                            }

                            img {
                                margin-left: auto;
                                max-width: 125px;
                            }
                        }

                        .card_content {
                            display: flex;
                            border-bottom: 1px solid #DDE5ED;
                            margin-bottom: 15px;

                            .left_side {
                                width: 100%;
                                max-width: 280px;
                                padding-right: 35px;
                                border-right: 1px solid #DDE5ED;
                                padding-top: 20px;
                                padding-left: 18px;

                                h3 {
                                    color: #11BF80;
                                    font-size: 14.75px;
                                    line-height: 14.75px;
                                    margin-bottom: 8px;
                                    @include Gilroy_bold;

                                    &.red {
                                        color: #EE934A;
                                    }

                                    &.orange {
                                        color: #FF8531;
                                    }

                                    &.blue {
                                        color: #204ACB
                                    }
                                }

                                .groupno_box {
                                    display: flex;
                                    margin-top: 10px;
                                    margin-bottom: 8px;

                                    > div {
                                        &:first-child {
                                            width: 91px;
                                            font-weight: normal;
                                            color: #1D252D;
                                            font-size: 13.22px;
                                            @include Urbanist;
                                            font-weight: 400;
                                        }

                                        &:last-child {
                                            width: 91px;
                                            font-weight: normal;
                                            color: #1D252D;
                                            font-size: 16px;
                                            @include Gilroy_bold;
                                        }
                                    }
                                }
                            }

                            .right_side {
                                padding-top: 20px;
                                width: 100%;
                                max-width: calc(100% - 270px);
                                padding-left: 27px;
                                padding-right: 27px;

                                p {
                                    @include Urbanist;
                                    color: #1D252D;
                                    font-size: 11px;
                                }

                                img {
                                    width: 100%;
                                    margin-top: 10px;
                                }
                            }
                        }

                        .disclaimer {
                            @include Urbanist;
                            font-size: 9px;
                            color: #1D252D;
                            font-weight: 400;
                            padding-left: 18px;
                            padding-bottom: 15px;
                        }

                        .instruction {
                            display: none;
                        }

                        .footer {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

.signup_enquiry {
    position: relative;
    background: #F5F9FE;
    padding-top: 50px;
    padding-bottom: 50px;

    > h2 {
        @include Urbanist;
        font-weight: 700;
        color: #1D252D;
        font-size: 36px;
        margin-bottom: 10px;
        text-align: center;
    }

    > p {
        margin-bottom: 52px;
        color: #1D252D;
        font-weight: 500;
        font-size: 20px;
        text-align: center;
        @include Urbanist;
    }

    .form_area {
        width: 100%;
        max-width: 938px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;

        > div {
            width: calc(50% - 15px);

            > div.fields {

                > div {
                    position: relative;

                    > span {
                        color: #000000 !important;
                        @include Urbanist;
                        font-size: 14px !important;
                        line-height: normal;
                        font-weight: 400 !important;
                        top: 50%;
                        transform: translate(0, -50%);
                        position: absolute;
                        left: 17px;
                        display: flex;
                        width: auto;
                        padding-left: 3.5px;
                        padding-right: 3.5px;
                        transition: all 0.16s linear;
                        z-index: -1;
                    }

                    &.errors {
                        position: relative;
                        /* input[type="text"], input[type="email"] {
                            border-color: #EE934A !important;
                        }*/
                        span {
                            top: 0;
                            z-index: 1;

                            & + span {
                                top: 50%;
                                color: #000000 !important;
                                z-index: 1;
                                padding-left: 25px;
                                background: #ffffff;
                            }
                        }

                        &:before {
                            content: "!";
                            width: 16px;
                            height: 16px;
                            border-radius: 50%;
                            background: #000000;
                            display: flex;
                            color: #fff;
                            align-items: center;
                            justify-content: center;
                            font-size: 13px;
                            position: absolute;
                            top: 50%;
                            transform: translate(0, -50%);
                            left: 20px;
                            z-index: 9;
                        }
                    }
                }

                input {
                    &[type="text"],
                    &[type="password"],
                    &[type="email"] {
                        width: 100%;
                        height: 52px;
                        display: flex;
                        align-items: center;
                        border-radius: 8px;
                        border: solid 1.1px #dde5ed;
                        background-color: transparent;
                        margin-bottom: 30px;
                        padding-left: 21px;
                        background: #FFFFFF !important;
                        @include Urbanist;
                        font-weight: 700;
                        font-size: 16px;
                        color: #1B252E;

                        + span {
                            background: #fff;
                            z-index: 1;
                            @include Urbanist;
                            font-weight: 700 !important;
                            font-size: 16px !important;
                            color: #1B252E !important;
                        }

                        &:focus, &:not(:placeholder-shown) {
                            + span {
                                background: #FFFFFF !important;
                                border-radius: 5px;
                                top: 0;
                            }
                        }
                    }
                }

                select {
                    width: 100%;
                    height: 52px;
                    display: flex;
                    align-items: center;
                    border-radius: 8px;
                    border: solid 1.1px #dde5ed;
                    background-color: transparent;
                    margin-bottom: 30px;
                    padding-left: 21px;
                    background: #FFFFFF !important;
                    @include Urbanist;
                    font-weight: 700;
                    font-size: 16px;
                    color: #1B252E;
                }

                textarea {
                    width: 100%;
                    height: 134px;
                    display: flex;
                    align-items: center;
                    border-radius: 8px;
                    border: solid 1.1px #dde5ed;
                    background-color: transparent;
                    margin-bottom: 30px;
                    padding-left: 21px;
                    background: #FFFFFF !important;
                    @include Urbanist;
                    font-weight: 700;
                    font-size: 16px;
                    color: #1B252E;
                    resize: none;
                    padding-top: 15px;

                    + span {
                        background: #fff;
                        z-index: 1;
                        @include Urbanist;
                        font-weight: 700 !important;
                        font-size: 16px !important;
                        color: #1B252E !important;
                        top: 20px;
                    }

                    &:focus, &:not(:placeholder-shown) {
                        + span {
                            background: #FFFFFF !important;
                            border-radius: 5px;
                            top: 0;
                        }
                    }
                }
            }
        }
    }

    .status_msg {
        color: #000000 !important;
        @include Urbanist;
        margin-bottom: 15px;
        font-size: 14px !important;
        display: flex;
        justify-content: center;
        align-items: center;

        &:before {
            content: "!";
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: #000000;
            display: flex;
            color: #fff;
            align-items: center;
            justify-content: center;
            font-size: 13px;
            left: 20px;
            z-index: 9;
            margin-right: 10px;
        }
    }

    .save_continue {
        width: 186px;
        height: 58px;
        border-radius: 8px;
        background-color: #EE934A;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-family: "Urbanist", sans-serif !important;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
        margin-top: 40px;
        cursor: pointer;
        position: relative;
        transition: all .2s ease-in-out;
        @include Urbanist;
        margin: 0 auto 50px auto;
        background-image: radial-gradient(circle at bottom right, #F1857F 1%, #EE934A 100%);

        &.loader {
            pointer-events: none;
            position: relative;
            transition: all .2s ease-in-out;
            justify-content: space-around;
            width: auto;
            max-width: 190px;

            &:after {
                content: "";
                border: 4px solid #f65865;
                border-radius: 50%;
                border-top: 4px solid #fff;
                width: 25px;
                height: 25px;
                animation: .5s linear infinite spin;
                position: relative;
                margin-right: 10px;
            }
        }
    }

    .email_contact_text {
        width: 100%;
        max-width: 481px;
        display: flex;
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;

        > div {
            width: 50%;
            display: flex;
            align-items: center;
            height: 35px;
            @include Urbanist;
            font-weight: 500;
            color: #1D252D;
            font-size: 14px;

            &:before {
                min-width: 35px;
                min-height: 35px;
                margin-right: 12px;
                content: '';
            }

            &:first-child {
                &:before {
                    background: url(./images/phone_icon.png) no-repeat;
                    background-size: 100%;
                }
            }

            &:last-child {
                &:before {
                    background: url(./images/email_icon.png) no-repeat;
                    background-size: 100%;
                }
            }
        }
    }
}

.signup_footer {
    display: flex;
    align-items: center;
    height: 100px;
    justify-content: center;
    color: #1D252D;
    @include Urbanist;
    font-size: 14px;
    font-weight: 500;
    background: #fff;
    flex-flow: column;

    > div {
        &:first-child {
            display: flex;
            margin-bottom: 10px;
        }
    }

    a {
        display: flex;
        margin-right: 10px;
        margin-left: 10px;
        color: #000000;
    }
}

.contentarea .content_sec .tabular_box {
    &.pricing_tool_table {
        .table_header.user_page {
            > div {
                &:first-child {
                    width: 100%;
                    max-width: 200px;
                }

                &:nth-child(2) {
                    width: 100%;
                    max-width: 100px;
                }

                &:nth-child(3) {
                    width: 100%;
                    max-width: 300px;
                }

                &:nth-child(4) {
                    width: 100%;
                    max-width: 100px;
                }

                &:nth-child(5) {
                    width: 100%;
                    max-width: 150px;
                }
            }
        }

        .table_body .table_row {
            > div {
                > div {
                    &:first-child {
                        width: 100%;
                        max-width: 200px;
                        cursor: pointer;
                    }

                    &:nth-child(2) {
                        width: 100%;
                        max-width: 100px;
                    }

                    &:nth-child(3) {
                        width: 100%;
                        max-width: 300px;
                    }

                    &:nth-child(4) {
                        width: 100%;
                        max-width: 100px;
                    }

                    &:nth-child(5) {
                        width: 100%;
                        max-width: 150px;
                        display: flex;

                        i {
                            &.deactivate {
                                min-width: 19px;
                                height: 17px;
                                max-height: 17px;
                                max-width: 19px;
                            }

                            &.activate {
                                min-width: 18px;
                                height: 16px;
                                max-height: 18px;
                                max-width: 16px;
                            }
                        }

                        .accept {
                            width: 69px;
                            height: 32px;
                            background: #11BF80;
                            border-radius: 4px;
                            @include Urbanist;
                            font-size: 14px;
                            color: #FFFFFF;
                            font-weight: 600;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-right: 10px;
                            cursor: pointer;
                        }

                        .reject {
                            width: 66px;
                            height: 32px;
                            background: #F1857F;
                            border-radius: 4px;
                            @include Urbanist;
                            font-size: 14px;
                            color: #FFFFFF;
                            font-weight: 600;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}

.contentarea .content_sec {
    .header_bard_button.pricing_page {
        &.loader {
            position: relative;
            transition: all .2s ease-in-out;

            &:before {
                content: "";
                border: 4px solid #EE934A;
                border-radius: 50%;
                border-top: 4px solid #fff;
                width: 25px;
                height: 25px;
                -webkit-animation: .5s linear infinite spin;
                animation: .5s linear infinite spin;
                position: absolute;
                right: 0;
            }
        }
    }

    &.pricing_area {
        .add_url_pop {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(29, 37, 45, .5);
            z-index: 99;

            > div {
                width: 470px;
                border-radius: 4px;
                box-shadow: 0 0 47px 0 rgba(0, 0, 0, 0.23);
                border: solid 1px #dde5ed;
                background-color: #ffffff;
                position: absolute;
                top: 50%;
                left: 0;
                right: 0;
                margin: 0 auto;
                transform: translate(0, -50%);

                .header {
                    display: flex;
                    align-items: center;
                    @include Urbanist;
                    font-weight: 700;
                    color: #1d252d;
                    font-size: 20px;
                    padding: 13px 30px;
                    border-bottom: 1px solid #dde5ed;

                    .close {
                        cursor: pointer;
                        margin-left: auto;

                        img {
                            width: 16px;
                            height: 16px;
                        }
                    }
                }

                .body {
                    padding: 20px 30px 0 30px;

                    p {
                        padding: 0;
                        margin: 0;
                        @include Urbanist;
                        font-weight: 400;
                        font-size: 16px;
                        color: #1D252D;
                        line-height: 24px;
                    }

                    .textarea_box {
                        position: relative;

                        span {
                            color: #000000 !important;
                            @include Urbanist;
                            font-size: 14px !important;
                            line-height: normal;
                            font-weight: 400 !important;
                            top: 50%;
                            transform: translate(0, -50%);
                            position: absolute;
                            left: 17px;
                            display: flex;
                            width: auto;
                            padding-left: 3.5px;
                            padding-right: 3.5px;
                            background: #fff;
                            transition: all 0.16s linear;
                            z-index: -1;
                        }

                        input {
                            &[type="text"] {
                                width: 100%;
                                height: 52px;
                                display: flex;
                                align-items: center;
                                border-radius: 8px;
                                border: solid 1.1px #dde5ed;
                                background-color: transparent;
                                margin-bottom: 27px;
                                padding-left: 21px;
                                @include Urbanist;
                                font-size: 14px;
                                color: #1b252e;
                                font-weight: 700;

                                &:focus,
                                &:not(:placeholder-shown) {
                                    & + span {
                                        top: 0;
                                        z-index: 1;
                                    }
                                }
                            }
                        }

                        select {
                            width: 100%;
                            height: 52px;
                            display: flex;
                            align-items: center;
                            border-radius: 8px;
                            border: solid 1.1px #dde5ed;
                            background-color: transparent;
                            margin-bottom: 27px;
                            padding-left: 21px;
                            @include Urbanist;
                            font-size: 14px;
                            color: #1b252e;
                            font-weight: 700;
                            -webkit-appearance: none;

                            & + span {
                                top: 0;
                                z-index: 1;
                            }
                        }

                        &.select {
                            &:after {
                                content: "";
                                width: 0px;
                                height: 0px;
                                border-left: 5px solid transparent;
                                border-right: 5px solid transparent;
                                border-top: 5px solid #1d252d;
                                display: flex;
                                position: absolute;
                                right: 10px;
                                top: 50%;
                                transform: translate(0, -50%);
                                z-index: 9;
                            }
                        }

                        &.errors {
                            position: relative;

                            span {
                                top: 0;
                                z-index: 1;

                                & + span {
                                    top: 50%;
                                    color: #000000 !important;
                                    z-index: -1;
                                    padding-left: 25px;
                                }
                            }

                            &:before {
                                content: "!";
                                width: 16px;
                                height: 16px;
                                border-radius: 50%;
                                background: #000000;
                                display: flex;
                                color: #fff;
                                align-items: center;
                                justify-content: center;
                                font-size: 13px;
                                position: absolute;
                                top: 50%;
                                transform: translate(0, -50%);
                                left: 20px;
                            }
                        }
                    }
                }

                .footer {
                    margin: 32px 0 30px 0;
                    display: flex;
                    padding: 0 30px;

                    > div {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        @include Urbanist;
                        font-size: 16px;
                        width: 96px;
                        height: 48px;
                        border-radius: 6px;
                        cursor: pointer;

                        &.yes_btn {
                            background-color: #EE934A;
                            color: #ffffff;
                            background-image: radial-gradient(circle at bottom right, #F1857F 1%, #EE934A 100%);

                            &.primary {
                                width: 206px;
                            }
                        }

                        &.no_btn {
                            border: solid 1px #dde5ed;
                            background-color: #f5f8fa;
                            margin-left: 16px;
                            color: #000000;
                        }
                    }
                }
            }
        }
    }
}

.contentarea .content_sec .tabular_box .table_body .table_row.user_page > div > div i {
    margin-right: 15px;
}

.deactivate_ref_link_table {
    .personal_box {
        .user_table {
            .header_row {
                > div {
                    &:first-child {
                        width: 100% !important;
                        max-width: 110px;
                    }

                    &:nth-child(2) {
                        width: 100% !important;
                        max-width: 300px;
                    }

                    &:nth-child(3) {
                        width: 100% !important;
                        max-width: 100px;
                    }
                }
            }

            .user_table_body {
                max-height: 100% !important;
                overflow: visible !important;

                .body_row {
                    > div {
                        &:first-child {
                            width: 100% !important;
                            max-width: 110px;
                        }

                        &:nth-child(2) {
                            width: 100% !important;
                            max-width: 300px;
                        }

                        &:nth-child(3) {
                            width: 100% !important;
                            max-width: 100px;
                        }
                    }

                    &:nth-child(even) {
                        background: #F5F8FA !important;
                    }
                }
            }

            i {
                position: relative;
                cursor: pointer;

                &:before {
                    content: '';
                    width: 0px;
                    height: 0px;
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;
                    border-bottom: 6px solid #DDE5ED;
                    position: absolute;
                    bottom: -12px;
                    left: -1px;
                    display: none;
                }

                span {
                    position: absolute;
                    bottom: 0;
                    padding-left: 10px;
                    padding-right: 10px;
                    background: #FFFFFF;
                    border: 1px solid #DDE5ED;
                    box-shadow: 0 2px 14px #8ba6c126;
                    min-width: max-content;
                    font-style: normal;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    border-radius: 4px;
                    color: #000000;
                    @include Urbanist;
                    font-size: 12px;
                    font-weight: 400;
                    bottom: -40px;
                    right: -10px;
                    display: none !important;
                    z-index: 9;
                }

                &:hover {
                    &:before, span {
                        display: flex !important;
                    }
                }
            }

            i.deactivate {
                min-width: 19px;
                height: 17px;
                background-size: 19px 17px !important;
                background: url(./images/deactivate_user.svg) no-repeat;
            }

            i.activate {
                min-width: 18px;
                height: 16px;
                background-size: 18px 16px !important;
                background: url(./images/activate.svg) no-repeat;
            }

            i.deactive {
                min-width: 16px;
                height: 16px;
                background-size: 16px !important;
                background: url(./images/group_edit.svg);
            }
        }
    }
}

.contentarea .content_sec .tabular_box .table_header.user_page > div:nth-child(7) {
    max-width: 143px;
    min-width: 143px;
    width: 100%;
    display: flex;
}

.contentarea .content_sec .tabular_box .table_body .table_row.user_page > div > div:nth-child(7) {
    max-width: 143px;
    width: 100%;
    min-width: 143px;
    display: flex;
    justify-content: flex-start;

    @media screen and (max-width:767.2px) {
        min-width: auto;
    }
}

@media screen and (min-width:1101px) and (max-width:1199.2px) {
    .signup_box_parent .container_signup .signupbox_area > div > div .signup_logo {
        margin-bottom: 15px;
    }

    .signup_box_parent .container_signup .signupbox_area > div > div .signup_logo img {
        max-width: 200px;
    }

    .signup_box_parent .container_signup .signupbox_area > div > div {
        padding-top: 15px;
    }

    .signup_box_parent .container_signup .signupbox_area > div > div, .container_signup .header {
        padding-top: 15px;
    }

    .signup_box_parent .container_signup .signupbox_area > div > div .signuparea > div.fields p {
        font-size: 30px;
        margin-bottom: 15px;
        line-height: 40px;
    }

    .powerdby {
        width: 100%;
        max-width: 110px;
        margin: 0 auto;
        position: absolute;
        bottom: 50px;
        background-size: 100%;
        height: 105px;
        display: flex;
        flex-flow: column;
        background-size: 80px auto;
        background-position: bottom;

        &:after {
            position: absolute;
            bottom: 0;
            text-align: center;
            left: 0;
            right: 0;
            font-size: 12px;
            color: #1D252D;
            @include Urbanist;
            font-weight: 400;
        }
    }

    .signup_box_parent .container_signup .signupbox_area > div > div .signuparea > div.fields input {
        height: 45px;
        margin-bottom: 15px;
    }

    .signup_box_parent .container_signup .signupbox_area > div > div .signuparea {
        max-width: 400px;
    }

    .left_sidebar {
        min-width: 240px;
        max-width: 240px;
        padding: 10px 20px 0 20px;
    }

    .left_sidebar .Logo_img img {
        max-width: 200px;
    }

    .left_sidebar .navbar {
        margin-top: 20px;
    }

    .left_sidebar .navbar ul li {
        min-height: 55px;
        max-height: 55px;
    }

    .powerdby {
        width: 100%;
        max-width: 110px;
        margin: 0 auto;
        position: absolute;
        bottom: 50px;
        background-size: 100%;
        height: 105px;
        display: flex;
        flex-flow: column;
        background-size: 80px auto;
        background-position: bottom;
        display: none;

        &:after {
            position: absolute;
            bottom: 0;
            text-align: center;
            left: 0;
            right: 0;
            font-size: 12px;
            color: #1D252D;
            @include Urbanist;
            font-weight: 400;
        }
    }

    .contentarea {
        width: calc(100% - 240px);
        margin-left: 240px;
    }

    .contentarea .Header_Area {
        padding: 16px 30px;
    }

    .contentarea .content_sec {
        padding-left: 30px;
        padding-right: 30px;
    }

    .contentarea .content_sec .summary_left_part .Transactions_Summary .body {
        max-width: 100%;
    }

    .contentarea .content_sec .summary_left_part .Transactions_Summary .body .header_title {
        font-size: 18px;
    }

    .contentarea .content_sec .summary_left_part .Transactions_Summary .body .content {
        font-size: 24px;
    }

    .contentarea .content_sec .summary_right_part .Transactions_Summary {
        padding: 24px 10px;
    }

    .contentarea .content_sec .summary_right_part .Transactions_Summary .header {
        font-size: 19px;
        align-items: center;
    }

    .contentarea .content_sec .summary_right_part .Transactions_Summary .header > div {
        font-size: 14px;
    }

    .contentarea .content_sec .summary_right_part .payments_box .contentbox h3 {
        font-size: 19px;
    }

    .contentarea .content_sec .summary_right_part .payments_box .btns_payment_summary {
        min-width: 80px;
        font-size: 14px;
        height: 40px;
    }

    .contentarea .content_sec .summary_right_part .payments_box .contentbox p {
        font-size: 18px;
        padding-right: 10px;
    }

    .contentarea .content_sec .affiliate_url_sec .add_sec {
        width: 250px;
    }

    .contentarea .content_sec .url_card_sec {
        width: calc(100% - 266px);
    }

    .contentarea .content_sec .card_page_content .card .content {
        padding: 18px 10px 0 10px;
    }

    .contentarea .content_sec .card_page_content .card {
        padding: 25px 15px 40px 15px;
    }

    .contentarea .content_sec .card_page_content .card .content .image_container .card_content .left_side {
        max-width: 50%;
    }

    .contentarea .content_sec .card_page_content .card .content .image_container .card_content .right_side {
        max-width: 50%;
    }

    .contentarea .content_sec .card_page_content .card .content .image_container .card_content .left_side h3 {
        font-size: 14px;
    }

    .contentarea .content_sec .card_page_content .card .content .image_container .card_content .right_side {
        padding-left: 15px;
        padding-right: 15px;
    }

    .contentarea .content_sec .card_page_content .card .content .image_container .header .title p {
        font-size: 18px;
    }

    .contentarea .content_sec .card_page_content .card .content .image_container .instruction {
        align-items: center;
    }

    .contentarea .content_sec .card_page_content .card {
        width: calc(50% - 10px);
    }

    .contentarea .content_sec .Account_info_form_parent > div > div {
        margin-right: 8px;
    }

    .contentarea .content_sec .url_card_sec .card.affiliate_refferal .table_area .table_body .table_row > div:first-child,
    .contentarea .content_sec .url_card_sec .card.affiliate_refferal .table_area .table_head > div:first-child {
        word-break: break-all;
        max-width: 50% !important;
        padding-right: 10px;
    }

    .contentarea .content_sec .url_card_sec .card.affiliate_refferal .table_area .table_head > div:nth-child(2),
    .contentarea .content_sec .url_card_sec .card.affiliate_refferal .table_area .table_body .table_row > div:nth-child(2) {
        max-width: 30% !important;
        min-width: 30%;
    }

    .contentarea .content_sec .url_card_sec .card.affiliate_refferal .table_area .table_body .table_row > div:nth-child(3),
    .contentarea .content_sec .url_card_sec .card.affiliate_refferal .table_area .table_head > div:nth-child(3) {
        max-width: 20% !important;
    }

    .contentarea .content_sec .header_bard_button .searchbar {
        max-width: 250px;
    }

    .contentarea .content_sec .header_bard_button .tabbed_button {
        max-width: 300px;
        margin-right: 15px;
    }

    .contentarea .content_sec .header_bard_button .tabbed_button > div {
        font-size: 15px;
    }

    .contentarea .content_sec .header_bard_button .add_group_no_btn {
        width: 180px;
    }

    .contentarea .content_sec .tabular_box .table_body .table_row > div > div:nth-child(6),
    .contentarea .content_sec .tabular_box .table_header > div:nth-child(6) {
        max-width: 80px;
    }

    .contentarea .content_sec .tabular_box .table_header > div:nth-child(4),
    .contentarea .content_sec .tabular_box .table_body .table_row > div > div:nth-child(4) {
        max-width: 114px;
    }

    .contentarea .content_sec .tabular_box .table_header > div:nth-child(3),
    .contentarea .content_sec .tabular_box .table_body .table_row > div > div:nth-child(3) {
        max-width: 250px;
        padding-right: 10px;
    }

    .contentarea .content_sec .tabular_box .table_body .table_row > div > div:nth-child(2),
    .contentarea .content_sec .tabular_box .table_header > div:nth-child(2) {
        max-width: 150px;
    }

    .contentarea .content_sec .tabular_box .table_body .table_row > div > div:first-child,
    .contentarea .content_sec .tabular_box .table_header > div:first-child {
        max-width: 115px;
    }

    .contentarea .content_sec .tabular_box .table_header > div:nth-child(5),
    .contentarea .content_sec .tabular_box .table_body .table_row > div > div:nth-child(5) {
        max-width: 105px;
    }

    .contentarea .content_sec .tabular_box .table_body .table_row > div > div {
        font-size: 15px;
    }

    .contentarea .content_sec .tabular_box .table_body .table_row.user_page > div > div:nth-child(6),
    .contentarea .content_sec .tabular_box .table_header.user_page > div:nth-child(6) {
        max-width: 115px;
    }

    .contentarea .content_sec .tabular_box .table_body .table_row.user_page > div > div i {
        margin-right: 15px;
    }

    .contentarea .content_sec .tabular_box .table_header.user_page > div:nth-child(5),
    .contentarea .content_sec .tabular_box .table_body .table_row.user_page > div > div:nth-child(5) {
        max-width: 70px;
    }

    .contentarea .content_sec .tabular_box .table_header.user_page > div:nth-child(4),
    .contentarea .content_sec .tabular_box .table_body .table_row.user_page > div > div:nth-child(4) {
        max-width: 156px;
    }

    .contentarea .content_sec .tabular_box .table_body .table_row.user_page > div > div:first-child,
    .contentarea .content_sec .tabular_box .table_header.user_page > div:first-child {
        width: 100%;
        max-width: 109px;
        padding-right: 10px;
        word-break: break-all;
    }

    .contentarea .content_sec .asign_edit_pop .add_url_pop > div .header {
        padding: 7px 30px;
    }

    .contentarea .content_sec .asign_edit_pop.edit_user_popup .add_url_pop > div .body {
        padding: 10px 32px 0 32px;
    }

    .contentarea .content_sec .asign_edit_pop.edit_user_popup .add_url_pop > div .body .personal_box > div input[type="text"], .contentarea .content_sec .asign_edit_pop.edit_user_popup .add_url_pop > div .body .personal_box > div input[type="password"], .contentarea .content_sec .asign_edit_pop.edit_user_popup .add_url_pop > div .body .personal_box > div input[type="email"] {
        height: 40px;
    }

    .contentarea .content_sec .asign_edit_pop.edit_user_popup .add_url_pop > div .body .personal_box > div select {
        height: 40px;
    }

    .contentarea .content_sec .asign_edit_pop.edit_user_popup .add_url_pop > div .body .personal_box > div.user_table > .user_table_body .body_row > div {
        min-height: 40px;
    }

    .contentarea .content_sec .asign_edit_pop.edit_user_popup .add_url_pop > div .body .personal_box > div.user_table > .user_table_body .body_row {
        min-height: 40px;
    }

    .contentarea .content_sec .tabular_box.pricing_tool_table .table_header.user_page > div:first-child,
    .contentarea .content_sec .tabular_box.pricing_tool_table .table_body .table_row.user_page > div > div:first-child {
        width: 100%;
        max-width: 200px;
    }

    .contentarea .content_sec .tabular_box.pricing_tool_table .table_header.user_page > div:nth-child(2),
    .contentarea .content_sec .tabular_box.pricing_tool_table .table_body .table_row.user_page > div > div:nth-child(2) {
        width: 100%;
        max-width: 120px;
    }

    .contentarea .content_sec .tabular_box.pricing_tool_table .table_header.user_page > div:nth-child(3),
    .contentarea .content_sec .tabular_box.pricing_tool_table .table_body .table_row.user_page > div > div:nth-child(3) {
        width: 100%;
        max-width: 300px;
    }

    .contentarea .content_sec .tabular_box.pricing_tool_table .table_header.user_page > div:nth-child(4),
    .contentarea .content_sec .tabular_box.pricing_tool_table .table_body .table_row.user_page > div > div:nth-child(4) {
        width: 100%;
        max-width: 100px;
    }

    .contentarea .content_sec .tabular_box.pricing_tool_table .table_header.user_page > div:nth-child(5),
    .contentarea .content_sec .tabular_box.pricing_tool_table .table_body .table_row.user_page > div > div:nth-child(5) {
        width: 100%;
        max-width: 70px;
    }

    .contentarea .content_sec .tabular_box .table_body .table_row > div {
        height: auto;
        min-height: 48px;
    }

    .contentarea .content_sec .tabular_box .table_body .table_row {
        height: auto;
    }

    .container_signup {
        max-width: 90%;
    }
}

@media screen and (min-width:1200px) and (max-width:1367px) {
    .signup_box_parent .container_signup .signupbox_area > div > div .signup_logo {
        margin-bottom: 15px;
    }

    .signup_box_parent .container_signup .signupbox_area > div > div .signup_logo img {
        max-width: 200px;
    }

    .signup_box_parent .container_signup .signupbox_area > div > div, .container_signup .header {
        padding-top: 15px;
    }

    .signup_box_parent .container_signup .signupbox_area > div > div .signuparea > div.fields p {
        font-size: 30px;
        margin-bottom: 15px;
        line-height: 40px;
    }

    .signup_box_parent .container_signup .signupbox_area > div > div .signuparea > div.fields input {
        height: 45px;
        margin-bottom: 15px;
    }

    .signup_box_parent .container_signup .signupbox_area > div > div .signuparea {
        max-width: 400px;
    }

    .left_sidebar {
        min-width: 240px;
        max-width: 240px;
        padding: 10px 18px 0 18px;
    }

    .left_sidebar .Logo_img img {
        max-width: 200px;
    }

    .left_sidebar .navbar {
        margin-top: 20px;
    }

    .left_sidebar .navbar ul li {
        min-height: 45px;
        max-height: 45px;

        a {
            font-size: 15px;

            > div {
                margin-right: 13px;
            }
        }
    }

    .contentarea {
        width: calc(100% - 240px);
        margin-left: 240px;
    }

    .contentarea .Header_Area {
        padding: 16px 30px;
    }

    .contentarea .content_sec {
        padding-left: 30px;
        padding-right: 30px;
    }

    .contentarea .content_sec .summary_left_part .Transactions_Summary .body {
        max-width: 100%;
    }

    .contentarea .content_sec .summary_left_part .Transactions_Summary .body .header_title {
        font-size: 18px;
    }

    .contentarea .content_sec .summary_left_part .Transactions_Summary .body .content {
        font-size: 24px;
    }

    .contentarea .content_sec .summary_right_part .Transactions_Summary {
        padding: 24px 10px;
    }

    .contentarea .content_sec .summary_right_part .Transactions_Summary .header {
        font-size: 19px;
        align-items: center;
    }

    .contentarea .content_sec .summary_right_part .Transactions_Summary .header > div {
        font-size: 14px;
    }

    .contentarea .content_sec .summary_right_part .payments_box .contentbox h3 {
        font-size: 19px;
    }

    .contentarea .content_sec .summary_right_part .payments_box .btns_payment_summary {
        min-width: 80px;
        font-size: 14px;
        height: 40px;
    }

    .contentarea .content_sec .summary_right_part .payments_box .contentbox p {
        font-size: 18px;
        padding-right: 10px;
    }

    .contentarea .content_sec .affiliate_url_sec .add_sec {
        width: 250px;
    }

    .contentarea .content_sec .url_card_sec {
        width: calc(100% - 266px);
    }

    .contentarea .content_sec .card_page_content .card .content {
        padding: 18px 10px 0 10px;
    }

    .contentarea .content_sec .card_page_content .card {
        padding: 25px 15px 40px 15px;
    }

    .contentarea .content_sec .card_page_content .card .content .image_container .card_content .left_side {
        max-width: 50%;
    }

    .contentarea .content_sec .card_page_content .card .content .image_container .card_content .right_side {
        max-width: 50%;
    }

    .contentarea .content_sec .card_page_content .card .content .image_container .card_content .left_side h3 {
        font-size: 14px;
    }

    .contentarea .content_sec .card_page_content .card .content .image_container .card_content .right_side {
        padding-left: 15px;
        padding-right: 15px;
    }

    .contentarea .content_sec .card_page_content .card .content .image_container .header .title p {
        font-size: 18px;
    }

    .contentarea .content_sec .card_page_content .card .content .image_container .instruction {
        align-items: center;
    }

    .contentarea .content_sec .card_page_content .card {
        width: calc(50% - 10px);
    }

    .contentarea .content_sec .Account_info_form_parent > div > div {
        margin-right: 8px;
    }

    .contentarea .content_sec .url_card_sec .card.affiliate_refferal .table_area .table_body .table_row > div:first-child,
    .contentarea .content_sec .url_card_sec .card.affiliate_refferal .table_area .table_head > div:first-child {
        word-break: break-all;
        max-width: 55% !important;
        padding-right: 10px;
    }

    .contentarea .content_sec .url_card_sec .card.affiliate_refferal .table_area .table_head > div:nth-child(2),
    .contentarea .content_sec .url_card_sec .card.affiliate_refferal .table_area .table_body .table_row > div:nth-child(2) {
        max-width: 25% !important;
    }

    .contentarea .content_sec .url_card_sec .card.affiliate_refferal .table_area .table_body .table_row > div:nth-child(3),
    .contentarea .content_sec .url_card_sec .card.affiliate_refferal .table_area .table_head > div:nth-child(3) {
        max-width: 20% !important;
    }

    .contentarea .content_sec .header_bard_button .searchbar {
        max-width: 250px;
    }

    .contentarea .content_sec .header_bard_button .tabbed_button {
        max-width: 330px;
    }

    .contentarea .content_sec .header_bard_button .tabbed_button > div {
        font-size: 15px;
    }

    .contentarea .content_sec .header_bard_button .add_group_no_btn {
        width: 180px;
    }

    .contentarea .content_sec .tabular_box .table_body .table_row > div > div:nth-child(6),
    .contentarea .content_sec .tabular_box .table_header > div:nth-child(6) {
        max-width: 80px;
    }

    .contentarea .content_sec .tabular_box .table_header > div:nth-child(4),
    .contentarea .content_sec .tabular_box .table_body .table_row > div > div:nth-child(4) {
        max-width: 114px;
    }

    .contentarea .content_sec .tabular_box .table_header > div:nth-child(3),
    .contentarea .content_sec .tabular_box .table_body .table_row > div > div:nth-child(3) {
        max-width: 250px;
        padding-right: 10px;
    }

    .contentarea .content_sec .tabular_box .table_body .table_row > div > div:nth-child(2),
    .contentarea .content_sec .tabular_box .table_header > div:nth-child(2) {
        max-width: 150px;
    }

    .contentarea .content_sec .tabular_box .table_body .table_row > div > div:first-child,
    .contentarea .content_sec .tabular_box .table_header > div:first-child {
        max-width: 115px;
    }

    .contentarea .content_sec .tabular_box .table_header > div:nth-child(5),
    .contentarea .content_sec .tabular_box .table_body .table_row > div > div:nth-child(5) {
        max-width: 105px;
    }

    .contentarea .content_sec .tabular_box .table_body .table_row > div > div {
        font-size: 15px;
    }

    .contentarea .content_sec .tabular_box .table_body .table_row.user_page > div > div:nth-child(6),
    .contentarea .content_sec .tabular_box .table_header.user_page > div:nth-child(6) {
        max-width: 140px;
    }

    .contentarea .content_sec .tabular_box .table_body .table_row.user_page > div > div i {
        margin-right: 15px;
    }

    .contentarea .content_sec .tabular_box .table_header.user_page > div:nth-child(5),
    .contentarea .content_sec .tabular_box .table_body .table_row.user_page > div > div:nth-child(5) {
        max-width: 135px;
    }

    .contentarea .content_sec .tabular_box .table_header.user_page > div:nth-child(4),
    .contentarea .content_sec .tabular_box .table_body .table_row.user_page > div > div:nth-child(4) {
        max-width: 156px;
    }

    .contentarea .content_sec .tabular_box .table_body .table_row.user_page > div > div:first-child,
    .contentarea .content_sec .tabular_box .table_header.user_page > div:first-child {
        width: 100%;
        max-width: 109px;
        padding-right: 10px;
        word-break: break-all;
    }

    .contentarea .content_sec .asign_edit_pop .add_url_pop > div .header {
        padding: 7px 30px;
    }

    .contentarea .content_sec .asign_edit_pop.edit_user_popup .add_url_pop > div .body {
        padding: 10px 32px 0 32px;
    }

    .contentarea .content_sec .asign_edit_pop.edit_user_popup .add_url_pop > div .body .personal_box > div input[type="text"], .contentarea .content_sec .asign_edit_pop.edit_user_popup .add_url_pop > div .body .personal_box > div input[type="password"], .contentarea .content_sec .asign_edit_pop.edit_user_popup .add_url_pop > div .body .personal_box > div input[type="email"] {
        height: 40px;
    }

    .contentarea .content_sec .asign_edit_pop.edit_user_popup .add_url_pop > div .body .personal_box > div select {
        height: 40px;
    }

    .contentarea .content_sec .asign_edit_pop.edit_user_popup .add_url_pop > div .body .personal_box > div.user_table > .user_table_body .body_row > div {
        min-height: 40px;
    }

    .contentarea .content_sec .asign_edit_pop.edit_user_popup .add_url_pop > div .body .personal_box > div.user_table > .user_table_body .body_row {
        min-height: 40px;
    }

    .contentarea .content_sec .tabular_box.pricing_tool_table .table_header.user_page > div:first-child,
    .contentarea .content_sec .tabular_box.pricing_tool_table .table_body .table_row.user_page > div > div:first-child {
        width: 100%;
        max-width: 200px;
    }

    .contentarea .content_sec .tabular_box.pricing_tool_table .table_header.user_page > div:nth-child(2),
    .contentarea .content_sec .tabular_box.pricing_tool_table .table_body .table_row.user_page > div > div:nth-child(2) {
        width: 100%;
        max-width: 120px;
    }

    .contentarea .content_sec .tabular_box.pricing_tool_table .table_header.user_page > div:nth-child(3),
    .contentarea .content_sec .tabular_box.pricing_tool_table .table_body .table_row.user_page > div > div:nth-child(3) {
        width: 100%;
        max-width: 230px;
    }

    .contentarea .content_sec .tabular_box.pricing_tool_table .table_header.user_page > div:nth-child(4),
    .contentarea .content_sec .tabular_box.pricing_tool_table .table_body .table_row.user_page > div > div:nth-child(4) {
        width: 100%;
        max-width: 100px;
    }

    .contentarea .content_sec .tabular_box.pricing_tool_table .table_header.user_page > div:nth-child(5),
    .contentarea .content_sec .tabular_box.pricing_tool_table .table_body .table_row.user_page > div > div:nth-child(5) {
        width: 100%;
        max-width: 135px;
    }

    .contentarea .content_sec .tabular_box .table_body .table_row > div {
        height: auto;
        min-height: 48px;
    }

    .contentarea .content_sec .tabular_box .table_body .table_row {
        height: auto;
    }

    .contentarea .content_sec .tabular_box .table_header.user_page > div:nth-child(6) {
        max-width: 115px;
    }

    .contentarea .content_sec .tabular_box .table_header.user_page > div:nth-child(7) {
        max-width: 120px;
        width: 100%;
        display: flex;
    }

    .contentarea .content_sec .tabular_box .table_header.user_page > div:nth-child(5) {
        max-width: 120px;
    }

    .contentarea .content_sec .tabular_box .table_header.user_page > div:nth-child(3) {
        max-width: 180px;
    }

    .contentarea .content_sec .tabular_box .table_header.user_page > div:nth-child(2) {
        max-width: 100px;
    }

    .contentarea .content_sec .tabular_box .table_header.user_page > div:nth-child(4) {
        max-width: 155px;
    }

    .contentarea .content_sec .tabular_box .table_body .table_row.user_page > div > div:nth-child(6) {
        max-width: 115px;
    }

    .contentarea .content_sec .tabular_box .table_body .table_row.user_page > div > div:nth-child(7) {
        max-width: 120px;
        width: 100%;
        display: flex;
    }

    .contentarea .content_sec .tabular_box .table_body .table_row.user_page > div > div:nth-child(5) {
        max-width: 120px;
    }

    .contentarea .content_sec .tabular_box .table_body .table_row.user_page > div > div:nth-child(3) {
        max-width: 180px;
    }

    .contentarea .content_sec .tabular_box .table_body .table_row.user_page > div > div:nth-child(2) {
        max-width: 100px;
    }

    .contentarea .content_sec .tabular_box .table_body .table_row.user_page > div > div:nth-child(4) {
        max-width: 155px;
    }

    .contentarea .content_sec .tabular_box .table_body .table_row > div {
        margin-left: 20px;
        margin-right: 20px;
        max-width: calc(100% - 40px);
    }

    .contentarea .content_sec .tabular_box .table_header {
        margin-left: 20px;
        margin-right: 20px;
        max-width: calc(100% - 40px);
    }

    .contentarea .content_sec .tabular_box .table_body .table_row.user_page > div > div:nth-child(6) > div {
        margin-left: 30px !important;
    }

    .contentarea .content_sec .tabular_box .table_body .table_row.user_page > div > div i {
        margin-right: 15px;
    }
}

@media screen and (min-width:768px) and (max-width:1100px) {
    .container_signup {
        max-width: 95%;
    }

    .signup_box_parent .container_signup .signupbox_area > div > div, .container_signup .header {
        padding-top: 10px;
    }

    .signup_box_parent .container_signup .signupbox_area > div > div .signup_logo img {
        max-width: 145px;
    }

    .signup_box_parent .container_signup .signupbox_area > div > div .signup_logo {
        margin-bottom: 10px;
    }

    .signup_box_parent .container_signup .signupbox_area > div > div .signuparea {
        max-width: 350px;
    }

    .signup_box_parent .container_signup .signupbox_area > div > div .signuparea > div.fields p {
        font-size: 25px;
        line-height: 30px;
        margin-bottom: 15px;
    }

    .signup_box_parent .container_signup .signupbox_area > div > div .signuparea > div.fields input {
        height: 35px;
        margin-bottom: 10px;
        font-size: 14px;
    }

    .signup_box_parent .container_signup .signupbox_area > div > div .signuparea > div.fields input + span,
    .signup_box_parent .container_signup .signupbox_area > div > div .signuparea > div.fields input + span + span {
        font-size: 14px !important;
    }

    .signup_box_parent .container_signup .signupbox_area > div > div .signuparea > div.fields .save_continue {
        height: 35px;
        width: 150px;
        font-size: 14px;
        margin-bottom: 10px;
    }

    .signup_video_parent .container_sec .video_sec {
        width: 50%;
    }

    .signup_video_parent .container_sec .video_content {
        max-width: calc(50% - 40px);
        margin-left: 40px;
    }

    .signup_video_parent .container_sec .video_content h2 {
        font-size: 25px;
        line-height: 38px;
    }

    .discount_card_sec_signup .cards_area .card_page_content .card {
        width: 100%;
    }

    .signup_enquiry .form_area {
        max-width: 95%;
    }

    .left_sidebar {
        min-width: 180px;
        max-width: 180px;
        padding: 10px;
    }

    .left_sidebar .Logo_img img {
        width: 100%;
        max-width: 150px;
    }

    .left_sidebar .navbar ul li {
        min-height: 40px;
        max-height: 40px;
        font-size: 13px;
    }

    .dropdown-active .analytics-menu {
        margin-bottom: 5px;
    }

    .left_sidebar .navbar ul li a {
        font-size: 13px;
    }

    .left_sidebar .navbar ul li a > div, .left_sidebar .navbar ul li > div {
        margin-right: 10px;
        transform: scale(0.7);
    }

    .contentarea {
        width: calc(100% - 180px);
        margin-left: 180px;
    }

    .contentarea .Header_Area {
        padding: 16px 15px;
    }

    .contentarea .content_sec {
        padding-left: 15px;
        padding-right: 15px;
    }

    .contentarea .content_sec .affiliate_url_sec {
        flex-flow: column;
    }

    .contentarea .content_sec .summary_left_part {
        max-width: 100%;
    }

    .contentarea .content_sec .summary_right_part {
        max-width: 100%;
    }

    .contentarea .content_sec .summary_left_part .Transactions_Summary .body.affiliate_body {
        max-width: 70%;
    }

    .contentarea .content_sec .summary_left_part .Transactions_Summary .body {
        max-width: 100%;
    }

    .contentarea .content_sec .summary_left_part .Transactions_Summary .body .header_title {
        font-size: 16px;
    }

    .contentarea .content_sec .affiliate_url_sec .add_sec {
        display: none;
    }

    .contentarea .content_sec .url_card_sec {
        width: 100%;
    }

    .contentarea .content_sec .url_card_sec .card p {
        font-size: 16px;
    }

    .contentarea .content_sec .url_card_sec .card.pricing_tool_box .requesting_box p {
        padding-left: 15px;
        font-size: 16px;
    }

    .contentarea .content_sec .url_card_sec .card.affiliate_refferal .table_area .table_body .table_row > div:first-child, .contentarea .content_sec .url_card_sec .card.affiliate_refferal .table_area .table_head > div:first-child {
        word-break: break-all;
        max-width: 50% !important;
        padding-right: 10px;
    }

    .contentarea .content_sec .url_card_sec .card.affiliate_refferal .table_area .table_head > div:nth-child(2), .contentarea .content_sec .url_card_sec .card.affiliate_refferal .table_area .table_body .table_row > div:nth-child(2) {
        max-width: 25% !important;
    }

    .contentarea .content_sec .url_card_sec .card.affiliate_refferal .table_area .table_body .table_row > div:nth-child(3), .contentarea .content_sec .url_card_sec .card.affiliate_refferal .table_area .table_head > div:nth-child(3) {
        max-width: 20% !important;
    }

    .contentarea .content_sec .Card_Page_header .buttons_area {
        min-width: 382px;
    }

    .contentarea .content_sec .Card_Page_header .buttons_area .button {
        padding: 13px 15px;
        font-size: 14px;
    }

    .contentarea .content_sec .card_page_content .card {
        width: 100%;
    }

    .contentarea .content_sec .card_page_content .card .content {
        padding: 18px 20px 0 20px;
    }

    .contentarea .content_sec .Account_info_form_parent > div > div {
        margin-right: 8px;
    }

    .contentarea .content_sec .Account_info_form_parent {
        padding-right: 33px;
    }
    /*.........For admin.............*/
    .contentarea .content_sec .header_bard_button .searchbar {
        max-width: 100%;
        margin-bottom: 10px;
    }

    .contentarea .content_sec .header_bard_button .tabbed_button {
        max-width: 330px;
        margin-left: 0;

        &.loader:before {
            right: -50px;
            left: auto;
        }
    }

    .contentarea .content_sec .header_bard_button .tabbed_button > div {
        font-size: 15px;
    }

    .contentarea .content_sec .header_bard_button .add_group_no_btn {
        width: 180px;
        margin-left: auto;
    }

    .contentarea .content_sec .tabular_box .table_body .table_row > div > div:nth-child(6),
    .contentarea .content_sec .tabular_box .table_header > div:nth-child(6) {
        max-width: 80px;
    }

    .contentarea .content_sec .tabular_box .table_header > div:nth-child(4),
    .contentarea .content_sec .tabular_box .table_body .table_row > div > div:nth-child(4) {
        max-width: 114px;
    }

    .contentarea .content_sec .tabular_box .table_header > div:nth-child(3),
    .contentarea .content_sec .tabular_box .table_body .table_row > div > div:nth-child(3) {
        max-width: 250px;
        padding-right: 10px;
    }

    .contentarea .content_sec .tabular_box .table_body .table_row > div > div:nth-child(2),
    .contentarea .content_sec .tabular_box .table_header > div:nth-child(2) {
        max-width: 150px;
    }

    .contentarea .content_sec .tabular_box .table_body .table_row > div > div:first-child,
    .contentarea .content_sec .tabular_box .table_header > div:first-child {
        max-width: 115px;
    }

    .contentarea .content_sec .tabular_box .table_header > div:nth-child(5),
    .contentarea .content_sec .tabular_box .table_body .table_row > div > div:nth-child(5) {
        max-width: 105px;
    }

    .contentarea .content_sec .tabular_box .table_body .table_row > div > div {
        font-size: 15px;
    }

    .contentarea .content_sec .tabular_box .table_body .table_row.user_page > div > div:nth-child(6),
    .contentarea .content_sec .tabular_box .table_header.user_page > div:nth-child(6) {
        max-width: 115px;
    }

    .contentarea .content_sec .tabular_box .table_body .table_row.user_page > div > div i {
        margin-right: 15px;
    }

    .contentarea .content_sec .tabular_box .table_header.user_page > div:nth-child(5),
    .contentarea .content_sec .tabular_box .table_body .table_row.user_page > div > div:nth-child(5) {
        max-width: 70px;
    }

    .contentarea .content_sec .tabular_box .table_header.user_page > div:nth-child(4),
    .contentarea .content_sec .tabular_box .table_body .table_row.user_page > div > div:nth-child(4) {
        max-width: 156px;
    }

    .contentarea .content_sec .tabular_box .table_body .table_row.user_page > div > div:first-child,
    .contentarea .content_sec .tabular_box .table_header.user_page > div:first-child {
        width: 100%;
        max-width: 109px;
        padding-right: 10px;
        word-break: break-all;
    }

    .contentarea .content_sec .asign_edit_pop .add_url_pop > div .header {
        padding: 7px 30px;
    }

    .contentarea .content_sec .asign_edit_pop.edit_user_popup .add_url_pop > div .body {
        padding: 10px 32px 0 32px;
    }

    .contentarea .content_sec .asign_edit_pop.edit_user_popup .add_url_pop > div .body .personal_box > div input[type="text"], .contentarea .content_sec .asign_edit_pop.edit_user_popup .add_url_pop > div .body .personal_box > div input[type="password"], .contentarea .content_sec .asign_edit_pop.edit_user_popup .add_url_pop > div .body .personal_box > div input[type="email"] {
        height: 40px;
    }

    .contentarea .content_sec .asign_edit_pop.edit_user_popup .add_url_pop > div .body .personal_box > div select {
        height: 40px;
    }

    .contentarea .content_sec .asign_edit_pop.edit_user_popup .add_url_pop > div .body .personal_box > div.user_table > .user_table_body .body_row > div {
        min-height: 40px;
    }

    .contentarea .content_sec .asign_edit_pop.edit_user_popup .add_url_pop > div .body .personal_box > div.user_table > .user_table_body .body_row {
        min-height: 40px;
    }

    .contentarea .content_sec .tabular_box.pricing_tool_table .table_header.user_page > div:first-child,
    .contentarea .content_sec .tabular_box.pricing_tool_table .table_body .table_row.user_page > div > div:first-child {
        width: 100%;
        max-width: 200px;
    }

    .contentarea .content_sec .tabular_box.pricing_tool_table .table_header.user_page > div:nth-child(2),
    .contentarea .content_sec .tabular_box.pricing_tool_table .table_body .table_row.user_page > div > div:nth-child(2) {
        width: 100%;
        max-width: 120px;
    }

    .contentarea .content_sec .tabular_box.pricing_tool_table .table_header.user_page > div:nth-child(3),
    .contentarea .content_sec .tabular_box.pricing_tool_table .table_body .table_row.user_page > div > div:nth-child(3) {
        width: 100%;
        max-width: 300px;
    }

    .contentarea .content_sec .tabular_box.pricing_tool_table .table_header.user_page > div:nth-child(4),
    .contentarea .content_sec .tabular_box.pricing_tool_table .table_body .table_row.user_page > div > div:nth-child(4) {
        width: 100%;
        max-width: 100px;
    }

    .contentarea .content_sec .tabular_box.pricing_tool_table .table_header.user_page > div:nth-child(5),
    .contentarea .content_sec .tabular_box.pricing_tool_table .table_body .table_row.user_page > div > div:nth-child(5) {
        width: 100%;
        max-width: 70px;
    }

    .contentarea .content_sec .header_bard_button {
        flex-flow: row wrap;
    }

    .contentarea .content_sec .tabular_box .table_header {
        display: none;
    }

    .contentarea .content_sec .tabular_box .table_body .table_row {
        height: auto;
        margin-bottom: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .contentarea .content_sec .tabular_box .table_body .table_row > div {
        display: block;
        height: auto;
    }

    .contentarea .content_sec .tabular_box .table_body .table_row > div > div {
        display: block;
        width: 100%;
        max-width: 100% !important;
    }

    .contentarea .content_sec .tabular_box .table_body .table_row > div > div {
        height: 30px;
        display: flex;
        align-items: center;
        position: relative;
    }

    .contentarea .content_sec .tabular_box .table_footer {
        display: flex;
        position: fixed;
        width: calc(100% - 210px);
        bottom: 0;
        z-index: 99;
        left: 195px;
        right: 15px;
    }

    .contentarea .content_sec .tabular_box:not(.training-module-box) {
        background-color: transparent;
    }

    .contentarea .content_sec .tabular_box .table_body .table_row:nth-child(even) > div {
        background: transparent;
    }

    .contentarea .content_sec .tabular_box .table_body .table_row > div > div:before {
        width: 30%;
    }

    .contentarea .content_sec .tabular_box .table_body .table_row > div > div:first-child:before {
        content: 'Group Number';
    }

    .contentarea .content_sec .tabular_box .table_body .table_row > div > div:nth-child(2):before {
        content: 'User Name';
    }

    .contentarea .content_sec .tabular_box .table_body .table_row > div > div:nth-child(3):before {
        content: 'Parent Affiliate';
    }

    .contentarea .content_sec .tabular_box .table_body .table_row > div > div:nth-child(4):before {
        content: 'Commission($)';
    }

    .contentarea .content_sec .tabular_box .table_body .table_row > div > div:nth-child(5):before {
        content: 'Status';
    }

    .admin_user_page .contentarea .content_sec .tabular_box.refferal_pge_table .table_body .table_row.user_page > div > div:nth-child(5):before {
        content: '';
    }

    .contentarea .content_sec .tabular_box .table_body .table_row > div > div:nth-child(6):before {
        content: 'Actions';
    }

    .contentarea .content_sec .tabular_box .table_body {
        max-height: 100%;
    }

    .contentarea .content_sec .tabular_box .table_body .table_row > div > div:after {
        content: '';
        position: absolute;
        width: 1px;
        height: 100%;
        background: #33abe2;
        left: calc(30% - 15px);
    }

    .contentarea .content_sec .tabular_box .table_body .table_row.user_page > div > div:after {
        left: calc(45% - 25px);
    }

    .contentarea .content_sec .asign_edit_pop .add_url_pop > div {
        max-width: 90%;
    }

    .contentarea .content_sec .tabular_box .table_body .table_row:nth-child(even) > div {
        background: transparent;
    }

    .contentarea .content_sec .tabular_box .table_body .table_row > div > div:before {
        width: 30%;
    }

    .contentarea .content_sec .tabular_box .table_body .table_row.user_page > div > div:before {
        width: 45%;
    }

    .contentarea .content_sec .tabular_box .table_body .table_row.user_page > div > div:first-child:before {
        content: 'User Name';
    }

    .contentarea .content_sec .tabular_box .table_body .table_row.user_page > div > div:nth-child(2):before {
        content: 'Full Name';
    }

    .contentarea .content_sec .tabular_box .table_body .table_row.user_page > div > div:nth-child(3):before {
        content: 'Email';
    }

    .contentarea .content_sec .tabular_box .table_body .table_row.user_page > div > div:nth-child(4):before {
        content: 'Primary Group Number';
    }

    .contentarea .content_sec .tabular_box .table_body .table_row.user_page > div > div:nth-child(5):before {
        content: 'Status';
    }

    .admin_user_page .contentarea .content_sec .tabular_box.refferal_pge_table .table_body .table_row.user_page > div > div:nth-child(5):before {
        content: '';
    }

    .contentarea .content_sec .tabular_box .table_body .table_row.user_page > div > div:nth-child(7):before {
        content: 'Actions';
    }

    .contentarea .content_sec .tabular_box .table_body .table_row.user_page > div > div:nth-child(6):before {
        content: 'Approve Payment';
    }
    /**/
    .contentarea .content_sec .tabular_box.pricing_tool_table .table_body .table_row.user_page > div > div:first-child:before {
        content: 'Full Name ';
    }

    .contentarea .content_sec .tabular_box.pricing_tool_table .table_body .table_row.user_page > div > div:nth-child(2):before {
        content: 'Group Number';
    }

    .contentarea .content_sec .tabular_box.pricing_tool_table .table_body .table_row.user_page > div > div:nth-child(3):before {
        content: 'Domin';
    }

    .contentarea .content_sec .tabular_box.pricing_tool_table .table_body .table_row.user_page > div > div:nth-child(4):before {
        content: 'Status';
    }

    .contentarea .content_sec .tabular_box.pricing_tool_table .table_body .table_row.user_page > div > div:nth-child(5):before {
        content: 'Actions';
    }
}

.mobile_header {
    display: none;
}

.left_side_bar_user_name {
    display: none;
}

.mobile_menu_icon {
    display: none !important;
}

.filter_btn_box {
    display: none !important;
}

.container_signup .signupbox_area .image_area img.mobile_login_bg {
    display: none;
}

.contentarea .content_sec .asign_edit_pop .add_url_pop > div .body .user_list_commision > div .selectize-control.single .selectize-input {
    width: 100%;
    height: 52px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: solid 1.1px #dde5ed;
    background-color: transparent;
    padding-left: 20px;
    font-size: 14px;
    color: #1b252e;
    font-family: "Urbanist", sans-serif !important;
    font-weight: 700;
    box-shadow: none;
}

.selectize-control.single .selectize-input {
    box-shadow: none;
}

.contentarea .content_sec .tabular_box.refferal_pge_table {
    .table_header.user_page > div:nth-child(5) {
        max-width: 150px;
    }

    .table_body {
        .table_row {
            > div {
                > div {
                    &:last-child {
                        max-width: 150px;
                        display: flex;

                        .accept {
                            width: 69px;
                            height: 32px;
                            background: #11BF80;
                            border-radius: 4px;
                            @include Urbanist;
                            font-size: 14px;
                            color: #FFFFFF;
                            font-weight: 600;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-right: 10px;
                            cursor: pointer;
                        }

                        .reject {
                            width: 66px;
                            height: 32px;
                            background: #F1857F;
                            border-radius: 4px;
                            @include Urbanist;
                            font-size: 14px;
                            color: #FFFFFF;
                            font-weight: 600;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width:0) and (max-width:767.2px) {
    .mobile_header {
        display: flex;
        width: 100%;
        height: 56px;
        background: #FFFFFF;
        align-items: center;

        .menu_btn {
            width: 22px;
            height: 19px;
            margin-right: 20px;
            background: url(./images/header_menu.svg) no-repeat;
            background-size: 100%;
            margin-left: 16px;
            display: none;
        }

        .signup_logo {
            width: 106px;
            height: auto;
            margin-left: 10px;

            img {
                width: 100%;
            }
        }
    }

    .container_signup .header {
        height: 56px;
        padding: 0;

        a.logosec {
            width: 106px;

            img {
                max-width: 100%;
            }
        }
    }

    .signup_box_parent .container_signup .signupbox_area {
        flex-flow: column;

        .image_area {
            order: 1;
            display: flex;
        }

        > div {
            width: 100%;
            min-width: 100%;

            &:first-child {
                order: 2;

                .signup_logo {
                    display: none;
                }

                > div {
                    position: relative;
                }
            }
        }
    }

    .container_signup .signupbox_area .signuparea > div.fields .already_link {
        margin-bottom: 35px;
    }

    .signup_video_parent .container_sec {
        flex-flow: column;
        align-items: center;
    }

    .signup_video_parent .container_sec .video_sec {
        width: 100%;
        max-width: 335px;
        height: 244px;
        background-size: 100%;
    }

    .signup_video_parent .container_sec .video_content {
        margin-left: 15px;
        max-width: 335px;
        padding-top: 30px;
        margin-right: 15px;
        text-align: center;
    }

    .signup_video_parent .container_sec .video_content h2 {
        font-size: 24px;
        line-height: 30px;
    }

    .signup_video_parent .container_sec .video_content p.second {
        line-height: 22px;
        font-size: 14px;
    }

    .signup_video_parent .container_sec .video_content .signup_btn {
        margin-left: auto;
        margin-right: auto;
    }

    .signup_faq > h2 {
        font-size: 24px;
    }

    .signup_faq > p {
        font-size: 14px;
    }

    .signup_faq .faq_area {
        padding-left: 0;
        padding-right: 0;
    }

    .signup_faq .faq_area .accordion_content .panel {
        border-radius: 0;
    }

    .signup_faq .faq_area .accordion_content .panel__label:after, .signup_faq .faq_area .accordion_content .panel__label:before {
        top: 42px;
    }

    .signup_faq .faq_area .accordion_content .panel__label {
        font-size: 14px;
    }

    .signup_faq .faq_area .accordion_content .panel__content {
        font-size: 14px;
        line-height: 21px;
    }

    .discount_card_sec_signup .cards_area .card_page_content {
        justify-content: center;
    }

    .discount_card_sec_signup > h2 {
        font-size: 24px;
        line-height: 30px;
    }

    .discount_card_sec_signup .cards_area .card_page_content .card {
        width: 100%;
        max-width: 335px;
    }

    .discount_card_sec_signup .cards_area .card_page_content .card .content .image_container .header .title p {
        font-size: 14px;
    }

    .discount_card_sec_signup .cards_area .card_page_content .card .content .image_container .header .title span {
        font-size: 10px;
    }

    .discount_card_sec_signup .cards_area .card_page_content .card .content .image_container .header img {
        max-width: 84px;
    }

    .discount_card_sec_signup .cards_area .card_page_content .card .content .image_container .card_content .left_side h3 {
        font-size: 10px;
    }

    .discount_card_sec_signup .cards_area .card_page_content .card .content .image_container .card_content .left_side .groupno_box > div:first-child {
        font-size: 10px;
        width: 64px;
    }

    .discount_card_sec_signup .cards_area .card_page_content .card .content .image_container .card_content .left_side .groupno_box > div:last-child {
        font-size: 12px;
        width: 64px;
    }

    .discount_card_sec_signup .cards_area .card_page_content .card .content .image_container .card_content .left_side {
        padding-left: 5px;
        padding-right: 15px;
        max-width: 50%;
    }

    .discount_card_sec_signup .cards_area .card_page_content .card .content .image_container .card_content .right_side {
        padding-top: 20px;
        width: 100%;
        max-width: 50%;
        padding-left: 15px;
        padding-right: 15px;
    }

    .discount_card_sec_signup .cards_area .card_page_content .card .content .image_container .disclaimer {
        font-size: 7px;
    }

    .signup_enquiry > h2 {
        font-size: 24px;
    }

    .signup_enquiry > p {
        font-size: 14px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .signup_enquiry .form_area {
        flex-flow: column;
        padding-left: 15px;
        padding-right: 15px;

        > div {
            width: 100%;
        }
    }

    .signup_enquiry .email_contact_text {
        flex-flow: column;
        padding-left: 15px;
        padding-right: 15px;

        > div {
            width: 100%;
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .signup_footer {
        font-size: 11px;
        text-align: center;
    }

    .discount_card_sec_signup .cards_area .card_page_content .card .content .image_container .header {
        min-height: 50px;
    }

    .signup_box_parent .container_signup .signupbox_area > div:first-child > div {
        padding-right: 15px;
    }

    .signup_box_parent .container_signup .signupbox_area > div > div .signuparea > div.fields p {
        position: absolute;
        top: -95px;
        font-size: 28px;
        color: #FFFFFF;
        line-height: 33px;
        letter-spacing: -1.166667px;
    }

    .container_signup .signupbox_area .signuparea > div.fields input[type="text"], .container_signup .signupbox_area .signuparea > div.fields input[type="password"], .container_signup .signupbox_area .signuparea > div.fields input[type="email"] {
        margin-bottom: 20px;
    }

    .left_sidebar {
        display: flex;
        padding: 0;
        transition: all .5s ease-in-out;
        left: calc(-100% - 272px);

        &.menu_open {
            transition: all .5s ease-in-out;
            left: 0;
            z-index: 10;
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            left: 100%;
            width: calc(100vw - 100%);
            height: 100%;
            background: rgba(29, 37, 45, .5);
        }

        .Logo_img {
            display: none;
        }

        .navbar {
            padding-left: 28px;
            margin-top: 10px;

            ul li {
                min-height: 50px;
                max-height: 50px;
                font-size: 15px;

                a {
                    font-size: 15px;

                    div {
                        transform: scale(.75);
                        margin-right: 10px;
                    }
                }

                > div {
                    transform: scale(.75);
                    margin-right: 10px;
                }
            }
        }

        .left_side_bar_user_name {
            display: flex;
            width: 100%;
            height: 120px;
            background: #000000;
            //background: rgb(48,158,255);
            //background: linear-gradient(133deg, rgba(48,158,255,1) 0%, rgba(68,194,238,1) 100%);
            flex-flow: column;

            .close_btn {
                width: 16px;
                height: 16px;
                background: url(./images/close_btn_left.svg) no-repeat;
                background-size: 100%;
                margin-top: 24px;
                margin-left: 24px;
            }

            .username_email {
                display: flex;
                @include Urbanist;
                font-size: 16px;
                color: #FFFFFF;
                font-weight: 400;
                margin-left: 24px;
                align-items: center;
                margin-top: 15px;

                &:before {
                    content: "";
                    width: 40px;
                    height: 40px;
                    background: url(./images/user_symbol_left.svg) no-repeat;
                    margin-right: 15px;
                }
            }
        }

        .powerdby {
            margin-left: 33px;
            max-width: 95px;
            bottom: 35px;
            height: 40px;
        }
    }

    .contentarea {
        width: 100%;
        margin: 0;

        .mobile_header {
            .menu_btn {
                display: flex;
            }

            .user_icon {
                width: 24px;
                height: 24px;
                background: url(./images/header_user_icon.svg) no-repeat;
                background-size: 100%;
                margin-left: auto;
                margin-right: 25px;
            }
        }

        .Header_Area {
            display: none;
        }

        .content_sec {
            padding-left: 15px;
            padding-right: 15px;

            h2 {
                font-size: 24px;
                padding-left: 17px;
            }

            h2.differ {
                padding-left: 0px;
            }

            .affiliate_url_sec {
                > div {
                    max-width: 100%;
                    width: 100%;
                }
            }
        }
    }

    .contentarea .content_sec .summary_left_part .Transactions_Summary {
        padding: 15px;

        .header {
            font-size: 17px;
            border-bottom: 1px solid #DDE5ED;
            padding-bottom: 15px;
            margin-bottom: 15px;

            > div {
                font-size: 15px;

                img {
                    margin-left: 0;
                }
            }
        }

        .body {
            flex-flow: row wrap;
            max-width: 100%;

            > div {
                min-width: 50%;
                max-width: 50%;
                margin-bottom: 25px;

                &:last-child {
                    margin: 0;
                }

                .header_title {
                    font-size: 14px;
                }
            }
        }
    }

    .contentarea .content_sec .summary_left_part .Transactions_Summary .body.affiliate_body {
        max-width: 100%;

        > div {
            margin: 0;
        }
    }

    .contentarea .content_sec .summary_left_part .payments_box {
        flex-flow: column;

        .contentbox {
            h3 {
                font-size: 18px;
            }

            p {
                font-size: 18px;
                line-height: normal;
                max-width: 202px;
            }
        }

        .btns_payment_summary {
            margin: 0;
            margin-top: 24px;
        }
    }

    .contentarea .content_sec .summary_right_part .Transactions_Summary.group_no_box {
        padding: 15px;

        .header {
            font-size: 20px;
            margin-bottom: 10px;

            > div {
                font-size: 15px;
            }
        }
    }

    .contentarea .content_sec .summary_right_part .payments_box {
        flex-flow: column;
        padding: 15px;

        .contentbox {
            h3, p {
                font-size: 18px;
            }
        }

        .btns_payment_summary {
            margin: 0;
            margin-top: 24px;
        }
    }

    .contentarea .content_sec .affiliate_url_sec {
        flex-flow: column;

        .add_sec {
            margin-bottom: 16px;

            > div {
                img {
                    position: relative;
                }
            }

            p {
                position: relative;
                top: 0;
                left: 0;
                z-index: 9;
                right: 31px;
                font-size: 28px;
                @include Urbanist;
                font-weight: 800;
                padding: 19px;
            }
        }
    }

    .contentarea .content_sec .url_card_sec .card {
        padding: 15px;

        > div.id_box {
            margin: 0;
            width: 50%;

            p {
                font-size: 12px;
            }
        }
    }

    .contentarea .content_sec .url_card_sec .card.pricing_tool_box .requesting_box {
        display: flex;
        align-items: flex-start;
        flex-flow: column;
        max-height: 100%;

        p {
            padding-left: 17px;
            padding-top: 24px;
        }

        .btn_box {
            display: flex;
            flex-flow: column;
            margin: 0;
            padding-left: 17px;
            padding-top: 15px;
            align-items: flex-start;

            .btn_setup {
                margin-top: 24px;
                margin-right: 0;
                margin-bottom: 24px;
            }
        }
    }

    .contentarea .content_sec .url_card_sec .card.affiliate_refferal {
        padding: 15px;

        .card_header {
            padding-bottom: 15px;
            border-bottom: 1px solid #DDE5ED;

            p {
                @include Urbanist;
                font-size: 16px;
                font-weight: 700;
                color: #1D252D
            }
        }
    }

    .contentarea .content_sec .url_card_sec .card.affiliate_refferal .table_area {
        margin-top: 0;

        .table_head {
            display: none;

            & + div {
                .table_body {
                    padding-left: 0;

                    .table_row {
                        position: relative;
                        border-bottom: 1px solid #DDE5ED;
                        flex-flow: column;
                        align-items: flex-start;
                        padding-top: 20px;
                        padding-bottom: 20px;

                        > div {
                            width: 100%;
                            max-width: calc(100% - 40px);
                            word-break: break-all;
                            margin-bottom: 15px;
                            font-size: 14px;
                            font-weight: 600;
                            color: #1D252D;
                            display: flex;
                            flex-flow: column;
                            align-items: flex-start;

                            &:before {
                                content: "";
                                @include Urbanist;
                                font-size: 12px;
                                font-weight: 400;
                                color: #000000;
                                margin-bottom: 8px;
                            }

                            &:first-child:not(.nourl):before {
                                content: "Referral URL"
                            }

                            &:nth-child(2):before {
                                content: "Number of Signups";
                            }

                            &:last-child {
                                display: none;
                            }

                            &.nourl {
                                display: block;
                            }
                        }

                        &:last-child {
                            border: none;
                        }
                    }
                }
            }
        }
    }

    .mobile_menu_icon {
        display: flex !important;
        width: 4px !important;
        height: 20px;
        position: absolute !important;
        top: 30px;
        right: 0;
        background: url(./images/mobile_menu_icon.svg) no-repeat;
        margin: 0 !important;
    }

    .action_menu_open {
        position: absolute;
        display: flex !important;
        flex-flow: column !important;
        background: #FAFAFA;
        max-width: 123px !important;
        right: 0;
        top: 30px;
        padding-bottom: 10px;
        overflow: hidden;
        box-shadow: 0 8px 8px 0 rgba(0, 0, 0, .24);

        &:before {
            content: 'Action' !important;
            @include Urbanist;
            font-size: 14px !important;
            color: #000000 !important;
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 24px;
            border-bottom: 1px solid rgba(0, 0, 0, .12);
            width: 100% !important;
            max-width: 100% !important;
        }

        > div {
            padding-left: 24px;
            width: 100% !important;
            max-width: 100% !important;
            height: 40px;
            align-items: center;
            display: flex;

            span {
                font-size: 14px !important;
                font-weight: 400 !important;
                color: #1D252D !important;
            }

            img {
                display: none;
            }
        }
    }

    .contentarea .content_sec .url_card_sec .card.pricing_tool_box .requestbox_popup > div {
        transform: translate(0);
        top: auto;
        bottom: 0;
        width: 100%;
        border-radius: 4px 4px 0 0;
    }

    .contentarea .content_sec .url_card_sec .card.pricing_tool_box .requestbox_popup > div .header,
    .contentarea .content_sec .url_card_sec .card.pricing_tool_box .pricing_setup_pop > div .header {
        font-size: 16px;
        margin-bottom: 15px;
    }

    .contentarea .content_sec .url_card_sec .card.pricing_tool_box .requestbox_popup > div .body p {
        font-size: 18px;
        line-height: 27px;
    }

    .contentarea .content_sec .url_card_sec .card.pricing_tool_box .pricing_setup_pop > div,
    .contentarea .content_sec .url_card_sec .card.affiliate_refferal .add_url_pop > div {
        transform: translate(0);
        top: auto;
        bottom: 0;
        width: 100%;
        border-radius: 4px 4px 0 0;
    }

    .contentarea .content_sec .url_card_sec .card.affiliate_refferal .add_url_pop > div .header {
        border: none;
        font-size: 16px;
    }

    .contentarea .content_sec .refferals_list {
        width: calc(100% + 30px);
        margin-left: -15px;

        .refferal_list_box {
            padding: 0;
            background: transparent;

            .heading, .subheading {
                background: #FFFFFF;
                padding: 10px 15px;
                margin: 0;
            }

            .subheading {
                padding-top: 0;
                border-bottom: 1px solid #DDE5ED;
            }

            .refferal_table {
                margin: 0;

                .header {
                    display: none;
                }

                .content {
                    .trow {
                        background: #FFFFFF;
                        margin-bottom: 8px;
                    }
                }
            }
        }
    }

    .account_info_page {
        .contentarea .content_sec {
            padding: 0;

            h2, p {
                padding-left: 24px;
            }

            h2 {
                padding-top: 24px;
            }

            p {
                font-size: 12px;
            }

            .Account_info_form_parent {
                padding: 15px;

                > div {
                    > div {
                        width: 100%;
                    }
                }

                .changepassword_box .pricing_setup_pop > div {
                    transform: translate(0);
                    top: auto;
                    bottom: 0;
                    width: 100%;
                }
            }
        }
    }

    .contentarea .content_sec .Account_info_form_parent.loader:before {
        right: 20px;
    }

    .claim_dashboard_page {
        .contentarea .content_sec {
            padding: 0;

            .claim_dashboard {
                h2 {
                    background: #FFFFFF;
                    padding-top: 39px;
                    margin: 0;
                }

                .dashboard_box {
                    img {
                        max-width: 100%;
                    }

                    p {
                        text-align: center;
                    }
                }

                .bottom_text {
                    padding: 0 15px 20px 15px;
                    font-size: 12px;
                }
            }
        }
    }

    .contentarea .content_sec .Card_Page_header .h2_title p,
    .contentarea .content_sec .Card_Page_header .buttons_area {
        display: none;
    }

    .contentarea .content_sec .Card_Page_header {
        border: none;
    }

    .contentarea .content_sec .card_page_content {
        padding-left: 0;
        padding-right: 0;
        width: calc(100% + 30px);
        margin-left: -15px;

        > .card {
            width: 100%;
            max-width: 100%;
            border-radius: 0;
            padding: 15px;

            .content {
                padding: 0;
                margin-top: 13px;
            }
        }
    }

    .contentarea .content_sec .card_page_content .card .content .image_container .header .title span {
        font-size: 10px;
    }

    .contentarea .content_sec .card_page_content .card .content .image_container .header .title p {
        font-size: 14px;
    }

    .contentarea .content_sec .card_page_content .card .content .image_container .header img {
        max-width: 80px;
    }

    .contentarea .content_sec .card_page_content .card .content .image_container .header {
        min-height: 47px;
    }

    .contentarea .content_sec .card_page_content .card .content .image_container .card_content .left_side {
        max-width: 50%;
        padding-right: 10px;
    }

    .contentarea .content_sec .card_page_content .card .content .image_container .card_content .left_side h3 {
        font-size: 9.8px;
        line-height: 9.8px;
    }

    .contentarea .content_sec .card_page_content .card .content .image_container .card_content .left_side .groupno_box > div:first-child {
        font-size: 9px;
    }

    .contentarea .content_sec .card_page_content .card .content .image_container .card_content .left_side .groupno_box > div:last-child {
        font-size: 9px;
    }

    .contentarea .content_sec .card_page_content .card .content .image_container .card_content .left_side {
        max-width: 123px;
    }

    .contentarea .content_sec .card_page_content .card .content .image_container .card_content .right_side {
        max-width: calc(100% - 123px);
        padding-left: 10px;
        padding-right: 10px;
    }

    .contentarea .content_sec .card_page_content .card .content .image_container .instruction {
        align-items: center;
    }

    .contentarea .content_sec .card_page_content .card .content .image_container {
        box-shadow: 0 1px 18px 0 rgba(29, 37, 45, .5);
    }

    .contentarea .content_sec .card_page_content .card .content .image_container .instruction ul li {
        font-size: 7px;
    }

    .contentarea .content_sec .card_page_content .card .content .image_container .footer p {
        font-size: 8px;
    }

    .contentarea .content_sec .accordion_content .accordion {
        width: calc(100% + 30px);
        max-width: calc(100% + 30px);
        margin-left: -15px;
    }

    .contentarea .content_sec .accordion_content .panel__label {
        font-size: 14px;
    }

    .contentarea .content_sec .accordion_content .panel__content {
        font-size: 14px;
        line-height: 21px;
    }

    .contentarea .content_sec .accordion_content .panel__label span {
        border: none;
    }

    .contentarea .content_sec .accordion_content .panel__label:after, .contentarea .content_sec .accordion_content .panel__label:before {
        background: #1D252D;
    }

    .container_signup {
        max-width: 90%;
    }

    .container_signup .signupbox_area {
        flex-flow: column;
        margin-top: 10px;

        .image_area > img {
            &.mobile_login_bg {
                width: calc(100% + 33px);
                margin-left: -17px;
                margin-bottom: 20px;
                display: flex;

                & + img {
                    display: none;
                }
            }
        }
    }

    .container_signup .signupbox_area .signuparea {
        margin: 0 auto;
        margin-left: auto !important;
    }

    .container_signup .signupbox_area .signuparea > div.fields .save_continue {
        margin-bottom: 30px;
        width: 100%;
    }

    .contentarea .content_sec .header_bard_button .searchbar {
        display: none;
    }

    .contentarea .content_sec .tabular_box {
        margin-top: 0;
    }

    .filter_btn_box {
        display: flex !important;
        height: 53px;
        background: #FFFFFF;
        border-bottom: 1px solid #DDE5ED;
        min-width: 100%;
        align-items: center;
        padding-left: 24px;
        padding-right: 24px;

        .filtered_btn {
            margin-left: auto;
            @include Urbanist;
            font-size: 16px;
            color: #000000;
            font-weight: 700;
        }

        .filtered_btn_text {
            @include Urbanist;
            font-size: 18px;
            font-weight: 700;
            color: #1D252D;
        }
    }

    .contentarea .content_sec .header_bard_button .add_group_no_btn {
        display: none;
    }

    .contentarea .content_sec .header_bard_button .tabbed_button {
        display: none;
    }

    .contentarea .content_sec.admin_sec {
        padding: 28px 0 0 0;
    }

    .contentarea .content_sec .header_bard_button {
        position: relative;
    }

    .contentarea .content_sec .header_bard_button .tabbed_button {
        &.open {
            display: flex;
            position: absolute;
            top: 0;
            flex-flow: column;
            right: 0;
            left: auto;
            max-width: 120px;
            background: #FAFAFA;
            padding: 0;
            box-shadow: 0 8px 8px 0 rgba(0, 0, 0, .24);
            z-index: 11;

            > div {
                width: 100%;
                border: none;
                justify-content: flex-start;
                padding-left: 11px;
                @include Urbanist;
                font-weight: 400;
                font-size: 14px;
                color: #1D252D;
                background: transparent !important;
            }
        }
    }

    .mobile_header .header_searcharea {
        position: absolute;
        z-index: 9;
        right: 65px;

        input[type="text"] {
            width: 50px;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            border-radius: 20px;
            margin: 3px 0;
            background: #fff url(./images/search_header.svg) no-repeat;
            border: none;
            background-size: 26px auto;
            background-position: right;
            height: 40px;
            padding-left: 10px;
            border: 1px solid transparent;
        }

        input[type="text"]:focus {
            background: #fff;
            color: #1D252D;
            width: 150px;
            border: 1px solid rgba(0,0,0,.45);
            margin-left: 35px;
            outline: none;
        }

        input[type="text"] {
            -webkit-transition: all 0.7s ease 0s;
            -moz-transition: all 0.7s ease 0s;
            -o-transition: all 0.7s ease 0s;
            transition: all 0.7s ease 0s;
        }
    }

    .contentarea .content_sec .tabular_box .table_header {
        display: none;
    }

    .contentarea .content_sec .tabular_box {
        margin-bottom: 0;
    }

    .contentarea .content_sec .tabular_box .table_body {
        min-height: calc(100vh - 239px);
        max-height: calc(100vh - 239px);
        background: #F4F7FC;
        overflow: auto;
    }

    .contentarea .content_sec .tabular_box .table_body .table_row:nth-child(even) > div {
        background: transparent;
    }

    .contentarea .content_sec .refferals_list .refferal_list_box .refferal_table .content .trow {
        flex-wrap: wrap;

        > div {
            width: calc(100%/2);
            font-size: 14px;
            font-weight: 600;
            font-family: "Urbanist", sans-serif !important;

            &:before {
                content: '';
                ont-family: "Urbanist", sans-serif !important;
                font-size: 12px;
                font-weight: 400;
                color: #000000;
                margin-bottom: 6px;
                display: block;
            }

            &:first-child:not(.no_record_found):before {
                content: 'User Name';
            }

            &:nth-child(2):before {
                content: 'Group Number';
            }

            &:nth-child(3):before {
                content: 'Email';
            }

            &:nth-child(4):before {
                content: 'Referral Date';
            }
        }
    }

    .contentarea .content_sec .summary_left_part .Transactions_Summary .body .footer.ddl:after {
        right: 20px;
    }

    .loader_mobile {
        &.loader {
            pointer-events: none;
            position: relative;
            transition: all .2s ease-in-out;
            align-items: center;

            &:before {
                content: "";
                border: 4px solid #EE934A;
                border-radius: 50%;
                border-top: 4px solid #f4f7fc;
                width: 25px;
                height: 25px;
                -webkit-animation: .5s linear infinite spin;
                animation: .5s linear infinite spin;
                position: absolute;
                right: 20px;
                top: -46px;
            }
        }
    }

    .contentarea .content_sec .tabular_box .table_body .table_row {
        height: auto;
        padding-top: 26px;
        padding-bottom: 26px;
        margin-bottom: 8px;
        position: relative;

        .mobile_menu_icon {
            right: 15px !important;
        }

        > div {
            display: flex;
            flex-flow: row wrap;
            margin-left: 0;
            margin-right: 0;
            max-width: 100%;
            padding-left: 24px;
            padding-right: 24px;
            height: auto;
            align-items: flex-start;

            > div {
                display: flex;
                flex-flow: column;
                @include Urbanist;
                font-size: 14px;
                font-weight: 600;
                color: #1D252D;
                margin-bottom: 20px;

                &:before {
                    content: '';
                    @include Urbanist;
                    font-size: 12px;
                    font-weight: 400;
                    color: #000000;
                    margin-bottom: 6px;
                }

                &:first-child {
                    width: 100%;
                    max-width: 95px;

                    &:before {
                        content: 'Group Number';
                    }
                }

                &:nth-child(2) {
                    width: 100%;
                    max-width: 130px;
                    margin-left: auto;

                    &:before {
                        content: 'User Name';
                    }
                }

                &:nth-child(3) {
                    width: 100%;
                    max-width: 100%;

                    &:before {
                        content: 'Parent Affiliate';
                    }
                }

                &:nth-child(4) {
                    width: 100%;
                    max-width: 95px;

                    &:before {
                        content: 'Commission($)';
                    }
                }

                &:nth-child(5) {
                    width: 100%;
                    max-width: 130px;
                    margin-left: auto;

                    &:before {
                        content: 'Status';
                    }
                }

                &:nth-child(7) {
                    display: none;
                }
            }
        }

        .action_menu_open {
            position: absolute;
            display: flex !important;
            flex-flow: column !important;
            background: #FAFAFA;
            max-width: 123px !important;
            right: 15px;
            top: 30px;
            padding-bottom: 10px;
            overflow: hidden;
            box-shadow: 0 8px 8px 0 rgba(0, 0, 0, .24);
            min-width: 123px;

            &:before {
                content: 'Action' !important;
                @include Urbanist;
                font-size: 14px !important;
                color: #000000 !important;
                padding-top: 10px;
                padding-bottom: 10px;
                padding-left: 24px;
                border-bottom: 1px solid rgba(0, 0, 0, .12);
                width: 100% !important;
                max-width: 100% !important;
            }

            > i {
                padding-left: 24px;
                width: 100% !important;
                max-width: 100% !important;
                height: 40px !important;
                align-items: center;
                display: flex;
                background: transparent !important;
                margin-right: 0;

                span {
                    font-size: 14px !important;
                    font-weight: 400 !important;
                    color: #1D252D !important;
                    display: flex !important;
                    position: relative !important;
                    padding: 0;
                    background: transparent;
                    border: none;
                    box-shadow: none;
                    min-width: 100%;
                    bottom: auto;
                    bottom: auto;
                    right: auto !important;
                    left: auto !important;
                }

                img {
                    display: none;
                }
            }
        }
    }

    .contentarea .content_sec .tabular_box .table_footer {
        z-index: 9;
        justify-content: flex-start;
        padding-left: 15px;
        padding-right: 15px;

        .pagination {
            align-items: center;

            li {
                min-height: 16px;

                &.prev {
                    margin-right: 5px;
                }

                &.numbers {
                    min-width: 16px;
                    height: 16px;
                    margin-right: 2px;
                    margin-left: 2px;

                    a {
                        font-size: 11px;
                    }
                }

                &.first {
                    margin-right: 6px;
                }

                &.last {
                    margin-left: 6px;
                }
            }
        }
    }

    .contentarea .content_sec .resetgroup_box .add_url_pop > div {
        width: 100%;
        top: auto;
        bottom: 0;
        transform: translate(0);
    }

    .contentarea .content_sec .resetgroup_box .add_url_pop > div .header {
        font-size: 16px;
    }

    .contentarea .content_sec .asign_edit_pop .add_url_pop > div {
        width: 100%;
        max-height: 100%;
        top: auto;
        height: calc(100% - 20px) !important;
        transform: none;
        bottom: 0;

        .header {
            padding: 8px 15px;
            font-size: 16px;
        }

        .body {
            padding: 0 15px;

            .user_list_commision {
                display: flex;
                flex-flow: column;
                padding-bottom: 0;

                > div {
                    width: 100%;
                    max-width: 100%;
                    margin-bottom: 15px;
                }
            }

            .overidebox {
                min-height: 200px;

                .override_body {
                    height: 210px;
                    overflow: auto;

                    .over_ride_table {
                        .theader {
                            display: none;
                        }

                        .tbody_parent {
                            max-height: 185px;
                            overflow-y: auto;

                            .table_body {
                                height: 75px;
                                border: 1px solid #DDE5ED;
                                border-radius: 4px;
                                padding-left: 10px;
                                padding-right: 10px;
                                margin-bottom: 10px;

                                > div {
                                    display: flex;
                                    color: #1D252D;
                                    font-weight: 600;
                                    font-size: 14px;
                                    flex-flow: column;

                                    &:before {
                                        content: '';
                                        @include Urbanist;
                                        font-size: 11px;
                                        font-weight: 400;
                                        color: #000000;
                                        margin-bottom: 5px;
                                    }

                                    &:first-child {
                                        width: 100%;
                                        max-width: calc(100% - 80px);

                                        &:before {
                                            content: "User";
                                        }
                                    }

                                    &:nth-child(2) {
                                        width: 100%;
                                        max-width: 80px;

                                        &:before {
                                            content: "Commisssion $";
                                        }
                                    }

                                    &:last-child {
                                        width: 50px;
                                        min-width: 50px;
                                    }
                                }

                                &.add_new, &.edit_row {
                                    flex-flow: column;
                                    border-top: 1px solid #DDE5ED;
                                    border-radius: 8px;
                                    height: auto;
                                    padding-left: 10px;
                                    padding-right: 10px;

                                    > div {
                                        width: 100%;
                                        padding: 0;
                                        margin: 0;
                                        margin-top: 10px;
                                        max-width: 100%;

                                        &:before {
                                            display: none;
                                        }

                                        &:last-child {
                                            margin-bottom: 10px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .contentarea .content_sec.pricing_area {
        padding: 0;

        h2 {
            padding-top: 28px;
        }

        .tabular_box {
            &.pricing_tool_table {
                .table_body {
                    min-height: calc(100vh - 186px);
                    max-height: calc(100vh - 186px);

                    .table_row.user_page {
                        > div {
                            > div {
                                &:first-child {
                                    max-width: 110px;

                                    &:before {
                                        content: 'Full Name';
                                    }
                                }

                                &:nth-child(2) {
                                    max-width: 90px;

                                    &:before {
                                        content: 'Group Number';
                                    }
                                }

                                &:nth-child(3) {
                                    max-width: 100%;

                                    &:before {
                                        content: 'Domain';
                                    }
                                }

                                &:nth-child(4) {
                                    max-width: 100%;

                                    &:before {
                                        content: 'Status';
                                    }
                                }

                                &:nth-child(5) {
                                    max-width: 100%;
                                    display: none;

                                    &:before {
                                        content: '';
                                    }

                                    &.accept_reject {
                                        display: flex !important;
                                        flex-flow: row;
                                        margin-bottom: 0;

                                        > div {
                                            width: calc(50% - 15px);
                                        }
                                    }
                                }

                                &:nth-child(6) {
                                    display: none;
                                }

                                &.action_menu_open {
                                    position: absolute;
                                    display: flex !important;
                                    flex-flow: column !important;
                                    background: #FAFAFA;
                                    max-width: 145px !important;
                                    right: 15px;
                                    top: 30px;
                                    padding-bottom: 10px;
                                    overflow: hidden;
                                    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, .24);
                                    min-width: 123px;

                                    &:before {
                                        content: 'Action' !important;
                                        @include Urbanist;
                                        font-size: 14px !important;
                                        color: #000000 !important;
                                        padding-top: 10px;
                                        padding-bottom: 10px;
                                        padding-left: 24px;
                                        border-bottom: 1px solid rgba(0, 0, 0, .12);
                                        width: 100% !important;
                                        max-width: 100% !important;
                                        box-sizing: border-box;
                                    }

                                    > i {
                                        padding-left: 24px;
                                        width: 100% !important;
                                        max-width: 100% !important;
                                        height: 40px !important;
                                        align-items: center;
                                        display: flex;
                                        background: transparent !important;
                                        margin-right: 0;

                                        span {
                                            font-size: 14px !important;
                                            font-weight: 400 !important;
                                            color: #1D252D !important;
                                            display: flex !important;
                                            position: relative !important;
                                            padding: 0;
                                            background: transparent;
                                            border: none;
                                            box-shadow: none;
                                            min-width: 100%;
                                            bottom: auto;
                                            bottom: auto;
                                            right: auto !important;
                                            left: auto !important;
                                        }

                                        img {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .contentarea .content_sec.pricing_area .add_url_pop > div {
        width: 100%;
        top: auto;
        transform: translate(0);
        bottom: 0;
    }

    .admin_user_page {
        .contentarea .content_sec {
            padding: 0;

            h2 {
                padding-top: 28px;
            }

            .tabular_box {

                .table_body {
                    min-height: calc(100vh - 186px);
                    max-height: calc(100vh - 186px);

                    .table_row.user_page {
                        > div {
                            > div {
                                &:first-child {
                                    max-width: 50%;

                                    &:before {
                                        content: 'User Name';
                                    }
                                }

                                &:nth-child(2) {
                                    max-width: 50%;

                                    &:before {
                                        content: 'Full Name';
                                    }
                                }

                                &:nth-child(3) {
                                    max-width: 100%;

                                    &:before {
                                        content: 'Email';
                                    }
                                }

                                &:nth-child(4) {
                                    max-width: 50%;

                                    &:before {
                                        content: 'Primary Group Number';
                                    }
                                }

                                &:nth-child(5) {
                                    max-width: 50%;

                                    &:before {
                                        content: 'Status';
                                    }
                                }

                                &:nth-child(6) {
                                    display: none;
                                }

                                &.action_menu_open {
                                    position: absolute;
                                    display: flex !important;
                                    flex-flow: column !important;
                                    background: #FAFAFA;
                                    max-width: 175px !important;
                                    right: 15px;
                                    top: 30px;
                                    padding-bottom: 10px;
                                    overflow: hidden;
                                    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, .24);
                                    min-width: 175px;
                                    z-index: 9;

                                    &:before {
                                        content: 'Action' !important;
                                        @include Urbanist;
                                        font-size: 14px !important;
                                        color: #000000 !important;
                                        padding-top: 10px;
                                        padding-bottom: 10px;
                                        padding-left: 24px;
                                        border-bottom: 1px solid rgba(0, 0, 0, .12);
                                        width: 100% !important;
                                        max-width: 100% !important;
                                    }

                                    > i {
                                        padding-left: 24px;
                                        width: 100% !important;
                                        max-width: 100% !important;
                                        height: 40px !important;
                                        align-items: center;
                                        display: flex;
                                        background: transparent !important;
                                        margin-right: 0;

                                        span {
                                            font-size: 14px !important;
                                            font-weight: 400 !important;
                                            color: #1D252D !important;
                                            display: flex !important;
                                            position: relative !important;
                                            padding: 0;
                                            background: transparent;
                                            border: none;
                                            box-shadow: none;
                                            min-width: 100%;
                                            bottom: auto;
                                            bottom: auto;
                                            right: auto !important;
                                            left: auto !important;
                                        }

                                        img {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .contentarea .content_sec .asign_edit_pop.edit_user_popup .add_url_pop > div .body .personal_box > div {
        flex-flow: column;

        > div {
            width: 100%;
            margin: 0;
            margin-bottom: 15px;
        }
    }

    .contentarea .content_sec .asign_edit_pop.edit_user_popup .add_url_pop > div .body {
        max-height: calc(100vh - 115px);
        overflow: auto;
    }

    .contentarea .content_sec .reset_pass_box.changepassword_box .pricing_setup_pop > div {
        width: 100%;
        top: auto;
        bottom: 0;
        transform: translate(0);
    }

    .contentarea .content_sec .asign_edit_pop.edit_user_popup .add_url_pop > div .body .personal_box > div.user_table > .user_table_body .body_row:nth-child(even) {
        background: transparent;
    }

    .contentarea .content_sec .asign_edit_pop.edit_user_popup .add_url_pop > div .body.refferal_body {
        padding-left: 15px;
        padding-right: 15px;
    }

    .contentarea .content_sec .asign_edit_pop.edit_user_popup .add_url_pop > div .body .personal_box > div.user_table {
        .header_row {
            display: none;
        }

        .user_table_body {
            .body_row {
                padding: 0;
                border: 1px solid #DDE5ED;
                border-radius: 4px;
                padding: 0 10px;
                padding-bottom: 10px;
                margin-bottom: 10px;
                position: relative;

                > div {
                    width: 50% !important;
                    max-width: 50%;
                    @include Urbanist;
                    font-weight: 600;
                    font-size: 14px;
                    color: #1D252D;
                    display: flex;
                    flex-flow: column;
                    align-items: flex-start;
                    padding-top: 20px;
                    word-break: break-all;

                    &:nth-last-child(3) {
                        margin-bottom: 10px;
                    }

                    &:before {
                        content: '';
                        @include Urbanist;
                        font-size: 11px;
                        font-weight: 400;
                        color: #000000;
                        margin-bottom: 0;
                    }

                    &:first-child {
                        &:before {
                            content: 'User Name';
                        }
                    }

                    &:nth-child(2) {
                        &:before {
                            content: 'Name';
                        }
                    }

                    &:nth-child(3) {
                        &:before {
                            content: 'No. of Group Numbers';
                        }
                    }

                    &:nth-child(4) {
                        i {
                            &.delete {
                                position: absolute;
                                top: 10px;
                                right: 10px;
                            }

                            &.toggle_row {
                                border: none;
                                width: auto;
                                height: auto;
                                position: absolute;
                                right: 20px;

                                &:before {
                                    content: "Expand";
                                    color: #000000;
                                    @include Urbanist;
                                    font-weight: 600;
                                    font-size: 14px;
                                    font-style: normal;
                                }
                            }
                        }
                    }
                }

                &.opened_group {
                    > div {
                        &:nth-child(4) {
                            i {
                                &.toggle_row {
                                    transform: none;

                                    &:before {
                                        content: "Collapse";
                                    }
                                }
                            }
                        }
                    }

                    .inner_groups {
                        margin-left: -10px;
                        min-width: calc(100% + 20px);
                        background: #fff;
                        border: none !important;
                        padding-top: 0;
                        background: #f4f7fc !important;

                        .user_table_body {
                            background: #f4f7fc;

                            .body_row {
                                border: none;
                                background: #f4f7fc !important;
                                margin-bottom: 0;

                                > div {
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    display: flex;
                                    justify-content: center;

                                    &:before {
                                        display: none;
                                    }

                                    &:last-child {
                                        i {
                                            margin-left: auto;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .contentarea .content_sec .asign_edit_pop.edit_user_popup .add_url_pop .add_url_pop > div {
        width: 100%;
        transform: translate(0);
        top: auto;
        bottom: 0;
    }

    .admin_user_page .contentarea .content_sec .tabular_box.refferal_pge_table .table_body .table_row.user_page > div > div:nth-child(5):before {
        content: '';
    }

    .contentarea .content_sec .asign_edit_pop.override_pop .add_url_pop {
        > div {
            height: calc(100% - 20px) !important;

            .body {
                height: calc(100% - 40px);
                padding-bottom: 70px;

                .overidebox {
                    height: calc(100% - 160px);

                    .override_body {
                        height: calc(100% - 38px);
                    }
                }
            }
        }
    }
}

.signup_here_link {
    @include Urbanist;
    font-size: 14px;
    font-weight: 700;
    color: #000000;
    cursor: pointer;
    display: flex;
    margin-top: 10px;
}

.disable_email_div {
    pointer-events: none;

    input {
        pointer-events: none;
    }
}

.extra_info {
    @include Urbanist;
    color: #000000 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    margin-bottom: 15px;
    padding-left: 10px;
}
/*.selectize-dropdown [data-selectable].option {
    display: flex;
}*/
.selectize-dropdown [data-selectable] .highlight {
    display: inline !important;
}

.no_record_found, .data-not-found {
    min-width: 100%;
    color: #EE934A !important;
}

.contentarea .content_sec .url_card_sec .card.pricing_tool_box .requestbox_popup > div .footer.urlbox {
    > div {
        &.yes_btn {
            &.loader {
                &:before {
                    position: relative;
                    right: 0;
                    order: 2;
                    margin-left: 10px;
                }
            }
        }
    }
}
/*
.requested_box {
    i {
        margin-right: 15px !important;
    }
}*/

.selectize-input > * {
    font-size: 14px;
    color: #1b252e;
    @include Urbanist;
    font-weight: 700;
}

.selectize-dropdown .option, .selectize-dropdown [data-disabled], .selectize-dropdown [data-disabled] [data-selectable].option {
    font-size: 14px;
    color: #1b252e;
    @include Urbanist;
    font-weight: 700;
    cursor: pointer;
}

.login_area_new_change {
    .signupbox_area {
        .signuparea {
            > div.fields {
                p.paaword_area, .paaword_area {
                    input {
                        background: #FFFFFF !important;
                        font-family: "Urbanist", sans-serif !important;
                        font-weight: 700;
                        font-size: 16px;
                        color: #1B252E;

                        & + span {
                            background: transparent;
                            z-index: 1;
                            font-family: "Urbanist", sans-serif !important;
                            font-weight: 700 !important;
                            font-size: 16px !important;
                            color: #1B252E !important;
                        }

                        &:not(:placeholder-shown) + span, &:focus + span {
                            background: #FFFFFF !important;
                            border-radius: 5px;
                        }
                    }

                    &.errors {
                        span {
                            background: #FFFFFF !important;
                            border-radius: 5px;
                        }

                        span + span {
                            z-index: 1 !important;
                            background: #FFFFFF !important;
                        }

                        &:before {
                            z-index: 9 !important;
                        }
                    }
                }
            }
        }
    }
}

.contentarea .content_sec .asign_edit_pop .add_url_pop > div {
    min-height: 300px;
}

p.terms_chkbox {
    span {
        font-size: 14px;
        font-weight: 500;
        color: #1B252E;
        line-height: 16px;
    }

    &:not(.paaword_area_relative_m) {
        span {
            width: 100%;
            display: flex;
            align-items: center;
            padding-left: 30px;
        }
    }
}

p.terms_chkbox {
    display: flex;
    align-items: flex-start;

    input[type="checkbox"] {
        //display: none;
        margin-right: 10px;
        max-height: 15px;

        & + label {
            @include Urbanist;
            font-weight: 500;
            font-size: 14px;
            color: #000000;
            line-height: 16px;
            width: 100%;
            display: flex;
            align-items: flex-start;
            //align-items: center;
            //flex-flow: wrap;
            /*i {
                width: 20px;
                min-width: 20px;
                height: 20px;
                display: flex;
                border: 1px solid #000000;
                margin-right: 10px;
                padding: 0;
                align-items: center;
                justify-content: center;
            }*/
        }
        /*&:checked {
            & + label {
                i {
                    &:before {
                        content: '';
                        width: 14px;
                        height: 14px;
                        background: #000000;
                    }
                }
            }
        }*/
    }
}

.save_continue.disabled {
    opacity: .7 !important;
    pointer-events: none !important;
}

.save-successfully {
    display: flex;
    align-items: center;
    margin-bottom: 0 !important;
    color: #11bf80;
    @include Urbanist;
    padding-left: 20px;
}

.terms_box {
    padding: 80px 0 0;
    background: #f0f2f2;
    background: linear-gradient(to bottom,#f0f2f2 0,#fff 4%,#fff 4%,#fff 4%,#fff 4%,#fff 70%,#e4e6e9 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f0f2f2', endColorstr='#e4e6e9', GradientType=0 );

    h1, h2 {
        color: #000000;
        font-size: 20px;
        @include Urbanist;
        font-weight: 700;
        margin-bottom: 35px;
    }

    h2 {
        font-size: 18px;
        color: #000;
    }

    p {
        list-style: decimal;
        color: #000000;
        font-size: 16px;
        //font-family: 'IBM Plex Sans', sans-serif;
        font-weight: 400;
        line-height: 25px;
        margin-bottom: 15px;
        @include Urbanist;

        a {
            color: #000000;
        }

        strong {
            font-weight: 700;
            color: #000;
        }
    }

    ul {
        li {
            list-style: decimal;
            color: #000000;
            font-size: 16px;
            @include Urbanist;
            font-weight: 400;
            line-height: 34px;
            margin-bottom: 15px;
            margin-left: 22px;

            a {
                color: #000000;
            }
        }
    }

    &.compliance {
        ul {
            li {
                list-style: inherit;
                margin-bottom: 10px;
            }
        }
    }
}

.changepassword_box.admin {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9999;

    .pricing_setup_pop {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(29, 37, 45, .5);
        z-index: 99;
        width: 100%;
        margin: 0;

        > div {
            width: 406px;
            border-radius: 4px;
            box-shadow: 0 0 47px 0 rgba(0, 0, 0, 0.23);
            border: solid 1px #dde5ed;
            background-color: #ffffff;
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            margin: 0 auto;
            transform: translate(0, -50%);

            .header {
                display: flex;
                align-items: center;
                @include Urbanist;
                font-weight: 700;
                color: #1d252d;
                font-size: 20px;
                padding: 13px 30px;
                border-bottom: 1px solid #dde5ed;

                .close {
                    cursor: pointer;
                    margin-left: auto;

                    img {
                        width: 16px;
                        height: 16px;
                    }
                }
            }

            .body {
                padding: 20px 30px 0 30px;

                p {
                    padding: 0;
                    margin: 0;
                    @include Urbanist;
                    font-weight: 400;
                    font-size: 14px;
                    color: #000000;
                    line-height: 24px;
                    padding-bottom: 0;

                    &:nth-child(4) {
                        margin-bottom: 24px;
                    }

                    &:first-child {
                        font-size: 16px;
                        color: #1D252D;
                    }
                }

                .textarea_box {
                    position: relative;

                    i {
                        width: 22px;
                        height: 12px;
                        background: url(./images/eye_icon.png) no-repeat;
                        position: absolute;
                        right: 10px;
                        top: 50%;
                        transform: translate(0, -50%);
                        cursor: pointer;
                        background-size: 100%;

                        &.active {
                            background: url(./images/eye_selected.png) no-repeat;
                            background-size: 100%;
                        }
                    }

                    span {
                        color: #000000 !important;
                        @include Urbanist;
                        font-size: 14px !important;
                        line-height: normal;
                        font-weight: 400 !important;
                        top: 50%;
                        transform: translate(0, -50%);
                        position: absolute;
                        left: 17px;
                        display: flex;
                        width: auto;
                        padding-left: 3.5px;
                        padding-right: 3.5px;
                        background: #fff;
                        transition: all 0.16s linear;
                        z-index: -1;
                    }

                    input {
                        &[type="text"], &[type="password"] {
                            width: 100%;
                            height: 52px;
                            display: flex;
                            align-items: center;
                            border-radius: 8px;
                            border: solid 1.1px #dde5ed;
                            background-color: transparent;
                            margin-bottom: 27px;
                            padding-left: 21px;
                            @include Urbanist;
                            font-size: 14px;
                            color: #1b252e;
                            font-weight: 700;

                            &:focus,
                            &:not(:placeholder-shown) {
                                & + span {
                                    top: 0;
                                    z-index: 1;
                                }
                            }
                        }
                    }

                    select {
                        width: 100%;
                        height: 52px;
                        display: flex;
                        align-items: center;
                        border-radius: 8px;
                        border: solid 1.1px #dde5ed;
                        background-color: transparent;
                        margin-bottom: 27px;
                        padding-left: 21px;
                        @include Urbanist;
                        font-size: 14px;
                        color: #1b252e;
                        font-weight: 700;
                        -webkit-appearance: none;

                        & + span {
                            top: 0;
                            z-index: 1;
                        }
                    }

                    &.select {
                        &:after {
                            content: "";
                            width: 0px;
                            height: 0px;
                            border-left: 5px solid transparent;
                            border-right: 5px solid transparent;
                            border-top: 5px solid #1d252d;
                            display: flex;
                            position: absolute;
                            right: 10px;
                            top: 50%;
                            transform: translate(0, -50%);
                            z-index: 9;
                        }
                    }

                    &.errors {
                        position: relative;

                        span {
                            top: 0;
                            z-index: 1;

                            & + span {
                                top: 50%;
                                color: #000000 !important;
                                z-index: -1;
                                padding-left: 25px;
                            }
                        }

                        &:before {
                            content: "!";
                            width: 16px;
                            height: 16px;
                            border-radius: 50%;
                            background: #000000;
                            display: flex;
                            color: #fff;
                            align-items: center;
                            justify-content: center;
                            font-size: 13px;
                            position: absolute;
                            top: 50%;
                            transform: translate(0, -50%);
                            left: 20px;
                            z-index: 9;
                        }
                    }
                }
            }

            .footer {
                margin: 6px 0 30px 0;
                display: flex;
                padding: 0 30px;

                > div {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @include Urbanist;
                    font-size: 16px;
                    width: 96px;
                    height: 48px;
                    border-radius: 6px;
                    cursor: pointer;

                    &.yes_btn {
                        background-color: #EE934A;
                        color: #ffffff;
                        width: 140px;
                        background-image: radial-gradient(circle at bottom right, #F1857F 1%, #EE934A 100%);

                        &.loader {
                            pointer-events: none;
                            position: relative;
                            transition: all .2s ease-in-out;
                            justify-content: space-evenly;
                            width: 180px;

                            &:after {
                                content: "";
                                border: 4px solid #EE934A;
                                border-radius: 50%;
                                border-top: 4px solid #fff;
                                width: 25px;
                                height: 25px;
                                -webkit-animation: .5s linear infinite spin;
                                animation: .5s linear infinite spin;
                                position: relative;
                                right: 0;
                            }
                        }
                    }

                    &.no_btn {
                        border: solid 1px #dde5ed;
                        background-color: #f5f8fa;
                        margin-left: 16px;
                        color: #000000;
                    }
                }
            }
        }
    }
}

@media screen and (min-width:0) and (max-width:767.2px) {
    .contentarea .content_sec .affiliate_url_sec .add_sec {
        width: 100%;
    }

    .contentarea .content_sec .url_card_sec .card.pricing_tool_box .setup_done_box .body.pricing_box_res {
        .head {
            > div {
                &:nth-child(2) {
                    display: none;
                }
            }
        }

        .content {
            flex-flow: column;

            .url_box {
                display: flex;
                flex-flow: column;
                font-size: 16px;
                word-break: break-all;
                margin-top: 20px;
                position: relative;
                align-items: flex-start;

                &:before {
                    content: 'Subdomain';
                    @include Urbanist;
                    font-size: 14px;
                    color: #000000;
                    font-weight: 400;
                    margin-bottom: 10px;
                }

                a {
                    position: absolute;
                    top: 30px;
                    right: -20px;
                }

                > div {
                    display: none;

                    span {
                        position: absolute;
                        bottom: 0;
                        padding-left: 10px;
                        padding-right: 10px;
                        background: #FFFFFF;
                        border: 1px solid #DDE5ED;
                        box-shadow: 0 2px 14px #8ba6c126;
                        min-width: max-content;
                        font-style: normal;
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border-radius: 4px;
                        color: #000000;
                        font-family: "Urbanist", sans-serif !important;
                        font-size: 12px;
                        font-weight: 400;
                        bottom: -40px;
                        right: -10px;
                        display: none !important;
                        z-index: 9;
                    }
                }
            }
        }
    }
}

.addusers_fields > div > span + span {
    z-index: 1 !important;
}
/* popup css */
.contentarea.signup-popup .content_sec .resetgroup_box .add_url_pop > div {
    width: 800px;
}

.contentarea.signup-popup .scroll-section .paragraph-text {
    display: block;
    font-size: 14px;
    color: #1D252D;
    line-height: 21px;
    padding-right: 10px;
}

.contentarea.signup-popup .scroll-section {
    max-height: 320px;
    overflow: auto;
}

.contentarea.signup-popup.order-section .scroll-section {
    max-height: 430px;
}

.contentarea.signup-popup .scroll-section ul, .contentarea.signup-popup .footer p {
    font-size: 14px !important;
    color: #1D252D !important;
    line-height: 21px !important;
    padding-right: 10px !important;
    font-family: "Urbanist", sans-serif;
}

.contentarea.signup-popup h1 {
    font-family: "Urbanist", sans-serif;
    font-size: 16px;
    font: bold;
    text-align: center;
}

.contentarea.signup-popup h2 {
    font-family: "Urbanist", sans-serif;
    font-size: 14px;
    font: bold;
    display: inline;
}

.contentarea.signup-popup .add_url_pop .termpopup {
    width: auto;
    max-width: 100%;
    // height: 44px;
    margin: 0;
    font-size: 32px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1d252d;
    border: 0;
}

.contentarea.signup-popup .secondpopup {
    font-size: 14px;
    font-weight: normal;
    color: #000000;
    border: none;
}

.contentarea.signup-popup .scroll-section ul {
    padding-right: 10px;
}

.contentarea.signup-popup .scroll-section .decimalalign {
    list-style-type: decimal;
    list-style-position: inside;
    text-indent: -1em;
    padding-left: 1em;
}

.contentarea.signup-popup .scroll-section .aligndisc {
    list-style-type: disc;
    list-style-position: inside;
    text-indent: -1.5em;
    padding-left: 2em;
}

.contentarea.signup-popup .scroll-section .aligncircle {
    list-style-type: circle;
    list-style-position: inside;
    text-indent: -1.5em;
    padding-left: 2em;
}

.contentarea.signup-popup .scroll-section .aligndisc li {
    margin-top: 10px;
}

li::marker {
    //font-size: 1rem;
    font-weight: bolder;
}

.footer .review-terms {
    float: right;
    padding-top: 23px;
    font-size: 20px;
}

.accept_btn {
    width: 186px;
    height: 58px;
    font-size: 16px;
    color: #fff;
    text-align: center;
    line-height: 58px;
    margin-top: 5px;
    border: none;
    background: #EE934A;
    border-radius: 6px;
    margin-left: -30px;
    cursor: pointer;
}

.accept_btn:disabled {
    opacity: .5;
}

.contentarea.signup-popup .content_sec .resetgroup_box .add_url_pop > div .footer {
    display: block;
    margin-top: 0px;
    margin-bottom: 23px;
}

.contentarea.signup-popup .footer p {
    padding-top: 10px;
}
/* btn css */
.Transactions_Summary.payments_box .btn-section {
    display: flex;
    margin-left: auto;
    width: 337px;
    align-items: flex-end;
}

.contentarea .content_sec .summary_left_part .payments_box a.btns_payment_summary {
    margin-left: auto;
}

.contentarea .content_sec .summary_left_part .payments_box a.btns_payment_summary {
    margin-left: auto;
    height: 40px !important;
}

.contentarea .content_sec .summary_left_part .payments_box a.btns_payment_summary.pdf {
    margin-right: 15px;
    height: 40px !important;
}

.contentarea .content_sec .resetgroup_box .order-section .add_url_pop {
    left: 0px;
}

.payment-popup-active .contentarea, .edit-payment-popup-active .contentarea, .confirm-active .contentarea {
    position: relative;
    z-index: 99;
}

.edit-payment-popup-active {
    overflow: hidden !important;
}

.payment-popup-active .summary_repeat.faq.gap .contentarea {
    z-index: 1;
}

.contentarea .content_sec .resetgroup_box .order-section .add_url_pop > div .body {
    padding: 0px 30px 0 0px !important;
}

.order-section.confirmation {
    position: relative;
}

.order-section.confirmation .Mask.intro-kit {
    height: 220px !important;
    width: 500px !important;
    overflow: hidden !important;
}

.contentarea .content_sec .confirmation h3 {
    padding: 5px 0 0 20px;
}

.contentarea .content_sec .confirmation .close.change {
    top: 20px;
    right: 20px;
    position: absolute;
    cursor: pointer;
}

.Account_info_form_parent.confirm {
    z-index: 99;
}

.contentarea .content_sec .confirmation p {
    padding: 20px 20px 0 20px;
}

.contentarea .content_sec .confirmation .btn {
    display: inline;
    margin: 22px 0 0 20px;
    background: #EE934A;
    color: #fff;
}

.Get-a-jump-start-wit span {
    font-weight: normal;
    font-size: 20px;
    display: block;
}

@media screen and (min-width:768px) and (max-width:1920px) {
    .left_sidebar .navbar {
        max-height: 320px;
        /*overflow: auto;*/
    }
}

@media screen and (min-width:0) and (max-width:768px) {
    .contentarea .content_sec .header_bard_button.pricing_page.loader:before {
        content: "";
        border: 4px solid #EE934A;
        border-radius: 50%;
        border-top: 4px solid #fff;
        width: 25px;
        height: 25px;
        animation: .5s linear infinite spin;
        position: absolute;
        right: 18px;
        top: -40px;
    }

    .contentarea.signup-popup .content_sec .resetgroup_box .add_url_pop > div {
        width: 80%;
    }
}

@media screen and (min-width:1000px) and (max-width:1100px) {
    .contentarea .content_sec .header_bard_button.pricing_page.loader:before {
        content: "";
        border: 4px solid #EE934A;
        border-radius: 50%;
        border-top: 4px solid #fff;
        width: 25px;
        height: 25px;
        animation: .5s linear infinite spin;
        position: absolute;
        right: 18px;
        top: -40px;
    }

    .contentarea.signup-popup .content_sec .resetgroup_box .add_url_pop > div {
        width: 80%;
    }
}

@media screen and (min-width:0) and (max-width:767.2px) {
    .contentarea .content_sec .tabular_box .table_body .table_row.user_page > div > div:nth-child(8) {
        width: 100%;
        max-width: 100%;
        flex-flow: row;
        display: none;
    }

    .admin_user_page .contentarea .content_sec .tabular_box .table_body .table_row.user_page > div > div:nth-child(6):before {
        content: 'Approve Payment';
    }

    .admin_user_page .contentarea .content_sec .tabular_box .table_body .table_row.user_page > div > div:nth-child(6) {
        display: flex !important;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .flip_icon {
        top: 0px !important;
        left: 170px !important;
    }

    .signup_box_parent .container_signup .signupbox_area > div > div .signuparea > div.fields p.paaword_area_relative_m {
        position: relative;
    }

    .contentarea .content_sec .header_bard_button .add_group_no_btn.usersarea.adduserbtn {
        display: flex;
        max-width: 110px;
        font-size: 15px;
        margin-right: 10px;
        height: 40px;
    }

    .contentarea .content_sec .header_bard_button.userpage_header_bard {
        position: absolute;
        top: 23px;
        right: 0;
    }

    .contentarea.signup-popup .scroll-section {
        //max-height: 160px;
    }

    .contentarea.signup-popup .content_sec .resetgroup_box .add_url_pop > div {
        width: 80%;
        top: 50%;
        bottom: auto;
        transform: translate(0, -50%);
    }
}

.reps_commision_box.Assign_dollar {
    &:before {
        top: 50% !important;
    }
}

.Calendly {
    width: 100% !important;
    height: 500px;
}

@media screen and (max-width:1024px) {
    .contentarea .content_sec .summary_left_part .payments_box a.btns_payment_summary {
        .Calendly {
            height: 500px;
            width: 100% !important;
        }

        @media screen and (max-width:1024px) {
            .contentarea .content_sec .summary_left_part .payments_box a.btns_payment_summary {
                margin: 10px 0 0 0;
            }

            .contentarea .content_sec .summary_left_part .payments_box a.btns_payment_summary.pdf {
                order: 2;
                margin-right: 0px;
            }

            .Transactions_Summary.payments_box .btn-section {
                flex-direction: column;
            }

            .Transactions_Summary.payments_box .btn-section {
                flex-direction: column;
            }

            .contentarea .content_sec .summary_left_part .payments_box {
                flex-flow: row;
            }
        }

        @media screen and (max-width:767px) {
            * {
                cursor: pointer;
            }
        }

        @media screen and (max-width:480px) {
            .contentarea .content_sec .summary_left_part .payments_box {
                flex-flow: column;
            }

            .Transactions_Summary.payments_box .btn-section {
                width: 100%;
            }

            .contentarea .content_sec .summary_left_part .payments_box a.btns_payment_summary {
                width: 100% !important;
            }
        }

        .contentarea .content_sec .resetgroup_box .add_url_pop.CalendlyPopup {
            width: 100%;
            top: 0;
            bottom: 0;
            transition: auto;
        }

        .contentarea .content_sec .resetgroup_box .add_url_pop > div .body.Popup {
            padding: 0;
        }

        height: 500px
    }

    @media screen and (max-width:1366px) {
        .contentarea .content_sec .summary_left_part .payments_box a.btns_payment_summary {
            margin: 10px 0 0 0;
        }

        .contentarea .content_sec .summary_left_part .payments_box a.btns_payment_summary.pdf {
            order: 2;
            margin-right: 0px;
        }

        .Transactions_Summary.payments_box .btn-section {
            flex-direction: column;
        }
    }

    .contentarea .content_sec .summary_left_part .payments_box {
        flex-flow: row;
    }
}

@media screen and (max-width:480px) {
    .contentarea .content_sec .summary_left_part .payments_box {
        flex-flow: column;
    }

    .Transactions_Summary.payments_box .btn-section {
        width: 100%;
    }

    .contentarea .content_sec .summary_left_part .payments_box a.btns_payment_summary {
        width: 100% !important;
        height: 40px;
    }
}

@media screen and (max-width:425px) {
    .contentarea .content_sec .asign_edit_pop .add_url_pop > div .footer {
        padding-left: 15px;
        padding-right: 15px;
        flex-wrap: wrap;

        .error_submit_msg {
            max-width: 100%;
            height: auto;
            padding-left: 0;
            padding-top: 5px;
        }
    }
}

.contentarea .content_sec .resetgroup_box .add_url_pop.CalendlyPopup {
    width: 100%;
    top: 0;
    bottom: 0;
    transition: auto;
}

.contentarea .content_sec .resetgroup_box .add_url_pop > div .body.Popup {
    padding: 0;
}


.Mask {
    width: 799px !important;
    height: 600px !important;
    margin: 65px 400px 140px 113px;
    padding: 14px 0 0;
    border-radius: 4px;
    box-shadow: 0 0 47px 0 rgba(0, 0, 0, 0.23);
    background-color: #ffffff;
}

.order-section .Mask {
    height: 550px !important;
}

.order-section .Mask.intro-kit {
    height: 710px !important;
    width: 652px !important;
}

.Shipto {
    margin: 19px 0 18px 0;
    font-family: "Urbanist", sans-serif !important;
    font-size: 20px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #1d252d;
}

.Get-a-jump-start-wit {
    margin: 0 0 24px 0;
    font-family: "Urbanist", sans-serif;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1d252d;
}

.Get-a-jump-start-wit .text-style-1 {
    font-size: 20px;
    font-weight: normal;
}

.Group-16 {
    width: 100%;
    height: 233px;
    object-fit: contain;
}

.Rectangle {
    width: 377px;
    height: 545px;
    margin: 14px 32px 0 0;
    padding: 26px 39px 31px 34px;
    border-radius: 4px;
    background-color: #f4f7fc;
}

.order-section .Rectangle {
    height: auto;
    overflow: hidden;
    padding: 26px 34px 31px 34px;
    float: left;
    width: 50%;
    margin: 0px;
}

.order-section .-Featuring-500-of-o {
    margin: 15px 0 0 0;
    height: auto;
}

.right-view {
    width: 44%;
    float: right;
}

.-Featuring-500-of-o {
    width: 304px;
    height: 120px;
    margin: 304px 0 0;
    @include Urbanist;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #1d252d;
}

add_url_pop .Mask .scroll-section {
    max-height: 600px;
    overflow: auto;
}

personal_box.UserInfo {
    vertical-align: right;
    padding: 10px;
}

.contentarea.signup-popup.order-section .scroll-section {
    overflow: visible;
}

.first-name {
    width: 45%;
    float: left;
}

.last-name {
    width: 45%;
    float: right;
}

.order-section .same {
    position: relative;
    margin-bottom: 16px;
}

.order-section .right-view span {
    color: #000000 !important;
    font-family: "Urbanist", sans-serif !important;
    font-size: 14px !important;
    line-height: normal;
    font-weight: 400 !important;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    left: 14px;
    display: flex;
    width: auto;
    padding-left: 3.5px;
    padding-right: 3.5px;
    background: transparent;
    transition: all .16s linear;
    z-index: -1;

    &.error {
        background: #ffffff;
    }
}

.order-section .right-view input[type=text]:focus + span, .order-section .right-view input[type=text]:not(:placeholder-shown) + span {
    top: 0;
    z-index: 1;
    background: #fff;
}

.contentarea.signup-popup .add_url_pop > div .body .secondpopup input[type=checkbox]:checked {
    background: #000000 !important;
    border-color: #000000 !important;
}

.contentarea.signup-popup .add_url_pop > div .body .secondpopup #checkoboxpopup2 {
    width: 33px;
}

.contentarea.signup-popup .add_url_pop > div .body .secondpopup input[type=checkbox] {
    width: 24px;
    height: 24px;
    border: solid 1px #000000;
    background: #fff;
    margin-right: 15px;
}

.contentarea.signup-popup .add_url_pop > div .body .secondpopup .alignlbl {
    font-size: 14px;
    color: #000;
    display: flex;
    align-items: left;
}

contentarea.signup-popup .add_url_pop > div .body .secondpopup input[type=checkbox] label {
    font-size: 14px;
    color: #000;
    display: flex;
    align-items: center;
}

.order-section .right-view div.errors span + span {
    top: 50%;
    color: #000000 !important;
    //z-index: -1;
    padding-left: 20px;
    background: #fff;
    z-index: 0;
}

.order-section .right-view div.errors span {
    top: 0;
    z-index: 1;
    background: #fff;
}

.order-section .right-view div.errors:before {
    content: "!";
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #000000;
    display: flex;
    color: #fff;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 15px;
    z-index: 9;
}

.order-section .full {
    display: inline-block;
    width: 100%;
}

.order-section .right-view select + span {
    background: #fff;
    top: 0;
    z-index: 1;
}

.order-section .drpdown:after {
    position: absolute;
    top: 25px;
    right: 16px;
}

.order-section .footer {
    padding: 0px !important;
    display: block !important;
    margin: 0px !important;
}

.order-section .yes_btn {
    width: 156px !important;
}

.shipping {
    padding: 15px 20px 20px 16px;
    border-radius: 8px;
    background-color: #f4f7fc;
    margin-top: 24px;
    position: relative;
    margin-bottom: 20px;
}

.right-view h3 {
    font-family: "Urbanist", sans-serif !important;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    margin-bottom: 5px;
}

.right-view address {
    font-family: "Urbanist", sans-serif !important;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: #1d252d;
}

.right-view .shipping .close {
    font-family: "Urbanist", sans-serif !important;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    position: absolute;
    top: 15px;
    right: 20px;
    cursor: pointer;
}

.right-view h2 {
    font-family: "Urbanist", sans-serif !important;
    font-size: 20px !important;
    font-weight: 800 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.2 !important;
    letter-spacing: normal !important;
    color: #1d252d !important;
    border: none !important;
    margin: 0px !important;
}

.right-view.success-msg h3 {
    font-family: "Urbanist", sans-serif !important;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1d252d;
}

.right-view.success-msg {
    font-family: "Urbanist", sans-serif !important;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #1d252d;
}

.right-view.success-msg img {
    display: block;
    margin-top: 40px;
    margin-bottom: 10px;
}

.right-view #sq-walletbox hr {
    display: none;
}

.contentarea .content_sec .Card_Page_header .buttons_area.AddPayment {
    //min-width: 590px;
    max-width: 590px;
}

.contentarea .content_sec .Card_Page_header .buttons_area.AddPayment .button {
    padding: 13px 12px;
}

.contentarea .content_sec .asign_edit_pop .add_url_pop > div .body .overidebox .override_body .over_ride_table .table_body > div > ul.open {
    max-height: 85px;
}

@media screen and (min-width:768px) and (max-width:1100px) {
    .contentarea .content_sec .resetgroup_box .order-section .add_url_pop {
        left: 180px;
    }
}

@media screen and (min-width:768px) and (max-width:980px) {
    .order-section .Mask {
        width: 600px !important;
    }

    .order-section .yes_btn {
        width: 125px !important;
    }

    .contentarea .content_sec .Card_Page_header .buttons_area.AddPayment {
        min-width: 65%;
        max-width: 65%;
    }

    .contentarea .content_sec .Card_Page_header .buttons_area.AddPayment .button {
        margin-left: 5px;
        margin-right: 5px;
    }

    .order-section .Mask {
        width: 530px !important;
    }

    .order-section .Rectangle {
        display: none;
    }

    .right-view {
        width: 100%;
    }

    .contentarea .content_sec .resetgroup_box .order-section .add_url_pop > div .body {
        padding-left: 30px !important;
    }
}

@media screen and (max-width:767.2px) {
    .order-section .Rectangle {
        display: none;
    }

    .right-view {
        width: 100%;
    }

    .contentarea .content_sec .resetgroup_box .order-section .add_url_pop > div .body {
        padding-left: 30px !important;
    }

    .contentarea .content_sec .Card_Page_header .h2_title p, .contentarea .content_sec .Card_Page_header .buttons_area {
        display: block;
    }

    .contentarea .content_sec .Card_Page_header {
        display: block;
    }

    .contentarea .content_sec .Card_Page_header .buttons_area.AddPayment {
        min-width: 100%;
        max-width: 100%;
        margin-top: 15px;
    }

    .contentarea .content_sec .Card_Page_header .h2_title h2 {
        padding-left: 0px;
    }

    .contentarea .content_sec .Card_Page_header .buttons_area.AddPayment .button {
        margin-bottom: 5px;
    }

    .contentarea .content_sec .resetgroup_box .order-section .add_url_pop {
        left: 0px;
    }

    .order-section .Mask {
        width: 90% !important;
        margin: 0 auto;
        height: 320px !important;
        transform: translate(0, 0) !important;
        top: 30px !important;
    }

    /*.contentarea .content_sec .resetgroup_box .order-section .add_url_pop > div .body {
        height: 240px;
        overflow: auto;
    }*/

    .order-section .errormsg {
        padding-top: 15px !important;
    }
}


@media screen and (max-width:480px) {
    .first-name, .last-name {
        width: 100%;
    }
}

.right-view .no_btn.Payment {
    margin-top: -20px !important;
}
/* make payment loader */
.footer .make-payment.loader {
    pointer-events: none;
    position: relative;
    display: flex;
    align-items: center;
    transition: all .2s ease-in-out;
    width: auto;
    justify-content: space-around;
    width: 180px !important
}

.footer .make-payment.loader:after {
    content: "";
    border: 4px solid #EE934A;
    border-radius: 50%;
    border-top: 4px solid #fff;
    width: 25px;
    height: 25px;
    animation: .5s linear infinite spin;
    position: relative;
    right: -11px;
    order: 2;
}

.payment-popup-active {
    overflow-y: hidden;
}

@media screen and (min-width: 0) and (max-width: 767.2px) {
    .mobile_header .header_searcharea input[type=text] {
        text-indent: -9999px;
        padding-right: 15px;
        font-size: 16px;
    }

    .mobile_header .header_searcharea input[type=text]:focus {
        text-indent: inherit;
    }
}

.contentarea.signup-popup .scroll-section .clrchng span {
    color: #000000 !important;
}

.contentarea.signup-popup .scroll-section .clrchng h3 {
    text-align: left;
}

.contentarea.signup-popup .scroll-section .clrchng h4 {
    color: #8ba6c1 !important;
    font-size: 20px;
    margin-left: -10px;
}

.contentarea.signup-popup .scroll-section .clrchng span span {
    font-size: 14px;
    margin-left: 15px;
    display: flex;
    margin-bottom: -20px;
    margin-top: -20px;
    color: #1d252d !important;
}

.contentarea.signup-popup .scroll-section .clrchng span span span span {
    margin-left: 25px;
    margin-top: 0px;
    margin-bottom: 2px;
}

.contentarea.signup-popup .scroll-section .clrchng span span span {
    margin-left: 20px;
    margin-top: 0px;
    margin-bottom: -5px;
}

contentarea .content_sec .resetgroup_box .add_url_pop > div .header.tc {
    border-bottom: 0 !important;
}

.Dashboard_Area.terms_box {
    background: #f4f7fc;
    padding: 0px;
}

.Dashboard_Area.terms_box.compliance .navbar ul li {
    margin: 0px;
    line-height: normal;
}

.Dashboard_Area.terms_box .Header_Area p {
    margin-bottom: 0px;
}

.terms-popup.update-terms {
    display: flex;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 99;
}

.Dashboard_Area.terms_box.compliance .contentarea .content_sec h2 {
    font-size: 18px;
}

.Dashboard_Area.terms_box.compliance .contentarea .content_sec h2.differ {
    font-size: 28px;
}

body.UpdatedTerms {
    overflow: hidden;
}

.resize {
    width: 50px;
    height: auto;
    margin-left: 10px;
}

.flip-icons a {
    float: left;
}

.linkified, a:link, a:visited {
    color: #EE934A;
}

a:link, a:visited {
    outline: none;
    text-decoration: none;
}


.contentarea .content_sec .tabular_box .table_body .table_row.user_page > div > div:nth-child(6) {
    position: relative;
}

.flip_icon:before {
    top: -3px;
    left: -4px;
    width: 19px;
    height: 19px;
}

.flip_icon {
    width: 41px;
    height: 17px;
    background-color: rgba(237,136,8,.5);
    border-radius: 20px;
    text-indent: -9999px;
    position: absolute;
    top: -8px;
    left: 0px;
}

.flip_icon, .flip_icon.active {
    border: 1px solid #F1857F;
    background-color: #fff;
}

.flip_icon.active:before, .flip_icon:before {
    position: absolute;
    content: "";
    border-radius: 20px;
    border-style: solid;
    border-width: 0.5px;
    border-image-source: linear-gradient(180deg,hsla(0,0%,100%,.12),hsla(0,0%,100%,.06) 20%,hsla(0,0%,100%,0));
    border-image-slice: 1;
    background-color: #ed8808;
}


.flip_icon.active:before {
    background-color: #000000;
}

.flip_icon:before {
    background-color: #EE934A;
}

.flip_icon.active:before {
    top: -3px;
    left: calc(100% - 20px);
    width: 20px;
    height: 20px;
}

.flip_icon.deactivate:before {
    opacity: .2;
}

.flip_icon.deactivate {
    border-opacity: 0.2;
    border: 1px solid rgba(182, 172, 162, .2);
    background: rgba(255, 255, 255, 0.2);
}

.flip_icon.loader:after {
    content: "";
    border: 4px solid #EE934A;
    border-radius: 50%;
    border-top: 4px solid #fff;
    width: 25px;
    height: 25px;
    animation: .5s linear infinite spin;
    position: absolute;
    right: calc(100% + 30px);
    top: -9px;
    left: 5px;
}

.toggle-switch .error {
    position: absolute;
    top: -7px;
    left: 50px;
    font-size: 12px;
}

.dashboarderr {
    width: 150px !important;
    color: #db2269 !important;
}

.contentarea .content_sec .url_card_sec .card.pricing_tool_box .requesting_box .btn_box .btn_setup.add-icon {
    width: 230px;
    justify-content: right;
    padding: 0 20px;
    background: #EE934A url(./images/exclaimation_mark.png) 17px center no-repeat;
}

.admin_report_page .content_sec ul {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style: none;
}

.admin_report_page .content_sec li {
    -ms-flex: 0 0 30%;
    flex: 0 0 30%;
    height: 104px;
    border-radius: 6px;
    background-color: #fff;
    margin-right: 46px;
    margin-bottom: 32px;
    position: relative;
    font-family: "Urbanist", sans-serif;
    font-size: 22px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    cursor: pointer;
}

.admin_report_page .content_sec .arrow {
    width: 15px;
    height: 15px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 50%;
    right: 32px;
    margin-top: -8px;
}

.admin_report_page .content_sec .arrow:before {
    content: "";
    width: 100%;
    height: 100%;
    border-width: 0.2vmin 0.2vmin 0 0;
    border-style: solid;
    border-color: #827c78;
    -webkit-transition: .2s ease;
    -o-transition: .2s ease;
    transition: .2s ease;
    display: block;
    -webkit-transform-origin: 100% 0;
    -ms-transform-origin: 100% 0;
    transform-origin: 100% 0;
}

.admin_report_page .content_sec .manage-content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
}

.admin_report_page .content_sec li img {
    margin: 0 20px 0 37px;
    width: 24px;
    height: 24px;
}

.admin_report_page .content_sec .content {
    padding: 20px 30px;
}

.admin_report_page .content_sec .content label {
    display: block;
    padding-bottom: 5px;
}

.admin_report_page .content_sec .content select, .admin_report_page .content_sec .content .same-on > div {
    width: 100%;
    height: 52px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: solid 1.1px #dde5ed;
    background-color: transparent;
    margin-bottom: 30px;
    padding-left: 10px;
}

.admin_report_page .content_sec .content .same-on > div .form-control {
    border: none;
}

.admin_report_page .content_sec .content .same-on {
    position: relative;
}

.admin_report_page .content_sec .content select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: transparent;
    position: relative;
    z-index: 9;
}

.admin_report_page .content_sec .content .down-arrow {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #1d252d;
    position: absolute;
    top: 46px;
    right: 25px;
}

.react-datepicker_input-container.first-one::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #1d252d;
    position: absolute;
    top: 5px;
    left: 130px;
}

.react-datepicker_input-container.second-one::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #1d252d;
    position: absolute;
    top: 5px;
    left: 130px;
}

.react-datepicker_input-container.first-one, .react-datepicker_input-container.second-one {
    position: relative;
}

.admin_report_page .content_sec .content .main {
    display: flex;
}

.admin_report_page .content_sec .content .new-column {
    -ms-flex: 50% 1;
    flex: 50% 1;
}

.admin_report_page .content_sec .content .new-column.gap-right {
    padding-right: 3%;
}

.admin_report_page .content_sec .content .new-column.gap-left {
    padding-left: 3%;
}

.admin_report_page .contentarea .content_sec .asign_edit_pop .add_url_pop > div .footer {
    padding: 45px 0 0 0px;
    box-shadow: none;
}

.react-bootstrap-daterangepicker-container {
    width: 330px;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 7px 0 rgba(139, 166, 193, 0.07);
    box-shadow: 0 1px 7px 0 rgba(139, 166, 193, 0.07);
    border: solid 1px #dde5ed;
    background-color: #ffffff;
    float: left;
}

.react-bootstrap-daterangepicker-container .form-control {
    width: 100%;
    border: 0px;
    height: 44px;
    margin-bottom: 0px;
    padding-left: 55px;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000 !important;
    cursor: pointer;
}

.react-bootstrap-daterangepicker-container .form-control.first {
    border-right: 1px solid #dde5ed;
    border-radius: 4px 0px 0px 4px;
}

.react-bootstrap-daterangepicker-container .react-datepicker_input-container::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #000000;
    position: absolute;
    top: 21px;
    right: 15px;
    left: auto;
}

.react-bootstrap-daterangepicker-container .react-datepicker_input-container {
    position: relative;
    width: 50%;
    display: inline-block;
}

.react-bootstrap-daterangepicker-container .react-datepicker_input-container.first-one::before {
    content: "From";
    width: 0;
    height: 0;
    position: absolute;
    top: 15px;
    left: 15px;
    font-family: "Urbanist", sans-serif;
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #827C78;
}

.react-bootstrap-daterangepicker-container .react-datepicker_input-container.second-one::before {
    content: "To";
    width: 0;
    height: 0;
    position: absolute;
    top: 15px;
    left: 15px;
    font-family: "Urbanist", sans-serif;
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #827C78;
}

.daterangepicker {
    width: 641px;
    height: 377px;
    border-radius: 4px;
    -webkit-box-shadow: 0 12px 15px 0 rgba(139, 166, 193, 0.37);
    box-shadow: 0 12px 15px 0 rgba(139, 166, 193, 0.37);
    border: solid 1px #dde5ed;
    background-color: #fff;
}

.daterangepicker .calendar-table th.month {
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1d252d;
}

.daterangepicker .calendar-table th, .daterangepicker .calendar-table td {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #8ba6c1;
    width: 42px;
    height: 38px;
    min-width: 42px;
}

.daterangepicker td.start-date.in-range, .daterangepicker td.start-date, .daterangepicker td.start-date:hover, .daterangepicker td.in-range.end-date, .daterangepicker td.in-range.end-date:hover {
    border-radius: 4px;
    background-color: #EE934A;
    padding: 0px;
    color: #fff;
    cursor: pointer;
}

.daterangepicker td.start-date.in-range.off, .daterangepicker td.end-date.in-range.off {
    background: #fff;
    color: #8ba6c1;
}

.daterangepicker td.in-range {
    background: rgba(255, 142, 65,.22);
    color: #202830;
}

.daterangepicker .drp-calendar.left, .daterangepicker .drp-calendar.right {
    width: 48%;
}

.daterangepicker .drp-buttons {
    border: none;
    padding: 0px;
    text-align: left;
    display: -ms-flexbox !important;
    display: flex !important;
}

.daterangepicker .drp-buttons {
    padding-top: 15px;
}

.daterangepicker .drp-selected {
    display: none;
}

.daterangepicker .drp-buttons .btn.applyBtn {
    background-color: #EE934A !important;
}

.daterangepicker .drp-buttons .cancelBtn {
    -ms-flex-order: 2;
    order: 2;
    margin-top: -2px;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.daterangepicker .drp-buttons .applyBtn {
    -ms-flex-order: 1;
    order: 1;
    margin: -2px 0 0 24px;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.daterangepicker .drp-buttons .btn.applyBtn, .daterangepicker .drp-buttons .btn.applyBtn:hover, .daterangepicker .drp-buttons .btn.applyBtn:active, .daterangepicker .drp-buttons .btn.applyBtn:focus {
    width: 89px;
    height: 40px;
    border-radius: 6px;
    background: #ed8808;
    border: none !important;
    outline: none !important;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
}

.daterangepicker .drp-calendar.right {
    float: left;
    margin-left: 43px;
}

.daterangepicker .drp-buttons .cancelBtn, .daterangepicker .drp-buttons .cancelBtn:hover, .daterangepicker .drp-buttons .cancelBtn:active, .daterangepicker .drp-buttons .cancelBtn:focus {
    width: 89px;
    height: 40px;
    border-radius: 6px;
    background: #f5f8fa;
    border: 1px solid #dde5ed;
    outline: none !important;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
}

.daterangepicker .calendar-table thead th {
    color: #000000;
    font-weight: 600;
    height: auto;
    padding-top: 8px;
}

.daterangepicker th.available:hover {
    background: none;
}

.react-datepicker_input-container.first-one:before {
    content: "From";
    width: 0;
    height: 0;
    position: absolute;
    top: -2px;
    left: 5px;
    font-family: "Urbanist", sans-serif;
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #827c78;
}

.react-datepicker_input-container.second-one:before {
    content: "To";
    width: 0;
    height: 0;
    position: absolute;
    top: -2px;
    left: 22px;
    font-family: "Urbanist", sans-serif;
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #827c78;
}

.admin_report_page .content_sec .content .same-on > div::after {
    content: '';
    position: absolute;
    top: 24px;
    left: 50%;
    border-right: solid 1.1px #dde5ed;
    height: 50px;
}

.admin_report_page .content_sec .content .same-on > div .form-control {
    float: left;
    margin-left: 44px;
    width: 70%;
}

.admin_report_page .contentarea .content_sec .asign_edit_pop.edit_user_popup .add_url_pop > div .footer {
    position: absolute;
    bottom: 25px;
    left: 30px;
}

.admin_report_page .contentarea .content_sec .loader::after {
    content: "";
    border: 4px solid #EE934A;
    border-radius: 50%;
    border-top: 4px solid #fff;
    width: 25px;
    height: 25px;
    animation: .5s linear infinite spin;
    position: absolute;
    right: calc(100% + 30px);
}

.admin_report_page .contentarea .content_sec .loader {
    position: relative;
}

.admin_report_page .contentarea .content_sec .yes_btn.disabled {
    opacity: 0.3;
}

.admin_report_page .contentarea .content_sec .error {
    color: #000000;
    padding-left: 15px;
    width: auto !important;
}

@media screen and (min-width: 1367px) and (max-width: 1600px) {
    .admin_report_page .content_sec li {
        flex: 0 0 45%;
    }
}

@media screen and (min-width: 980px) and (max-width: 1366px) {
    .admin_report_page .contentarea .content_sec .loader::after {
        top: -3px;
    }

    .transaction .daterangepicker, .analyticPage .daterangepicker {
        height: auto;
        padding-bottom: 15px;
    }

    .transaction .daterangepicker .calendar-table th, .transaction .daterangepicker .calendar-table td {
        font-size: 12px;
        height: 26px;
    }

    .analyticPage .daterangepicker .calendar-table th, .analyticPage .daterangepicker .calendar-table td {
        font-size: 12px;
        height: 26px;
    }

    .contentarea .content_sec .url_card_sec .card.pricing_tool_box .requesting_box .btn_box .text_request, .contentarea .content_sec .url_card_sec .card.pricing_tool_box .requesting_box .btn_box .text_request {
        flex-shrink: 0;
    }

    .contentarea .content_sec .tabular_box.pricing_tool_table .table_body .table_row.user_page > div > div:nth-child(3) {
        max-width: 230px;
    }

    .contentarea .content_sec .tabular_box.pricing_tool_table .table_body .table_row.user_page > div > div:nth-child(5) {
        max-width: 140px;
    }

    .signup_faq .faq_area .accordion_content .panel__inner[aria-hidden="false"], .contentarea .content_sec .accordion_content .panel__inner[aria-hidden="false"] {
        height: auto !important;
    }

    .admin_report_page .content_sec li {
        flex: 0 0 44%;
        font-size: 18px;
    }
}

@media screen and (min-width: 981px) and (max-width: 1024px) {
    .admin_report_page .content_sec li {
        flex: 0 0 50%;
        font-size: 18px;
    }

    .daterangepicker.ltr.show-calendar.opensright {
        right: 13% !important;
    }
}

@media screen and (min-width: 0) and (max-width: 1600px) {
    .contentarea .content_sec .asign_edit_pop .add_url_pop > div.signuparea {
        overflow: auto;
        max-height: 100vh;

        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-track {
            background-color: #f4f7fc;
            border-radius: 3px;
        }

        &::-webkit-scrollbar-thumb {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            border-radius: 3px;
        }
    }
}

@media screen and (min-width: 0) and (max-width: 980px) {
    .reportPage .daterangepicker.ltr.show-calendar.opensright {
        margin-left: -350px;
    }

    .admin_report_page .contentarea .content_sec .loader::after {
        top: -3px;
    }

    .daterangepicker .calendar-table th, .daterangepicker .calendar-table td {
        font-size: 12px;
        height: 16px;
    }

    .daterangepicker.opensright:before {
        right: 9px !important;
        left: auto !important;
    }

    .daterangepicker.opensright:after {
        right: 10px !important;
        left: auto !important;
    }

    .daterangepicker.ltr.show-calendar.opensright {
        left: 50% !important;
        margin-left: -320px;
    }

    .daterangepicker .calendar-table th.month {
        font-size: 11px;
    }

    .admin_report_page .content_sec .content .same-on > div .form-control {
        width: 68%;
    }

    .react-datepicker_input-container.first-one::after, .react-datepicker_input-container.second-one::after {
        left: auto;
        right: 15px;
    }

    .daterangepicker.opensright:before {
        right: 9px !important;
        left: auto !important;
    }

    .daterangepicker.opensright:after {
        right: 10px !important;
        left: auto !important;
    }

    .daterangepicker.ltr.show-calendar.opensright {
        right: 9% !important;
    }

    .admin_report_page .content_sec li {
        flex: 0 0 90%;
    }

    .contentarea .content_sec .asign_edit_pop .add_url_pop > div {
        height: 350px;
        overflow: auto;
    }

    .contentarea .content_sec .asign_edit_pop .add_url_pop.change-new > div {
        height: auto;
        overflow: hidden;
    }

    .contentarea .content_sec .asign_edit_pop .add_url_pop > div .scroll-section-part {
        height: 350px;
        overflow: auto;
        margin: 5px 5px 5px 0px;
    }

    .contentarea .content_sec .asign_edit_pop .add_url_pop > div .header.loader:before {
        right: 55px;
    }

    .signup_faq .faq_area .accordion_content .panel__inner[aria-hidden="false"], .contentarea .content_sec .accordion_content .panel__inner[aria-hidden="false"] {
        height: auto !important;
    }
}

@media screen and (min-width: 481) and (max-width: 767.2px) {
    .daterangepicker .calendar-table th, .daterangepicker .calendar-table td {
        font-size: 10px;
        height: 19px;
    }

    .daterangepicker .drp-buttons {
        padding-top: 0px;
    }

    .daterangepicker .calendar-table th.month {
        font-size: 11px;
    }
}

@media screen and (min-width: 0) and (max-width: 480px) {
    .admin_report_page .contentarea .content_sec .loader::after {
        top: -6px;
    }

    .daterangepicker .calendar-table th.month {
        font-size: 13px;
    }

    .daterangepicker .calendar-table th, .daterangepicker .calendar-table td {
        font-size: 13px;
        height: 15px;
    }

    .daterangepicker.opensright:before {
        right: 9px !important;
        left: auto !important;
    }

    .daterangepicker.opensright:after {
        right: 10px !important;
        left: auto !important;
    }

    .daterangepicker.ltr.show-calendar.opensright {
        left: auto !important;
        margin-left: 0px;
    }

    .daterangepicker .drp-calendar.right {
        margin-left: 0px;
        padding-top: 0px;
    }

    .daterangepicker .drp-calendar.left {
        padding-bottom: 0px;
    }

    .daterangepicker {
        height: 395px !important;
    }

    .admin_report_page .content_sec .content {
        padding-left: 15px;
        padding-right: 15px;
    }

    .daterangepicker.ltr.show-calendar.opensright {
        width: 82% !important
    }

    .admin_report_page .content_sec .content .same-on > div .form-control {
        width: 60%;
    }

    .admin_report_page .content_sec .content .gap-right label {
        padding-bottom: 10px;
    }

    .admin_report_page .content_sec .content select {
        margin-bottom: 0px;
    }

    .admin_report_page .content_sec .content .new-column.gap-left {
        padding-left: 0px;
    }

    .admin_report_page .content_sec .content .new-column.gap-right {
        padding-right: 0px;
    }

    .react-datepicker_input-container.second-one:before {
        left: 21px;
    }

    .admin_report_page .contentarea .content_sec .asign_edit_pop .add_url_pop > div {
        //width: 90%;
        //top: 60px;
        height: 320px;
    }

    .admin_report_page .content_sec li {
        flex: 0 0 100%;
    }

    .admin_report_page .content_sec .manage-content {
        padding-right: 50px;
    }

    .admin_report_page .content_sec .content .main {
        flex-direction: column;
    }

    .contentarea .content_sec .asign_edit_pop .add_url_pop > div .header.loader:before {
        right: 42px;
    }

    .contentarea .content_sec .asign_edit_pop .add_url_pop > div {
        height: auto;
        overflow: auto;
    }

    .contentarea .content_sec .url_card_sec .card.pricing_tool_box .setup_done_box {
        padding-left: 15px;
        padding-right: 15px;
    }

    .contentarea .content_sec .url_card_sec .card.pricing_tool_box .setup_done_box .body.pricing_box_res .content .url_box a {
        right: -10px;
    }

    .contentarea .content_sec .url_card_sec .card.pricing_tool_box .requesting_box .btn_box .add_url_btn {
        margin-left: 0px;
        margin-bottom: 20px;
    }

    .contentarea .content_sec .url_card_sec .card.pricing_tool_box .requesting_box .btn_box .text_request.gap-bottom {
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 1600px) and (min-width: 1200px) {
    .contentarea .content_sec .summary_right_part .payments_box .contentbox {
        max-width: 176px;
    }
}

@media screen and (min-width: 981px) and (max-width: 1920px) {
    .contentarea .content_sec .asign_edit_pop .add_url_pop.change-new > div.top-aligned_popup {
        overflow-y: visible;
    }

    .contentarea .content_sec .asign_edit_pop .add_url_pop.change-new > div.top-aligned_popup .scroll-section-part, .contentarea .content_sec .asign_edit_pop .add_url_pop.change-new .scroll-section-part {
        max-height: 90vh;
        overflow-y: auto;
        margin-right: 5px;
    }

    .contentarea .content_sec .asign_edit_pop .add_url_pop > div.top-aligned_popup {
        transform: translate(0);
        top: 0;
        overflow-y: scroll;
        max-height: 100%;

        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-track {
            background-color: #f4f7fc;
            border-radius: 3px;
        }

        &::-webkit-scrollbar-thumb {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            border-radius: 3px;
        }
    }
}

@media screen and (min-width: 0) and (max-width: 767.2px) {
    .contentarea .content_sec .asign_edit_pop .add_url_pop > div.top-aligned_popup, .contentarea .content_sec .asign_edit_pop .add_url_pop > div .scroll-section-part {
        height: calc(100% - 20px) !important;
        overflow: unset;

        .body {
            max-height: calc(100vh - 134px);
        }
    }
}

i.tooltip, .tooltip {
    cursor: pointer;
    margin-right: 25px;
    display: flex;
    position: relative;
    display: inline-block;

    img.icon {
        width: 20px;
        vertical-align: middle;
        margin-top: -3px;
    }

    span {
        position: absolute;
        bottom: 0;
        padding-left: 10px;
        padding-right: 10px;
        background: #FFFFFF;
        border: 1px solid #DDE5ED;
        box-shadow: 0 2px 14px #8ba6c126;
        //min-width: max-content;
        width: 260px;
        font-style: normal;
        padding-top: 5px;
        padding-bottom: 5px;
        border-radius: 4px;
        color: #000000 !important;
        @include Urbanist;
        font-size: 12px !important;
        font-weight: 400 !important;
        bottom: -6px;
        right: -130px;
        display: none !important;
        z-index: 9;
        transform: translateY(100%);
        -moz-transform: translateY(100%);
        -webkit-transform: translateY(100%);
    }

    &:after {
        content: '';
        width: 0px;
        height: 0px;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid #fff;
        position: absolute;
        bottom: -6.5px;
        left: 6px;
        z-index: 999;
        display: none;
    }

    &:before {
        content: '';
        width: 0px;
        height: 0px;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid #DDE5ED;
        position: absolute;
        bottom: -6px;
        left: 5px;
        display: none;
    }

    &:hover {
        span, &:before, &:after {
            display: flex !important;
        }
    }

    @media screen and (min-width: 0) and (max-width: 767px) {
        span {
            right: 5px;
            transform: translateY(100%) translateX(50%);
            -moz-transform: translateY(100%) translateX(50%);
            -webkit-transform: translateY(100%) translateX(50%);
        }
    }
}

.progress {

    &:hover {
        & + .progress-tooltip {
            display: flex;
        }
    }
}

.progress-tooltip {
    display: none;
    position: absolute;
    bottom: 0;
    padding-left: 10px;
    padding-right: 10px;
    background: #FFFFFF;
    border: 1px solid #DDE5ED;
    box-shadow: 0 2px 14px #8ba6c126;
    width: auto;
    font-style: normal;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 4px;
    color: #000000 !important;
    @include Urbanist;
    font-size: 12px !important;
    font-weight: 400 !important;
    bottom: -6px;
    left: 0;
    z-index: 9;
    transform: translateY(100%);
    -moz-transform: translateY(100%);
    -webkit-transform: translateY(100%);

    &:after {
        content: '';
        width: 0px;
        height: 0px;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid #fff;
        position: absolute;
        top: -5px;
        left: 6px;
        z-index: 999;
    }

    &:before {
        content: '';
        width: 0px;
        height: 0px;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid #DDE5ED;
        position: absolute;
        top: -6px;
        left: 5px;
    }

    &.right {
        left: auto;
        right: 0;

        &:after {
            left: auto;
            right: 6px;
        }

        &:before {
            left: auto;
            right: 5px;
        }
    }
}

.disabled-btn {
    pointer-events: none;
    opacity: 0.8;
}

@media screen and (min-width: 0) and (max-width: 767.2px) {
    .override_pop.asign_edit_pop .add_url_pop > div .footer {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background: #ffffff;
    }

    .contentarea .content_sec .url_card_sec .card.pricing_tool_box .requesting_box .btn_box .text_request.gap-bottom {
        margin-bottom: 20px;
    }

    .contentarea .content_sec .url_card_sec .card.pricing_tool_box .requesting_box .btn_box .add_url_btn {
        margin-left: 0px;
        margin-bottom: 20px;
    }
}

@media screen and (min-width: 0) and (max-width: 376px) {
    .contentarea .content_sec .url_card_sec .card.affiliate_refferal .table_area .table_body .table_row > div:not(.mobile_menu_icon):first-child {
        padding-right: 10px;
    }

    .contentarea .content_sec .url_card_sec .card.pricing_tool_box .setup_done_box .body.pricing_box_res .content .url_box {
        padding-right: 15px;
    }
}


.contentarea .content_sec .timifit-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1088px;
    width: 100%;
    margin-bottom: 60px;


    @media screen and (max-width: 991.2px) {
        margin-bottom: 50px !important;
        justify-content: center;
    }

    @media screen and (min-width: 768px) and (max-width: 991.2px) {
        flex-wrap: wrap;

        &:first-child {
            .timi-content {
                padding-bottom: 40px;

                h2 {
                    padding-left: 0;
                }
            }
        }
    }

    @media screen and (min-width: 0) and (max-width: 767.2px) {
        flex-wrap: wrap;

        &:first-child {
            .timi-content {
                margin-bottom: 20px;

                h2 {
                    padding-left: 0;
                }
            }
        }
    }

    h2 {
        font-size: 40px;
        line-height: 46px;
        color: #F1857F;
        font-weight: 800;
        margin-bottom: 16px;
        font-family: "Urbanist", sans-serif !important;
    }

    h3 {
        font-size: 32px;
        font-weight: 800;
        line-height: 38px;
    }

    p, h3 {
        color: #1d252d;
        margin-bottom: 16px;
        font-family: "Urbanist", sans-serif !important;
    }

    p {
        line-height: 26px;
        font-size: 20px;

        span.blue-color {
            color: #F1857F;
        }

        .orange-text {
            color: #fc6947;
        }
    }

    .timifit-logo {
        max-width: 170px;
        margin-bottom: 25px;

        img {
            margin-right: 28px;
            max-width: 100%;
        }
    }

    &:first-child {
        margin-bottom: 123px;
        align-items: flex-start;

        .timi-content {
            max-width: 588px;
        }
    }

    &:nth-child(2) {
        .timi-content {
            p {
                font-size: 16px;
                line-height: 22px;
            }
        }
    }

    > div {
        &:nth-child(1) {
            width: 100%;
            max-width: 615px;
        }

        &:nth-child(2) {
            width: 100%;
            max-width: 420px;
        }

        &.timi-content {
            .download-btn {
                width: 158px;
                height: 48px;
                font-size: 16px;
                color: #ffffff;
                background: #fc6947;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 8px;
                background-image: radial-gradient(circle at bottom right, #F1857F 1%, #EE934A 100%);
                font-family: "Urbanist", sans-serif !important;
            }
        }

        &.timi-image {

            img {
                max-width: 100%;
            }
        }
    }
}

.contentarea .content_sec .timifit-section {

    @media screen and (max-width: 991.2px) {

        &:last-child {
            .timi-content {
                max-width: 588px;
            }
        }

        .timi-image {
            max-width: 420px !important;
            text-align: center;
        }
    }
}

@media screen and (max-width: 1100.2px) {
    .contentarea .content_sec .tabular_box .table_body .table_row > div > div.toggle-switch span.error {
        position: relative;
        top: 0px;
        left: auto;
        margin-left: 20px;
    }
}

@media screen and (max-width: 979.2px) {
    .daterangepicker {
        height: 237px;
    }

    .reportPage .daterangepicker {
        height: 320px;
    }
}

@media screen and (min-width: 0) and (max-width: 767.2px) {
    .contentarea .content_sec .asign_edit_pop.edit_user_popup .add_url_pop.new_user_popup > div .body.fields > div > span {
        z-index: 0;
    }

    .contentarea .content_sec .asign_edit_pop.edit_user_popup .add_url_pop.new_user_popup > div .footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: #ffffff;
    }

    .contentarea .content_sec .asign_edit_pop.edit_user_popup .add_url_pop.new_user_popup > div .header {
        background: #ffffff;
    }

    .admin_user_page .contentarea .content_sec .tabular_box .table_body .table_row.user_page > div > div.toggle-switch {
        width: 100%;
        max-width: 100%;

        &:before {
            width: 50%;
        }

        .flip_icon {
            left: 50% !important;
        }
    }

    .admin_user_page .contentarea .content_sec .tabular_box .table_body .table_row.user_page.referral_links_page > div > div:nth-child(5) {
        width: 100%;
        max-width: 100%;
        display: flex !important;
        flex-direction: row;
        flex-wrap: wrap;

        &:before {
            content: 'Approve Payment';
            width: 100%;
        }

        > div {
            width: 48%;
        }
    }

    .contentarea .content_sec .tabular_box .table_body .table_row > div > div.toggle-switch span.error {
        left: 50px;
    }
}

@media screen and (max-width: 641.2px) {
    .daterangepicker {
        max-width: 100%;
        max-height: calc(100% - 200px);
        width: 451px;
        height: 100%;
        overflow-y: scroll;
    }

    .daterangepicker .drp-calendar.left, .daterangepicker .drp-calendar.right {
        width: 100%;
        max-width: 100%;
        padding-right: 8px;
        margin-left: 0;
    }

    .daterangepicker .drp-calendar.right {
        margin-left: 0;
    }

    .daterangepicker .calendar-table th, .daterangepicker .calendar-table td {
        width: calc(100%/7);
        min-width: calc(100%/7);
    }

    .daterangepicker.ltr.show-calendar.opensright {
        margin-left: -227px;
    }
}

@media screen and (max-width: 375.5px) {
    .mobile_header .menu_btn {
        margin-right: 8px;
    }
}

.toggle-switch {
    &.disabled-switch {
        .flip_icon {
            pointer-events: none;
            cursor: pointer;
        }
    }

    .flip_icon {
        cursor: pointer;
    }
}

.commision_tier {
    background: #f4f7fc;
    padding: 12px 18px 12px 10px;
    border-radius: 10px;
    @include Urbanist;
    cursor: context-menu;

    @media screen and (max-width:767.4px) {
        margin-right: 20px;
    }

    .ct_title_box {
        margin-bottom: 13px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .ct_title {
        @include Urbanist;
        font-size: 14px;
        font-weight: 800;
        color: #1d252d;
        line-height: normal;
        margin-bottom: 0;
        display: flex;
        align-items: center;
    }

    .ct_icon {
        width: 24px;
        margin-right: 5px;
        font-weight: 700;
    }

    .ct_infoicon {
        width: 12px;
        height: 12px;
        cursor: pointer;
    }

    .ct_progress {
        position: relative;

        .progress {
            width: 100%;
            position: relative;
            overflow: hidden;
            height: 4px;
            background: #ffffff;
            border-radius: 6px;

            .progress-bar {
                position: absolute;
                width: 30%;
                height: 100%;
                background: #EE934A;
                border-radius: 6px;
            }
        }
    }

    .ct_minmax {
        margin-top: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 10px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: normal;
        color: #000000;
    }

    p {
        font-size: 12px;
        font-weight: 600;
        color: #1d252d;
        font-style: normal;
        line-height: normal;
        margin-top: 10px;
    }
}

.ct_popup {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(29, 37, 45, .5);
    z-index: 99;

    > div {
        width: 660px;
        border-radius: 4px;
        box-shadow: 0 0 47px 0 rgba(0, 0, 0, 0.23);
        border: solid 1px #dde5ed;
        background-color: #ffffff;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: 0 auto;
        transform: translate(0, -50%);
        max-height: 100%;
        overflow-y: auto;

        @media screen and (max-width:767.4px) {
            width: 100%;
            max-height: calc(100% - 20px);
            height: auto;
            transform: unset;
            top: auto;
            bottom: 0;

            .tabular_box .table_body {

                .ct_tiername {

                    .ct_icon {
                        margin-right: 8px !important;
                    }
                }
            }

            .body, .header {
                padding-left: 15px !important;
                padding-right: 15px !important;
            }
        }

        .header {
            display: flex;
            align-items: center;
            @include Urbanist;
            font-weight: 700;
            color: #1d252d;
            font-size: 20px;
            padding: 13px 30px;
            border-bottom: 1px solid #dde5ed;

            .close {
                cursor: pointer;
                margin-left: auto;

                img {
                    width: 16px;
                    height: 16px;
                }
            }
        }

        .body {
            padding: 20px 30px 30px 30px;

            p {
                padding: 0;
                margin: 0;
                @include Urbanist;
                font-weight: 400;
                font-size: 16px;
                color: #000000;
                line-height: 24px;
                padding-bottom: 16px;
                padding-top: 16px;
            }

            h3 {
                font-size: 16px;
                margin-bottom: 12px;
                color: #1d252d;
                @include Urbanist;
            }

            .commision_tier {
                margin-right: 0 !important;

                .ct_title {
                    font-size: 16px;
                }

                .ct_progress {
                    .progress {
                        height: 8px;
                    }
                }

                .ct_minmax {
                    font-size: 12px;
                }

                p {
                    font-size: 14px;
                    color: #1d252d;
                    font-weight: 600;
                }
            }

            .tabular_box .table_body {
                max-height: 95px;
                overflow-y: scroll;

                &::-webkit-scrollbar {
                    width: 5px;
                }

                &::-webkit-scrollbar-track {
                    background-color: #f4f7fc;
                    border-radius: 3px;
                }

                &::-webkit-scrollbar-thumb {
                    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                    border-radius: 3px;
                }

                .ct_tiername {
                    align-items: center;
                    display: flex;

                    .ct_icon {
                        margin-right: 17px;
                    }
                }
            }
        }
    }
}

.tabular_box {
    border-radius: 8px;
    box-shadow: 0 1px 7px 0 rgba(139, 166, 193, 0.07);
    background-color: #ffffff;
    display: flex;
    flex-flow: column;
    width: 100%;

    .table_header {
        background: #F5F8FA;
        width: 100%;
        height: 32px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 10px;
        padding-right: 10px;
        max-width: 100%;

        > div {
            font-size: 14px;
            color: #000000;
            @include Urbanist;
            font-weight: 400;
            width: 100%;
            max-width: calc(100% / 3);
        }
    }

    .table_body {
        //max-height: 120px;

        .table_row {
            background: #fff;
            width: 100%;
            height: 48px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: auto;

            &:nth-child(even) {
                > div {
                    background-color: #F5F8FA;
                }
            }

            > div {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-left: 10px;
                padding-right: 10px;
                max-width: 100%;
                height: auto;
                min-height: 48px;
            }

            > div > div {
                font-size: 16px;
                color: #1D252D;
                @include Urbanist;
                font-weight: 400;
                word-break: break-all;
                width: 100%;
                max-width: calc(100% / 3);
            }
        }
    }
}

.invalidEmail {
    color: #000000 !important;
    @include Urbanist;
    font-size: 14px !important;
    line-height: normal;
    font-weight: 400 !important;
    display: flex;
    transition: all 0.16s linear;
    margin-bottom: 15px;
    margin-top: -15px;

    &:before {
        content: "!";
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: #000000;
        display: flex;
        color: #fff;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        margin-top: 1.5px;
        margin-right: 5px;
    }

    a {
        margin-left: 4px;
        cursor: pointer;
        color: #000000;
    }
}

.tooltip.risky-email {
    display: inline-block !important;

    .icon {
        font-style: normal;
        color: #f00;
        font-weight: 800;
        font-size: 18px;
    }

    span {
        min-width: 100px;
        width: 150px;
        bottom: -4px !important;
    }

    &:before {
        bottom: -4px !important;
        left: -3px !important;
    }

    &:after {
        bottom: -4px !important;
        left: -4px !important;
    }

    @media only screen and (min-width: 0) and (max-width: 767.4px) {
        margin-left: 5px;
        width: 18px;
        height: 18px;
        line-height: 20px;
        text-align: center;

        span {
            bottom: -38px !important;
            transform: translateX(-50%);
            left: 0;
        }

        &:before {
            left: 3px !important;
        }

        &:after {
            left: 2px !important;
        }
    }
}

.overflow-reset {
    overflow: unset !important;

    .invalidEmail {
        color: #000000 !important;
        @include Urbanist;
        font-size: 14px !important;
        line-height: normal;
        font-weight: 400 !important;
        display: flex;
        transition: all 0.16s linear;
        margin-bottom: 15px;
        margin-top: -15px;

        &:before {
            content: "!";
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: #000000;
            display: flex;
            color: #fff;
            align-items: center;
            justify-content: center;
            font-size: 13px;
            margin-top: 1.5px;
            margin-right: 5px;
        }

        a {
            margin-left: 4px;
            cursor: pointer;
            color: #000000;
        }
    }

    .tooltip.risky-email {
        display: inline-block !important;

        .icon {
            font-style: normal;
            color: #f00;
            font-weight: 800;
            font-size: 18px;
        }

        span {
            min-width: 100px;
            width: 150px;
            bottom: -4px !important;
        }

        &:before {
            bottom: -4px !important;
            left: -3px !important;
        }

        &:after {
            bottom: -4px !important;
            left: -4px !important;
        }

        @media only screen and (min-width: 0) and (max-width: 767.4px) {
            margin-left: 5px;
            width: 18px;
            height: 18px;
            line-height: 20px;
            text-align: center;

            span {
                bottom: -38px !important;
                transform: translateX(-50%);
                left: 0;
            }

            &:before {
                left: 3px !important;
            }

            &:after {
                left: 2px !important;
            }
        }
    }

    .overflow-reset {
        overflow: unset !important;

        @media only screen and (min-width: 0) and (max-width: 767.2px) {
            flex-direction: row !important;
            flex-wrap: wrap !important;

            &:before {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width:1280.4px) and (min-width:1200.4px) {
    .left_sidebar .navbar ul li {
        min-height: 42px;
        max-height: 42px;
    }

    .left_sidebar .navbar {
        margin-top: 15px;
    }

    .commision_tier .ct_title_box {
        margin-bottom: 10px;
    }
}

@media screen and (min-width: 768px) {
    .contentarea .content_sec .tabular_box .table_header.user_page.user_data > div:nth-child(6) {
        max-width: 130px;
    }

    .contentarea .content_sec .tabular_box .table_body .table_row.user_page.user_data > div > div:nth-child(6) {
        max-width: 130px;
    }

    .contentarea .content_sec .tabular_box .table_header.user_page.user_data > div:nth-child(2),
    .contentarea .content_sec .tabular_box .table_body .table_row.user_page.user_data > div > div:nth-child(2) {
        padding-right: 10px;
    }

    .contentarea .content_sec .tabular_box .table_header.user_page.user_data > div:nth-child(5),
    .contentarea .content_sec .tabular_box .table_body .table_row.user_page.user_data > div > div:nth-child(5) {
        max-width: 75px;
        word-break: break-word;
    }

    .contentarea .content_sec .tabular_box .table_body .table_row.user_page.user_data > div > div:nth-child(3) {
        max-width: 195px;
    }

    .contentarea .content_sec .tabular_box .table_header.user_page.user_data > div:nth-child(3) {
        max-width: 195px;
    }
}

.tt_cards_header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    div {
        padding-left: 40px;
    }

    .loader {
        pointer-events: none;
        position: relative;
        transition: all .2s ease-in-out;
        justify-content: space-evenly;

        &:after {
            content: "";
            border: 4px solid #EE934A;
            border-radius: 50%;
            border-top: 4px solid #fff;
            width: 25px;
            height: 25px;
            -webkit-animation: .5s linear infinite spin;
            animation: .5s linear infinite spin;
            position: absolute;
            left: 0;
        }
    }
}

.tt_select {
    width: 142px;
    height: 36px;
    padding: 9px 16px 8px 11px;
    border: solid 1px #e4ebf6;
    background-color: #fff;
    font-size: 14px;
    font-weight: 500;
    color: #1d252d;
    background: #ffffff;

    @media screen and (max-device-width: 620px) {
        text-transform: capitalize;
        width: 130px;
    }
}

.transaction_type_cards {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 16px;

    .tt_card_wrapper {
        //max-width: 200px;
        width: 17.5%;
        min-height: 150px;
        flex-grow: 0;
        padding: 21px 21px 18px 21px;
        border-radius: 8px;
        box-shadow: 0 2px 4px 0 rgba(80, 109, 133, 0.05);
        background-color: #ffffff;
        margin-right: 16px;

        &:nth-child(5) {
            max-width: 350px;
            width: 32%;
        }

        &:last-child {
            margin-right: 0;
            position: relative;

            .tt_select {
                position: absolute;
                top: 18px;
                right: 16px;
                width: 122px;
                height: 32px;
                font-size: 12px;
                padding: 8px 16px 7px 8px;
                margin-right: 16px;
            }

            h2 {
                margin-top: 8px;
            }
        }

        p {
            font-size: 13px;
            font-weight: 700;
            line-height: 1.64;
            text-align: left;
            color: #000000;
            margin-bottom: 0;
        }

        h2 {
            font-size: 28px;
            font-weight: 700;
            text-align: left;
            color: #000000;
            margin-bottom: 0;
            margin-top: 21px;
            padding: 0;
        }
    }

    @media screen and (max-width: 1478px) and (min-width: 1291px) {

        .tt_card_wrapper {
            padding: 21px 11px 18px 11px;
        }
    }

    @media screen and (max-width: 1280px) {
        flex-wrap: wrap;

        .tt_card_wrapper {
            width: calc(25% - 14px);

            &:nth-child(4) {
                margin-right: 0;
            }

            &:nth-child(5) {
                max-width: 100%;
                width: calc(50% - 9px);
                margin-top: 16px;
            }
        }
    }

    @media screen and (max-width: 992px) {
        .tt_card_wrapper {
            //padding: 21px 17px 21px 17px;
            padding: 21px 15px 18px 15px;

            &:last-child .tt_select {
                //width: 100px;
                margin-right: 12px;
            }
        }
    }

    @media screen and (max-width: 811px) and (min-width: 768px) {

        .tt_card_wrapper {
            padding: 21px 12px 18px 12px;

            &:last-child .tt_select {
                width: 103px;
            }
        }
    }

    @media screen and (max-width: 620px) {
        .tt_card_wrapper {
            width: calc(50% - 8px);
            max-width: 100%;
            margin-right: 0;
            margin-bottom: 16px;

            &:nth-child(5) {
                width: 100%;
                margin-top: 0;
            }
        }
    }
}

@media screen and (max-width: 620px) {
    .tt_cards_header {
        margin-bottom: 14px;

        h2 {
            padding-left: 0 !important;
            margin-bottom: 0 !important;
        }
    }
}

@media screen and (max-width: 375px) {
    .tt_cards_header {
        flex-wrap: wrap;

        h2 {
            width: 100%;
        }

        div {
            padding-left: 0;
            padding-right: 40px;

            &.loader:after {
                left: auto;
                right: 0;
            }
        }
    }
}

.report-loader {
    pointer-events: none;
    display: flex;
    align-items: center;
    transition: all .2s ease-in-out;
    justify-content: space-around;
    width: 18px;
    height: 18px;
    position: absolute;
    right: 10px;
    top: 10px;

    &:after {
        content: "";
        border: 3px solid #EE934A;
        border-radius: 50%;
        border-top: 3px solid #fff;
        width: 12px;
        height: 12px;
        -webkit-animation: .5s linear infinite spin;
        animation: .5s linear infinite spin;
        position: relative;
        right: 0;
        order: 2;
    }
}

.page-loader {
    pointer-events: none;
    display: flex;
    align-items: center;
    transition: all .2s ease-in-out;
    justify-content: space-around;
    width: 18px;
    height: 18px;
    margin-bottom: 10px;
    margin-left: auto;

    &:after {
        content: "";
        border: 3px solid #EE934A;
        border-radius: 50%;
        border-top: 3px solid #fff;
        width: 12px;
        height: 12px;
        -webkit-animation: .5s linear infinite spin;
        animation: .5s linear infinite spin;
        position: relative;
        right: 0;
        order: 2;
    }
}

.btn-loader {
    pointer-events: none;
    opacity: 0.85;

    &:after {
        content: "";
        border: 3px solid #EE934A;
        border-radius: 50%;
        border-top: 3px solid #fff;
        width: 12px;
        height: 12px;
        -webkit-animation: .5s linear infinite spin;
        animation: .5s linear infinite spin;
        position: relative;
        right: 0;
        order: 2;
        margin-left: 10px;
    }
}

.summary-footer {
    text-align: center;
    padding-top: 20px;
    font-size: 14px;
}

.seclink-toggle a {
    position: relative;
    top: 0;
    display: block;
    margin-right: 10px;
}

.error-page {
    width: 100%;
    min-height: 100vh;
    border: none;
    outline: none;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 0;

    &.error-loggedin {
        width: 100%;
        min-height: calc(100vh - 40px);
        border: none;
        outline: none;
        display: block;
        margin-left: 0;

        .error-container {
            height: calc(100% - 80px);
            margin-top: 20px;
            width: calc(100% - 312px);
            margin-left: auto;
            margin-right: 20px;
        }
    }

    .error-container {
        background: #fff;
        padding: 80px;
        border-radius: 4px;
        text-align: center;
        margin: 10px auto;
        max-width: calc(100% - 20px);

        @media screen and (max-width: 576px) {
            padding: 40px;
        }

        .error-image {
            margin-bottom: 30px;
        }

        h2 {
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #1d252d;
            margin-bottom: 25px;
            font-size: 40px;
            line-height: 1;
        }

        p {
            color: #000000;
            font-size: 20px;
            font-weight: 400;
        }
    }

    .view_btn a {
        color: #ffffff;
        width: 196px;
        height: 48px;
        line-height: 48px;
        border-radius: 8px;
        background: #EE934A;
        margin: 40px auto 0;
        text-decoration: none;
        font-size: 20px;
        display: block;
    }
}

.typehead {
    .typehead-item {
        list-style: none;
        position: absolute;
        top: 54px;
        left: 0;
        width: 100%;
        z-index: 10;
        background: #ffffff;
        box-shadow: 0 2px 10px rgba(0, 0, 0, .15);
        border-radius: 5px;
        max-height: 300px;
        overflow: overlay;
        display: none;

        li {
            padding: 10px 20px;
            cursor: pointer;
            font-size: 14px;
        }

        &.open {
            display: block
        }
    }
}

.new_user_popup {
    .yes_btn {
        &.disabled {
            opacity: 0.3;
            pointer-events: none;
        }
    }
}

.secsignupcopy {
    span {
        left: 50% !important;
        transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
    }
}

.admin_user_page .contentarea .content_sec .tabular_box {
    .table_header {
        &.userlink_data {

            > div {
                &:nth-child(2) {
                    max-width: 130px;
                }
            }
        }
    }

    .table_body {
        .table_row {
            &.userlink_data {
                > div > div {
                    &:nth-child(2) {
                        max-width: 130px;
                    }
                }

                @media screen and (max-width: 1024px) {
                    > div > div {

                        &:first-child:before {
                            content: 'Assigned User';
                        }

                        &:nth-child(2) {
                            &:before {
                                content: 'Group Number';
                            }
                        }

                        &:nth-child(3):before {
                            content: 'Created On';
                        }

                        &:nth-child(4):before {
                            content: 'Link Type';
                        }

                        &:nth-child(5):before {
                            content: 'Link Suffix';
                        }

                        &:nth-child(6) {

                            &:before {
                                content: 'No. of Signups';
                            }
                        }
                    }
                }

                @media screen and (max-width: 767.5px) {
                    > div > div {
                        width: 50%;
                        margin-left: 0;
                        min-width: 50%;

                        &:nth-child(6) {
                            flex-direction: column;
                        }

                        &.icons {
                            flex-direction: row;

                            .seclink-toggle .flip_icon {
                                left: 0 !important;
                            }
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width:375px) {
    .seclink_header {
        position: relative !important;
        left: 0;
        top: 0 !important;

        .adduserbtn {
            margin-left: 17px !important;
            margin-bottom: 20px;

            &:before {
                right: auto;
                left: calc(100% + 30px);
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 1100px) {
    .contentarea .content_sec .tabular_box .table_body .table_row.user_page.userlink_data > div > div:first-child,
    .contentarea .content_sec .tabular_box .table_body .table_row.user_page > div > div:nth-child(3) {
        padding-right: 0;
    }
}

.spam_msg {
    font-size: 14px !important;
    line-height: 1 !important;
}

.sqbox {
    margin-bottom: 20px;
}

.make-payment {
    &.disabled {
        pointer-events: none;
        opacity: 0.8;
    }
}

.contentarea .content_sec .resetgroup_box .add_url_pop.payment_popup > div {
    @media screen and (min-width: 0px) and (max-width: 767.5px) {
        &.Mask {
            height: auto !important;
            max-height: calc(100% - 40px) !important;
            overflow: overlay;
            padding-bottom: 20px;
            /*.body {
                height: auto;
                overflow: overlay;
                max-height: calc(100% - 90px);
            }*/
        }
    }

    @media screen and (min-width: 0px) and (max-width: 376px) {
        #form-container .footer .yes_btn {
            width: 116px !important;
        }

        h2 {
            padding-left: 0 !important;
        }

        .sq-card-wrapper {
            min-width: auto;
            max-width: 100%;
        }
    }
}

.summary_card {
    .summary_card_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
    }

    h2 {
        margin-bottom: 0 !important;
        font-size: 24px !important;
    }

    .summary_card_header a {
        width: 150px;
        height: 36px;
        padding: 9px 18px 8px;
        border-radius: 6px;
        border: solid 1px #EE934A;
        background-color: #ffffff;
        font-family: Urbanist;
        font-size: 14px;
        font-weight: bold;
        color: #EE934A;
        display: block;
    }
}

.order_card_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;

    .order_card.card_front .card_footer {
        padding: 8px 14px;
    }

    > a {
        display: flex;
        font-family: "Urbanist", sans-serif !important;

        .order_card {
            min-height: 235px;

            .card_body {
                font-family: "Urbanist", sans-serif !important;

                .instructions {
                    margin-top: 8px;
                }
            }

            &.card_front {
                .card_body {
                    padding-top: 12px;
                }
            }
        }
    }
}

.order_card {
    width: 500px;
    min-height: 300px;
    max-width: 100%;
    overflow: hidden;
    border-radius: 16px;
    color: #1d252d;
    background-image: url('./images/card-background.png');
    background-size: cover;
    background-position: top left;

    h2, p, h3 {
        font-family: "Urbanist", sans-serif !important;
        color: #ffffff;
    }

    &.card_front {

        .card_body {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            padding: 16px 14px;
            flex-wrap: wrap;

            .memberid {
                color: #ffffff;
                font-size: 24px;

                span {
                    display: block;
                }
            }

            .member-info {
                width: 180px;
                max-width: 40%;

                p {
                    font-size: 16px;
                    color: #ffffff;
                }

                @media screen and (min-width: 0px) and (max-width: 575px) {
                    width: 170px;
                    max-width: 50%;

                    p {
                        font-size: 16px;
                    }

                    .memberid {
                        font-size: 12px;
                    }
                }
            }
        }

        .card_info {
            color: #ffffff;
            font-weight: 400;
            width: 244px;
            max-width: 60%;
            font-size: 30px;
            line-height: 1.3;

            @media screen and (min-width: 0px) and (max-width: 575px) {
                font-size: 18px;
                width: 150px;
                max-width: 50%;
            }
        }

        .contact_info {
            width: 140px;
            font-size: 13px;
            text-align: center;

            p {
                font-size: 12px;
                font-weight: 600;
                line-height: 19px;
                margin-top: 13px;
            }
        }

        .card_header {
            padding: 20px 14px 0px;
            text-align: right;

            h2 {
                font-weight: 400;
                color: #ffffff;
                font-size: 24px;
                margin-top: 10px;
                margin-bottom: 0;
            }

            @media screen and (min-width: 0px) and (max-width: 1024px) {
                h2 {
                    font-size: 16px;
                }

                .card_logo {
                    max-width: 188px;
                }
            }

            @media screen and (min-width: 0px) and (max-width: 575px) {
                .card_logo {
                    max-width: 188px;
                }

                h2 {
                    font-size: 16px;
                }
            }
        }

        .card_logo {
            max-width: 268px;
            display: block;
            margin-left: auto;
        }

        .card_footer {
            background-color: #EE934A;
            background-size: 100%;
            background-repeat: no-repeat;
            padding: 10px 14px;
            font-size: 14px;
            color: #000000;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            font-weight: 400;

            .footer-right {
                max-width: 100%;
                width: 270px;
            }

            .footer-left {
                width: 190px;
                max-width: 50%;
            }

            .insurance-text {
                font-weight: 700;
                margin-bottom: 8px;

                @media screen and (min-width: 0px) and (max-width: 575px) {
                    margin-bottom: 5px;
                }
            }

            p {
                color: #000000;
            }
        }

        @media screen and (min-width: 0px) and (max-width: 1200px) {
        }

        @media screen and (min-width: 0px) and (max-width: 420px) {
        }
    }

    &.card_back {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .card_body {
            display: flex;
            padding: 5px 12px;
            flex-wrap: wrap;
            line-height: 1;

            p {
                margin-bottom: 6px;

                @media screen and (min-width: 0px) and (max-width: 575px) {
                    margin-bottom: 3px;
                }
            }

            .left_info {
                width: 100%;
                font-size: 11px;
                display: flex;
                flex-wrap: wrap;
                color: #ffffff;
            }

            .right_info {
                font-size: 8px;
                text-align: center;
                display: flex;
                align-items: flex-end;
                flex-wrap: wrap;

                img {
                    max-width: 70px;
                }

                a {
                    display: block;
                }

                p {
                    margin-top: 10px;
                }

                @media screen and (min-width: 0px) and (max-width: 420px) {
                    font-size: 7px;
                    width: 75px;
                }
            }

            .col-6 {
                width: 50%;
                padding-left: 6px;
                margin-bottom: 6px;

                @media screen and (min-width: 0px) and (max-width: 575px) {
                    &:first-of-type {
                        padding-left: 0;
                    }

                    margin-bottom: 4px;
                }
            }

            .seperator-text {
                position: relative;
                text-align: center;
                margin-bottom: 8px;
                width: 100%;
                z-index: 1;
                color: #ffffff;
                font-size: 11px;

                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    height: 1px;
                    width: 100%;
                    background-color: #ffffff;
                    top: 5px;
                    z-index: -1;
                }

                span {
                    background: #272b2b;
                }

                @media screen and (min-width: 0px) and (max-width: 575px) {
                    margin-bottom: 4px;
                }
            }
        }

        .logos {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            padding-right: 10px;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 5px;

            img {
                max-width: 60px;
                max-height: 19px;
            }

            @media screen and (min-width: 0px) and (max-width: 575px) {
                margin-bottom: 3px;

                img {
                    max-width: 50px;
                }
            }
        }


        .card-footer {
            font-size: 11px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-wrap: wrap;
            background: #3b3d40;
            padding: 6px 12px;

            @media screen and (min-width: 0px) and (max-width: 575px) {
                font-size: 10px;
                padding: 6px 8px;
            }

            .logos {
                width: 290px;
                max-width: 60%;
                gap: 6px;
                padding-right: 1px;
            }

            .right_info {
                width: 180px;
                max-width: 40%;
                text-align: right;
                font-size: 14px;

                div img {
                    width: 70px;
                    display: inline-block;

                    &:last-of-type {
                        margin-left: 4px;
                    }

                    @media screen and (min-width: 0px) and (max-width: 575px) {
                        max-width: 60px;
                    }
                }

                div > a {
                    display: inline-block;
                    margin-left: 4px;

                    img {
                        display: block;
                        margin-left: 2px;
                    }
                }

                p > a {
                    display: block;
                }

                p {
                    line-height: 1.1;
                }
            }

            .instructions {
                color: #ffffff;

                h4 {
                    font-size: 14px;
                    font-weight: 600;
                }
            }
        }

        @media screen and (min-width: 0px) and (max-width: 575px) {
            .card_body {
                padding: 5px 8px;
                padding-top: 10px;

                .seperator-text {
                    margin-bottom: 4px;
                }

                .left_info {
                    font-size: 10px;
                }
            }

            .card-footer {
                .logos {
                    width: 210px;
                    max-width: 55%;
                    gap: 0px;
                    /*gap: 10px;
                    width: 100%;
                    max-width: 100%;*/
                    img {
                        max-width: 35px;
                    }
                }

                .right_info {
                    font-size: 10px !important;
                    max-width: 45%;
                    /*width: 100%;
                    max-width: 100%;
                    font-size: 10px;
                    text-align: left;*/
                    div img {
                        max-width: 50px;
                    }
                }

                .instructions {
                    h4 {
                        font-size: 13px;
                    }
                }
            }
        }
    }

    @media screen and (min-width: 0px) and (max-width: 1179px) {
        margin-bottom: 10px;
        margin-right: 0 !important;
    }
}

.consumer_summary_card {
    .order_card.card_front {
        .card_header {
            h2 {
                font-size: 22px !important;
            }
        }

        .card_logo {
            max-width: 238px;
            margin-bottom: 0;
            margin-right: 0;
        }

        .card_body {
            .member-info {
                width: 175px;
            }

            .memberid {
                font-size: 22px;

                span {
                    font-size: 22px;
                    color: #ffffff;
                }
            }
        }

        .card_info {
            span, div {
                color: #ffffff;
                font-weight: 400;
                font-size: 26px;
                line-height: 1.3;
            }

            span {
                display: inline-block;
            }
        }

        .card_footer {
            font-size: 12px;

            p {
                font-size: 12px;
            }
        }
    }

    .order_card.card_front {
        position: relative;

        .side-icon {
            background-image: url('./images/card-left-icon.png');
            position: absolute;
            background-repeat: no-repeat;
            left: 0;
            top: 0;
            width: 50px;
            height: 50px;
            background-size: contain;

            @media screen and (min-width: 0px) and (max-width: 575px) {
                width: 50px;
                height: 50px;
            }
        }
    }
}

.all_cards {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 10px;
    background-color: #fff;
    padding: 23px 30px;
    font-family: "Urbanist", sans-serif !important;

    h2, h3 {
        font-family: "Urbanist", sans-serif !important;
        color: #1d252d;
    }

    .tt_select {
        display: table;
        margin-left: auto;
    }

    .select_group {
        width: 100%;
        margin-bottom: 10px;
    }

    > div {
        position: relative;
        max-width: 100%;

        > h3 {
            font-size: 20px;
            font-weight: 800;
            margin-bottom: 22px;
        }

        .buttons {
            width: 77px;
            margin-left: auto;
            display: flex;
            justify-content: space-between;
            position: absolute;
            top: 3px;
            right: 0;

            .download {
                width: 20px;
                height: 22px;
                cursor: pointer;
                background: url(./images/download_card.png) no-repeat;
                background-size: 100%;
            }

            .print {
                width: 24px;
                height: 22px;
                cursor: pointer;
                background: url(./images/card_print.png) no-repeat;
                background-size: 100%;
            }
        }
    }

    @media screen and (min-width: 0px) and (max-width: 420px) {
        padding: 15px 10px;

        .cards {
            margin-bottom: 18px;
            gap: 0;
        }
    }
}

.cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 40px;

    @media screen and (min-width: 0px) and (max-width: 1360px) {
        gap: 30px;
    }

    .order_card {
        width: 500px;
        max-width: calc(50% - 20px);
        margin-bottom: 48px;

        @media screen and (min-width: 0px) and (max-width: 1024px) {
            max-width: 100%;
        }

        &:last-of-type {
            margin-right: 0;
        }
    }

    .card_back {
        background-color: #000000;

        .card_body {
            padding-top: 10px;
        }
    }

    .card_front {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: #000000;
        position: relative;

        .side-icon {
            background-image: url('./images/card-left-icon.png');
            position: absolute;
            background-repeat: no-repeat;
            left: 0;
            top: 0;
            width: 80px;
            height: 80px;
            background-size: contain;

            @media screen and (min-width: 0px) and (max-width: 575px) {
                width: 50px;
                height: 50px;
            }
        }

        @media screen and (min-width: 0px) and (max-width: 575px) {
            min-height: 310px;

            &:before {
                width: 50px;
                height: 50px;
            }
        }
    }

    &.walmart_card, &.cvs_card {
        .order_card {

            &.card_back {
                .card_body {
                    text-align: center;

                    h3 {
                        color: #1e76d0;
                        font-size: 14px;
                        padding: 0 30px;
                        padding-bottom: 4px;
                        width: 100%;
                        margin-bottom: 5px;
                    }

                    .instructions {

                        p:last-child {
                            margin-bottom: 0;
                        }
                    }

                    p {
                        font-size: 11px;
                    }

                    h4 {
                        font-size: 12px;
                        display: block;
                        width: 100%;
                        color: #ffffff;
                        margin-bottom: 6px;
                        margin-top: 4px;

                        img {
                            max-width: 60px;
                            max-height: 8px;
                        }
                    }

                    > p {
                        width: 90%;
                        margin-left: auto;
                        margin-right: auto;
                    }

                    .walmart_info {
                        display: flex;
                        font-size: 9px;
                        width: 100%;
                        justify-content: space-between;
                    }
                }

                .card-footer {
                    .right_info {
                        width: 100%;
                        max-width: 100%;
                        display: flex;
                        color: #ffffff;
                        justify-content: space-between;

                        span {
                            display: inline-block;
                            width: 50%;

                            &:first-of-type {
                                text-align: left;
                            }
                        }
                    }
                }
            }

            &.card_front {

                .tagline {
                    background: #1e76d0;
                    color: #ffffff;
                    text-transform: uppercase;
                    text-align: center;
                    font-weight: 700;
                    font-size: 14px;
                    padding: 5px;
                    margin-top: 8px;
                }

                .card_heading {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-size: 14px;
                    font-weight: 500;
                    color: #ffffff;
                    padding-left: 50px;
                }

                .card_body {
                    padding: 10px 25px;

                    .card_info {
                    }

                    .prescription-info {
                        font-weight: 600;
                        font-size: 20px;
                        width: 100%;
                        color: #ffffff;
                        text-align: center;
                    }
                }

                .card_footer {
                    background-image: none;
                    color: #000000;
                }
            }
        }
    }

    &.nvt_card {
        .order_card {

            &.card_front {
                .card_info {
                }
            }
        }
    }

    &.cvs_card {
        .order_card {

            &.card_back {
                .card_body {
                    > h3 {
                        color: #ce0c2e;
                    }
                }
            }

            &.card_front {
                .card_header {
                    padding: 20px 30px 0;
                }

                .tagline {
                    background: #ce0c2e;
                    font-size: 16px;
                    padding: 7px 6px;
                    text-transform: unset;
                }

                .card_heading {
                    letter-spacing: -.4px;

                    img {
                        max-width: 146px;
                        display: block;
                        margin-left: 0;
                    }

                    @media screen and (min-width: 0px) and (max-width: 575px) {
                        padding-left: 10px;

                        img {
                            max-width: 106px;
                        }

                        .header_info {
                            font-size: 12px;
                        }
                    }
                }

                .card_body {
                    padding-left: 30px;
                    padding-right: 30px;

                    .prescription-info {
                        font-weight: 400;
                        font-size: 16px;

                        @media screen and (min-width: 0px) and (max-width: 575px) {
                            margin-bottom: 15px;
                        }
                    }
                }
            }
        }
    }

    &.walmart_card {
        &.order_card.card_front {
            .card_heading {
                align-items: flex-end;
            }
        }
    }

    &.dst_card {
        .order_card {

            &.card_front {
            }
        }
    }
}

@media print {
    body {
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
    }

    .cards .card_front, .cards .card_back {
        background-color: #000000 !important;
        background-image: url(./images/card-background.png);
        width: 500px !important;
    }

    .cards {
        flex-direction: column;

        .order_card {
            width: 100% !important;
            min-width: 500px !important;
        }
    }

    .all_cards {
        .buttons {
            display: none !important;
        }
    }
}

.select_group {
    width: 830px;
    max-width: 100%;
}

@media print {
    .order_card {
        width: 500px !important;
        background-color: #000000 !important;
        background-image: url(./images/card-background.png);
        margin-bottom: 60px !important;
    }

    .all_cards > div > h3 {
        margin-bottom: 45px !important;
    }

    .select_group {
        display: none;
    }

    .order_card.card_back .card-footer .instructions h4 {
        font-size: 12px !important;
    }

    .order_card.card_back .card-footer .instructions p {
        font-size: 10px !important;
    }

    .order_card.card_back .card-footer .right_info {
        font-size: 12px;
    }

    .order_card.card_back .card-footer .right_info div img {
        width: 60px;
    }

    .order_card.card_back .card-footer .instructions {
        margin-top: -5px;
    }

    .cards .card_back .card_body {
        padding-bottom: 0px;
        padding-top: 7px;
    }
}

@media screen and (min-width: 1600px) and (max-width: 2600px) {
    .Transactions_Summary {
        .order_card_wrapper {
            justify-content: flex-start;

            > a {
                min-width: 45%;
                max-width: 47%;

                &:first-child {
                    margin-right: 34px;
                }

                .order_card {
                    width: 100%;
                }
            }
        }
    }
}

.Transactions_Summary {
    .order_card_wrapper {
        .order_card {
            margin-bottom: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &.card_front {
                position: relative;

                .side-icon {
                    background-image: url('./images/card-left-icon.png');
                    position: absolute;
                    background-repeat: no-repeat;
                    left: 0;
                    top: 0;
                    width: 80px;
                    height: 80px;
                    background-size: contain;

                    @media screen and (min-width: 0px) and (max-width: 575px) {
                        width: 50px;
                        height: 50px;
                    }
                }
            }
        }
    }
}

.contentarea .content_sec .tabular_box .table_header.groupNoHeader > div:nth-child(3) {
    max-width: 170px;
}

.contentarea .content_sec .tabular_box .table_body .table_row > div.groupNoRow > div:nth-child(3) {
    max-width: 170px;
}

.contentarea .content_sec .tabular_box .table_header.groupNoHeader > div:nth-child(4) {
    max-width: 170px;
}

.contentarea .content_sec .tabular_box .table_body .table_row > div.groupNoRow > div:nth-child(4) {
    max-width: 170px;
}

.contentarea .content_sec .tabular_box .table_header.groupNoHeader > div:last-child {
    width: 100%;
    max-width: 150px;
    display: flex;
}

.contentarea .content_sec .tabular_box .table_body .table_row > div.groupNoRow > div:last-child {
    width: 100%;
    max-width: 150px;
    display: flex;

    i {
        max-width: 20px;
    }
}

@media screen and (min-width: 0) and (max-width: 1100px) {
    .contentarea .content_sec .tabular_box .table_body .table_row > div.groupNoRow > div {
        &:nth-child(1):before {
            content: 'Group Number';
        }

        &:nth-child(2):before {
            content: 'Full Name';
        }

        &:nth-child(3):before {
            content: 'Parent Affiliate';
        }

        &:nth-child(4):before {
            content: 'Grandparent Affiliate';
        }

        &:nth-child(5):before {
            content: 'Commission($)';
        }

        &:nth-child(6):before {
            content: 'Status';
        }

        &:nth-child(7):before {
            content: 'Actions';
        }
    }
}

@media only screen and (min-width: 0) and (max-width: 767.2px) {
    .contentarea .content_sec .tabular_box .table_body .table_row > div.groupNoRow > div {
        max-width: 50% !important;
        width: 50%;

        &:last-child {
            display: none;
        }

        &.mobile_menu_icon {
            &:before {
                content: ''
            }
        }
    }
}

.tt_card {
    .tooltip {
        margin: 0;
        position: absolute;
        right: 10px;
        top: 16px;

        img {
            width: 15px !important;
        }

        span {
            right: -10px;
            transform: translateY(-33px);
            -moz-transform: translateY(-33px);
            -webkit-transform: translateY(-33px);
        }

        &:after {
            bottom: 22.5px;
            transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
        }

        &:before {
            bottom: 21px;
            transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
        }
    }
}

.user-table-actions {
    position: relative;

    .report-loader {
        right: 0;
        top: 0;
        z-index: 9;
    }
}

.tcstatus {
    position: absolute;
    padding-left: 10px;
    padding-right: 10px;
    background: #FFFFFF;
    border: 1px solid #DDE5ED;
    box-shadow: 0 2px 14px #8ba6c126;
    min-width: max-content;
    font-style: normal;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 4px;
    color: #000000;
    font-family: "Urbanist", sans-serif !important;
    font-size: 12px;
    font-weight: 400;
    bottom: -30px;
    right: -10px;
    z-index: 9;
}

.training-module-box {
    min-height: 90vh;

    &.no-module {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.trainingpage_header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
        margin-bottom: 0;
    }
}

.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Urbanist", sans-serif !important;
    font-size: 16px;
    height: 48px;
    padding: 12px 32px;
    border-radius: 6px;
    cursor: pointer;

    &.red-btn {
        background-color: $red;
        color: $white;
        transition: all .2s ease-in-out;
        background-image: radial-gradient(circle at bottom right, #F1857F 1%, #EE934A 100%);
    }

    &.gray-btn {
        border: solid 1px #dde5ed;
        background-color: #f5f8fa;
        margin-left: 16px;
        color: #000000;
    }

    &.h-40 {
        height: 40px;
        font-size: 16px;
    }
}

.no-module-section {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    min-width: 300px;
    width: auto;
    align-items: center;
    text-align: center;
    flex-direction: column;

    img {
        width: 240px;
        height: 240px;
        margin-bottom: 28px;
    }

    h3 {
        font-size: 28px;
        font-weight: bold;
        line-height: 36px;
        letter-spacing: normal;
        color: $black;
        margin-bottom: 8px;
    }

    p {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: normal;
        color: $black;
        margin-bottom: 28px;
    }

    &.no-module-section-small {
        img {
            width: 180px;
            height: 180px;
            margin-bottom: 8px;
        }

        h3 {
            font-size: 20px;
            line-height: 36px;
        }
    }
}

.training_modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(29, 37, 45, 0.5);
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;

    &.videoPopup {
        .modal_wrapper .modal_body {
            padding: 24px;

            iframe {
                max-width: 100%;
            }
        }
    }

    .modal_wrapper {
        width: 590px;
        border-radius: 4px;
        box-shadow: 0 0 47px 0 rgba(0, 0, 0, 0.23);
        border: solid 1px #dde5ed;
        background-color: $white;
        margin: 0 auto;

        .header {
            display: flex;
            align-items: center;
            font-family: "Urbanist", sans-serif !important;
            font-weight: 700;
            color: $black;
            font-size: 20px;
            padding: 13px 30px;
            border-bottom: 1px solid #dde5ed;

            h3 {
                font-family: "Urbanist", sans-serif !important;
                font-weight: 700;
                color: #1d252d;
                font-size: 20px;
            }

            .close-icon {
                margin-left: auto;
                cursor: pointer;
            }
        }

        .modal_body {
            padding: 50px 32px 24px 32px;
        }

        .footer {
            padding: 12px 32px 12px 32px;
            display: flex;
            box-shadow: 0 -6px 7px rgba(139, 166, 193, 0.07);
        }
    }

    &.delete_modal {
        .modal_wrapper {
            max-width: 470px;

            p {
                margin-bottom: 20px;
            }
        }
    }
}

.training_section {
    padding: 26px 32px;

    .training-header {
        padding: 18px 24px 19px 24px;
        background: #f4f7fc;
        display: flex;
        justify-content: space-between;

        h3 {
            font-weight: 800;
            font-size: 20px;
            text-align: left;
            color: $black;
            font-family: "Urbanist", sans-serif !important;
        }

        .action-icons {
            img {
                cursor: pointer;
                margin-right: 20px;

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }

    .training-content {
        padding: 32px 0px 35px;
    }
}

.mat-input-wrapper {
    position: relative;
    margin-bottom: 30px;

    .mat-input {
        width: 100%;
        height: 52px;
        display: flex;
        border-radius: 8px;
        padding-left: 20px;
        border: solid 1.1px #dde5ed;
        background-color: transparent;

        &:focus, &:not(:placeholder-shown) {
            + .mat-label {
                top: 0;
                background: #fff;
            }
        }
    }

    .mat-label {
        color: #000000;
        font-family: "Urbanist", sans-serif !important;
        font-size: 14px;
        line-height: normal;
        font-weight: 400;
        top: 50%;
        transform: translate(0, -50%);
        position: absolute;
        left: 17px;
        display: flex;
        width: auto;
        padding-left: 3.5px;
        padding-right: 3.5px;
        background: transparent;
        transition: all 0.16s linear;
        z-index: 0;
    }
}

.training-videos {
    display: flex;
    margin: 0 -16px;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.video-card {
    margin: 0 16px;
    cursor: pointer;
    margin-bottom: 25px;
    max-width: calc(33.3% - 32px);

    .video-image {
        width: 383px;
        max-width: 100%;
        border-radius: 20px;
        overflow: hidden;
        max-height: 215px;
        position: relative;

        img.video-thumb {
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 215px;
        }

        .play-icon {
            position: absolute;
            top: calc(50% - 28px);
            left: calc(50% - 28px);
            width: 56px;
            height: 56px;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    h3 {
        font-size: 16px;
        font-weight: 700;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: left;
        color: #1d252d;
        margin-top: 16px;
        font-family: "Urbanist", sans-serif !important;
    }

    .action-icons {
        position: absolute;
        right: 12px;
        top: 12px;

        img {
            margin-right: 13px;
            cursor: pointer;

            &:last-of-type {
                margin-right: 0;
            }
        }
    }
}

.addVideoBtn {
    width: calc(33.3% - 32px);
    height: 215px;
    flex-grow: 0;
    margin: 0 16px;
    cursor: pointer;
    border-radius: 16px;
    border: dashed 1px #d9d9d9;
    background-color: #f6faff;
    display: flex;
    justify-content: center;
    align-items: center;

    .play-icon {
        width: 22px;
        height: 22px;
        margin-right: 10px;
    }
}

.success_msgs {
    color: #000000;
    font-size: 12px;
}

@media screen and (min-width:0) and (max-width:767.5px) {
    .admin_user_page.new .contentarea .content_sec h2 {
        font-size: 20px;
        padding-top: 0px;
    }

    .admin_user_page.new .contentarea .content_sec .back-button {
        display: block;
        padding: 10px 0 0 15px;
    }

    .admin_user_page.new .contentarea .content_sec .monthly_comm .tt_card {
        margin: 0 10px 10px 0;
    }

    .training-videos {
        margin: 0;
        flex-wrap: wrap;
    }

    .video-card {
        max-width: 100%;
        margin: auto;
        margin-bottom: 20px;
    }

    .contentarea .content_sec .tabular_box.training-module-box {
        margin-top: 20px;
    }

    .addVideoBtn {
        margin: auto;
    }

    .training_section .training-content {
        padding-bottom: 10px;
    }

    .no-module-section {
        min-width: auto;
        max-width: 100%;
    }
}


@media screen and (min-width:0) and (max-width:420px) {
    .trainingpage_header {
        flex-wrap: wrap;

        .btn {
            margin-left: 17px;
        }
    }

    .no-module-section h3 {
        font-size: 24px;
    }
}

.asign_edit_pop.edit_user_popup .add_url_pop > div {
    max-height: calc(100% - 10px);
    overflow-y: overlay;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background-color: #f4f7fc;
        border-radius: 3px;
    }

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background-color: #f4f7fc;
        border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 3px;
    }
}

.contentarea .content_sec .resetgroup_box.dist_popup .add_url_pop > div {
    min-width: 840px;
    border-radius: 24px;
}

.contentarea .content_sec .resetgroup_box.dist_popup .add_url_pop > div .header {
    border-bottom: 0px;
    padding-bottom: 0px;
    padding-top: 30px;
}

.contentarea .content_sec .dist_popup .tabular_box {
    margin-top: 0px;
    box-shadow: none;
}

.contentarea .content_sec .dist_popup .tabular_box .table_header.tab {
    margin-top: 20px;
    background: none;
    padding: 0px;
    justify-content: flex-start;
    border-bottom: 1px solid #dde5ed;
    padding-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    max-width: 100%;
    padding-left: 32px;
}

.contentarea .content_sec .dist_popup .tabular_box .table_header.tab span {
    cursor: pointer;
}

.contentarea .content_sec .dist_popup .tabular_box .table_header > div {
    color: #1d252d;
    font-weight: 500;
}

.contentarea .content_sec .dist_popup .tabular_box .table_header.user_page.tab div {
    max-width: none !important;
    width: auto !important;
    padding-right: 36px;
}

.contentarea .content_sec .dist_popup .tabular_box .table_body .table_row:nth-child(even) > div {
    background: none;
}

.contentarea .content_sec .dist_popup .tabular_box .table_body .table_row > div {
    border-bottom: 1px solid #e2e2e2;
}

.contentarea .content_sec .dist_popup .tabular_box .table_body .table_row > div > div {
    font-size: 14px;
}

.contentarea .content_sec .dist_popup .tabular_box .table_body {
    max-height: 491px;
    overflow: auto;
}

.contentarea .content_sec .dist_popup .tabular_box {
    min-height: 600px;
}

.contentarea .content_sec .dist_popup .close {
    position: absolute;
    top: 20px;
    right: 20px;
}

.contentarea .content_sec .dist_popup .close.loader::before {
    content: "";
    border: 4px solid #EE934A;
    border-radius: 50%;
    border-top: 4px solid #f4f7fc;
    width: 25px;
    height: 25px;
    animation: .5s linear infinite spin;
    position: absolute;
    left: -50px;
    top: -4px;
}

.contentarea .content_sec .dist_popup .tabular_box .table_header.tab span {
    border-bottom: 2px solid #fff;
    display: block;
    padding-bottom: 10px;
}

.contentarea .content_sec .dist_popup .tabular_box .table_header.tab span.active {
    border-bottom: 2px solid #EE934A;
    color: #EE934A;
}

.contentarea .content_sec .dist_popup .tabular_box .table_body .table_row.user_page.user_data > div > div:nth-child(3) {
    font-weight: 500;
}

.contentarea .content_sec .dist_popup .tabular_box .table_body .table_row.user_page.user_data > div > div:nth-child(4) {
    font-weight: 500;
}

.contentarea .content_sec .dist_popup .tabular_box .table_body .table_row.user_page.user_data > div > div:nth-child(5) {
    font-weight: 500;
}

.contentarea .content_sec .dist_popup .no_record_found {
    min-height: 330px;
    padding: 20px 0 0 32px;
    font-size: 14px;
}

.Dashboard_Area.admin_user_page.new .contentarea .content_sec .tabular_box .table_body .table_row > div {
    cursor: pointer;
}

.Dashboard_Area.admin_user_page.new .contentarea .content_sec .dist_popup .tabular_box .table_body .table_row > div {
    cursor: default;
}

.contentarea .content_sec .asign_edit_pop.edit_user_popup .add_url_pop .add_url_pop.delete_gnr_popup > div {
    min-height: auto;

    .footer {
        margin-bottom: 0;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

.Dashboard_Area.admin_user_page.new .contentarea .content_sec .header_bard_button {
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.new .contentarea .content_sec .header_bard_button {

    .tt_card {
        background: #fff;
        position: relative;
    }

    .tt_card.loader::before {
        content: "";
        border: 4px solid #EE934A;
        border-radius: 50%;
        border-top: 4px solid #f4f7fc;
        width: 25px;
        height: 25px;
        animation: .5s linear infinite spin;
        position: absolute;
        left: -320px;
        top: 6px;
    }

    .down-arrow {
        display: inline-block;
        content: "";
        width: 8px;
        height: 8px;
        border-right: 1.5px solid black;
        border-top: 1.5px solid black;
        transform: rotate(135deg);
        position: absolute;
        top: 16px;
        right: 14px;
    }

    select {
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background-color: transparent;
        border: solid 1px #dde5ed;
        border-radius: 4px;
        padding: 8px 14px 8px 11px;
        color: #1d252d;
        font-size: 12px;
        height: 48px;
    }
}

.new .contentarea .content_sec .tabular_box .table_body, .contentarea .content_sec .dist_popup .tabular_box {
    min-height: 330px;
}

.contentarea .content_sec .dist_popup .tabular_box .table_body {
    max-height: 330px;
}

.new .contentarea .content_sec .header_bard_button select {
    position: relative;
    z-index: 99;
}

.order_video {
    .modal_wrapper {
        .modal_body {
            padding: 15px 32px 24px 32px;
            margin-bottom: 20px;
            max-height: 550px;
            overflow: auto;
        }

        .success-section {
            padding: 0 0 0 32px;

            div {
                padding-bottom: 15px;
            }
        }
    }

    ul {
        list-style: none;

        li {
            padding: 10px 0 10px 0px;
            font-size: 16px;
            line-height: 28px;
            display: flex;
            border-bottom: 1px solid #e2e2e2;

            .arrow-section {
                display: flex;
                margin-left: auto;

                .up-arrow {
                    border: 2px solid #000000;
                    width: 30px;
                    height: 30px;
                    border-radius: 100%;
                    margin-right: 12px;
                    position: relative;
                    cursor: pointer;
                    margin-left: 12px;

                    &:after {
                        content: "";
                        width: 10px;
                        height: 10px;
                        border-width: 0.2vmin 0.2vmin 0 0;
                        border-style: solid;
                        border-color: #000000;
                        transition: .2s ease;
                        display: block;
                        transform: rotate(315deg);
                        position: absolute;
                        top: 10px;
                        left: 8px;
                    }
                }

                .down-arrow {
                    border: 2px solid #000000;
                    width: 30px;
                    height: 30px;
                    border-radius: 30px;
                    position: relative;
                    cursor: pointer;

                    &:after {
                        content: "";
                        width: 10px;
                        height: 10px;
                        border-width: 0.2vmin 0.2vmin 0 0;
                        border-style: solid;
                        border-color: #000000;
                        transition: .2s ease;
                        display: block;
                        transform: rotate(135deg);
                        position: absolute;
                        top: 3px;
                        right: 7px;
                    }
                }
            }
        }

        &:after {
            content: "";
            width: 0px;
            height: 0px;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 8px solid #fff;
            position: absolute;
            top: -7px;
            right: 20px;
            transform: rotate(180deg);
        }
    }
}

.orderlist-section {
    display: flex;
    margin: 0 25px 0 auto;
    position: relative;
}

.training_modal.order_video .modal_wrapper {
    width: 720px;
}

.btn.order-list img {
    margin-left: 7px;
}

.training_section .training-header .action-icons > span {
    position: relative;
}

.training_section .training-header .action-icons > span.gap {
    margin-right: 20px;
}

.training_section .training-header .action-icons > span:hover .tooltip {
    display: inline-block !important;
}

.training_section .training-header .tooltip {
    margin-right: 0px;
    position: absolute;
    top: -46px;
    background: #fff;
    border: 1px solid #dde5ed;
    box-shadow: 0 2px 14px rgba(139,166,193,.14901960784313725);
    min-width: -webkit-max-content;
    min-width: max-content;
    font-style: normal;
    padding: 5px 10px;
    border-radius: 4px;
    color: #000000;
    font-family: "Urbanist", sans-serif !important;
    font-size: 12px;
    font-weight: 400;
    left: 50%;
    transform: translate(-50%, 0);
    display: none !important;
    z-index: 9;
}

.training_section .training-header .action-icons > span:hover .tooltip::before {
    content: "";
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #dde5ed;
    position: absolute;
    bottom: -6px;
    left: 50%;
    transform: rotate(180deg);
    position: absolute;
    display: flex !important;
    margin-left: -5px;
}

.training_section .training-header .action-icons > span:hover .tooltip::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #fff;
    position: absolute;
    z-index: 999;
    bottom: -5px;
    left: 50%;
    transform: rotate(180deg);
    position: absolute;
    display: flex !important;
    margin-left: -5px;
}

.training_section .training-header .action-icons > span.gap .order-icon, .orderlist-section .btn img {
    width: 22px;
}

.transaction .daterangepicker.ltr.show-calendar.opensright, .analyticPage .daterangepicker.ltr.show-calendar.opensright {
    right: 101px !important;
    z-index: 9999999;
}

.transaction .daterangepicker.opensright:before, .analyticPage .daterangepicker.opensright:before {
    right: 9px !important;
    left: auto !important;
}

.transaction .daterangepicker.opensright:after, .analyticPage .daterangepicker.opensright:after {
    right: 10px !important;
    left: auto !important;
}

.dashboard-menu {
    width: 100%;
    position: relative;
    z-index: 2;
    float: left;

    .react-bootstrap-daterangepicker-container {
        float: right;
        border-radius: 33.5px !important;

        .second-one {
            .form-control {
                border-radius: 0 33.5px 33.5px 0 !important;
                padding-left: 40px;
            }
        }

        .second-one::before {
            font-size: 13px;
        }

        .first-one {
            .form-control {
                border-right: 1px solid #dde5ed;
            }
        }

        .first-one::before {
            font-size: 13px;
        }

        .form-control {
            width: 100%;
            border: 0;
            height: 44px;
            margin-bottom: 0;
            padding-left: 55px;
            font-size: 13px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #000000 !important;
            cursor: pointer;
            border-radius: 33.5px 0 0 33.5px !important;
        }
    }

    .searchSection {
        position: relative;
        float: left;

        &.active {
            .SearchIcon {
                left: calc(100% - 35px);
            }

            .form-control {
                padding-left: 14px;
                font-size: 17px;
            }
        }

        .SearchIcon {
            position: absolute;
            top: 15px;
            left: 15px;
            transition: all 1s;
            width: 20px;
            height: auto;
        }

        .form-control {
            height: 48px;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            width: 440px;
            float: left;
            margin-left: 0px;
            padding-left: 44px;
            border: 1px solid #dde5ed;
            background: #fff;
            padding: 14px 24px 14px 44px;
            border-radius: 33.5px;
            color: #000000;
            font-weight: 600;
            font-size: 12px;
        }

        .form-control::placeholder {
            font-size: 12px;
        }
    }

    .radio-button-section {
        width: 400px;
        float: left;
        padding-top: 1px;
        position: relative;
        z-index: 9;
        margin-left: 16px;

        form > div {
            float: left;
            width: 25%;
        }

        .radio-design.custom-tooltip {
            position: relative;
            float: left;
            width: 100%;
        }

        .form-control {
            width: 15px;
            height: 15px;
            float: left;
            margin-bottom: 0;
            border: 1px solid #dde5ed;
            background: #fff;
        }

        label {
            float: left;
            height: 44px;
            line-height: 46px;
            text-align: center;
            background: #fff;
            color: #000000;
            font-size: 13px;
            font-weight: 600;
            border: 1px solid #dde5ed;
            padding: 0px;
            width: 100%;
            cursor: pointer;
            border-right: 0px;
        }

        .radio-design {
            input {
                display: none;
            }

            input:checked ~ .checkmark {
                background: #000000;
                color: #fff;
            }
        }

        #repeat0 {
            label {
                border-radius: 33.5px 0 0 33.5px;
            }
        }

        #repeat3 {
            label {
                border-radius: 0 33.5px 33.5px 0;
            }
        }
    }
}
/* widget start */
.dashboard-content-parent {
    position: static;
    z-index: 9;
    width: 100%;

    .newBoard {
        position: relative;
        width: 100%;

        .parent {
            width: 100%;
        }

        .contentContainer {
            margin: 0px;
            width: 100%;
        }

        .row {
            display: -ms-flexbox;
            display: flex;
            -ms-flex-direction: row;
            flex-direction: row;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            width: 100%;
        }

        .double-column {
            -ms-flex-direction: column;
            flex-direction: column;
            -ms-flex-preferred-size: 100%;
            width: 100%;
        }

        #dashboard-container {
            left: auto;
            top: auto;
            position: relative;
            width: auto;
            height: 100%;
        }

        .widget.barlist {
            min-height: 390px;
        }

        #dashboard-container .widget.full-layout {
            width: 100%;
            margin-right: 0px;
        }

        .barlist .custom-scroll, .list .custom-scroll {
            height: 385px;
        }

        .widget {
            margin: 20px 0 0;
            padding: 0;
            position: relative;
            border-radius: 6px;
            min-width: 200px;
            box-shadow: 0 1px 7px 0 rgba(139,166,193,.07);
            background-color: hsla(0,0%,47%,0);
            border: none;

            .widget-head {
                overflow: visible;
                padding: 20px 10px 15px 28px;
                height: auto;
                background-color: #fff;
                border-top-right-radius: 6px;
                border-top-left-radius: 6px;
                display: none;
            }

            .widget-content {
                text-align: left;
                border-radius: 6px;
                min-width: 160px;
                background: #fff;
                padding: 0 20px;

                .bar, .bar .green-bar {
                    float: left;
                    height: 4px;
                }

                .bar {
                    width: 100%;
                    background: #fff;
                    margin-bottom: 7px;
                }
            }
        }
    }
}

.contentContainer > div {
    width: 100%;
}

.column, .double-column {
    display: -ms-flexbox;
    display: flex;
}

#dashboard-container .widget .widget-head, #dashboard-container .widget .widget-head h3 {
    overflow: visible;
}

#dashboard-container .widget .widget-head {
    padding: 20px 10px 15px 28px;
    height: auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: column;
    flex-flow: column;
}

#dashboard-container .widget .flip-section .widget-head, #dashboard-container .widget.list .widget-head {
    padding: 20px 10px 0 8px;
}

#dashboard-container .widget .widget-head h3 span.transition-title {
    position: relative;
}

#dashboard-container .widget .widget-head h3 {
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: calc(100% - 44px);
    min-width: 90px;
    padding: 6px;
    float: left;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: normal;
    font-weight: 500;
    font-size: 19px;
}

.widget-head h3 {
    white-space: nowrap;
}

.flip-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    -webkit-transition: -webkit-transform .56s;
    -o-transition: -o-transform .56s;
    transition: -webkit-transform .56s;
    -o-transition: transform .56s;
    transition: transform .56s;
    transition: transform .56s, -webkit-transform .56s;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform-origin: 50% 50%;
    max-width: calc(100% - 43px);
}

.flip-bg .back {
    -webkit-transform: rotateY( 180deg );
    transform: rotateY( 180deg );
}

.flip-bg.multibar > div {
    height: calc(100% - 115px) !important;
}

.flip-bg > div {
    display: block;
    height: 100%;
    width: 100%;
    line-height: 260px;
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 140px;
    position: absolute;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    height: calc(100% - 110px);
    background: #fff;
}

.flip-bg.flipped {
    -webkit-transform: rotateY( 180deg );
    transform: rotateY( 180deg );
}

.transaction .flip_icon, .analyticPage .flip_icon {
    position: absolute;
    top: 23px;
    right: 34px;
}

_:-ms-fullscreen, :root .flip-bg.flipped .front {
    display: none;
}

_:-ms-fullscreen, :root .flip-bg.flipped .back {
    -webkit-transform: rotateY( 0deg );
    transform: rotateY( 0deg )
}

_:-ms-fullscreen, :root .flip-bg.flipped {
    -webkit-transform: rotateY( 0deg );
    transform: rotateY( 0deg );
}

_:-ms-fullscreen, :root .flip-bg.flipped .back {
    animation: fadeIn ease .5s;
    -webkit-animation: fadeIn ease .5s;
    -moz-animation: fadeIn ease .5s;
    -o-animation: fadeIn ease .5s;
    -ms-animation: fadeIn ease .5s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

_:-ms-fullscreen, :root .flip-bg .front {
    animation: fadeOut ease .5s;
    -webkit-animation: fadeOut ease .5s;
    -moz-animation: fadeOut ease .5s;
    -o-animation: fadeOut ease .5s;
    -ms-animation: fadeOut ease .5s;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-moz-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-o-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-ms-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.customScroll .flip-section .widget-iframe {
    position: absolute;
    height: calc(100% - 90px) !important;
    left: 34px;
}

#dashboard-container #Column_PieChartsdmjkjio13.widget {
    margin-left: 1%;
}

.flip-bg .second {
    margin-left: 15px;
    width: calc(100% - 16px);
}

.flip-bg .front {
    z-index: 0;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.flip-bg .flipped .front {
    z-index: 9;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.newFilterIcon {
    margin: 11px 0 0 15px;
    float: left;
    cursor: pointer;
}

.transaction .flip-icons, .analyticPage .flip-icons {
    position: absolute;
    top: 12px;
    right: 52px;
}

.transaction .flip-icons a, .analyticPage .flip-icons a {
    float: left;
}

.transaction .flip_icon, .analyticPage .flip_icon {
    width: 41px;
    height: 17px;
    background-color: #fff;
    border-radius: 20px;
    text-indent: -9999px;
    position: absolute;
    top: 2px;
    right: auto;
    left: 31px;
}

.transaction .flip_icon::before, .analyticPage .flip_icon::before {
    position: absolute;
    top: -3px;
    left: -4px;
    content: '';
    width: 19px;
    height: 19px;
    border-radius: 20px;
    -webkit-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.24), 0 0 1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.24), 0 0 1px 0 rgba(0, 0, 0, 0.12);
    border-style: solid;
    border-width: 0.5px;
    border-image-source: linear-gradient(to bottom, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.06) 20%, rgba(255, 255, 255, 0));
    border-image-slice: 1;
    background-color: #000000;
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
}

.transaction .flip_icon.active::before, .analyticPage .flip_icon.active::before {
    position: absolute;
    top: -3px;
    left: calc(100% - 20px);
    content: '';
    width: 20px;
    height: 20px;
    border-radius: 20px;
    -webkit-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.24), 0 0 1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.24), 0 0 1px 0 rgba(0, 0, 0, 0.12);
    border-style: solid;
    border-width: 0.5px;
    border-image-source: linear-gradient(to bottom, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.06) 20%, rgba(255, 255, 255, 0));
    border-image-slice: 1;
    background-color: #000000;
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
}

.bar_chart_icon {
    margin-right: 58px;
    float: left;
}

.bar_chart_icon.inactive, .listing-icon.activeicon {
    display: none;
}

.flip-icons.active .bar_chart_icon.inactive, .flip-icons.active .listing-icon.activeicon {
    display: block;
}

.flip-icons.active .bar_chart_icon, .flip-icons.active .listing-icon {
    display: none;
}

.listing-icon, .change-design .info-icon {
    float: left;
}

.barlist > .widget-head, .list > .widget-head {
    display: none !important;
}

.hover-tooltip {
    display: none;
    position: absolute;
    top: -70px;
    right: -37px;
    border: 2px solid #dde5ed;
    padding: 12px;
    text-align: center;
    font-size: 15px;
    color: #000000;
    border-radius: 6px;
    background: #fff;
}

.bar .onlyList {
    position: absolute;
    top: 26px;
    right: 24px;
    z-index: 9;
}

.hover-tooltip.down-report {
    top: -54px;
    right: -4px;
    width: 120px;
}

.bar .export_icon {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.bar .flip-icons {
    position: absolute;
    top: 24px;
    right: 52px;
}

.flip-icons .custom-tooltip:first-child {
    width: 24px;
    margin-right: 58px;
}

widget a:hover {
    text-decoration: none;
}

.flip-icons a {
    float: left;
}

.mobile_bar_icon, .mobile_list_icon {
    display: none;
}

.bar_chart_icon.inactive, .listing-icon.activeicon {
    display: none;
}

.bar_chart_icon.inactive, .listing-icon.activeicon {
    display: none;
}

.flip-icons .custom-tooltip .hover-tooltip.bar {
    width: auto;
    height: auto;
}

.hover-tooltip.bar {
    top: -49px;
    right: 70px;
    z-index: 9;
}

.mobileBarLabel, .mobileExportLabel, .mobileinfoLabel, .mobileListIcon, .mobileListLabel {
    display: none;
}

.hover-tooltip.list {
    top: -49px;
    right: -16px;
    z-index: 9;
}

.widget-iframe {
    width: 100%;
    height: 180px;
    border: none;
}

.widget-iframe {
    background-color: #fff;
    height: 300px;
}

.customScroll .widget-iframe {
    position: absolute;
    height: calc(100% - 100px) !important;
    left: 34px;
    right: 34px;
    max-width: calc(100% - 57px);
}

.customScroll .second .widget-iframe {
    max-width: calc(100% - 17px);
    margin-left: -20px;
}

#dashboard-container .widget-content, #dashboard-container .widget-iframe {
    min-width: 160px;
}

.myColumnChart > div {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: column;
    flex-flow: column;
}

.tool-section {
    margin: 110px 0 0 313px;
    position: relative;
}

.tool-section.list-data {
    margin: 0;
}

.tool-section .tableheader {
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.tool-section .tableheader {
    text-align: left;
    font-size: 17px;
    line-height: normal;
    color: #EE934A;
    border-radius: 4px;
    padding-left: 0;
    margin-bottom: 0;
    min-height: 46px;
}

.tool-section .tableheader div {
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    padding-right: 15px;
}

.tool-section .mainList {
    overflow-y: auto;
    max-height: 228px;
    overflow-x: hidden;
}

.tool-section .tablebody {
    padding-left: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    text-align: left;
    border-bottom: 1px solid #e6e0e0;
}

.tool-section .tablebody > div {
    font-size: 14px;
    line-height: normal;
    color: #19342f;
    font-weight: 400;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    padding-right: 15px;
    padding-bottom: 9px;
    padding-top: 9px;
}

.tool-section .tablebody > div {
    word-break: break-word;
}

#dashboard-container .widget .widget-head h3 span {
    color: #EE934A;
    position: relative;
    font-weight: 600;
}

#dashboard-container .widget .widget-head h3 span.transition-title {
    color: #000000;
    position: relative;
    font-weight: 600;
    font-size: 19px;
    line-height: 22px;
}

#dashboard-container .widget .bar .widget-head span p {
    position: absolute;
    top: 25px;
    left: 0;
    padding-left: 0;
    color: #F1857F !important;
    font-size: 15px;
    font-weight: 400;
}

.tool-section .pagination, .tool-section .pagination2 {
    margin: 22px 0 !important;
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -ms-flex-align: center;
    align-items: center;
}

.tool-section ul {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.list-data .pagination {
    margin: 20px 0 0 !important;
}

.tool-section li {
    -ms-flex: 0 0 30%;
    flex: 0 0 30%;
    height: 104px;
    border-radius: 6px;
    -webkit-box-shadow: 0 1px 7px 0 rgba(139,166,193,.07);
    box-shadow: 0 1px 7px 0 rgba(139,166,193,.07);
    background-color: #fff;
    margin-right: 46px;
    margin-bottom: 32px;
    position: relative;
    font-family: "Urbanist", sans-serif;
    font-size: 22px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1d252d;
    cursor: pointer;
}

.tool-section ul.pagination li {
    display: inline;
    margin-right: 10px;
    margin-bottom: 0;
}

.tool-section ul.pagination li {
    font-size: 14px;
}

.tool-section ul.pagination li {
    float: left;
    height: auto;
}

.tool-section ul.pagination li:first-child {
    font-weight: 500;
    margin-right: 14px;
}

.tool-section ul.pagination li:last-child {
    font-weight: 500;
}

.tool-section .pagination a[aria-label="Go to first page"]:before {
    font-size: 14px;
    content: "First";
}

.tool-section .pagination a[aria-label="Go to first page"] {
    font-size: 0;
}

.tool-section .pagination a[aria-label="Go to last page"] {
    font-size: 0;
}

.tool-section .pagination a[aria-label="Go to last page"]:before {
    font-size: 14px;
    content: "Last";
}

.tool-section ul.pagination li:last-child {
    margin-right: 0px;
}
/* new pagination*/
.tool-section ul.pagination {
    display: block;
    float: right;
}

.tool-section ul.pagination li {
    display: inline;
    margin-right: 10px;
    margin-bottom: 0px;
}

.tool-section ul.pagination li.active a, .tool-section .pagination .disabled a[aria-label="Go to first page"], .tool-section .pagination .disabled a[aria-label="Go to last page"] {
    color: #000000 !important;
}

.tool-section ul.pagination li {
    font-size: 14px;
}

.tool-section ul.pagination li:nth-child(2) a {
    z-index: 99;
    width: 10px;
    height: 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    transform: rotate(225deg);
    text-indent: -9999px;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    margin-top: 3px;
}

.tool-section ul.pagination li:nth-child(2) a:after {
    content: "";
    width: 100%;
    height: 100%;
    border-width: 0.1vmin 0.1vmin 0 0;
    border-style: solid;
    border-color: #000000;
    -webkit-transition: .2s ease;
    -o-transition: .2s ease;
    transition: .2s ease;
    display: block;
    -webkit-transform-origin: 100% 0;
    -ms-transform-origin: 100% 0;
    transform-origin: 100% 0;
    text-indent: -9999px;
}

.tool-section ul.pagination li:nth-last-child(2) a {
    z-index: 99;
    width: 10px;
    height: 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transform: rotate(400deg);
    -ms-transform: rotate(400deg);
    transform: rotate(400deg);
    text-indent: -9999px;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    text-indent: -9999px;
    margin-top: 3px;
}

.tool-section ul.pagination li:nth-last-child(2) a:after {
    content: "";
    width: 100%;
    height: 100%;
    border-width: 0.1vmin 0.1vmin 0 0;
    border-style: solid;
    border-color: #000000;
    -webkit-transition: .2s ease;
    -o-transition: .2s ease;
    transition: .2s ease;
    display: block;
    -webkit-transform-origin: 100% 0;
    -ms-transform-origin: 100% 0;
    transform-origin: 100% 0;
}

.myBarChart canvas, .myChart .chartjs-render-monitor {
    height: 300px !important;
    margin-top: 10px;
}

#dashboard-container .widget .bar .widget-head h3 {
    padding-bottom: 25px;
}

.dashboard-content-parent .newBoard .widget .widget-content .flip-icons .custom-tooltip .hover-tooltip.bar {
    width: auto;
    height: auto;
}

.bar .custom-tooltip:hover .hover-tooltip {
    display: block;
}

.hover-tooltip:after {
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #dde5ed;
    position: absolute;
    bottom: -7px;
    right: calc(50% - 5px);
    -webkit-transition: all .9s;
    -o-transition: all .9s;
    transition: all .9s;
}

.hover-tooltip.down-report {
    top: -54px;
    right: -4px;
    width: 140px;
}

.hover-tooltip.down-report:after {
    right: 8px;
}

body.transaction {
    width: 100%;
    overflow-x: hidden;
}

.tool-section .flex-table.header, .tool-section .pagination2 a, .tool-section .pagination a, .selectbox, .tab-section span, .tab .tab-link, .WhCommissionPopup label {
    color: #827c78 !important
}

#dashboard-container .widget .widget-foot {
    height: 25px;
    overflow: hidden;
    padding: 4px 0 3px;
    font-size: 12px;
    line-height: 100%;
    text-align: right;
    font-weight: 700;
    background: transparent;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    padding-right: 20px;
}

#dashboard-container .widget .widget-foot {
    color: #000000;
    background-color: #fff;
    height: 8px;
}

.contentarea .content_sec {
    overflow: hidden;
}
/* widget end */
/* pie chart start */
.PieChart.first {
    max-width: 584px !important;
    margin: auto;
    width: 100% !important;
    height: 292px !important;
    margin-top: 15px;
}

.PieChart {
    margin: auto;
    width: 290px !important;
    height: 290px !important;
}

.PieChart canvas {
    margin: auto;
    width: 100% !important;
    height: 100% !important;
    border-radius: 100%;
}

.bar.multiline .myChart {
    width: 100% !important;
    height: 260px !important;
    margin-top: 60px;
}

.bar.linelist .myChart {
    width: 100% !important;
    height: 300px !important;
    margin-top: 20px;
}

.bar.multiline .myChart canvas, .bar.linelist .myChart canvas {
    width: 100% !important;
    height: 100% !important;
}

.transitions {
    text-align: center;
}

.flip-section .transitions {
    position: absolute;
    left: 0;
    width: 100%;
    font-size: medium;
    line-height: normal;
    height: auto;
    top: 5px;
}

.same-transitions {
    display: inline-block;
    padding: 0 6px;
}

.flip-bg .transitions div {
    position: relative;
    line-height: normal;
    font-size: medium;
    height: auto;
}

.flip-bg .transitions div.same-transitions {
    display: inline-block;
    width: auto;
}

transitions .left {
    width: 100%;
    text-align: center;
    margin-bottom: 6px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
}

.flip-bg .transitions div {
    position: relative;
    line-height: normal;
    font-size: medium;
    height: auto;
}

.transitions .right {
    width: 100%;
    float: left;
    text-align: center;
}

.flip-bg .transitions div {
    position: relative;
    line-height: normal;
    font-size: medium;
    height: auto;
}

.transitions .rectangle {
    width: 15px;
    height: 15px;
    display: inline-block;
}

.flip-section .transitions span {
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #000000;
    margin: 0 5px 0 0;
}

.flip-section .transitions .bold {
    font-size: 19px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 17px;
    letter-spacing: normal;
    color: #1d252d;
}

.flip-section .transitions .left span {
    color: #000000 !important;
}

.flip-section .transitions .right span {
    color: #19342F !important;
}

.claimSection .paid {
    width: 25%;
    height: 153px;
    background: #fff;
    border-radius: 6px;
    margin-right: 1%;
    padding: 20px 0 0 30px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
}

.claimSection .paid .small {
    color: #000000;
    display: block;
    font-size: 19px;
    line-height: 22px;
    font-weight: bold;
    margin-bottom: 4px;
}

.claimSection .paid span {
    font-size: 41px;
    color: #ffba10;
    line-height: 42px;
}

.claimSection .paid span {
    color: #827c78;
}

.claimSection, .manage-content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
}

.claimSection {
    -ms-flex-pack: left;
    justify-content: left;
    width: 100%;
}

#dashboard-container #RxSensClaim1.widget .widget-content {
    background-color: #f4f7fc !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    padding: 0px;
}

#RxSensClaim0, #RxSensClaim1, #RxSensClaim12, #RxSensClaimNew2 {
    min-height: inherit !important;
    box-shadow: none;
}

#RxSensClaim0 .custom-scroll, #RxSensClaim1 .custom-scroll, #RxSensClaim12 .custom-scroll, #RxSensClaimNew2 .custom-scroll {
    height: auto;
}

#RxSensClaim0 .widget-foot, #RxSensClaim1 .widget-foot, #RxSensClaim1 .widget-head, #RxSensClaim12 .widget-foot, #RxSensClaim12 .widget-head, #RxSensClaimNew2 .widget-foot, #RxSensClaimNew2 .widget-head {
    display: none !important;
}

.claimSection .paid.last {
    margin-right: 0;
}

.loader-section {
    background: #fff;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 15px;
    right: 170px;
    z-index: 9;
}

.list-only .loader-section {
    top: 18px;
    right: 55px;
}

.theme-loader:before {
    content: "";
    border: 4px solid #000000;
    border-radius: 50%;
    border-top: 4px solid #fff;
    width: 25px;
    height: 25px;
    -webkit-animation: .5s linear infinite spin;
    animation: .5s linear infinite spin;
    position: absolute;
    right: 0px;
    top: 0px;
}

.claimSection .loader-section {
    background: #fff;
    width: 30px;
    height: calc(100% - 90px);
    position: absolute;
    top: 15px;
    right: 8px;
    z-index: 9;
}

.theme-loader {
    pointer-events: none;
    position: relative;
    display: flex;
    align-items: center;
}

.claimSection .theme-loader:before {
    content: "";
    border: 4px solid #000000;
    border-radius: 50%;
    border-top: 4px solid #fff;
    width: 25px;
    height: 25px;
    -webkit-animation: .5s linear infinite spin;
    animation: .5s linear infinite spin;
    position: absolute;
    right: 10px;
}

.tool-tip-section:hover .claimSection .paid span.toolTipContent {
    display: block;
}

.claimSection .paid span.toolTipContent {
    display: none;
    position: absolute;
    top: -55px;
    right: -50px;
    border: 2px solid #dde5ed;
    padding: 0 5px;
    text-align: center;
    font-size: 15px;
    color: #000000;
    border-radius: 6px;
    background: #fff;
    width: 122px;
    z-index: 999;
}

.claimSection .paid span.toolTipContent::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #dde5ed;
    position: absolute;
    bottom: -7px;
    right: calc(50% - 5px);
    -webkit-transition: all .9s;
    -o-transition: all .9s;
    transition: all .9s;
}

.tool-tip-section {
    position: absolute;
    top: 16px;
    right: 24px;
}

.tool-tip-section:hover span.toolTipContent {
    display: block;
}

.multibar .myBarChart canvas {
    height: 280px !important;
    margin-top: 35px;
}

#dashboard-container .widget .bar.oList .widget-head {
    padding-left: 8px;
    padding-bottom: 5px;
}

#dashboard-container .widget {
    width: 49.5%;
    float: left;
    min-height: 400px;
    margin-left: 0;
    margin-right: 0;
    margin-top: 1%;
}

#dashboard-container .layout-left-margin {
    margin-left: 1%;
}

#dashboard-container .layout-not-margin.widget:nth-child(odd) {
    margin-left: 0;
}

.tool-section.list .tableheader {
    line-height: normal;
}

.tool-section.list .mainList {
    max-height: 215px;
}

.analytics-menu {
    flex-direction: column;
    align-items: flex-start !important;
    margin-top: 10px;
    margin-bottom: 10px;
    min-height: auto !important;
    max-height: auto !important;
    position: relative;

    .menu-text.down-arrow {
        position: relative;
    }

    .menu-text.down-arrow:after {
        content: "";
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        position: absolute;
        top: 9px;
        right: 0px;
        -webkit-transition: all .9s;
        -o-transition: all .9s;
        transition: all .9s;
        color: #827c78 !important;
        border-top: 5px solid #827c78;
    }

    ul {
        padding: 14px 0 0 48px;
        margin: 0;
        width: 100%;
        overflow: hidden;
        display: none;
    }

    li {
        color: #55738c;
        width: 100%;
        float: left;
        display: -ms-flexbox;
        display: flex !important;
        max-width: 100%;
        white-space: nowrap;
        margin-bottom: 10px;
        min-height: auto !important;
    }

    li a {
        color: #000000 !important;
        text-decoration: none;
        font-size: 14px !important;
        font-weight: normal !important;
    }

    li.active a {
        color: #EE934A !important;
    }

    li a div {
        display: none !important;
    }

    .menu-text.down-arrow {
        background: url(./images/anylitics.svg) no-repeat;
        background-size: 24px 23px;
        height: 23px;
        padding-left: 50px;
        padding-right: 18px;
        color: #000000;
    }
}

.dropdown-active {
    .analytics-menu {
        min-height: 80px !important;
    }

    .menu-text.down-arrow:after {
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
    }
}

.beta-link {
    position: relative;
    padding-right: 14px;
}

.beta {
    position: absolute;
    top: 3px;
    left: 3px;
    color: #fff;
    font-size: 13px;
    text-transform: uppercase;
    background: #EE934A;
    padding: 6px 10px;
    border-radius: 20px;
    font-weight: bold;
}

#dashboard-container .widget.barlist {
    min-height: 390px;
}

.filtered_tag_selected span {
    border-radius: 33.5px;
    border: 1px solid rgba(139,166,193,.5);
    background-color: #f8fbfd;
    color: #000000;
    font-size: 12px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    position: relative;
    height: 30px;
    padding: 3px 22px 0 8px;
    display: inline-block;
    line-height: 24px;
    margin: 0 8px 4px 0;
}

.filtered_tag_selected .active {
    background-color: #EE934A;
    color: #fff;
}

.filtered_tag_selected span:last-child {
    margin-right: 0;
}

.filtered_tag_selected label {
    position: absolute;
    right: 17px;
    top: 10px;
    width: 10px;
    height: 10px;
    cursor: pointer;
    opacity: 1;
}

.filtered_tag_selected label:after, .filtered_tag_selected label:before {
    position: absolute;
    left: 15px;
    content: " ";
    height: 10px;
    width: 2px;
    background-color: #000000;
}

.filtered_tag_selected label:before {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.filtered_tag_selected .active label:after, .filtered_tag_selected .active label:before {
    background-color: #fff;
}

.filtered_tag_selected label:after {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.filtered_tag_selected .reset-btn {
    background: #000000;
    border: none;
    margin-right: 0;
    width: 64px;
    font-size: 12px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    border-radius: 33.5px;
    padding: 5px 14px;
    cursor: pointer;
    height: 30px;
}

.filtered_content {
    display: -ms-flexbox;
    display: flex;
}

.filtered_adjust {
    display: inline;
    max-width: calc(100% - 66px);
    overflow-x: auto;
    -webkit-overflow-x: auto;
    -moz-overflow-x: auto;
    margin-right: 15px;
}

.filtered_tag_selected {
    padding-top: 12px;
    padding-bottom: 1px;
}

.filterNewDesign:after, .react-bootstrap-daterangepicker-container .react-datepicker_input-container:after, .selectAdjust:after {
    color: #827c78 !important;
    border-top: 5px solid #827c78;
}
/* pie chart end */
#myInputautocomplete-list {
    width: 440px;
    height: 176px;
    -webkit-box-shadow: 0 8px 8px 0 rgba(0,0,0,.24), 0 0 8px 0 rgba(0,0,0,.12);
    box-shadow: 0 8px 8px 0 rgba(0,0,0,.24), 0 0 8px 0 rgba(0,0,0,.12);
    position: absolute;
    top: 46px;
    left: 0;
    padding: 7px 6px 3px 16px;
    overflow: auto;
    -webkit-transition: all .9s;
    -o-transition: all .9s;
    transition: all .9s;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1d252d;
    z-index: 999999;
}

#myInputautocomplete-list, .filterNewDesign, .filterNewDesign2 {
    border-radius: 4px;
    border: 1px solid #dde5ed;
    background-color: #fff;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

#myInputautocomplete-list > div, #myInputautocomplete-list span {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 28px;
    letter-spacing: normal;
    color: #000000;
    cursor: pointer;
}

#myInputautocomplete-list span {
    border-bottom: 1px solid #dde5ed;
    display: block;
    margin-bottom: 7px;
    padding-bottom: 7px;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1d252d;
    cursor: pointer;
}

#myInputautocomplete-list::-webkit-scrollbar {
    width: 10px;
}

#myInputautocomplete-list::-webkit-scrollbar-thumb {
    background: rgba(159,161,165,.8);
}

#myInputautocomplete-list::-webkit-scrollbar-thumb, #myInputautocomplete-list::-webkit-scrollbar-track {
    border-radius: 10px;
}

.mainList::-webkit-scrollbar-thumb, .mainList::-webkit-scrollbar-track {
    border-radius: 10px;
}

.mainList::-webkit-scrollbar-thumb {
    background: rgba(15,21,31,.8);
}

.data-not-found {
    padding: 10px 0 0 0px;
    display: block;
    font-size: 16px;
    font-weight: normal;
    line-height: 250px;
    text-align: center;
}

.mainList::-webkit-scrollbar, .mobile-scrolling-all::-webkit-scrollbar {
    background-color: #fff;
    width: 6px !important;
    height: 4px !important;
}

.widget .tableheader {
    font-weight: 600;
}

.mobile-scrolling-all.tool-section.list .tablebody:hover, .mobile-scrolling-all.tool-section.Pie-data .tablebody:hover, .clickable-list .tablebody:hover {
    cursor: pointer;
}

.mobile-scrolling-all.tool-section.list .tablebody.last-list:hover, .mobile-scrolling-all.tool-section.Pie-data .tablebody.last-list:hover, .clickable-list .tablebody.last-list:hover {
    cursor: default;
}

.tool-section .tablebody > div {
    text-transform: lowercase;
}

.tool-section .tablebody > div[data-id="Group Number"] {
    text-transform: uppercase;
}

.tool-section .tablebody > div[data-id="Drug Name"] {
    text-transform: lowercase;
}

.tool-section .tablebody > div:first-line {
    text-transform: capitalize;
}

.dashboard-content-parent .newBoard .list .custom-scroll {
    height: 395px;
}

.filterNewDesign2 {
    border-radius: 4px;
    border: 1px solid #dde5ed;
    background-color: #fff;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 33.5px !important;
    height: 48px;
    float: left;
    margin: 0 0 0 16px;
    position: relative;
    cursor: pointer;
    z-index: 99999;
    font-size: 14px;
    color: #000000;
    padding: 0;
    width: auto;
    min-width: 116px;

    .filter-over2 {
        padding: 15px 35px 15px 18px;
    }

    .FilterIcon {
        height: auto;
        width: 25px;
    }

    img {
        margin: 12px 0 0 13px;
        cursor: pointer;
        position: relative;
        z-index: 999;
        padding-right: 44px;
    }

    .filterDropdown2 {
        width: 208px;
        border-radius: 4px;
        -webkit-box-shadow: 0 8px 8px 0 rgba(0,0,0,.24), 0 0 8px 0 rgba(0,0,0,.12);
        box-shadow: 0 8px 8px 0 rgba(0,0,0,.24), 0 0 8px 0 rgba(0,0,0,.12);
        border: 1px solid #dde5ed;
        background-color: #fff;
        float: left;
        position: absolute;
        top: 47px;
        left: 0;
        display: none;
        z-index: 9;
    }

    &:after {
        content: "";
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #000000;
        position: absolute;
        top: 21px;
        right: 15px;
        -webkit-transition: all .9s;
        -o-transition: all .9s;
        transition: all .9s;
        color: #827c78 !important;
        border-top: 5px solid #827c78;
    }

    .create-new-filter {
        color: #19342f !important;
    }

    .filterDropdown2 ul {
        padding: 7px 16px 0;
        max-height: 155px;
        overflow: auto;
        min-height: 50px;
        list-style: none;
    }

    .filterDropdown2 li {
        border-top: 1px solid #dde5ed;
        padding: 0px;
        position: relative;
        cursor: pointer;
    }

    .filterDropdown2 li:first-child {
        border: none;
        padding-top: 0;
    }

    li span {
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 16px;
        letter-spacing: normal;
        color: #000000;
    }

    .filterDropdown2 li a {
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: normal;
        font-size: 12px;
        padding: 7px 0;
        display: block;
        text-decoration: none;
    }

    .filterDropdown2 li:hover a {
        color: #000000;
    }

    &.active:after {
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
    }
}

.reportPage .admin_report_page .content_sec .content .transact .same-on {
    width: 50%;
}

.reportPage .admin_report_page .content_sec .content .new-column.gap-left.transact {
    padding-left: 0px;
}

.filtered_tag_selected {
    overflow: auto;
    overflow-y: hidden;
    white-space: nowrap;
}

.CreateNewFilter .filter-Content::-webkit-scrollbar, .filtered_adjust::-webkit-scrollbar, .filtered_tag_selected::-webkit-scrollbar, .selectFilter::-webkit-scrollbar {
    height: 6px;
}

.CreateNewFilter .filter-Content::-webkit-scrollbar-thumb, .filter-scroll::-webkit-scrollbar-thumb, .filtered_adjust::-webkit-scrollbar-thumb, .filtered_tag_selected::-webkit-scrollbar-thumb, .selectFilter::-webkit-scrollbar-thumb {
    background: #dde5ed;
}

.filtered_adjust::-webkit-scrollbar-thumb, .filtered_adjust::-webkit-scrollbar-track {
    border-radius: 10px;
}

.seclink-toggle .flip_icon {
    left: 0px;
}

.seclink-toggle a {
    margin-right: 15px;
}

.loading-text {
    min-height: 491px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: normal;
    color: #EE934A;
}

.discount_card_sec_signup .cards_area .card_page_content .card {
    width: 100%;
}

.contentarea .content_sec .tabular_box .table_body .table_row.user_page.user_data > div > div i {
    margin-right: 10px;
}

.filtertag-highlight {
    display: none;
}

.contentarea .content_sec .claim_header {
    margin-bottom: 0px;
}

.contentarea .content_sec .claim_subheading {
    display: inline-block;
    font-size: 15px;
    color: #F1857F;
    cursor: pointer;
    font-weight: 400;
    font-family: "Urbanist", sans-serif;
    margin-bottom: 20px;
}

.active_refs .tab {
    float: right;
    width: 185px;
    -webkit-box-shadow: 0 1px 7px 0 rgba(139,166,193,.07);
    box-shadow: 0 1px 7px 0 rgba(139,166,193,.07);
}

.active_refs .tab .tab-link {
    height: 40px;
    float: left;
    width: 50%;
    font-family: "Urbanist", sans-serif;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    line-height: 40px;
    background-color: #fff;
    color: #000000;
}

.active_refs .link1 {
    border: 1px solid #dde5ed;
}

.active_refs .link2 {
    border: 1px solid #dde5ed;
    border-radius: 0 4px 4px 0;
    border-left: 0;
}

.active_refs .nav_in a.active, .active_refs .tab .active {
    color: #fff !important;
    border: 1px solid #000000;
}

.active_refs .tab .link1, .active_refs .tabs .first {
    border-radius: 35px 0 0 35px;
}

.active_refs .tab .active, .active_refs .tabs a.active {
    background: #000000;
}

.active_refs .tab-section span, .active_refs .tab .tab-link {
    color: #000000 !important;
}

.active_refs .tab .link2, .active_refs .tabs .last {
    border-radius: 0 35px 35px 0;
}

.active_refs .nav_in a.active, .active_refs .tab .active {
    color: #fff !important;
}

.contentarea .content_sec .refferals_list.active_refs .refferal_list_box .heading {
    padding-bottom: 15px;
}

.contentarea .content_sec .refferals_list.active_refs h3 {
    padding-bottom: 5px;
}

.active_refs .rep-download {
    float: right;
    margin: 10px 0 0 15px;
    position: relative;
}

.active_refs .rep-download img {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.active_refs .tab-section .custom-tooltip:hover .hover-tooltip {
    display: block;
}

.active_refs .tab-section .hover-tooltip.down-report {
    top: -54px;
    right: -4px;
    width: 140px;
}

.active_refs .tab-section .hover-tooltip.down-report:after {
    right: 8px;
}

.active_refs .tab-section .hover-tooltip {
    color: #000000 !important;
}

.contentarea .content_sec .refferals_list.active_refs .refferal_list_box .refferal_table .content .flex-row div:first-child {
    padding-top: 0px;
}

.contentarea .content_sec .refferals_list.active_refs .refferal_list_box .refferal_table .content .flex-row div {
    padding-top: 22px;
}

.contentarea .content_sec .affiliate_refferal .table_footer {
    height: 56px;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0 -6px 7px #8ba6c112;
    border-radius: 0 0 8px 8px;
    align-items: center;
    justify-content: flex-end;
    padding-right: 35px;
    display: flex;
}

.contentarea .content_sec .affiliate_refferal .table_footer .pagination {
    display: flex;
    list-style: none;
}

.contentarea .content_sec .affiliate_refferal .table_footer .pagination li {
    cursor: pointer;
    min-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contentarea .content_sec .affiliate_refferal .table_footer .pagination li.disabled {
    cursor: default;
}

.contentarea .content_sec .affiliate_refferal .table_footer .pagination li.first {
    margin-right: 12px;
}

.contentarea .content_sec .affiliate_refferal .table_footer .pagination li.prev {
    width: 9px;
    height: 24px;
    background-size: 9px 16px !important;
    background: url(./images/prev_arrow.svg) no-repeat;
    margin-right: 10px;
    background-position: center;
}

.contentarea .content_sec .affiliate_refferal .table_footer .pagination li.next {
    width: 9px;
    height: 24px;
    background-size: 9px 16px !important;
    background: url(./images/prev_arrow.svg) no-repeat;
    margin-left: 6px;
    background-position: center;
    transform: rotate(180deg);
}

.contentarea .content_sec .affiliate_refferal .table_footer .pagination li.last {
    margin-left: 12px;
}

.contentarea .content_sec .affiliate_refferal .table_footer .pagination li.numbers {
    min-width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    margin-right: 5px;
}

.contentarea .content_sec .affiliate_refferal .table_footer .pagination li.numbers.active {
    border-radius: 50%;
    background: #000000;
}

.contentarea .content_sec .affiliate_refferal .table_footer .pagination li.numbers.active a {
    color: #fff;
}

.contentarea .content_sec .affiliate_refferal .table_footer .pagination li a {
    color: #000000;
    font-weight: 400;
    font-size: 14px;
    font-family: "Urbanist", sans-serif !important;
}

.contentarea .content_sec .refferals_list .refferal_list_box .refferal_table .content .reps-not-found {
    padding: 15px 0 0 24px;
    color: #EE934A !important;
}

.contentarea .content_sec .tabular_box .table_body.less {
    min-height: 100px;
}

.contentarea .content_sec .asign_edit_pop .add_url_pop > div .footer > div.error_submit_msg {
    width: 65%;
    justify-content: left;
}

.contentarea .content_sec .asign_edit_pop.edit_user_popup .add_url_pop > div .body .personal_box > div.user_table .header_row.scroll {
    padding-right: 16px;
}

.summary_section {
    display: flex;
    flex-flow: row wrap;
}

.summary_section .summary_repeat {
    display: flex;
    flex-flow: column;
    width: 100%;
    max-width: 452px;
    margin-right: 16px;
    background: #fff;
    border-radius: 16px;
    padding: 24px 0 0 0;
    margin-bottom: 35px;
    min-height: 175px;
}

.summary_section .summary_repeat.gap {
    margin-right: 160px;
}

.summary_section .summary_repeat.payment {
    background: #fff url(./images/payment-back.png) right bottom no-repeat;
    background-size: 22%;
}

.summary_section h3 {
    font-size: 20px;
    color: #1d252d;
    line-height: normal;
    @include Urbanist;
    font-weight: bold;
}

.summary_section span {
    font-size: 14px;
    color: #9c9d9f;
    display: block;
    @include Urbanist;
}

.summary_section .current {
    padding: 24px 0 0 0;
}

.summary_section .balance {
    font-size: 24px;
    color: #1d252d;
    font-weight: 800;
    padding-top: 8px;
    padding-bottom: 15px;
    @include Urbanist;
}

.summary_section ul {
    list-style: none;
    height: 150px;
    overflow: auto;
    padding-right: 20px;
    margin-right: 0px;
}

.summary_section ul::-webkit-scrollbar, .summary_repeat.faq .contentarea::-webkit-scrollbar, .training_modal.videoPopup.pro-modal .modal_wrapper .modal_body::-webkit-scrollbar {
    background: #fff;
    width: 5px !important;
}

.summary_section ul::-webkit-scrollbar-thumb, .summary_repeat.faq .contentarea::-webkit-scrollbar-thumb, .training_modal.videoPopup.pro-modal .modal_wrapper .modal_body::-webkit-scrollbar-thumb {
    background: #cecece;
    border-radius: 10px;
}

.summary_section li:first-child {
    margin-top: 0px;
    padding-top: 10px;
    border-top: 1px solid #eee;
}

.summary_section li {
    display: flex;
    align-items: center;
    margin: 7px 0;
}

.summary_section .divider {
    border-bottom: 1px solid #eee;
    width: calc(100% - 20px);
    min-height: 60px;
    padding-top: 8px;
}

.summary_section .divider .manage {
    display: flex;
}

.summary_section li .transaction img {
    width: auto;
    height: auto;
    display: block;
    margin: 0 auto;
}

.summary_section .paid.transaction {
    display: flex;
    border-bottom: 0px;
    padding: 3px 0 3px 0px;
}

.summary_section .paid.transaction .divider {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-bottom: 3px;
}

.summary_section li .paid.transaction .icon img {
    width: 100%;
}

.summary_section li .paid.transaction .icon {
    width: 70px;
    padding: 8px;
    height: 65px;
}

.summary_section li span {
    font-size: 16px;
    color: #1d252d;
    font-weight: 800;
    @include Urbanist;
}

.summary_section li .paid span {
    font-weight: 600;
}

.summary_section li .paid.transaction span {
    font-size: 14px;
    color: #9c9d9f;
    font-family: "Urbanist", sans-serif !important;
    border: none;
    font-weight: normal;
}

.summary_section li .paid.transaction span.gap {
    font-size: 16px;
    color: #1d252d;
    font-weight: 800;
    font-family: "Urbanist", sans-serif !important;
}

.summary_section li .price {
    font-size: 20px;
    color: #21c179;
    font-weight: bold;
    display: flex;
    margin-left: auto;
}

.summary_section li .icon {
    width: 48px;
    height: 48px;
    border-radius: 100%;
    background: #fff7de;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
}

.summary_section .total {
    background: #e9f1ff;
    padding: 8px 0 8px 24px;
    font-size: 14px;
    color: #1d252d;
    line-height: 20px;
    border-radius: 0 0 16px 16px;
    @include Urbanist;
    font-weight: bold;
}

.summary_section .summary_content {
    padding: 0 0 0 24px;
}

.summary_section .summary_content.summary {
    padding-right: 24px;
    padding-bottom: 5px;
}

.summary_section .summary .btn {
    margin-right: 0px;
}

.summary_section .paid {
    border-bottom: 1px solid #eee;
    width: calc(100% - 20px);
    padding-top: 10px;
    padding-bottom: 10px;
}

.summary_section .paid span {
    display: inline;
}

.summary_section .paid .date {
    color: #858c98;
}

.summary_section .paid > span {
    display: block;
}

.summary_section .paid .gap {
    padding-bottom: 0px;
}

.summary_section p {
    font-size: 16px;
    color: #1d252d;
    padding-top: 16px;
    font-weight: normal;
    @include Urbanist;
}

.summary_section .btn {
    background: #EE934A;
    background-image: radial-gradient(circle at bottom right, #F1857F 1%, #EE934A 100%);
    @include Urbanist;
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    height: 40px;
    margin-right: 18px;
    margin-top: 24px;
    margin-bottom: 8px;
    cursor: pointer;
}

.summary_section .btn a {
    color: #fff;
}

.summary_section form {
    display: flex;
    justify-content: space-between;
}

.summary_section .form-control {
    background: initial;
    border: 1px solid #dde5ed;
    width: 100%;
    height: 40px;
    border-radius: 6px;
    padding-left: 16px;
    margin-top: 24px;
    margin-right: 18px;
    font-size: 14px;
    font-weight: 600;
    @include Urbanist;
    position: relative;
    color: #858c98;
}

.summary_section .summary_repeat.card .summary_card {
    margin-top: 16px;
}

.summary_repeat.card a:first-child {
    min-width: calc(100% - 24px);
    max-width: calc(100% - 24px);
}

.summary_repeat.card .order_card.card_front p {
    padding-top: 0px;
}

.summary_repeat.card img {
    margin: 0 10px 24px 0;
    cursor: pointer;
}

.summary_repeat.card img.apple-wallet {
    max-width: 127px;
}

.summary_repeat.card img.google-wallet {
    max-width: 141px;
}

.summary_repeat.faq .Dashboard_Area {
    background: #fff;
    margin-top: 10px;
}

.summary_repeat.faq .contentarea {
    width: 100%;
    margin-left: 0px;
    height: 350px;
    overflow: auto;
    background: #fff;
}

.summary_repeat.faq .content_sec {
    padding: 0 20px 0 24px;
}

.summary_repeat.card h3 img {
    margin: 0px;
}

.summary_repeat.faq .panel {
    box-shadow: inherit !important;
}

.summary_repeat.faq .contentarea .content_sec .accordion_content .panel__label:after, .summary_repeat.faq .contentarea .content_sec .accordion_content .panel__label:before {
    right: 10px;
}

.summary_repeat.faq .contentarea .content_sec .accordion_content .panel .panel__label {
    font-weight: bold;
}

.summary_repeat.faq .contentarea .content_sec .accordion_content .panel__label {
    padding: 0px;
    font-size: 16px;
    color: #1d252d;
    line-height: 24px;
    font-weight: normal;
    @include Urbanist;
    padding-right: 40px;
}

.summary_repeat.faq .contentarea .content_sec .accordion_content .panel {
    border-bottom: 1px solid #eee;
    margin-bottom: 0px;
    padding-right: 0px;
    box-shadow: inherit;
    border-radius: 0px;
    padding-top: 12px;
    padding-bottom: 12px;
}

.summary_repeat.faq .contentarea .content_sec .accordion_content .accordion {
    border-top: 1px solid #eee;
    margin-top: 0px;
}

.summary_repeat.faq .contentarea .content_sec .accordion_content .panel__content ul {
    height: auto;
    overflow: hidden;
    padding: 0px;
    margin: 0px;
}

.summary_repeat.faq .contentarea .content_sec .accordion_content .panel__content {
    margin-left: 0px;
    margin-bottom: 0px;
}

.summary_section .summary_repeat.faq li {
    display: block;
    border: none;
}

.calendar-heading {
    display: flex;
    justify-content: space-between;
}

.calendar-heading span {
    display: inline-block;
}

.calendar-heading input {
    border: 1px solid #e9eae9;
    margin-right: 24px;
    padding: 10px;
    border-radius: 8px;
    position: relative;
    background: url(./images/calendar-dropdown.png) 90% center no-repeat;
    max-width: 100px;
    font-size: 14px;
    color: #1d252d;
    @include Urbanist;
}

.summary_section .current.trans {
    padding-top: 9px;
}

.react-datepicker {
    background: #fff;
    border: 1px solid #dde5ed;
    box-shadow: 0 12px 15px 0 rgba(139,166,193,.37);
    border-radius: 4px;
}

.summary_section .react-datepicker__header {
    background: #fff;
    border: none;
    border-radius: 4px 4px 0 0;
    font-size: 18px;
    color: #1d252d;
    font-weight: 600;
}

.summary_section .react-datepicker__navigation--previous {
    top: 20px;
    left: 18px;
    color: #fff;
    border: solid #000;
    border-width: 0 2px 2px 0;
    border-radius: 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.summary_section .react-datepicker__navigation--previous:hover {
    border: solid #000;
    border-width: 0 2px 2px 0;
}

.summary_section .react-datepicker__navigation--next {
    top: 20px;
    right: 18px;
    color: #fff;
    border: solid #000;
    border-width: 0 2px 2px 0;
    border-radius: 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.summary_section .react-datepicker__navigation--next:hover {
    border: solid #000;
    border-width: 0 2px 2px 0;
}

.summary_section .react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #8ba6c1;
    width: 42px;
    height: 38px;
    min-width: 42px;
    line-height: 38px;
}

.summary_section .react-datepicker__month-text.react-datepicker__month--selected:hover, .summary_section .react-datepicker__month-text.react-datepicker__month--selected {
    background: #EE934A;
    color: #fff;
}

.summary_section .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
    top: 1px;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    border-bottom: 7px solid #ccc;
    border-top: 0px;
    position: absolute;
    left: 15px;
    z-index: 20;
}

.summary_section .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before {
    top: 1px;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    border-left: 6px solid transparent;
    border-top: 0px;
    position: absolute;
    left: -6px;
    z-index: 21;
}

.summary_section .daterangepicker td.in-range {
    background: rgba(240,77,93,.22);
    color: #202830;
}

.summary_section .react-datepicker__month-container {
    padding-top: 8px;
}

.summary_section .transaction li .icon {
    background: #ebf4ff;
}

.go-pro {
    position: absolute;
    bottom: 40px;
    left: calc(50% - 96px);
    background: #EE934A;
    background-image: radial-gradient(circle at bottom right, #F1857F 1%, #EE934A 100%);
    display: flex;
    font-size: 14px;
    color: #fff;
    height: 52px;
    cursor: pointer;
    width: 192px;
    text-align: center;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
}

.go-pro span {
    padding-left: 30px;
    background: url(./images/star.svg) left center no-repeat;
    height: 24px;
    line-height: 26px;
}

.summary_section .summary_repeat.second .summary_content {
    min-height: 275px;
}

.contentarea .content_sec.consumer-card .card_page_content {
    margin-bottom: 30px;
}

.contentarea .content_sec.consumer-card .Card_Page_header {
    border-bottom: 0px;
}

.contentarea .content_sec.consumer-card .all_cards {
    max-width: 954px;
}

.contentarea .content_sec.consumer-card .cards .order_card .right_info p {
    font-size: 12px;
}

.contentarea .content_sec.consumer-card .cards .order_card .instructions h4 {
    font-size: 13px;
}

.contentarea .content_sec.consumer-card .cards .order_card.card_front .card_footer {
    font-size: 12px;
}

.contentarea .content_sec.consumer-card .cards .order_card.card_front .card_info {
    font-size: 28px;
}

.contentarea .content_sec.consumer-card .cards .order_card {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 0px;
}

.consumer-card .summary_section .summary_repeat.share-card {
    max-width: 954px;
}

.consumer-card .summary_section .summary_repeat {
    max-width: 461px;
}

.consumer-card .summary_section .summary_repeat.share {
    margin-right: 30px;
}

.consumer-card .summary_section .summary_repeat.order-now {
    margin-right: 0px;
}

.contentarea .content_sec.consumer-card .all_cards > div {
    width: 100%;
}

.summary_section .summary_repeat.share p, .summary_section .summary_repeat.order-now p, .summary_section .summary_repeat.share-card p {
    padding-top: 8px;
}

.summary_section .summary_repeat.share {
    background: #fff url(./images/app-wallet-bg.png) right bottom no-repeat;
    min-height: 280px;
}

.summary_section .summary_repeat.order-now {
    background: #fff url(./images/order-card-bg.png) right bottom no-repeat;
    min-height: 280px;
}

.summary_section .summary_repeat.share-card {
    max-width: 81%;
    background: #fff url(./images/share-card-bg.png) right bottom no-repeat;
    min-height: 200px;
}

.summary_section .summary_repeat.share-card .summary_content {
    max-width: 65%;
}

.summary_section .summary_repeat.share-card .form-control, .apple-modal .form-control {
    height: 52px;
    border: 1px solid #dde5ed;
    font-size: 14px;
    color: #858c98;
    font-weight: normal;
    background-color: initial;
    background: initial;
    padding-left: 16.5px;
    padding-right: 16.5px;
}

.apple-modal form {
    display: flex;
    position: relative;
}

.apple-modal .form-control {
    min-width: 70%;
    margin-right: 15px;
    border-radius: 6px;
    position: relative;
    z-index: 1;
}

.apple-modal .modal_body input[type=text]:focus + .place, .apple-modal .modal_body input[type=text]:not(:placeholder-shown) + .place {
    top: 0px;
    z-index: 2;
}

.apple-modal .btn {
    border-radius: 6px;
    background: #EE934A;
    color: #fff;
}

.apple-modal .error span {
    padding-top: 12px;
    font-size: 12px;
    display: block;
}

.apple-modal .error.success span {
    color: #000000;
}

.summary_section .summary_repeat.share-card .btn, .apple-modal .btn {
    height: 52px;
    min-width: 142px;
    margin-bottom: 0px;
    margin-right: 0px;
}

.consumer-card .cards .card_back .card_body {
    min-height: 225px;
}

.consumer-card .all_cards > div .buttons .download {
    background: #fff url(./images/download.png) 20px center no-repeat;
    width: auto;
    border: 1px solid #ddd;
    height: 36px;
    border-radius: 6px;
    padding: 0 20px 0 55px;
    line-height: 36px;
    font-size: 16px;
    color: #1d252d;
    font-weight: 600;
    @include Urbanist;
}

.consumer-card .all_cards > div .buttons {
    width: auto;
}

.consumer-card .cards {
    padding-top: 15px;
}

.contentarea .content_sec.consumer-card .cards .order_card.card_front {
    margin-right: 35px;
}

.consumer-card .all_cards > div .buttons .print {
    background: #fff url(./images/printer.png) 20px center no-repeat;
    width: auto;
    border: 1px solid #ddd;
    height: 36px;
    border-radius: 6px;
    padding: 0 20px 0 55px;
    line-height: 36px;
    margin-right: 12px;
    font-size: 16px;
    color: #1d252d;
    font-weight: 600;
    @include Urbanist;
}

.summary_section form {
    position: relative;
    z-index: 0;
}

.summary_section form .place {
    margin-top: 13px;
    font-size: 14px;
    color: #858c98;
    font-weight: normal;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    left: 17px;
    display: flex;
    width: auto;
    padding-left: 3.5px;
    padding-right: 3.5px;
    background: #fff;
    transition: all .16s linear;
    z-index: -1;
}

.apple-modal .modal_body .place {
    margin-top: 0px;
    font-size: 14px;
    color: #858c98;
    font-weight: normal;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    left: 17px;
    display: flex;
    width: auto;
    padding-left: 3.5px;
    padding-right: 3.5px;
    background: #fff;
    transition: all .16s linear;
}

.summary_section form input[type=text]:focus + .place, .summary_section form input[type=text]:not(:placeholder-shown) + .place {
    top: 10px;
    z-index: 1;
}

.summary_section .email-list span {
    font-size: 14px;
    color: #858c98;
    line-height: 28px;
    background: #f2f2f2;
    border-radius: 4px;
    padding: 0 30px 0 12px;
    position: relative;
    margin-right: 12px;
}

.summary_section .email-list::-webkit-scrollbar {
    height: 6px;
}

.summary_section .email-list::-webkit-scrollbar-track {
    background-color: #f4f7fc;
    border-radius: 3px;
}

.summary_section .email-list {
    width: 100%;
    overflow: hidden;
    padding-top: 3px;
    margin-bottom: 0px;
    display: flex;
    overflow: auto;
    padding-bottom: 3px;
}

.summary_section .share-card .email-list {
    padding-top: 10px;
}

.summary_section .email-list .close {
    width: 12px;
    height: 12px;
    background: url(./images/close.png) no-repeat;
    position: absolute;
    top: 8px;
    right: 0px;
    padding: 0px;
    cursor: pointer;
}

.summary_section .error span {
    font-size: 14px;
    color: #EE934A;
    display: inline;
}

.summary_section .error.success span {
    color: #000000;
    background: url(./images/select_themed.png) right center no-repeat;
    padding-right: 15px;
}

.summary_section .error {
    width: 100%;
    overflow: hidden;
    padding: 10px 0 15px 0;
}

.summary_repeat.faq .h2_title, .summary_repeat.faq .left_sidebar, .summary_repeat.faq .mobile_header, .summary_repeat.faq .Header_Area {
    display: none;
}

.pro-modal .modal_wrapper .header h3 {
    font-size: 28px;
    color: #1d252d;
    line-height: normal;
    @include Urbanist;
}

.pro-modal .modal_wrapper {
    position: relative;
}

.pro-modal h3 span {
    display: block;
    font-size: 16px;
    color: #557188;
    line-height: normal;
    font-weight: normal;
}

.training_modal.pro-modal .modal_wrapper .header {
    border: none;
    align-items: inherit;
    padding-bottom: 0px;
}

.pro-modal .modal_wrapper {
    width: 851px;
    border-radius: 32px;
    padding-top: 20px;
}

.pro-modal .modal_wrapper li.header .first-one {
    text-indent: -9999px;
    min-height: auto;
    padding-top: 20px;
}

.pro-modal .modal_wrapper li.header .second-one {
    font-size: 16px;
    color: #1d252d;
    font-weight: 800;
    min-height: auto;
    padding-top: 20px;
}

.pro-modal .modal_wrapper li.header .third-one {
    min-height: auto;
    font-size: 16px;
    color: #005596;
    border-radius: 17px 17px 0 0;
    font-weight: 800;
    padding-top: 20px;
}

.pro-modal .modal_wrapper li.header .third-one img {
    margin-left: 4px;
}

.pro-modal .modal_wrapper li.header {
    padding: 0px;
    border-bottom: none;
}

.pro-modal .modal_wrapper li .first-one {
    min-width: 60%;
    min-height: 70px;
    padding-top: 12px;
}

.pro-modal .modal_wrapper li .second-one {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 20%;
    min-height: 70px;
    position: relative;
}

.pro-modal .modal_wrapper li .right-icon {
    height: 12px;
    width: 7px;
    border-bottom: 2px solid #21c17a;
    border-right: 2px solid #21c17a;
    transform: rotate( 45deg );
    text-indent: -9999px;
    display: block;
}

.pro-modal .modal_wrapper li .close-icon {
    width: 15px;
    height: 15px;
    display: block;
    position: relative;
    text-indent: -9999px;
    top: auto;
    right: auto;
}

.pro-modal .modal_wrapper li .close-icon:before, .pro-modal .modal_wrapper li .close-icon:after {
    position: absolute;
    left: 5px;
    content: ' ';
    height: 15px;
    width: 2px;
    background-color: #EE934A;
}

.pro-modal .modal_wrapper li .close-icon:before {
    transform: rotate(45deg);
}

.pro-modal .modal_wrapper li .close-icon:after {
    transform: rotate(-45deg);
}

.pro-modal .modal_wrapper li .third-one {
    min-width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #e5f4ff;
    min-height: 70px;
    position: relative;
}

.pro-modal .modal_wrapper li {
    padding: 0px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #dde5ed;
    padding-top: 0px;
    padding-bottom: 0px;
}

.pro-modal .modal_wrapper li:last-child {
    border-bottom: none;
}

.pro-modal .modal_wrapper li:last-child .third-one {
    border-radius: 0 0 17px 17px;
}

.training_modal.videoPopup.pro-modal .modal_wrapper .modal_body {
    padding-top: 0px;
    height: 75vh;
    overflow: auto;
    margin-bottom: 25px;
}

.pro-modal h4 {
    font-size: 16px;
    color: #1d252d;
    @include Urbanist;
    font-weight: 800;
    line-height: 20px;
    padding-bottom: 5px;
}

.pro-modal p {
    font-size: 14px;
    color: #557188;
    @include Urbanist;
    font-weight: normal;
    line-height: 20px;
}

.pro-modal .go-pro {
    width: 300px;
    left: auto;
    bottom: auto;
    position: relative;
    margin: 30px auto 30px auto;
}

.pro-modal ul {
    width: 100%;
    overflow: hidden;
}

.pro-modal .left_sidebar, .pro-modal .mobile_header, .pro-modal .Header_Area, .pro-modal .h2_title {
    display: none;
}

.pro-modal .contentarea .content_sec .accordion_content .panel {
    box-shadow: inherit;
    border-radius: 0px;
}

.pro-modal .contentarea {
    margin: 0px;
    width: 100%;
}

.pro-modal .contentarea .content_sec {
    padding: 0px;
}

.pro-modal .Dashboard_Area {
    background: #fff;
}

.pro-modal h5 {
    font-size: 28px;
    color: #1d252d;
    line-height: normal;
    font-weight: 800;
    @include Urbanist;
}

.pro-modal .contentarea .content_sec .accordion_content .panel__label {
    padding: 0px;
    padding-right: 20px;
}

.pro-modal .contentarea .content_sec .accordion_content .panel {
    border-bottom: 1px solid #747d84;
    padding: 15px 0 15px 0;
}

.pro-modal .contentarea .content_sec .accordion_content .panel:not(:last-child) {
    margin-bottom: 0px;
}

.pro-modal .contentarea .content_sec .accordion_content .panel__content {
    padding: 0px;
    margin: 0 0 0px 0;
}

.pro-modal .contentarea .content_sec .accordion_content .panel[aria-expanded='true'] .panel__content {
    font-size: 16px;
    line-height: 26px;
    color: #000;
    @include Urbanist;
}

.pro-modal .close-icon {
    position: absolute;
    top: 15px;
    right: 15px;
}

.pro-modal .contentarea .content_sec .accordion_content .panel__label span {
    display: none;
}

.pro-modal .contentarea .content_sec .accordion_content .panel__label:after, .pro-modal .contentarea .content_sec .accordion_content .panel__label:before {
    width: 14px;
    right: 15px;
    background-color: #1d252d;
}

.pro-modal .contentarea .content_sec .accordion_content .panel__label span {
    border: none;
}

.confirm-pro .btn {
    display: inline;
    margin-right: 15px;
    margin-top: 20px;
}

.confirm-pro p {
    font-size: 16px;
    line-height: 26px;
    @include Urbanist;
}

.confirm-pro .btn.loader {
    text-align: left;
}

.summary_section .summary_repeat.share-card .btn, .confirm-pro .btn, .apple-modal .btn {
    position: relative;

    &.loader {
        pointer-events: none;
        position: relative;
        transition: all .2s ease-in-out;

        &:before {
            content: "";
            border: 4px solid #EE934A;
            border-radius: 50%;
            border-top: 4px solid #fff;
            width: 25px;
            height: 25px;
            -webkit-animation: .5s linear infinite spin;
            animation: .5s linear infinite spin;
            position: absolute;
            right: 20px;
            top: 10px;
        }
    }
}

.summary_section .summary .btn {
    position: relative;

    &.loader {
        pointer-events: none;
        position: relative;
        transition: all .2s ease-in-out;

        &:before {
            content: "";
            border: 4px solid #EE934A;
            border-radius: 50%;
            border-top: 4px solid #fff;
            width: 25px;
            height: 25px;
            -webkit-animation: .5s linear infinite spin;
            animation: .5s linear infinite spin;
            position: absolute;
            right: 7px;
            top: 4px;
        }
    }
}

.summary_section li .status {
    font-size: 14px;
    color: #9c9d9f;
    @include Urbanist;
    border: none;
    font-weight: normal;
}

.summary_section .summary form .place {
    margin-top: 6px;
}

.summary_section .summary form input[type=text]:focus + .place, .summary_section .summary form input[type=text]:not(:placeholder-shown) + .place {
    top: 16px;
}

.summary_section .summary_repeat.share-card .btn.loader {
    text-align: left;
}

.apple-modal .btn.loader {
    justify-content: left;
}

.summary_section .summary .btn.loader {
    padding-left: 16px;
    padding-right: 48px;
}

.contentarea .content_sec .header_bard_button.userpage_header_bard .tabbed_button {
    margin-right: 12px;
    max-width: 350px;
    margin-left: auto;
}

.summary_section .summary form .error-mail {
    margin-top: 6px;
}

.summary_section form .error-mail, .apple-modal .error-mail {
    margin-top: 13px;
    font-size: 14px;
    color: #858c98;
    font-weight: normal;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    left: 17px;
    display: flex;
    width: auto;
    padding-left: 3.5px;
    padding-right: 3.5px;
    background: #fff;
    transition: all .16s linear;
    z-index: -1;
    padding-left: 20px;
}

.apple-modal .error-mail {
    margin-top: 0px;
    z-index: 0;
}

.summary_section form .error-mail:before, .apple-modal .error-mail:before {
    content: "!";
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #000000;
    display: flex;
    color: #fff;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0px;
    z-index: 9;
}

.Transactions_Summary .order_card_wrapper.adjust .order_card {
    width: calc(100% - 24px);
}

.contentarea .content_sec .accordion_content h3 {
    padding-bottom: 10px;
    padding-top: 10px;
    border-bottom: 1px solid #1d252d;
    margin-bottom: 25px;
}

.contentarea .content_sec .accordion_content {
    margin-bottom: 15px;
}

.contentarea .content_sec .header_bard_button .add_group_no_btn.usersarea.adduserbtn.align-right {
    margin-left: 0px;
}

.contentarea .content_sec .header_bard_button .add_group_no_btn.usersarea.loader.align-right:before {
    right: 575px;
}

.apple-modal .modal_body p {
    padding-bottom: 15px;
}

.month-calendar {
    &.loader {
        pointer-events: none;
        position: relative;
        transition: all .2s ease-in-out;

        &:before {
            content: "";
            border: 4px solid #EE934A;
            border-radius: 50%;
            border-top: 4px solid #fff;
            width: 25px;
            height: 25px;
            -webkit-animation: .5s linear infinite spin;
            animation: .5s linear infinite spin;
            position: absolute;
            right: 136px;
            top: 3px;
        }
    }
}

.contentarea .content_sec .asign_edit_pop.edit_user_popup .add_url_pop > div .body .personal_box > div.user_table > .user_table_body::-webkit-scrollbar {
    width: 15px;
}

.contentarea .content_sec .asign_edit_pop.edit_user_popup .add_url_pop > div .body .personal_box > div.user_table > .user_table_body::-webkit-scrollbar-thumb {
    background: #f1f1f1;
}

.profile_menu {
    display: none;
}

.profile_menu.open {
    display: block;
}

.profile_menu {
    top: 55px;
    background: #fff;
    padding: 15px 10px;
    min-height: 50px;
    font-size: 16px;
    font-weight: 400;
    width: 100%;
    height: auto;
    max-width: 310px;
    max-height: 295px;
    box-shadow: 0 2px 24px 0 rgba(0,0,0,.05), 0 9px 24px 0 rgba(0,0,0,.2);
    border: 1px solid rgba(80,109,133,.42);
    background-color: #fff;
    display: none;
    flex-flow: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    right: 0;
    bottom: auto;
    left: auto;
    border-radius: 6px;
    padding-left: 15px;
    padding-bottom: 15px;
    z-index: 99999;

    &.open {
        display: flex;
    }

    &:before {
        content: "";
        width: 14px;
        height: 14px;
        max-width: 14px;
        max-height: 14px;
        background: #fff;
        border: 1px solid rgba(80,109,133,.42);
        position: absolute;
        top: -8px;
        right: 14px;
        bottom: auto;
        left: auto;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        border-bottom: none;
        border-right: none;
    }

    ul {
        list-style: none;

        li {
            justify-content: center;
            @include Urbanist;
            font-size: 16px;
            font-weight: 400;
            cursor: pointer;
            padding-left: 5px;
            line-height: 26px;
            margin-top: 10px;
        }
    }
}

.contentarea .Header_Area .profile_top_section {
    position: relative;
}

.contentarea .Header_Area .profile_top_section .profile_image_name.less {
    margin-right: 0px;
}

.container_signup .header a.logosec.consumer img {
    width: auto;
    max-height: 28px;
}

.left_sidebar .navbar ul li.desktop-hide {
    display: none;
}

.contentarea .content_sec .asign_edit_pop.edit_user_popup .add_url_pop > div .body .personal_box > div.user_table:hover .user_table_body {
    overflow: auto;
}

.contentarea .content_sec .asign_edit_pop.edit_user_popup .add_url_pop > div .body .personal_box > div.user_table:hover .header_row.scroll {
    padding-right: 30px;
}

.contentarea .content_sec .Account_info_form_parent > div .account-number {
    font-size: 14px;
    line-height: 20px;
    color: #1d252d;
    font-weight: 500;
}

.contentarea .content_sec .Account_info_form_parent > div .account-number span {
    margin-right: 40px;
}

.contentarea .content_sec .Account_info_form_parent > div .account-number label {
    font-weight: 600;
}

.contentarea .content_sec .Account_info_form_parent > div > div.payments {
    width: 100%;
}

.contentarea .content_sec .Account_info_form_parent > div > div > span.changepassword {
    font-size: 20px !important;
    color: #1d252d !important;
    font-weight: bold !important;
    position: relative;
    top: auto;
    left: auto;
    cursor: pointer;
    margin-top: 30px;
}

.summary_section .intro-kit h3 {
    font-size: 24px;
    color: #1d252d;
    line-height: 28px;
    font-weight: 800;
    margin-bottom: 10px;
}

.contentarea .content_sec .resetgroup_box .add_url_pop > div.intro-kit .header {
    border: none;
}

.contentarea .content_sec .resetgroup_box .add_url_pop > div.intro-kit .header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.contentarea .content_sec .resetgroup_box .add_url_pop > div.intro-kit .left {
    max-width: 65%;
}

.contentarea .content_sec .resetgroup_box .add_url_pop > div.intro-kit .right {
    margin-left: auto;
    margin-right: 40px;
}

.contentarea .content_sec .resetgroup_box .add_url_pop > div.intro-kit .left span {
    font-size: 14px;
    color: #1d252d;
    line-height: 20px;
}

.contentarea .content_sec .resetgroup_box .add_url_pop > div.intro-kit .header .close {
    position: absolute;
    top: 20px;
    right: 30px;
}

.intro-kit .right-view {
    width: calc(100% - 30px);
    float: none;
    margin-left: 30px;
}

.order-section .intro-kit .first-name, .order-section .intro-kit .last-name {
    width: 48%;
    float: none;
}

.order-section .intro-kit .two-column {
    display: flex;
    justify-content: space-between;
}

.order-section .intro-kit .right-view div.errors span + span {
    background: none;
}

.order-section .intro-kit .right-view div.errors.drpdown span + span {
    background: #fff;
}

.order-section .intro-kit .same {
    margin-bottom: 24px;
}

.summary_section .intro-kit .error {
    padding: 15px 0 15px 0;
}

.intro-kit .yes_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    transition: all .2s ease-in-out;

    &.loader {
        pointer-events: none;
        position: relative;
        display: flex;
        align-items: center;
        transition: all .2s ease-in-out;
        width: auto;
        max-width: 200px;
        justify-content: left !important;
        width: 190px !important;
        padding-left: 15px;

        &:after {
            content: "";
            border: 4px solid #EE934A;
            border-radius: 50%;
            border-top: 4px solid #fff;
            width: 25px;
            height: 25px;
            -webkit-animation: .5s linear infinite spin;
            animation: .5s linear infinite spin;
            position: relative;
            right: -10px;
            order: 2;
        }
    }
}

.contentarea .content_sec .resetgroup_box .add_url_pop .intro-kit > div .footer > div.submit-error {
    color: #EE934A;
    font-size: 14px;
    padding: 10px 0 0 0;
    justify-content: left;
    width: auto;
    height: auto;
}

.contentarea .content_sec .Account_info_form_parent > div h4 .edit {
    font-size: 14px;
    color: #EE934A;
    line-height: 20px;
    cursor: pointer;
    font-weight: 500;
}

.contentarea .content_sec .header_bard_button .tabbed_button.commission > div {
    width: calc(100% / 2);
}

.contentarea .content_sec .header_bard_button.userpage_header_bard .tabbed_button.commission {
    max-width: 260px;
}

.contentarea .content_sec .header_bard_button .tabbed_button.commission > div:nth-child(2) {
    border: 1px solid #DDE5ED;
    border-left: none;
    border-radius: 0 8px 8px 0;
}

.intro-kit .submit-error {
    font-size: 14px;
    color: #EE934A;
    font-weight: normal;
    padding-top: 6px;
    font-family: "Urbanist", sans-serif !important;
}

.contentarea .content_sec .Account_info_form_parent .Account_info_form_parent {
    box-shadow: inherit;
    padding: 0px;
    background-color: inherit;
}

@media print {
    .card_page_content {
        display: block;
    }

    .summary_section, .Card_Page_header, .all_cards > div > h3, .consumer-card .all_cards > div .buttons {
        display: none;
    }
}

.summary_section p a {
    color: #EE934A;
}

.order_card.card_back .card_body .right_info img {
    cursor: pointer;
}
/* responsive */
@media screen and (min-width: 768px) and (max-width: 1600px) {
    .dashboard-menu .searchSection .form-control {
        width: 100%;
    }
}

@media screen and (min-width: 768px) and (max-width: 1400px) {
    .order-section .Mask.intro-kit {
        height: 550px !important;
        overflow: auto;
        padding-bottom: 50px;
    }

    .left_sidebar .navbar ul li.logout-link div.logout-icon {
        margin-right: 13px;
    }

    .contentarea .content_sec.consumer-card .cards .order_card {
        margin-top: 20px;
        margin-bottom: 0px;
        min-height: 330px;
    }

    .summary_section .summary_repeat, .consumer-card .summary_section .summary_repeat {
        max-width: 44%;
    }

    .summary_section .summary_repeat.gap {
        margin-right: 0px;
    }

    .contentarea .content_sec.consumer-card .all_cards, .consumer-card .summary_section .summary_repeat.share-card {
        max-width: 91%;
    }

    .discount_card_sec_signup .all_cards .order_card.card_front {
        margin-right: 34px !important;
    }

    .reportPage .daterangepicker {
        height: auto;
        padding-bottom: 20px;
    }

    .reportPage .daterangepicker .calendar-table th, .reportPage .daterangepicker .calendar-table td {
        font-size: 12px;
        height: 30px;
    }

    .reportPage .daterangepicker .drp-buttons {
        margin-top: 0px;
        padding-top: 5px;
    }

    .left_sidebar .navbar ul.drop::-webkit-resizer, .left_sidebar .navbar ul.drop::-webkit-scrollbar-button, .left_sidebar .navbar ul.drop::-webkit-scrollbar-corner, .left_sidebar .navbar ul.drop::-webkit-scrollbar-track-piece {
        display: none;
    }

    .left_sidebar .navbar ul.drop::-webkit-scrollbar-thumb {
        border-radius: 50px;
        background-color: rgba(159,161,165,.8);
        background-clip: padding-box;
        border: 3px solid transparent;
    }

    .left_sidebar .navbar ul.drop::-webkit-scrollbar {
        display: none;
        width: 10px;
    }

    .left_sidebar .navbar ul.drop:hover::-webkit-scrollbar {
        display: initial;
    }

    .left_sidebar .navbar ul.drop {
        height: 320px;
        overflow-y: auto;
    }

    .tool-section .tableheader {
        line-height: normal;
    }

    #dashboard-container .widget {
        width: 100% !important;
        margin-left: 0px;
    }

    .dashboard-menu .react-bootstrap-daterangepicker-container {
        width: 100%;
        margin-left: 0;
        margin-top: 4px;
        margin-bottom: 10px;
        float: left;
    }

    .claimSection .paid .small {
        padding-right: 50px;
    }

    .dashboard-menu .searchSection .form-control {
        width: 100%;
    }

    .transaction .daterangepicker.opensright:before, .analyticPage .daterangepicker.opensright:before {
        left: 9px !important;
        right: auto !important;
    }

    .transaction .daterangepicker.opensright:after, .analyticPage .daterangepicker.opensright:after {
        left: 10px !important;
        right: auto !important;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
    .contentarea .content_sec.consumer-card .cards .order_card {
        margin-top: 20px;
        margin-bottom: 0px;
    }

    .consumer-card .summary_section .summary_repeat.share {
        margin-right: 0px;
    }

    .summary_section .summary_repeat, .consumer-card .summary_section .summary_repeat {
        max-width: 100%;
        margin-right: 0px;
    }

    .reportPage .daterangepicker {
        margin-right: 250px;
    }

    .contentarea .content_sec.consumer-card .all_cards, .consumer-card .summary_section .summary_repeat.share-card {
        max-width: 100%;
        margin-right: 0px;
    }

    .reportPage .daterangepicker.opensright:before {
        left: auto !important;
        right: 9px !important;
    }

    .reportPage .daterangepicker.opensright:after {
        left: auto !important;
        right: 10px !important;
    }

    .reportPage.user-transact .daterangepicker.opensright:before {
        left: 9px !important;
        right: auto !important;
    }

    .reportPage.user-transact .daterangepicker.opensright:after {
        left: 10px !important;
        right: auto !important;
    }
}

@media screen and (min-width: 1100px) {
    .new .contentarea .content_sec .tabular_box .table_header.user_page > div:first-child {
        max-width: 18%;
    }

    .new .contentarea .content_sec .dist_popup .tabular_box .table_header.user_page > div:first-child {
        max-width: 120px;
    }

    .new .contentarea .content_sec .tabular_box .table_header.user_page > div:nth-child(2) {
        max-width: 22%;
    }

    .new .contentarea .content_sec .dist_popup .tabular_box .table_header.user_page > div:nth-child(2) {
        max-width: 160px;
    }

    .new .contentarea .content_sec .tabular_box .table_header.user_page > div:nth-child(3) {
        max-width: 10%;
    }

    .new .contentarea .content_sec .tabular_box .table_header.user_page.main > div:nth-child(4) {
        max-width: 10%;
    }

    .new .contentarea .content_sec .tabular_box .table_header.user_page.main > div:nth-child(5) {
        max-width: 10%;
    }

    .new .contentarea .content_sec .tabular_box .table_header.user_page.main > div:nth-child(7) {
        max-width: 15%;
    }

    .new .contentarea .content_sec .dist_popup .tabular_box .table_header.user_page > div:nth-child(3) {
        max-width: 150px;
    }

    .new .contentarea .content_sec .tabular_box .table_body .table_row.user_page > div > div:first-child {
        max-width: 18%;
    }

    .new .contentarea .content_sec .tabular_box .table_body .table_row.user_page > div > div:nth-child(2) {
        max-width: 22%;
    }

    .new .contentarea .content_sec .dist_popup .tabular_box .table_body .table_row.user_page > div > div:first-child {
        max-width: 120px;
    }

    .new .contentarea .content_sec .dist_popup .tabular_box .table_body .table_row.user_page > div > div:nth-child(2) {
        max-width: 160px;
    }

    .new .contentarea .content_sec .tabular_box .table_body .table_row.user_page > div > div:nth-child(3) {
        max-width: 10%;
    }

    .new .contentarea .content_sec .tabular_box .table_body .table_row.user_page.main_data > div > div:nth-child(4) {
        max-width: 10%;
    }

    .new .contentarea .content_sec .tabular_box .table_body .table_row.user_page.main_data > div > div:nth-child(5) {
        max-width: 10%;
    }

    .new .contentarea .content_sec .tabular_box .table_body .table_row.user_page.main_data > div > div:nth-child(6) {
        max-width: 15%;
    }

    .new .contentarea .content_sec .tabular_box .table_body .table_row.user_page.main_data > div > div:nth-child(7) {
        max-width: 15%;
    }

    .new .contentarea .content_sec .dist_popup .tabular_box .table_body .table_row.user_page > div > div:nth-child(3) {
        max-width: 150px;
    }

    .new .contentarea .content_sec .tabular_box .table_header.user_page.user_data > div:nth-child(6) {
        max-width: 15%;
    }
}

@media screen and (min-width: 0) and (max-width: 1100px) {
    .contentarea .content_sec.consumer-card .cards .order_card {
        margin-top: 20px;
        margin-bottom: 0px;
    }

    .all_cards > div > h3 {
        font-size: 15px;
    }

    .consumer-card .summary_section .summary_repeat.share {
        margin-right: 0px;
    }

    .contentarea .content_sec.consumer-card .all_cards {
        max-width: 100%;
    }

    .summary_section .summary_repeat, .consumer-card .summary_section .summary_repeat {
        max-width: 100%;
        margin-right: 0px;
    }

    .summary_section .btn {
        padding-left: 20px;
        padding-right: 20px;
    }

    .go-pro {
        width: 80%;
        margin-left: 10%;
        left: 0px;
    }

    .contentarea .content_sec .url_card_sec {
        position: relative;
    }

    .contentarea .content_sec .affiliate_refferal .table_footer {
        position: absolute;
        bottom: 0px;
        right: 0px;
    }

    .contentarea .content_sec .refferals_list {
        position: relative;
    }

    .contentarea .content_sec .tabular_box .table_footer {
        width: 100%;
        left: auto;
        right: 0px;
    }

    .contentarea .content_sec .tabular_box .table_footer {
        position: absolute;
    }

    .admin_user_page .flip_icon {
        position: relative;
        left: auto;
        right: auto;
        top: auto;
    }

    .mobile-scrolling-all.tool-section.list.list-data {
        overflow: auto;
    }

    .mobile-scrolling-all.tool-section.list.list-data > div {
        min-width: 900px;
        position: relative;
        overflow: hidden;
    }

    .mobile-scrolling-all.tool-section.list.list-data ul.pagination {
        float: left;
    }

    .new .contentarea .content_sec .header_bard_button .tt_card.loader::before {
        left: auto;
        right: -50px;
    }

    .contentarea .content_sec .resetgroup_box.dist_popup .add_url_pop > div {
        min-width: 90%;
    }

    .contentarea .content_sec .dist_popup .tabular_box {
        min-height: auto;
    }

    .contentarea .content_sec .tabular_box .table_body .table_row.user_page.main_data > div > div:first-child:before {
        content: 'Distributor Name';
    }

    .contentarea .content_sec .tabular_box .table_body .table_row.user_page.main_data > div > div:nth-child(2):before {
        content: 'Email Address';
    }

    .contentarea .content_sec .tabular_box .table_body .table_row.user_page.main_data > div > div:nth-child(3):before {
        content: 'Direct';
    }

    .contentarea .content_sec .tabular_box .table_body .table_row.user_page.main_data > div > div:nth-child(4):before {
        content: 'Tiered';
    }

    .contentarea .content_sec .tabular_box .table_body .table_row.user_page.main_data > div > div:nth-child(5):before {
        content: 'Override';
    }

    .contentarea .content_sec .tabular_box .table_body .table_row.user_page.main_data > div > div:nth-child(6):before {
        content: 'Secondary Override';
    }

    .contentarea .content_sec .tabular_box .table_body .table_row.user_page.main_data > div > div:nth-child(7):before {
        content: 'Total';
    }

    .contentarea .content_sec .dist_popup .tabular_box .table_body .table_row.user_page > div > div:first-child:before {
        content: 'Group Number';
    }

    .contentarea .content_sec .dist_popup .tabular_box .table_body .table_row.user_page > div > div:nth-child(2):before {
        content: 'Override User (if any)';
    }

    .contentarea .content_sec .dist_popup .tabular_box .table_body.direct-sec .table_row.user_page > div > div:nth-child(2):before {
        content: 'Distributor';
    }

    .contentarea .content_sec .dist_popup .tabular_box .table_body .table_row.user_page > div > div:nth-child(3):before {
        content: 'Transaction count';
    }

    .contentarea .content_sec .dist_popup .tabular_box .table_body .table_row.user_page > div > div:nth-child(4):before {
        content: 'Commission count ($)';
    }

    .contentarea .content_sec .dist_popup .tabular_box .table_body .table_row.user_page > div > div:nth-child(5):before {
        content: 'Total ($)';
    }

    .contentarea .content_sec .dist_popup .tabular_box .table_header.tab {
        display: flex;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .consumer-card .summary_section .summary_repeat.share-card {
        max-width: 100%;
    }

    .personal_box .user_table_body::-webkit-scrollbar {
        width: 15px !important;
        background: #f1f1f1;
    }

    .personal_box .user_table_body::-webkit-scrollbar-thumb {
        background: #c1c1c1;
    }

    .reportPage.user-transact .daterangepicker.ltr.show-calendar.opensright {
        margin-left: 0px;
    }

    .daterangepicker.ltr.show-calendar.opensright {
        margin-left: -150px;
    }

    .transaction .daterangepicker.ltr.show-calendar.opensright, .analyticPage .daterangepicker.ltr.show-calendar.opensright {
        margin-left: 0px;
    }

    .claimSection {
        display: block;
    }

    .claimSection .paid {
        margin-right: 0;
        width: 49%;
        margin-top: 1%;
        margin-bottom: 1%;
        display: inline-block;
    }

    .claimSection .paid:nth-child(2n) {
        margin-left: 2%;
    }

    .summary_section .summary_repeat.second .summary_content .react-datepicker-popper {
        left: -52px !important;
    }

    .summary_section .summary_repeat.second .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
        left: auto;
        right: 15px !important;
    }
}

@media screen and (min-width: 768px) and (max-width: 980px) {
    .summary_section .summary_repeat.share-card .summary_content {
        max-width: 75%;
    }

    .all_cards > div .buttons {
        top: 30px;
    }

    .consumer-card .summary_section .summary_repeat.share-card {
        max-width: 100%;
        min-height: 270px;
    }

    .summary_section .summary_repeat, .consumer-card .summary_section .summary_repeat {
        max-width: 100%;
    }

    .loading-text {
        min-height: 150px;
    }

    .reportPage .daterangepicker {
        height: auto;
        padding-bottom: 20px;
    }

    .reportPage.user-transact .daterangepicker.opensright:before {
        right: auto !important;
        left: 9px !important;
    }

    .reportPage.user-transact .daterangepicker.opensright:after {
        right: auto !important;
        left: 10px !important;
    }

    .reportPage.user-transact .daterangepicker.ltr.show-calendar.opensright {
        margin-left: -315px;
    }

    .searchSection.add-over {
        width: 22% !important;
    }

    .daterangepicker .calendar-table th, .daterangepicker .calendar-table td {
        font-size: 13px;
        height: 24px;
    }

    .transaction .daterangepicker, .analyticPage .daterangepicker {
        height: auto;
        padding-bottom: 20px;
    }

    .transaction .daterangepicker.ltr.show-calendar.opensright, .analyticPage .daterangepicker.ltr.show-calendar.opensright {
        margin-left: 0px;
    }

    .transaction .daterangepicker.ltr.show-calendar.opensright, .analyticPage .daterangepicker.ltr.show-calendar.opensright {
        right: auto !important;
        left: 200px !important;
    }

    .transaction .daterangepicker, .analyticPage .daterangepicker {
        width: 400px;
    }

    .transaction .daterangepicker .drp-calendar.left, .transaction .daterangepicker .drp-calendar.right {
        width: 100%;
        max-width: 100%;
    }

    .analyticPage .daterangepicker .drp-calendar.left, .analyticPage .daterangepicker .drp-calendar.right {
        width: 100%;
        max-width: 100%;
    }

    .analyticPage .daterangepicker .drp-calendar.right, .transaction .daterangepicker .drp-calendar.right {
        margin-left: 0px;
    }

    .dashboard-menu .radio-button-section {
        width: 50%;
    }

    .addVideoBtn .btn {
        font-size: 12px;
        padding: 12px 22px;
    }

    .orderlist-section .btn.red-btn {
        font-size: 15px;
    }

    .orderlist-section {
        margin-right: 15px;
    }

    .order_video .modal_wrapper .modal_body {
        max-height: 180px;
    }

    .contentarea .content_sec .dist_popup .no_record_found {
        min-height: 210px;
    }

    .contentarea .content_sec .dist_popup .tabular_box .table_body {
        max-height: 210px;
        min-height: 210px;
    }

    .order-section .Mask.intro-kit {
        height: 350px !important;
        overflow: auto;
        padding-bottom: 30px;
    }

    .order-section .yes_btn {
        width: 156px !important;
    }

    .order-section .Mask.intro-kit {
        width: 575px !important;
    }
}

@media screen and (min-width: 0px) and (max-width: 767.2px) {
    .left_sidebar .navbar ul li.desktop-hide {
        display: flex;
    }

    .summary_section .summary_repeat.gap {
        margin-right: 0px;
    }

    .contentarea .content_sec h2, .all_cards > div > h3 {
        font-size: 20px;
    }

    .pro-modal .contentarea .content_sec .accordion_content .accordion {
        margin-left: 0px;
        width: 100%;
    }

    .pro-modal .modal_wrapper li .second-one {
        padding-right: 3px;
    }

    .pro-modal h4, .pro-modal p {
        font-size: 13px;
    }

    .pro-modal .modal_wrapper li .first-one {
        min-width: 30%;
        max-width: 30%;
    }

    .pro-modal .modal_wrapper li.header .third-one, .pro-modal .modal_wrapper li .third-one {
        min-width: 35%;
    }

    .pro-modal .modal_wrapper li.header .second-one, .pro-modal .modal_wrapper li.header .third-one {
        font-size: 13px;
    }

    .summary_section .summary_repeat.second .summary_content .react-datepicker-popper {
        left: -52px !important;
    }

    .summary_section .summary_repeat.second .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
        left: auto;
        right: 15px !important;
    }

    .summary_section .summary_repeat.share-card .summary_content {
        padding-right: 24px;
    }

    .summary_section .summary_repeat.share-card .btn, .apple-modal .btn {
        min-width: 100px;
    }

    .summary_section .summary_repeat.share-card .summary_content {
        max-width: 100%;
    }

    .summary_section .summary_repeat.share-card {
        min-height: 350px;
    }

    .summary_section .summary_repeat.share {
        min-height: 310px;
    }

    .contentarea .content_sec.consumer-card .card_page_content {
        width: 100%;
        margin-left: 0px;
    }

    .consumer-card .summary_section .summary_repeat.share-card {
        max-width: 100%;
    }

    .all_cards > div .buttons.sharecard_button {
        top: 45px;
    }

    .all_cards > div > h3 {
        margin-bottom: 28px;
    }

    .consumer-card .all_cards > div .buttons {
        width: 100%;
    }

    .consumer-card .all_cards > div .buttons .download, .consumer-card .all_cards > div .buttons .print {
        width: 48%;
    }

    .consumer-card .summary_section form {
        display: block;
    }

    .consumer-card .summary_section form .place, .summary_section form .error-mail, .apple-modal .error-mail {
        top: 24%;
    }

    .summary_section .summary form .place, .summary_section .summary form .error-mail {
        top: 28%;
    }

    .summary_section .summary .btn {
        min-width: 125px;
    }

    .summary_section .Transactions_Summary.summary_card h3 {
        font-size: 11px;
    }

    .order_card.card_front .contact_info p {
        font-size: 11px;
        line-height: 14px;
    }

    .consumer-card .summary_section .btn {
        margin-top: 18px;
    }

    .summary_repeat.card .order_card.card_front img {
        margin-bottom: 0px;
    }

    .consumer-card .summary_section .summary_repeat.share-card .btn, .apple-modal .btn {
        min-width: 125px;
    }

    .summary_section h3 {
        font-size: 18px;
    }

    .summary_section li span {
        font-size: 14px;
    }

    .summary_section li .price {
        font-size: 16px;
    }

    .summary_section .summary_repeat.payment {
        background: #fff url(./images/payment-back-mobile.png) right top no-repeat;
        background-size: 30%;
    }

    .summary_section .summary_content.summary {
        background: #fff url(./images/share-card-bg.png) right bottom no-repeat;
        background-size: 50%;
        min-height: 250px;
    }

    .summary_section .summary form {
        display: block;
    }

    .consumer-card .cards {
        padding-top: 25px;
    }

    .contentarea .content_sec.consumer-card .cards .order_card {
        margin-bottom: 0px;
    }

    .summary_section .summary_repeat {
        max-width: 100%;
    }

    .order_card.card_front .card_footer {
        font-size: 8px;
    }

    .contentarea .content_sec .affiliate_refferal .table_footer .pagination li.numbers {
        min-width: 16px;
        height: 16px;
        margin-right: 2px;
        margin-left: 2px;
        min-height: 16px;
    }

    .contentarea .content_sec .affiliate_refferal .table_footer .pagination {
        align-items: center;
    }

    .contentarea .content_sec .affiliate_refferal .table_body {
        padding-top: 30px;
    }

    .contentarea .content_sec .affiliate_refferal .table_footer .pagination li.numbers a {
        font-size: 11px;
    }

    .contentarea .content_sec .affiliate_refferal .table_footer .pagination li.numbers.active {
        border-radius: 50%;
        background: #000000;
    }

    .contentarea .content_sec .affiliate_refferal .table_footer {
        bottom: auto;
        background-color: inherit;
        box-shadow: none;
        justify-content: flex-start;
        top: 0px;
    }

    .contentarea .content_sec .affiliate_refferal .table_area {
        position: relative;
    }

    .active_refs .pagination {
        margin-top: 5px;
    }

    .tab1 .rep, .tab2 .rep {
        padding: 0 15px 20px 15px;
        display: block;
    }

    .active_refs .rep-download {
        margin: 27px 15px 0 0;
    }

    .active_refs .tab {
        padding-top: 15px;
        padding-left: 15px;
        float: none;
        width: 80%;
        overflow: hidden;
    }

    .contentarea .content_sec .refferals_list.active_refs h3 {
        padding: 15px 15px 5px 15px;
    }

    .contentarea .content_sec .claim_subheading {
        padding-left: 17px;
        padding-right: 17px;
    }

    .discount_card_sec_signup .cards_area .card_page_content .card {
        max-width: 100%;
    }

    .discount_card_sec_signup .cards_area .card_page_content {
        padding: 0px;
        margin: 0px;
    }

    .discount_card_sec_signup .cards_area .card_page_content .card .cards .order_card:last-of-type {
        margin-bottom: 0px;
    }

    .discount_card_sec_signup {
        padding-bottom: 0px;
    }

    .reportPage.user-transact .admin_report_page .content_sec .content .transact .same-on {
        width: 100%;
    }

    .filterNewDesign2 .filterDropdown2 {
        width: 130px;
    }

    .searchSection.add-over {
        width: 60% !important;
    }

    .transaction .daterangepicker.ltr.show-calendar.opensright, .analyticPage .daterangepicker.ltr.show-calendar.opensright, .reportPage .daterangepicker.ltr.show-calendar.opensright {
        right: 40px !important;
        overflow-y: initial;
    }

    .data-not-found {
        line-height: 130px;
    }

    .filtered_tag_selected {
        padding-left: 15px;
    }

    #myInputautocomplete-list {
        width: 100%;
    }

    .tool-section .tableheader div {
        line-height: normal;
    }

    .tool-section.list .mainList {
        max-height: 75px;
    }

    #dashboard-container .widget {
        min-height: 260px;
    }

    .barlist .custom-scroll, .list .custom-scroll {
        height: 250px;
    }

    .bar.oList .export_icon, .bar.oList .onlyList {
        top: 35px;
    }

    .bar.oList .mobileExportLabel {
        top: 33px;
    }

    .bar.oList .flip-icons {
        height: 65px;
    }

    #dashboard-container .widget {
        width: 100% !important;
        margin-left: 0px;
    }

    .loader-section, .list-only .loader-section {
        top: 7px;
        right: 32px;
    }

    .claimSection .paid span.toolTipContent {
        top: -12px;
        right: 31px;
    }

    .claimSection .paid span.toolTipContent::after {
        bottom: calc(50% - 3px);
        transform: rotate(-90deg);
        right: -10px;
    }

    .flip-section .multibar .transitions .bold {
        font-size: 15px;
    }

    .multibar .myBarChart {
        margin-top: 45px;
        height: 135px !important;
    }

    .multibar .myBarChart canvas {
        width: 100% !important;
        height: 100% !important;
    }

    .PieChart {
        margin: auto;
        width: 155px !important;
        height: 155px !important;
    }

    .bar.multiline .myChart {
        height: 115px !important;
    }

    .bar.linelist .myChart {
        height: 160px !important;
    }

    .tool-section ul.pagination {
        float: left;
    }

    .mobile-scrolling-all::-webkit-scrollbar {
        background-color: #fff;
        height: 6px !important;
    }

    .max-height::-webkit-scrollbar-thumb, .mobile-scrolling-all::-webkit-scrollbar-thumb {
        border-radius: 20px;
        background-color: #dde5ed;
    }

    .tool-section .mainList {
        max-height: 83px;
    }

    .mobile-scrolling-all {
        overflow: auto;
        overflow-y: auto;
    }

    .mobile-scrolling-all > div {
        min-width: 800px;
        position: relative;
        overflow: hidden;
    }

    .dashboard-content-parent .newBoard .barlist .custom-scroll, .dashboard-content-parent .newBoard .list .custom-scroll {
        height: 250px;
    }

    #dashboard-container .widget.barlist {
        min-height: 260px;
    }

    .myBarChart canvas, .myChart .chartjs-render-monitor {
        height: 175px !important;
    }

    .bar_chart_icon, .bar_chart_icon.inactive, .flip-icons, .flip_icon, .listing-icon, .listing-icon activeicon, .mobileBarLabel, .mobileExportLabel, .mobileListLabel {
        display: none;
    }

    .bar_chart_icon, .bar_chart_icon.inactive, .listing-icon.activeicon {
        display: none !important;
    }

    .bar .export_icon {
        display: none;
    }

    .mobileExportLabel {
        position: absolute;
        top: 65px;
        right: 50px;
        z-index: 99999;
    }

    .bar .onlyList {
        top: 66px;
        right: 106px;
        z-index: 99999;
    }

    .flip-icons label, .mobileExportLabel {
        font-size: 15px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 22px;
        letter-spacing: normal;
        color: rgba(0,0,0,.87);
        padding-left: 7px;
    }

    .bar_chart_icon, .bar_chart_icon.inactive, .listing-icon.activeicon {
        display: none !important;
    }

    .flip-icons a {
        float: left;
    }

    .flip-icons .custom-tooltip:first-child {
        margin-right: 0px;
        width: auto;
    }

    .mobile_bar_icon, .mobile_list_icon {
        display: none;
        float: left;
    }

    .flip-icons {
        width: 128px;
        height: 96px;
        border-radius: 4px;
        -webkit-box-shadow: 0 8px 8px 0 rgba(0,0,0,.24), 0 0 8px 0 rgba(0,0,0,.12);
        box-shadow: 0 8px 8px 0 rgba(0,0,0,.24), 0 0 8px 0 rgba(0,0,0,.12);
        border: 1px solid #dde5ed;
        background-color: #fff;
        padding: 17px 16px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        z-index: 9;
    }

    .bar .flip-icons {
        display: none;
        z-index: 9999;
        top: 12px;
        right: 14px;
    }

    .mobileListIcon {
        position: absolute;
        top: 17px;
        right: 16px;
    }

    .mobileListIcon .dot {
        width: 4px;
        height: 4px;
        border-radius: 4px;
        background-color: #000000;
        text-indent: -9999px;
        display: block;
        margin-bottom: 2px;
    }

    .mobileListIcon {
        display: block;
    }

    .transaction .daterangepicker.ltr.show-calendar.opensright, .analyticPage .daterangepicker.ltr.show-calendar.opensright {
        left: 15px !important;
        right: auto !important;
        margin-left: 0px !important;
        overflow: visible;
    }

    .claimSection .paid {
        width: 100%;
        margin-bottom: 2%;
    }

    .claimSection .paid.last {
        margin-bottom: 0px;
    }

    .claimSection {
        display: block;
    }

    #dashboard-container .widget {
        margin-top: 2%;
    }

    .react-bootstrap-daterangepicker-container {
        width: 100%;
        margin-bottom: 10px;
    }

    .dashboard-menu .searchSection .form-control, .dashboard-menu .searchSection {
        width: 100%;
    }

    .dashboard-menu .radio-button-section {
        margin-top: 10px;
        width: 100%;
        margin-left: 0px;
    }

    .dashboard-menu {
        padding-left: 15px;
        padding-right: 15px;
    }

    .training-header .action-icons {
        width: 110px;
        display: flex;
        justify-content: flex-end;
    }

    .addVideoBtn {
        width: 100%;
    }

    .trainingpage_header .btn.red-btn {
        font-size: 14px;
        padding: 12px 14px;
        margin-left: 0px;
        margin-right: 15px;
    }

    .training_section .training-header .action-icons > span.gap {
        margin-right: 5px;
    }

    .orderlist-section {
        margin-right: 10px;
    }

    .contentarea .content_sec .trainingpage_header h2 {
        width: 100%;
        display: block;
    }

    .contentarea .content_sec .tabular_box .table_body .table_row.user_page.main_data > div > div:nth-child(6):before {
        width: 100%;
    }

    .contentarea .content_sec .dist_popup .tabular_box .table_body {
        min-height: 230px;
        max-height: 230px;
    }

    .contentarea .content_sec .dist_popup .no_record_found {
        min-height: 230px;
    }

    .contentarea .content_sec .dist_popup .tabular_box .table_body .table_row {
        margin-bottom: 0px;
    }

    .contentarea .content_sec .resetgroup_box.dist_popup .add_url_pop > div {
        top: 50px;
        max-height: 400px;
    }

    .contentarea .content_sec .dist_popup .tabular_box .table_header.user_page.tab div {
        padding-right: 25px;
        font-size: 13px;
    }

    .Dashboard_Area.admin_user_page.new .contentarea .content_sec .header_bard_button {
        height: 42px;
    }

    .new .contentarea .content_sec .header_bard_button .tt_card {
        margin-left: 17px;
    }

    .admin_user_page .contentarea .content_sec .tabular_box .table_body .table_row.user_page > div > div:nth-child(3) {
        max-width: 50%;
    }

    .contentarea .content_sec .tabular_box .table_body .table_row > div > div:nth-child(5) {
        margin-left: 0px;
    }

    .admin_user_page .contentarea .content_sec .tabular_box .table_body .table_row.user_page > div > div:nth-child(6) {
        max-width: 50%;
    }

    .summary_card h2 {
        font-size: 20px !important;
    }

    .summary_card .summary_card_header a {
        width: 185px;
    }

    .order_card_wrapper > a {
        width: 100%;
    }

    .Transactions_Summary .order_card_wrapper .order_card {
        width: 100%;
    }

    .order_card.card_front .contact_info {
        font-size: 12px;
    }

    .pro-modal .contentarea .content_sec .accordion_content .panel[aria-expanded='true'] .panel__content {
        font-size: 14px;
        line-height: 20px;
    }

    .contentarea .content_sec .accordion_content h3 {
        padding-left: 15px;
    }

    .apple-modal .form-control {
        min-width: 60%;
    }

    .contentarea .content_sec .tabular_box .table_body {
        padding-top: 38px;
    }

    .contentarea .content_sec .header_bard_button .add_group_no_btn.usersarea.loader:before {
        right: 159px;
    }

    .admin_user_page .contentarea .content_sec .tabular_box .table_body .table_row.user_page > div > div.toggle-switch:before {
        width: 60%;
    }

    .reportPage .admin_report_page .content_sec .content .transact .same-on {
        width: 90%;
    }

    .contentarea .content_sec .resetgroup_box .add_url_pop.payment_popup > div.Mask.intro-kit {
        width: 100% !important;
    }

    .intro-kit .right-view {
        margin-left: 0px;
    }

    .intro-kit .right-view {
        width: 100%;
    }

    .summary_section .intro-kit h3 {
        font-size: 18px;
    }

    .contentarea.signup-popup.order-section .intro-kit .scroll-section {
        max-height: 540px;
    }

    .order-section .intro-kit .first-name, .order-section .intro-kit .last-name {
        width: 100%;
    }

    .order-section .intro-kit .two-column {
        display: block;
    }

    .order-section .intro-kit .same {
        margin-bottom: 15px;
    }

    .contentarea .content_sec .Account_info_form_parent > div .account-number span {
        margin-right: 0px;
        margin-bottom: 10px;
        display: block;
    }
}

@media screen and (min-width: 0) and (max-width: 480px) {
    .contentarea .content_sec .resetgroup_box .order-section .add_url_pop > div .body {
        padding-top: 8px !important;
    }

    .edit-payment-popup-active {
        overflow-y: scroll !important;
    }

    .widget .data-not-found {
        line-height: 180px;
        min-height: inherit;
    }

    .transaction .daterangepicker.opensright:before, .analyticPage .daterangepicker.opensright:before {
        right: auto !important;
        left: 9px !important;
    }

    .transaction .daterangepicker.opensright:after, .analyticPage .daterangepicker.opensright:after {
        right: auto !important;
        left: 10px !important;
    }

    .daterangepicker .calendar-table th, .daterangepicker .calendar-table td {
        font-size: 13px;
        height: 22px;
    }

    .daterangepicker {
        height: 465px !important;
    }
}

.back-button {
    color: #EE934A;
    font-weight: 500;
}

input[type="checkbox"] {
    appearance: none;

    &:before {
        width: 16px;
        height: 16px;
        content: '';
        border: 1px solid #000000;
        position: relative;
        display: inline-block;
        border-radius: 4px;
    }

    &:checked:before {
        border-color: #fe8d41;
        background: #fe8d41;
        content: '\2714';
        color: #fff;
        text-align: center;
        line-height: 16px;
    }
}

.consumer-card .order_card.card_back .card_body .col-6 {
    max-width: 200px;
    padding-left: 0;
}

.consumer-card .order_card.card_back .card_body .left_info {
    font-size: 10.5px;
}

@media screen and (max-width:335px) {
    .summary_card .summary_card_header a {
        padding: 9px 10px 8px;
        margin-top: -8px;
    }

    .summary_card .summary_card_header h2 {
        padding-left: 0;
    }
}

@media screen and (max-width:767.2px) {
    .dashboard-content-parent .newBoard #dashboard-container {
        padding: 0 20px;
    }
}

@media screen and (max-width:352px) {
    .orderlist-section {
        margin-left: 0;
        margin-bottom: 10px;
    }

    .trainingpage_header .btn.red-btn {
        margin-left: 20px;
    }

    .consumer_summary_card .order_card {
        .card_logo {
            max-width: 180px !important;
        }

        &.card_front .card_header h2 {
            font-size: 16px !important;
        }

        .card_info div, .card_info div span, .memberid span, .memberid {
            font-size: 18px !important;
        }

        &.card_front p {
            font-size: 14px;
        }
    }
}

@media screen and (max-width:1310px) and (min-width:1024px) {
    .contentarea .content_sec.consumer-card .cards .order_card {
        max-width: 60%
    }
}

@media screen and (min-width: 0) and (max-width: 1280px) {
    .order_card.card_front .card_footer, .order_card.card_back .card-footer .right_info {
        font-size: 12px;
    }

    .order_card.card_back .card_body .left_info {
        font-size: 10px;
    }
}

.AddPayment .cart-button {
    display: flex;
    align-items: center;

    svg {
        width: 20px;
        margin-left: 5px;
    }
}

.print-order-cards {
    .all_cards {
        padding: 0;
        background: transparent;

        .cards {
            flex-direction: column;

            @media screen and (min-width: 0px) and (max-width: 420px) {
                gap: 20px;
            }

            .order_card {
                margin-bottom: 0;
                max-width: 100%;
                min-width: 500px;
            }
        }
    }
}

.contentarea .content_sec .resetgroup_box .order-section .add_url_pop.ordercard_payment_modal {
    .Mask {
        width: 500px !important;
        max-width: 100%;

        .body {
            padding: 0 30px !important;
        }

        .right-view {
            width: 100%;
        }

        #card-container {
            width: 100%;
        }

        .yes_btn.loader {
            pointer-events: none;
            position: relative;
            display: flex;
            align-items: center;
            transition: all .2s ease-in-out;
            width: auto;
            min-width: 188px;
            justify-content: space-around;

            &.make-payment {
                min-width: 140px;
            }

            &:after {
                content: "";
                border: 4px solid #EE934A;
                border-radius: 50%;
                border-top: 4px solid #fff;
                width: 25px;
                height: 25px;
                -webkit-animation: .5s linear infinite spin;
                animation: .5s linear infinite spin;
                position: relative;
                right: 10px;
                order: 2;
            }
        }
    }

    .pricing {
        margin-bottom: 10px;

        strong {
            font-weight: 700;
        }
    }
}

.ordered-cards {
    width: 1086px;

    .card-front {
        position: relative;

        .groupNumber {
            position: absolute;
            right: 98px;
            bottom: 174px;
            font-size: 62px;
            color: #ffffff;
        }
    }
}

.contentarea .content_sec .order_cards_form {
    &.Account_info_form_parent {
        padding: 0;
        box-shadow: none;

        .resetgroup_box {
            margin: 0;

            > div {
                margin: 0;
                width: auto;

                .signup-popup.order-section {
                    margin: 0;
                    width: auto;

                    .content_sec {
                        padding: 0;
                    }
                }
            }
        }
    }
}
